import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../../state/auction/auctionActions";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Catalogs = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [value1, setValue1] = useState();
    const [addValue1, setAddValue1] = useState();

    const handleChange1 = (e) => {
        setValue1(e.target.value);

        if(e.target.value === 'Zamawiający nie wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych'){
            setAddValue1(1);
        }else if(e.target.value === 'Zamawiający nie dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych'){
            setAddValue1(2);
        }else if(e.target.value === 'Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych'){
            setAddValue1(3);
        }
    }

    let form1;

    if (addValue1 === 1){
        form1 =
            <>

            </>
    }else if (addValue1 === 2 || addValue1 === 3){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Informacje dotyczące formatu, parametrów wykorzystywanego sprzętu elektronicznego oraz technicznych warunków i specyfikacji połączenia dotyczących przekazywania zamawiającemu katalogu elektronicznego
                        </FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionItemObject.infoCatalogs) : ''}
                            name="infoCatalogs"
                            value={form.infoCatalogs}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            required
                            label="Informacje dotyczące sprzętu elektronicznego"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={form.ifCatalogs} name="ifCatalogs">
                            <RadioGroup name="ifCatalogs" defaultValue={edit === 'edit' && auctionItemObject.ifCatalogs} onChange={(e) => {setValue(e); handleChange1(e)}}>
                                <FormControlLabel value="Zamawiający nie wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający nie wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                                <FormControlLabel value="Zamawiający dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                                <FormControlLabel value="Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Catalogs;