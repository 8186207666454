import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";
import {fetchAuctionItemId} from "../../services/fetchId";
import {useSelector} from "react-redux";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const WebPages = ({id}) => {

    const [auctionItem, setAuctionItem] = useState('');
    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0])
            }
        )
    }, [])

    const classes = useStyles();

    return(
        <AnimationRevealPage>
            <ValidatorForm >
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres strony internetowej postępowania
                            <Tooltip title="Adres strony internetowej, na której udostępniane będą zmiany i wyjaśnienia treści SWZ oraz inne dokumenty zamówienia bezpoœrednio związane z postępowaniem o udzielenie zamówienia"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionItem.urlDocuments}
                            name="urlTools"
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres dodatkowej strony internetowej
                            <Tooltip title="Adres strony internetowej, pod którym wykonawca będzie mógł uzyskać dostęp do narzędzii urządzeñ lub formatów plików, które nie są ogólnie dostępne"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionItem.urlTools}
                            variant="outlined"
                        />
                </Form>

            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default WebPages