import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import {MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Input from '@material-ui/core/Input';
import FileInput from "./FileInput";
import {PrimaryButton} from "./misc/Buttons";
import HintsInfo from "./HintsInfo";
import Hints from "./Hints";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../state/auction/auctionActions";
import Button from "@material-ui/core/Button";
import {fetchAuctionId, fetchAuctionItemId, fetchHint} from "../services/fetchId";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "./StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3 flex justify-center`;
const ButtonFill = tw(PrimaryButton)`text-lg p-6`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        // textAlign: 'justify', // DH
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const MarketCons = (props) => {

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const classes = useStyles();

    const [marketCons, setMarketCons] = useState(false);
    const [addInput, setAddInput] = useState(false);
    const [ifMarketCons, setIfMarketCons] = useState(false);
    const [addIfInput, setAddIfInput] = useState(false);
    const [auctionItem, setAuctionItem] = useState();
    const [marketConsVal, setMarketConsVal] = useState('');
    const [marketConsForm, setMarketConsForm] = useState();
    const [ifMarketConsVal, setIfMarketConsVal] = useState('');
    const [ifMarketConsForm, setIfMarketConsForm] = useState();
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(false);

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )

        if(!!auctionItem){
            setMarketConsVal(auctionItem.marketCons.toString())
        }
    }, [])

    useEffect(() => {
        setMarketConsForm(
            <Form className={classes.label}>
                <FormControl value={form.marketCons}>
                    <FormLabel className={classes.label}>Czy przeprowadzono wstępne konsultacje rynkowe
                    </FormLabel>
                    <RadioGroup  name="marketCons" value={marketConsVal} onChange={(e) => {setBooleanValue(e); handleChange(e); setMarketConsVal(e.target.value)}}>
                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setIfMarketConsForm(
            <Form className={classes.label}>
                <FormControl value={form.ifMarketCons}>
                    <FormLabel className={classes.label}>Czy chcesz przeprowadzić wstępne konsultacje rynkowe?
                    </FormLabel>
                    <RadioGroup value={ifMarketConsVal} className={classes.input} name="ifMarketCons" onChange={(e) => {setBooleanValue(e); handleChangeIf(e); setIfMarketConsVal(e.target.value)}}>
                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
    }, [marketConsVal, ifMarketConsVal])

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const handleChange = (e) => {
        setMarketCons(e.target.value);

        if(e.target.value === 'false'){
            setAddInput(true);
        }else if(e.target.value === 'true'){
            setAddInput(false);
        }
    }

    const handleChangeIf = (e) => {
        setIfMarketCons(e.target.value);

        if(e.target.value === 'false'){
            setAddIfInput(false);
            setShow1(false);
        }else if(e.target.value === 'true'){
            setShow1(true);
            setAddIfInput(true);
        }
    }

    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[12].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }else if(show1 && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[13].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow1(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    return (
        <React.Fragment>
            <AnimationRevealPage>
                <ValidatorForm >
                    {props.edit === true && !!auctionItem ? (
                        <>
                            {marketConsForm}
                            {marketConsVal === 'false' ? (
                                <React.Fragment>
                                    {ifMarketConsForm}
                                    {ifMarketConsVal === 'true' ? (
                                        <React.Fragment>
                                            <Form className={classes.label}>
                                                <ButtonFill>Uzupełnij wzór zaproszenia do <br/> wstępnych konsultacji rynkowych</ButtonFill>
                                            </Form>
                                        </React.Fragment>


                                    ) : (
                                        ''
                                    )}
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        <div onMouseOver={handleClick}>
                            <StackedSnackbar/>
                            <Form className={classes.label}>
                                <FormControl value={form.marketCons} className={classes.label}>
                                    <FormLabel className={classes.label}>Czy przeprowadzono wstępne konsultacje rynkowe
                                    </FormLabel>
                                    <RadioGroup  name="marketCons" className={classes.input} onChange={(e) => {setBooleanValue(e); handleChange(e)}}>
                                        <FormControlLabel value='true' name="marketCons" control={<Radio required color="primary"/>} label="Tak" />
                                        <FormControlLabel value='false' name="marketCons" control={<Radio color="primary"/>} label="Nie" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>

                            {addInput ? (
                                <React.Fragment>
                                    <Form className={classes.label}>
                                        <FormControl value={form.ifMarketCons} className={classes.label}>
                                            <FormLabel className={classes.label}>Czy chcesz przeprowadzić wstępne konsultacje rynkowe?
                                            </FormLabel>
                                            <RadioGroup name="ifMarketCons" className={classes.input} onChange={(e) => {setBooleanValue(e); handleChangeIf(e)}}>
                                                <FormControlLabel value='true' name="ifMarketCons" control={<Radio required color="primary"/>} label="Tak" />
                                                <FormControlLabel value='false' name="ifMarketCons" control={<Radio color="primary"/>} label="Nie" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Form>
                                    {addIfInput ? (
                                        <React.Fragment>
                                            <Form className={classes.label}>
                                                <ButtonFill>Uzupełnij wzór zaproszenia do <br/> wstępnych konsultacji rynkowych</ButtonFill>
                                            </Form>
                                        </React.Fragment>


                                    ) : (
                                        ''
                                    )}
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    )

                    }
                    <Button
                        onClick={() => {props.handleBack(); handleDimissAll()}}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {props.handleNext(); handleDimissAll()}}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </React.Fragment>
    )
}

export default MarketCons;