import { Dashboard } from '@material-ui/icons';
import ErrorActivateAccountPage from '../pages/ErrorActivateAccountPage';
import Home from '../pages/Home';
import KnowledgePage from '../pages/KnowledgePage';
import Login from '../pages/Login';
import MyAccountReg from '../pages/MyAccountReg';
import Register from '../pages/Register';
import SuccessActivateAccountPage from '../pages/SuccessActivateAccountPage';
import Verification from '../pages/Verification';
import RegisterData from '../pages/RegisterData';
import React from 'react';
import { Redirect } from 'react-router-dom';
import RegisterDataContinued from "../pages/RegisterDataContinued";
import MyAccount from "../pages/MyAccount";
import Logout from "../pages/Logout";
import MyTenders from "../pages/MyTenders";
import PriorInfoNotice from "../pages/PriorInfoNotice";
import NewTender from "../pages/NewTender";
import ChoosingOrderType from "../pages/ChoosingOrderType";
import SoloOrder from "../pages/SoloOrder";
import JointOrder from "../pages/JointOrder"
import ProxyOrder from "../pages/ProxyOrder";
import MyProfile from "../pages/MyProfile";
import OrderData from "../pages/OrderData";
import EditOrderData from "../pages/EditOrderData";
import CentralOrder from "../pages/CentralOrder";
import OrderSpecification from "../pages/OrderSpecification";
import IdLoad from "../pages/IdLoad";
import OrderSpecificationParts from "../pages/OrderSpecificationParts";
import EditMyProfile from "../pages/EditMyProfile";
import FinalSteps from "../pages/FinalSteps";
import InProgress from "../pages/InProgress";
import PublicatedAuctions from "../pages/PublicatedAuctions";
import ClosedAuctions from "../pages/ClosedAuctions";
import EditSoloOrder from "../pages/Edits/EditSoloOrder";
import Users from "../pages/Users";
import EditUsersData from "../components/EditUsersData";
import LoginLoading from "../pages/LoginLoading";

// PROTECTED PAGES

const authProtectedRoutes = [
    { path: "/myAccountReg",  component: MyAccountReg },
    { path: "/registerData",  component: RegisterData  },
    { path: "/registerDataCd",  component: RegisterDataContinued  },
    { path: "/myAccount",  component: MyAccount },
    { path: "/logout",  component: Logout },
    { path: "/myTenders",  component: MyTenders },
    { path: "/priorInfoNotice",  component: PriorInfoNotice },
    { path: "/newTender",  component: NewTender },
    { path: "/choosingOrderType",  component: ChoosingOrderType },
    { path: "/soloOrder/:id",  component: SoloOrder },
    { path: "/jointOrder/:id",  component: JointOrder },
    { path: "/proxyOrder/:id",  component: ProxyOrder },
    { path: "/myProfile",  component: MyProfile },
    { path: "/orderData",  component: OrderData },
    { path: "/editOrderData",  component: EditOrderData },
    { path: "/centralOrder/:id",  component: CentralOrder },
    { path: "/orderSpecification/:edit/:id",  component: OrderSpecification },
    { path: "/orderSpecificationParts/:edit/:id/:part",  component: OrderSpecificationParts },
    { path: "/editMyProfile",  component: EditMyProfile },
    { path: "/finalSteps/:edit/:id",  component: FinalSteps },
    { path: "/inProgress",  component: InProgress },
    { path: "/editSoloOrder",  component: EditSoloOrder },
    { path: "/users",  component: Users },
    { path: "/editUsersData",  component: EditUsersData },
    { path: "/loginLoading",  component: LoginLoading },
    { path: "/publicatedAuctions",  component: PublicatedAuctions },
    { path: "/closedAuctions",  component: ClosedAuctions },
];

// PUBLIC PAGES

const publicRoutes = [

    { path: "/register", component: Register },
    { path: "/knowledge", component: KnowledgePage },
    { path: "/login", component: Login },
    { path: "/verification", component: Verification },
    { path: "/verify-success", component: SuccessActivateAccountPage },
    { path: "/verify-error", component: ErrorActivateAccountPage },
    { path: "/",   component: Home },

];

export { authProtectedRoutes, publicRoutes };
