import React from 'react';
import { SectionHeading, SubheadingBlack } from '../components/misc/Headings'
import { Info} from '../components/misc/Typography'
import tw from "twin.macro";
import { MdDone } from 'react-icons/md';
import { PrimaryLink as Links } from "../components/misc/Links";
import { Link } from "react-router-dom"

const Container = tw.div`flex flex-col justify-center items-center`
const Heading = tw(SectionHeading)``;
const SubHeading = tw(SubheadingBlack)`text-gray-800 text-3xl mt-2`;
const style = {
    fontSize: '6em',
}

const LinkNext = tw(Links)`uppercase mt-12 text-2xl`;


const RegisterFinished = () => {

    return(
        <React.Fragment>
            <Container>
                <Heading>Gratulacje!</Heading>
                <MdDone style={style}/>
                <SubHeading>Rejestracja danych została zakończona</SubHeading>
                <LinkNext>
                    <Link to="/MyAccount">Rozpocznij pracę w systemie</Link>
                </LinkNext>
            </Container>

        </React.Fragment>
    )
}

export default RegisterFinished;