import React, {useState} from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import tw from "twin.macro";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setValues, setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const AdditionalRequirementsPartI = ({no, edit, id, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifDisabilityRequirements === true && 2));
    const [addValue2, setAddValue2] = useState(edit === 'edit' && (auctionObject.ifPerformanceRequirements === true && 2));
    const [addValue3, setAddValue3] = useState(edit === 'edit' && (auctionObject.ifEtiqRequirements === true && 2));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }
    const handleChange2 = (e) => {
        if(e.target.value === 'false'){
            setAddValue2(1);
        }else if(e.target.value === 'true'){
            setAddValue2(2);
        }
    }
    const handleChange3 = (e) => {
        if(e.target.value === 'false'){
            setAddValue3(1);
        }else if(e.target.value === 'true'){
            setAddValue3(2);
        }
    }

    let form1;
    let form2;
    let form3;

    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Wymagania w zakresie dostępności dla osób niepełnosprawnych</FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.disabilityRequirements) : ''}
                            value={formAuctionParts.disabilityRequirements}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            className={classes.input}
                            name="disabilityRequirements"
                            type="text"
                            id="outlined-basic-u"
                            required
                            label="Wymagania w zakresie dostępności dla osób niepełnosprawnych"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
            </>
    }

    if (addValue2 === 2){
        form2 =
            <>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności</FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.performanceRequirements) : ''}
                            value={formAuctionParts.performanceRequirements}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            className={classes.input}
                            type="text"
                            name="performanceRequirements"
                            id="outlined-basic-u"
                            required
                            label="Wymagania dotyczące wydajności lub funkcjonalności"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
            </>
    }

    if (addValue3 === 2){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności</FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.etiqRequirements) : ''}
                            value={formAuctionParts.etiqRequirements}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            name="etiqRequirements"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            required
                            label="Wymagania dotyczące etykiety"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
            </>
    }

    return(
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl value={formAuctionParts[no].ifDisabilityRequirements.toString()} className={classes.label} name="ifDisabilityRequirements">
                            <FormLabel className={classes.label}>Wymagania w zakresie dostępności dla osób niepełnosprawnych
                                <Tooltip title="w zakresie dostępności dla osób niepełnosprawnych oraz projektowania z przeznaczeniem dla wszystkich użytkowników, zgodnie z art. 100 ust. 1 ustawy, jeżeli zamawiający je przewiduje"
                                         placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <RadioGroup name="ifDisabilityRequirements" defaultValue={edit === 'edit' && auctionObject.ifDisabilityRequirements.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań dostępności dla osób niepełnosprawnych i przeznaczenia dla wszystkich użytkowników." />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania w zakresie dostępności dla osób niepełnosprawnych i przeznaczenia dla wszystkich użytkowników." />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                    <Form className={classes.label}>
                        <FormControl value={formAuctionParts[no].ifPerformanceRequirements.toString()} className={classes.label} name="ifPerformanceRequirements">
                            <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności
                                <Tooltip title="Informacja o wymaganiach dotyczących wydajności lub funkcjonalności, zgodnie z art. 101 ust. 1 ustawy"
                                         placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <RadioGroup name="ifPerformanceRequirements" defaultValue={edit === 'edit' && auctionObject.ifDisabilityRequirements.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange2(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań dotyczących wydajności lub funkcjonalności." />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania dotyczące wydajności lub funkcjonalności." />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form2}
                    <Form className={classes.label}>
                        <FormControl value={formAuctionParts[no].ifEtiqRequirements.toString()} className={classes.label} name="ifEtiqRequirements">
                            <FormLabel className={classes.label}>Wymagania dotyczące etykiety
                                <Tooltip title="Informacja czy zamawiający żąda określonej etykiety lub wskazuje mające zastosowanie wymagania określonej etykiety, zgodnie z art. 104 ustawy"
                                         placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <RadioGroup  name="ifEtiqRequirements" defaultValue={edit === 'edit' && auctionObject.ifEtiqRequirements.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange3(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie żąda okreslonej etykiety i nie wskazuje wymagań określonej etykiety, zgodnie z art. 104 ustawy." />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający żąda okreslonej etykiety i wskazuje mające zastosowanie wymagania określonej etykiety, zgodnie z art. 104 ustawy." />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form3}
                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}

export default AdditionalRequirementsPartI