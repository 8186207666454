import React, {useEffect, useMemo, useRef, useState} from "react";
import tw from "twin.macro";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import styled from "styled-components";
import {Info} from '../misc/Typography'
import {useStyles} from "../materialUI/Stepper/StepperStyles";
import {
   fetchUser
} from "../../services/fetchId";
import {Link} from "react-router-dom";


const Form = tw.div`mb-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl`;
const FormButton = tw.div`mb-5 mx-5 flex justify-center`;
const SubmitButton = styled.button`
  ${tw`my-3 w-32 font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;


const EditMyProfileForm = ({...props}) => {

    const [user, setUser] = useState([])
    const [email, setEmail] = useState([])
    const [username, setUsername] = useState([])
    const userId = localStorage.getItem('userId');

    useEffect(() => {

        fetchUser(userId).subscribe( data => {
            setUser(data)}
        )}, []
    )

    useEffect(() => {
        fetchUser().subscribe(data => {
            setUser(data)
        })


    }, [])

    const emailRef = useRef(null);
    const usernameRef = useRef(null);

    const classes = useStyles();

    const onSubmit = () => {
        const data = {
            email: emailRef.current.value,
            username: usernameRef.current.value,
        }

        props.onSubmit(data);
    };

    let form;

    if(!!user.username){
        form =
            <>
                <ValidatorForm onSubmit={onSubmit}>
                <Form>
                    <TextValidator
                        defaultValue={user.username}
                        inputRef={usernameRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="Nazwa Użytkownika"
                        variant="outlined"
                        placeholder="Nazwa Użytkownika"
                        required/>
                </Form>
                <Form>
                    <TextValidator
                        defaultValue={user.email}
                        inputRef={emailRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="Adres Email"
                        variant="outlined"
                        placeholder="Adres Email"/>
                </Form>
                    <TwoColumn>
                        <FormButton>
                            <Link to="/myAccount">
                                <SubmitButton>
                                    Cofnij
                                </SubmitButton>
                            </Link>
                        </FormButton>
                        <FormButton>
                            <SubmitButton onSubmit={onSubmit} type="submit">
                                Zapisz
                            </SubmitButton>
                        </FormButton>
                    </TwoColumn>
            </ValidatorForm>
        </>
    } else {
        form = 'Nieznany błąd użytkownika'
    }


    return (
        <>
            {form}
        </>
    )
}

export default EditMyProfileForm;