import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {fetchHint2} from "../../services/fetchId";
import StackedSnackbar from "../StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3`;

const EvaluationPartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.criterion === 'Kryteriów jakościowych oraz ceny lub kosztu' ? 1 : 2));
    const [addValue2, setAddValue2] = useState(edit === 'edit' && (auctionObject.criterion3 === 'Kryteriów jakościowych oraz ceny' ? 1 : 2));
    const [addValue4, setAddValue4] = useState(edit === 'edit' && (auctionObject.criterion2 === 'Kryterium ceny' ? 1 : 2));
    const [value3, setValue3] = useState();
    const [addValue3, setAddValue3] = useState();

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint2().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[0].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    const handleChange1 = (e) => {
        if(e.target.value === 'Kryteriów jakościowych oraz ceny lub kosztu'){
            setAddValue1(1);
        }else if(e.target.value === 'Kryterium ceny lub kosztu'){
            setAddValue1(2);
        }
    }

    const handleChange2 = (e) => {
        if(e.target.value === 'Kryteriów jakościowych oraz ceny'){
            setAddValue2(1);
        }else if(e.target.value === 'Kryteriów jakościowych oraz kosztu'){
            setAddValue2(2);
        }
    }

    const handleChange3 = (e) => {
        setValue3(e.target.value);

        if(e.target.value === '1'){
            setAddValue3(1);
        }else if(e.target.value === '2'){
            setAddValue3(2);
        }
    }

    const handleChange4 = (e) => {
        if(e.target.value === 'Kryterium ceny'){
            setAddValue4(1);
        }else if(e.target.value === 'Kryterium kosztu'){
            setAddValue4(2);
        }
    }


    let form1;
    let form2;
    let form3;
    let form4;



    if(addValue3 === 1){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kryterium</FormLabel>
                        <TextValidator
                            value={formAuctionParts.criterion4}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight4) : ''}
                            name="criterion4"
                            type="text"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Kryterium"
                            placeholder="Kryterium"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga:</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight7}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight7) : ''}
                            type="number"
                            name="weight7"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
            </>
    }

    if(addValue4 === 1){
        form4 =
            <>
                <Typography className={classes.nameSec}>Kryterium ceny</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: Cena </FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight5}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight5) : ''}
                            name="weight5"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
            </>
    }else if(addValue4 === 2){
        form4 =
            <>
                <Typography className={classes.nameSec}>Kryterium kosztu</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: Cena </FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight5}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight5) : ''}
                            name="weight5"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: Cena"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: Koszt użytkowania </FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight6}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight6) : ''}
                            name="weight6"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga Koszt użytkowania"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <RadioGroup value={value3} onChange={handleChange3}>
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Inne" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }

    if (addValue2 === 1){
        form2 =
            <>
                <Typography className={classes.nameSec}>Kryterium ceny</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: cena</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight5}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight5) : ''}
                            name="weight5"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: cena"
                            placeholder="Waga: cena"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Typography className={classes.nameSec}>Kryteria jakościowe</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: parametry techniczne</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight4}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight4) : ''}
                            name="weight4"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: parametry techniczne"
                            placeholder="Waga: parametry techniczne"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: integracja zawodowa i społeczna</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight3}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight3) : ''}
                            name="weight3"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: integracja zawodowa i społeczna"
                            placeholder="Waga: integracja zawodowa i społeczna"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: efektywność energetyczna</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight2}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight2) : ''}
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: efektywność energetyczna"
                            placeholder="Waga: efektywność energetyczna"
                            variant="outlined"
                            name="weight2"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: innowacyjność</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight1}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight1) : ''}
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: innowacyjność"
                            placeholder="Waga: innowacyjność"
                            variant="outlined"
                            name="weight1"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <RadioGroup value={value3} onChange={handleChange3}>
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Inne" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }else if(addValue2 === 2){
        form2 =
            <>
                <Typography className={classes.nameSec}>Kryterium ceny</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: cena</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight5}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight5) : ''}
                            name="weight5"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: cena"
                            placeholder="Waga: cena"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: Koszt użytkowania</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight8}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight8) : ''}
                            name="weight8"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: Koszt użytkowania"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Typography className={classes.nameSec}>Kryteria jakościowe</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: parametry techniczne</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight4}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight4) : ''}
                            name="weight4"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: parametry techniczne"
                            placeholder="Waga: parametry techniczne"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: integracja zawodowa i społeczna</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight3}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight3) : ''}
                            name="weight3"
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: integracja zawodowa i społeczna"
                            placeholder="Waga: integracja zawodowa i społeczna"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: efektywność energetyczna</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight2}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight2) : ''}
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: efektywność energetyczna"
                            placeholder="Waga: efektywność energetyczna"
                            variant="outlined"
                            name="weight2"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Waga: innowacyjność</FormLabel>
                        <TextValidator
                            value={formAuctionParts.weight1}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.weight1) : ''}
                            type="number"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Waga: innowacyjność"
                            placeholder="Waga: innowacyjność"
                            variant="outlined"
                            name="weight1"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <RadioGroup value={value3} onChange={handleChange3}>
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Inne" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }

    if (addValue1 === 1){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].criterion3} name="criterion3">
                        <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                        <RadioGroup name="criterion3" defaultValue={edit === 'edit' && auctionObject.criterion3}  onChange={(e) => {handleChange2(e); setValueAuctionParts(e,no)}}>
                            <FormControlLabel value="Kryteriów jakościowych oraz ceny" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz ceny" />
                            <FormControlLabel value="Kryteriów jakościowych oraz kosztu" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz kosztu" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form2}
            </>
    }else if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].criterion2} name="criterion2">
                        <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                        <RadioGroup name="criterion2" defaultValue={edit === 'edit' && auctionObject.criterion2}  onChange={(e) => {handleChange4(e); setValueAuctionParts(e,no)}}>
                            <FormControlLabel value="Kryterium ceny" control={<Radio color="primary"/>} label="Kryterium ceny" />
                            <FormControlLabel value="Kryterium kosztu" control={<Radio color="primary"/>} label="Kryterium kosztu" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form4}
            </>
    }

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].criterion} name="criterion">
                            <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                            <RadioGroup name="criterion" defaultValue={edit === 'edit' && auctionObject.criterion}  onChange={(e) => {handleChange1(e); setValueAuctionParts(e,no)}}>
                                <FormControlLabel value="Kryteriów jakościowych oraz ceny lub kosztu" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz ceny lub kosztu" />
                                <FormControlLabel value="Kryterium ceny lub kosztu" control={<Radio color="primary"/>} label="Kryterium ceny lub kosztu" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Szczegółowy opis zastosowanych kryteriów i sposobu oceny ofert
                            </FormLabel>
                            <TextValidator
                                defaultValue={edit === 'edit' ? (auctionObject.criterion1) : ''}
                                className={classes.input}
                                value={formAuctionParts.criterion1}
                                onChange={(e) => setValueAuctionParts(e, no)}
                                type="text"
                                name="criterion1"
                                id="outlined-basic-u"
                                required
                                label="Szczegółowy opis zastosowanych kryteriów"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                </ValidatorForm>

            </AnimationRevealPage>
        </div>
    )
}


export default EvaluationPartI;