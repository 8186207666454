import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {NonActiveButton, NotActiveButton, PrimaryButton} from "../misc/Buttons";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {setValues} from "../../state/auction/auctionActions";
import {useDispatch, useSelector} from "react-redux";
import {fetchAuctionItemId, fetchAuctionWithId} from "../../services/fetchId";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const PartsForOne = ({edit, id}) => {

    const dispatch = useDispatch();

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const classes = useStyles();
    const [value1, setValue1] = useState();
    const [value2, setValue2] = useState();
    const [value3, setValue3] = useState();
    const [addValue1, setAddValue1] = useState();
    const [addValue2, setAddValue2] = useState();
    const [addValue3, setAddValue3] = useState();
    const [joinedPartForm, setJoinedPartForm] = useState();
    const [oneExecutorPartForm, setOneExecutorPartForm] = useState();
    const [joinedPartVal, setJoinedPartVal] = useState('');
    const [oneExecutorPartVal, setOneExecutorPartVal] = useState('');
    const [auction, setAuction] = useState('');
    const [auctionItem, setAuctionItem] = useState('');

    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => {
                setAuction(data);
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )

        if(!!auctionItem){
            setJoinedPartVal(auctionItem.joinedPart.toString())
            setOneExecutorPartVal(auctionItem.oneExecutorPart.toString())
        }
    },[])

    useEffect(() => {
        setJoinedPartForm(
            <Form className={classes.label}>
                <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                    <RadioGroup value={joinedPartVal} name="joinedPart" onChange={(e) => {setBooleanValue(e); handleChange3(e); setJoinedPartVal(e.target.value)}}>
                        <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                        <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setOneExecutorPartForm(
            <Form className={classes.label}>
                <FormControl value={form.oneExecutorPart} className={classes.label} name="oneExecutorPart">
                    <RadioGroup value={oneExecutorPartVal} name="oneExecutorPart" onChange={(e) => {setBooleanValue(e); handleChange2(e); setOneExecutorPartVal(e.target.value)}}>
                        <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na wszystkie części" />
                        <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na określoną ilość części" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )

    }, [joinedPartVal, oneExecutorPartVal])

    const handleChange1 = (e) => {
        setValue1(e.target.value);

        if(e.target.value === '1'){
            setAddValue1(1);
        }else if(e.target.value === '2'){
            setAddValue1(2);
        }else if(e.target.value === '3'){
            setAddValue1(3);
        }
    }

    const handleChange2 = (e) => {
        setValue2(e.target.value);

        if(e.target.value === "true"){
            setAddValue2(1);
        }else if(e.target.value === "false"){
            setAddValue2(2);
        }
    }

    const handleChange3 = (e) => {
        setValue3(e.target.value);

        if(e.target.value === "true"){
            setAddValue3(1);
        }else if(e.target.value === "false"){
            setAddValue3(2);
        }
    }

    const handleChange4 = (e) => {
        setValue3(e.target.value);

        if(e.target.value === "true"){
            setAddValue3(1);
        }else if(e.target.value === "false"){
            setAddValue3(2);
        }
    }

    const handleChange5 = (e) => {
        setValue3(e.target.value);

        if(e.target.value === "true"){
            setAddValue3(1);
        }else if(e.target.value === "false"){
            setAddValue3(2);
        }
    }




    let form1;
    let form2;
    let form3;

    if(addValue3 === 2){
        if(edit){
            form3 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Do których części zamawiający zastrzega sobie prawo do udzielenia łącznie </FormLabel>
                            <TextValidator
                                defaultValue={auction.numberJoinedPart}
                                value={form.numberJoinedPart}
                                onChange={setValue}
                                name="numberJoinedPart"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                required
                                label="Do których części"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                </>
        }else{
            form3 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Do których części zamawiający zastrzega sobie prawo do udzielenia łącznie </FormLabel>
                            <TextValidator
                                value={form.numberJoinedPart}
                                onChange={setValue}
                                name="numberJoinedPart"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                required
                                label="Do których części zamawiający zastrzega sobie prawo"
                                placeholder="Do których części zamawiający zastrzega sobie prawo"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                </>
        }


    }


    if(addValue2 === 1){
        if(edit){
            form2 =
                <>
                    {joinedPartVal}
                    {form3}
                </>
        }else{
            form2 =
                <>
                    <Form className={classes.label}>
                        <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                            <RadioGroup value={value3} name="joinedPart" onChange={(e) => {setBooleanValue(e); handleChange3(e)}}>
                                <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                                <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form3}
                </>
        }

    } else if (addValue2 === 2){
        if(edit){
            form2 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                            <TextValidator
                                value={form.maxNumberPart}
                                defaultValue={auctionItem.maxNumberPart}
                                onChange={setValue}
                                name="maxNumberPart"
                                className={classes.input}
                                type="number"
                                id="outlined-basic-u"
                                required
                                label="Maksymalna ilość części"
                                placeholder="Maksymalna ilość części"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Kryteria lub zasady, mające zastosowanie do ustalenia,
                                które części zamówienia zostaną udzielone jednemu wykonawcy, w przypadku
                                wyboru jego oferty w większej niż maksymalnej liczbie części.
                            </FormLabel>
                            <TextValidator
                                defaultValue={auctionItem.partRequirements}
                                value={form.partRequirements}
                                onChange={setValue}
                                name="partRequirements"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                required
                                placeholder="Kryteria i zasady"
                                label="Kryteria i zasady"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    {joinedPartForm}
                    {form3}
                </>
        }else{
            form2 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                            <TextValidator
                                value={form.maxNumberPart}
                                onChange={setValue}
                                name="maxNumberPart"
                                className={classes.input}
                                type="number"
                                id="outlined-basic-u"
                                required
                                label="Maksymalna ilość części"
                                placeholder="Maksymalna ilość części"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Kryteria lub zasady, mające zastosowanie do ustalenia,
                                które części zamówienia zostaną udzielone jednemu wykonawcy, w przypadku
                                wyboru jego oferty w większej niż maksymalnej liczbie części.
                            </FormLabel>
                            <TextValidator
                                value={form.partRequirements}
                                onChange={setValue}
                                name="partRequirements"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                required
                                placeholder="Kryteria i zasady"
                                label="Kryteria i zasady"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                            <RadioGroup value={value3} name="joinedPart" onChange={(e) => {setBooleanValue(e); handleChange4(e)}}>
                                <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                                <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form3}
                </>
        }

    }

    if (addValue1 === 1) {
        if(edit){
            form1 =
                <>
                    {oneExecutorPartForm}
                    {form2}
                </>
        }else{
            form1 =
                <>
                    <Form className={classes.label}>
                        <FormControl value={form.oneExecutorPart} className={classes.label} name="oneExecutorPart">
                            <RadioGroup value={value2} name="oneExecutorPart" onChange={(e) => {setBooleanValue(e); handleChange2(e)}}>
                                <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na wszystkie części" />
                                <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na określoną ilość części" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form2}
                </>
        }

    } else if (addValue1 === 2){
        if(edit){
            form1 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                            <TextValidator
                                defaultValue={auctionItem.maxNumberExecutorPart}
                                value={form.maxNumberExecutorPart}
                                onChange={setValue}
                                name="maxNumberExecutorPart"
                                className={classes.input}
                                type="number"
                                id="outlined-basic-u"
                                required
                                label="Maksymalna ilość części"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    {joinedPartForm}
                    {form3}
                </>
        }else{
            form1 =
                <>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                            <TextValidator
                                value={form.maxNumberExecutorPart}
                                onChange={setValue}
                                name="maxNumberExecutorPart"
                                className={classes.input}
                                type="number"
                                id="outlined-basic-u"
                                required
                                label="Maksymalna ilość części"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                            <RadioGroup value={value3} name="joinedPart" onChange={(e) => {setBooleanValue(e); handleChange5(e)}}>
                                <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                                <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form3}
                </>
        }

    }


    return(
        <AnimationRevealPage>
            <ValidatorForm>
                <Form className={classes.label}>
                    <FormControl value={form.amountPart} className={classes.label} name="amountPart">
                        <RadioGroup  value={value1} name="numberParts" onChange={(e) => {setValue(e); handleChange1(e)}}>
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Wykonawca może złożyć ofertę na wszystkie części zamówienia" />
                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Wykonawca może złożyć ofertę na określoną ilość części zamówienia" />
                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Wykonawca może złożyć ofertę tylko na jedną część zamówienia" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form1}

            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default PartsForOne