import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../../state/auction/auctionActions";
import {fetchAuctionId, fetchAuctionItemId} from "../../services/fetchId";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const BgInformation = ({edit, id}) => {

    const classes = useStyles();
    const [auctionItem, setAuctionItem] = useState('');

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auction => auction.auction.slice(14) === id))
            }
        )
    }, [])

    console.log(id);

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const formAuction = useSelector(state => state.auction.formAuction);

    console.log(form)
    console.log(formAuction)

    return(
        <AnimationRevealPage>
            <ValidatorForm >
                {edit ?
                    (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Informacje wprowadzające
                                <Tooltip title="Informacje pomocne do prawidłowego zrozumienia SWZ, np. objaśnienia użytw w specyfikacji określeń" placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <TextValidator
                                value={form.bgInfo}
                                defaultValue={auctionItem.bgInfo}
                                onChange={setValue}
                                className={classes.input}
                                name="bgInfo"
                                type="text"
                                id="outlined-basic-u"
                                required
                                label="Informacje wprowadzające"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    ): (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Informacje wprowadzające
                                <Tooltip title="Informacje pomocne do prawidłowego zrozumienia SWZ, np. objaśnienia użytw w specyfikacji określeń" placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <TextValidator
                                value={form.bgInfo}
                                onChange={setValue}
                                className={classes.input}
                                name="bgInfo"
                                type="text"
                                id="outlined-basic-u"
                                required
                                label="Informacje wprowadzające"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    )}

            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default BgInformation