import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {TextValidator} from "react-material-ui-form-validator";
import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionItemId, fetchAuctionItemPartsId} from "../../services/fetchId";

const Form = tw.div`mb-5 mx-5 mr-3 flex justify-center`;

const PartValue = props =>  {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),

        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        step: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '1%',
            marginLeft: '2%',
            marginRight: '2%',
            fontSize: '1.1em',
            textAlign: 'justify',
            color: 'black',
            width: '100%',
            lineHeight: '1.4',
        },
        labelJ: {
            marginBottom: '1%',
            marginLeft: '2%',
            marginRight: '2%',
            fontSize: '1.1em',
            color: 'black',
            width: '100%',
            lineHeight: '1.4',
        },
        labelRadio: {
            marginTop: '2%',
            marginBottom: '1%',
            fontSize: '1.1em',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        labelRadioM: {
            marginTop: '0%',
            marginBottom: '1%',
            fontSize: '1.1em',
            textAlign: 'left',
            color: 'black',
            width: '100% !important',
        },
        name: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%',
            width: '100% !important'
        },
        one: {
            width: '95%',
        },
    }));

    const classes = useStyles();

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();
    const i = props.i;


    const [valueWoVat, setValueWoVat] = useState();
    const [partValue, setPartValue ] = useState();
    const [partValueSim, setPartValueSim ] = useState();
    const [partValueOp, setPartValueOp ] = useState();
    const [supplyValue, setSupplyValue ] = useState();
    const [addPercentageParts, setAddPercentageParts ] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [auctionItem, setAuctionItem] = useState();

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )
    }, [])

    useEffect(() => {
        if(!!auctionItem){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].find(auctionItemParts => auctionItemParts.auctionItem.slice(19) === auctionItem['@id'].slice(19)))
                }
            )
        }
    }, [auctionItem])


    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);

    console.log(formAuctionParts)

    useEffect(() => {
        if(!!partValue && !!partValueSim && !!partValueOp && !!supplyValue){
            setValueWoVat((partValue + partValueSim + supplyValue + partValueOp).toFixed(2))
        }

        if(!!partValueSim && !!partValue){
            setAddPercentageParts(((partValueSim) / (partValue) * 100).toFixed(2) + '%')
        }

    }, [form])

    const setValueAuctionParts = (e) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i))
        console.log(formAuctionParts)
    }

    return (
        <>
            <Typography className={classes.nameSec}>Część {i+1}</Typography>
                {props.edit === true && !!auctionItemParts ? (
                    <>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość części {i+1} zamówienia podstawowego</FormLabel>
                                <TextValidator
                                    onChange={(e)=>{setPartValue(parseFloat(e.target.value.replace(",", ".")));
                                        setValueAuctionParts(e)}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].partValue}
                                    name="partValue"
                                    className={classes.input}
                                    type="text"
                                    defaultValue={auctionItemParts[i].partValue}
                                    id="outlined-basic-u"
                                    label={"Wartość " + (i+1) + " częsci"}
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość zamówień dodatkowych dla części {i+1} zamówienia
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    onChange={(e)=>{setPartValueSim(parseFloat(e.target.value.replace(",", ".")));
                                        setValueAuctionParts(e)}}
                                    value={formAuctionParts[i].partValueSim}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    defaultValue={auctionItemParts[i].partValueSim}
                                    type="text"
                                    id="outlined-basic-u"
                                    label={"Wartość zamówień podobnych" + (i+1) + " części"}
                                    variant="outlined"
                                    required
                                    name="partValueSim"/>
                                <TextValidator
                                    value={addPercentageParts}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                />
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość opcji dla części {i+1} zamówienia (jeśli dotyczy)
                                </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItemParts[i].partValueOp}
                                    onChange={(e)=>{setValueAuctionParts(e); setPartValueOp(parseFloat(e.target.value.replace(",", ".")))}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].partValueOp}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label={"Wartość opcji" + (i+1) + " części"}
                                    variant="outlined"
                                    required
                                    name="partValueOp"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy) <br/>
                                </FormLabel><br/>
                                <TextValidator
                                    defaultValue={auctionItemParts[i].valueSupplyPart}
                                    onChange={(e)=> {setValueAuctionParts(e); setSupplyValue(parseFloat(e.target.value.replace(",", ".")))}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].valueSupplyPart}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Wartość dostaw i usług"
                                    variant="outlined"
                                    required
                                    name="valueSupplyPart"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Całkowita wartość zamówienia bez VAT
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    disabled
                                    value={valueWoVat}
                                    name="valueWoVatPart"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Waluta
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Waluta"
                                    variant="outlined"
                                    disabled
                                    value='PLN'
                                    name="Waluta"/>
                            </FormControl>
                        </Form>
                    </>
                ) : (
                    <>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość części {i+1} zamówienia podstawowego</FormLabel>
                                <TextValidator
                                    onChange={(e)=>{setPartValue(parseFloat(e.target.value.replace(",", ".")));
                                        setValueAuctionParts(e)}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].partValue}
                                    name="partValue"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label={"Wartość " + (i+1) + " częsci"}
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość zamówień dodatkowych dla części {i+1} zamówienia
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    onChange={(e)=>{setPartValueSim(parseFloat(e.target.value.replace(",", ".")));
                                        setValueAuctionParts(e)}}
                                    value={formAuctionParts[i].partValueSim}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    type="text"
                                    id="outlined-basic-u"
                                    label={"Wartość zamówień podobnych" + (i+1) + " części"}
                                    variant="outlined"
                                    required
                                    name="partValueSim"/>
                                <TextValidator
                                    value={addPercentageParts}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                />
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość opcji dla części {i+1} zamówienia (jeśli dotyczy)
                                </FormLabel>
                                <TextValidator
                                    onChange={(e)=>{setValueAuctionParts(e); setPartValueOp(parseFloat(e.target.value.replace(",", ".")))}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].partValueOp}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label={"Wartość opcji" + (i+1) + " części"}
                                    variant="outlined"
                                    required
                                    name="partValueOp"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy) <br/>
                                </FormLabel><br/>
                                <TextValidator
                                    onChange={(e)=> {setValueAuctionParts(e); setSupplyValue(parseFloat(e.target.value.replace(",", ".")))}}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={formAuctionParts[i].valueSupplyPart}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Wartość dostaw i usług"
                                    variant="outlined"
                                    required
                                    name="valueSupplyPart"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Całkowita wartość zamówienia bez VAT
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    disabled
                                    value={valueWoVat}
                                    name="valueWoVatPart"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Waluta
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Waluta"
                                    variant="outlined"
                                    disabled
                                    value='PLN'
                                    name="Waluta"/>
                            </FormControl>
                        </Form>
                    </>
                )}

        </>
    )

}

export default PartValue;