import React, {useState} from "react";
import SidebarRight from "../components/SidebarRight";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import {Link} from "react-router-dom";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import {SectionHeading} from "../components/misc/Headings";
import {NotActiveButton, PrimaryButton} from "../components/misc/Buttons";

const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;
const ButtonCol = tw.div`flex flex-col justify-center`;
const Button = tw(PrimaryButton)`mb-3 text-base p-5 w-64`
const ButtonNotActive = tw(NotActiveButton)`mb-3 text-base p-5 w-64 `

const NewTender = () => {

    const [active, setActive] = useState(false);


    return (
        <React.Fragment>
        <SidebarRight/>
        <AnimationRevealPage>
            <ContentWithPaddingMd>
                <HeadingRow >
                    <Heading>Nowy przetarg</Heading>
                </HeadingRow>
            </ContentWithPaddingMd>
            {
                active ?
                    (
                        <ButtonCol>
                            <Link to="">
                                <Button>Informacje o zamówieniu </Button>
                            </Link>
                            <Button>Czynności zamawiającego w trakcie przetargu</Button>
                            <Button>Odwołanie</Button>
                            <Button>Unieważnienie postępowania</Button>
                            <Button>Wybór wykonawcy</Button>
                        </ButtonCol>
                    ) : (
                        <ButtonCol>
                            <Link to="/choosingOrderType">
                                <Button>Informacje o zamówieniu </Button>
                            </Link>
                            <ButtonNotActive>Czynności zamawiającego w trakcie przetargu</ButtonNotActive>
                            <ButtonNotActive>Odwołanie</ButtonNotActive>
                            <ButtonNotActive>Unieważnienie postępowania</ButtonNotActive>
                            <ButtonNotActive>Wybór wykonawcy</ButtonNotActive>
                        </ButtonCol>
                    )
            }
        </AnimationRevealPage>
    </React.Fragment>
    )
}

export default NewTender;