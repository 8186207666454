import {createActions} from 'redux-actions'

export const {
    setValues,
    setValuesAuction,
    setDateValue,
    setDateValueAuctionPart,
    setValuesAuctionParts,
    setBooleanValuesAuctionParts,
    setValuesApi,
    setDateValueAuction,
    setDateOffersDate,
    setDateSubmissionDateDay,
    setDateSubmissionDateTime,
    setDateOpenDateDay,
    setDateOpenDateTime,
    setDatePartStartDate,
    setDatePartEndDate,


} = createActions({
    SET_VALUES: (name, value) => ({name, value}),
    SET_VALUES_AUCTION: (e) => ({e}),
    SET_DATE_VALUE: (date) => ({date}),
    SET_DATE_VALUE_AUCTION: (name,date) => ({name, date}),
    SET_DATE_VALUE_AUCTION_PART: (name,date) => ({name,date}),
    SET_VALUES_AUCTION_PARTS: (name, value, partIndex) => ({name, value, partIndex}),
    SET_BOOLEAN_VALUES_AUCTION_PARTS: (name, partIndex) => ({name, partIndex}),
    SET_VALUES_API: (data) => ({data}),
    SET_DATE_OFFERS_DATE: (date) => ({date}),
    SET_DATE_SUBMISSION_DATE_DAY: (date) => ({date}),
    SET_DATE_SUBMISSION_DATE_TIME: (date) => ({date}),
    SET_DATE_OPEN_DATE_DAY: (date) => ({date}),
    SET_DATE_OPEN_DATE_TIME: (date) => ({date}),
    SET_DATE_PART_START_DATE: (date, no) => ({date, no}),
    SET_DATE_PART_END_DATE: (date, no) => ({date, no}),

})