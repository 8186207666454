import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import {TextValidator} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionId, fetchAuctionItemId, fetchAuctionItemPartsId, fetchHint} from "../services/fetchId";
import {useDispatch, useSelector} from "react-redux";
import {setDateValue, setValues} from "../state/auction/auctionActions";
import {setSnackbar} from "../state/snackbar";
import {
    enqueueSnackbar as enqueueSnackbarAction,
    closeSnackbar as closeSnackbarAction,
} from '../state/hints1/hintsActions';
import StackedSnackbar from "./StackedSnackbar";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";

import CustomizedTooltip from "./CustomizedTooltip";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelRadio: {
        marginTop: '2%',
        // marginLeft: '1%', // DH
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: 'auto',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const WayForm = (props) => {
    const isHintOn = useSelector(state => state.toggleHints.hints);

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();
    console.log(form);

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const classes = useStyles();

    const [auction, setAuction] = useState();
    const [eOfferInput, setEOfferInput] = useState();
    const [auctionItem, setAuctionItem] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [ecommunication, setEcommunication] = useState('');
    const [ecommunicationForm, setEcommunicationForm] = useState();
    const [resOrder, setResOrder] = useState('');
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [date, setDate] = useState();



    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setAuction(data['hydra:member'].find(auction => auction['@id'].slice(14) === props.id))
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )

    }, [])

    useEffect(() => {
        if(!!auctionItem) {
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].find(auctionItemParts => auctionItemParts.auctionItem.slice(19) === auctionItem['@id'].slice(19)))
                }
            )
            setEcommunication(auctionItem.ecommunication.toString())
        }
    }, [auctionItem])

    useEffect(() => {
        setEcommunicationForm(
            <Form className={classes.label} >
                <FormControl component="fieldset2">
                    <FormLabel component="legend2" className={classes.label} value={form.ecommunication} >Określ, czy wykonawcy będą zobowiązani do składania ofert i innych dokumentów wyłącznie przy użyciu środków komunikacji elektronicznej</FormLabel>
                    <RadioGroup name="ecommunication" className={classes.input} value={ecommunication} onChange={(e) => {setBooleanValue(e); handleOfferChange(e); setEcommunication(e.target.value)}}>
                        <FormControlLabel value="true" control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value="false" control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>)
    }, [ecommunication])

    const handleOfferChange1 = (e) => {
        setResOrder(e.target.value);
    }

    const handleOfferChange = (e) => {
        if (e.target.value === 'true'){
            setEOfferInput(true);
            setShow1(true);
            closeSnackbar();
        }else if (e.target.value === 'false') {
            setEOfferInput(false);
            setShow1(false);
            setShow2(true);
            closeSnackbar();
        }else{
            setEOfferInput(false)
        }
    }

    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    if(show1 && isHintOn && !!hints){
        enqueueSnackbar({
            message: <p>{hints[3].content}</p>,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
                action: key => (
                    <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                ),
            },
        });
        setShow1(false);
    }

    if(show2 && isHintOn && !!hints){
        enqueueSnackbar({
            message: <p>{hints[4].content}</p>,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
                action: key => (
                    <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                ),
            },
        });
        setShow2(false);
    }

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[5].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#c83351'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[6].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#c83351'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[7].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[8].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };


    return(
        <div onMouseOver={handleClick}>
            <Typography className={classes.nameSec}>Sposób składania ofert (w formie elektronicznej lub innej)
                <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/sposob-skladania-ofert/">
                <CustomizedTooltip content="Skorzystaj z Słownika pojęć" placement="top" type="info" />
                </a></Typography>
            <StackedSnackbar/>
            {ecommunicationForm}
            {
                eOfferInput && (
                    <div>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Wpisz adres strony internetowej prowadzonego postępowania, za pośrednictwem której wykonawca złoży ofertę
                            </FormLabel>
                            <TextValidator
                                value={form.emailOffer}
                                name="emailOffer"
                                onChange={setValue}
                                className={classes.input}
                                type="email"
                                id="outlined-basic-u"
                                label="Adres strony internetowej"
                                variant="outlined"
                                required
                            />
                        </Form>
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Uzupełnij informacje o wymaganiach technicznych i organizacyjnych
                                sporządzania, wysyłania i odbierania korespondencji elektronicznej,
                                w tym informacje dotyczące wymogu użycia podpisu elektronicznego
                            </FormLabel>
                            <TextValidator
                                value={form.emailRequirements}
                                name="emailRequirements"
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Wymagania"
                                variant="outlined"
                                required
                            />
                            </FormControl>
                        </Form>
                    </div>
                )
                }
            {
                eOfferInput === false && (
                    <>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Wskaż powód, dla którego odstępujesz od wymagania użycia środków
                                komunikacji elektronicznej
                            </FormLabel>
                            <TextValidator
                                value={form.reason}
                                name="reason"
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Wskaż powód"
                                variant="outlined"
                                required
                            />
                        </Form>
                        <Form className={classes.label} >
                            <FormControl value={form.way}>
                                <FormLabel className={classes.label}>Wskaż, w jaki sposób wykonawca zobowiązany będzie złożyć ofertę</FormLabel>
                                <RadioGroup name="way" className={classes.input} onChange={(e) => {setValue(e); handleOfferChange1(e)}}>
                                    <FormControlLabel value="Osobiście" control={<Radio color="primary"/>} label="Osobiście" />
                                    <FormControlLabel value="Za pośrednictwem kuriera" control={<Radio color="primary"/>} label="Za pośrednictwem kuriera" />
                                    <FormControlLabel value="Za pośrednictwem operatora pocztowego" control={<Radio color="primary"/>} label="Za pośrednictwem operatora pocztowego" />
                                </RadioGroup>
                            </FormControl>
                        </Form>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Wskaż sposób składania JEDZ/oświadczenia o niepodleganiu
                                wykluczeniu
                            </FormLabel>
                            <TextField
                                defaultValue="wnioski o dopuszczenie do udziału w postępowaniu lub konkursie, wnioski, o których mowa w art. 371 ust. 3, oferty lub ich części składa się, pod rygorem nieważności, w formie pisemnej"
                                className={classes.input}
                                required
                                value={form.jedz}
                                id="outlined-basic-u"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                        <Form className={classes.label}>
                            <FormControl
                                // className={classes.label} // DH
                                name="openDate"
                                style={{display: 'flex', justifyContent: 'center', textAlign: 'left !important'}}>
                                <FormLabel className={classes.labelRadio}>Podaj planowaną datę otwarcia ofert <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/otwarcie-ofert/"><InfoIcon/></a></FormLabel>
                                <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
                                    <DatePicker
                                        value={date}
                                        onChange={(e) => {setDate(e); dispatch(setDateValue(e))}}
                                        inputVariant="outlined"
                                        className={classes.input}
                                        format="yyyy/MM/dd"
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Form>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Wpisz miejsce otwarcia ofert
                            </FormLabel>
                            <TextField
                                className={classes.input}
                                required
                                value={form.openPlace}
                                id="outlined-basic-u"
                                variant="outlined"
                                name="openPlace"
                            />
                        </Form>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Informacje dodatkowe
                            </FormLabel>
                            <TextField
                                className={classes.input}
                                required
                                value={form.wayAddInfo}
                                name="wayAddInfo"
                                id="outlined-basic-u"
                                variant="outlined"
                            />
                        </Form>
                    </>
                )
            }
        </div>
        )
}

export default WayForm;