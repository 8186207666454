import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import {SectionHeading} from "../components/misc/Headings";
import {Link} from "react-router-dom";
import {fetchAuction, fetchAuctionId, fetchAuctionItemId, fetchUsers} from "../services/fetchId";
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import {TextField} from "@material-ui/core";

const HeadingRow = tw.div`flex p-0! mb-24`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;

function createRowData(id, name, startDate, closeDate, initialOp, swz, final) {
    return { id, name, startDate, closeDate, initialOp, swz, final };
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nazwa przetargu' },
    { id: 'startDate', numeric: true, disablePadding: false, label: 'Data rozpoczęcia' },
    { id: 'closeDate', numeric: true, disablePadding: false, label: 'Data zakończenia' },
    { id: 'download', numeric: true, disablePadding: false, label: 'Pobierz plik PDF' },

];

function EnhancedTableHead(props) {


    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key={headCells[0].id}
                    align='center'
                    padding={headCells[0].disablePadding ? 'none' : 'default'}
                >Nazwa przetargu
                </TableCell>
                <TableCell
                    key={headCells[1].id}
                    align='center'
                    padding={headCells[1].disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCells[1].id ? order : false}
                ><TableSortLabel
                    style={{textAlign: 'center'}}
                    active={true}
                    direction={orderBy === headCells[1].id ? order : 'asc'}
                    onClick={createSortHandler(headCells[1].id)}
                >
                    {headCells[1].label}
                    {orderBy === headCells[1].id ? (
                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
                <TableCell
                    key={headCells[2].id}
                    align='center'
                    padding={headCells[2].disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCells[2].id ? order : false}
                ><TableSortLabel
                    style={{textAlign: 'center'}}
                    active={true}
                    direction={orderBy === headCells[2].id ? order : 'asc'}
                    onClick={createSortHandler(headCells[2].id)}
                >
                    {headCells[2].label}
                    {orderBy === headCells[2].id ? (
                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
                <TableCell
                    key={headCells[3].id}
                    align='center'
                    padding={headCells[3].disablePadding ? 'none' : 'default'}
                >Pobierz plik PDF</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontFamily: 'inherit',
    },
    selected: {
        backgroundColor: 'red'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Przetargi w trakcie przygotowywania - nieogłoszone
                </Typography>
            )}

        </Toolbar>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontFamily: 'inherit !important',
        textAlign: 'center'
    },
    selected: {
        backgroundColor: 'red'
    },

    paper: {
        width: '100%',
        fontFamily: 'inherit !important',
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    table: {
        fontFamily: 'inherit !important',
        minWidth: 750,
        textAlign: 'center',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [procedureType, setProcedureType] = React.useState();
    const [rows, setRows] = React.useState([]);
    const [auctionItems, setAuctionItems] = React.useState();
    const [editInitialOp, setEditInitialOp] = React.useState();
    const [auctions, setAuctions] = React.useState();
    const [ids, setIds] = React.useState();
    const [coloredRowsIds, setColoredRowsIds] = useState([]);
    const [search, setSearch] = React.useState(false);
    const [pagination1, setPagination1] = React.useState();
    const [pagination2, setPagination2] = React.useState();
    let today = new Date().toLocaleDateString('en-CA');

    useEffect(() => {
        setIds(rows.map(row => row.id));
    }, [rows])

    useEffect(() => {
        setColoredRowsIds(ids);
    }, [ids]);


    const creatInitialOpt = (link, auction) => (
        <Link to={link + auction['@id'].slice(14) }>
            <EditRoundedIcon style={{color: '#5a13e6', cursor: 'pointer'}}/>
        </Link>
    )

    const createData = (dbData) => {
        return dbData['hydra:member']
            .filter(auc => auc.owner.slice(11) === localStorage.getItem('userId') && auc.isClosed === true)
            .map((auction) => {
                switch(auction.procedureType) {
                    case "Samodzielnie":
                        setEditInitialOp(creatInitialOpt(`soloOrder/edit/`, auction))
                        break;
                    case "Jako centralny zamawiający":
                        setEditInitialOp(creatInitialOpt(`centralOrder/edit/`, auction))
                        break;
                    case "Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi":
                        setEditInitialOp(creatInitialOpt(`jointOrder/edit/`, auction))
                        break;
                    case "Jako podmiot, któremu zamawiający powierzył/powierzyli przeprowadzenie postępowania":
                        setEditInitialOp(creatInitialOpt(`proxyOrder/edit`, auction))
                        break;
                }
                return createRowData(auction['@id'].slice(14), auction.orderName, auction.startDate.slice(0, 10), auction.closeDate.slice(0,10, ''))
            })
    }

    useEffect(() => {
        fetchAuctionId().subscribe(data => {
            setAuctions(data['hydra:member'])
            setRows(createData(data))
        })
    }, [])


    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                if(!!auctions){
                    setAuctionItems(data['hydra:member'].map(auctionItem => (
                        {
                            auctionId: auctionItem.auction.slice(14),
                            shortDesc: auctionItem.shortAuctionDescription,
                            longDesc: auctionItem.auctionDescription,
                            orderName: auctions.filter(auction => auction['@id'].slice(14) === auctionItem.auction.slice(14))[0].orderName
                        })));
                }

            }
        )
    }, [auctions])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        fetchAuction().subscribe(data => {
            setProcedureType(data.procedureType);
        })
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchAuctionByDesc = searchTerm => {
        setColoredRowsIds(auctionItems
            .filter(item => searchTerm === "" ||
                item.shortDesc.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.longDesc.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.orderName.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(item => item.auctionId))
        setSearch(true);
        if(searchTerm === ''){
            setSearch(false)
        }
    }

    useEffect(() => {
        if(search){
            setPagination1(0)
            setPagination2(500)
        }else{
            setPagination1(page * rowsPerPage)
            setPagination2(page * rowsPerPage + rowsPerPage)
        }
    }, [search, page])


    return (
        <>
            <SidebarLeftAccount/>
            <SidebarRightAccount/>
            <AnimationRevealPage>
                <div className="content content-left" >
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Archiwum przetargów</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    {
                        rows.length === 0 ?
                            (
                                <p style={{textAlign: 'center'}}>Brak przetargów do wyświetlenia</p>
                            ) : (
                                <>
                                    <TextField id="outlined-basic" label="Szukaj..." variant="outlined" onChange={(e) => searchAuctionByDesc(e.target.value)} style={{marginBottom: '5%'}}/>
                                    <Paper className={classes.paper}>
                                        <TableContainer>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                size={'medium'}
                                                aria-label="enhanced table"
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rows.length}
                                                />
                                                <TableBody>
                                                    {stableSort(rows, getComparator(order, orderBy))
                                                        .slice(pagination1, pagination2)
                                                        .map((row, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            if(coloredRowsIds.includes(row.id)){
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                    >
                                                                        <TableCell align='center' component="th" id={labelId} scope="row" padding="none">
                                                                            {row.name}
                                                                        </TableCell>
                                                                        <TableCell align='center'>{row.startDate}</TableCell>
                                                                        <TableCell align='center'>{row.closeDate}</TableCell>
                                                                        <TableCell align='center'>{row.download}<GetAppIcon style={{color: '#5a13e6', cursor: 'pointer'}}/></TableCell>
                                                                    </TableRow>
                                                                );
                                                            }

                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={search ? coloredRowsIds.length : rows.length}
                                            rowsPerPage={search ? coloredRowsIds.length : rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelRowsPerPage = 'Ilość wierszy na stronie:'
                                        />
                                    </Paper>
                                </>
                            )
                    }

                </div>
            </AnimationRevealPage>

        </>
    );
}
