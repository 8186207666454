import React from "react";
import Button from '@material-ui/core/Button';
import {useSelector} from "react-redux";

const FinalStepsFinished = (props) => {

    const form = useSelector(state => state.auction.form);

    const formArray = Object.entries(form);
    // const newFormArray = formArray.filter(([key, value]) => key != '@context' && key != '@id' && key != '@type' && key!= 'addressVoivodeship' && key!= 'department' && key!= 'addressCountry' && key!= 'nutsCode'  && key!= 'regon' && key!= 'phoneNumber' && key!= 'faxNumber' && key!= 'epuap' && key!= 'additionalInformation' && key!= 'bipCoreBusiness' && key!= 'bipClientType' && key!= 'duueClientType' && key!= 'duueCoreBusiness'  && key!= 'owner' && key!= 'isSubscription' );
    const newFormArray = formArray.filter(([key, value]) => key !== '@context' && key !== '@id' && key !== '@type' && key!== 'addressVoivodeship' && key!== 'department' && key!== 'addressCountry' && key!== 'nutsCode'  && key!== 'regon' && key!== 'phoneNumber' && key!== 'faxNumber' && key!== 'epuap' && key!== 'additionalInformation' && key!== 'bipCoreBusiness' && key!== 'bipClientType' && key!== 'duueClientType' && key!== 'duueCoreBusiness'  && key!== 'owner' && key!== 'isSubscription' );
    const newForm = Object.fromEntries(newFormArray);

    return (
        <Button
            style={{color: 'white', backgroundColor: 'white', border: 'none', boxShadow: 'none'}}
            variant="contained"
            color="primary"
            type="submit"
            onClick={props.handleLast}
            onSubmit={props.onSubmit(newForm)}
        >Zakończ</Button>
    )
}

export default FinalStepsFinished