import React, {useState, useEffect} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {SectionHeading} from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import OrderForm from "../components/OrderForm";
import OrderNameForm from "../components/OrderNameForm";
import UEFunding from "../components/UEFunding";
import MarketCons from "../components/MarketCons";
import OrderType from "../components/OrderType";
import OrderValue from "../components/OrderValue";
import OrderMode from "../components/OrderMode";
import AdditionalInfo from "../components/AdditionalInfo";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import {fetchRegisterData} from "../services/fetchRegisterData";
import Swal from "sweetalert2";
import {postInitialOpAuction} from "../services/postInitialOpAuction";
import {useSelector, useDispatch} from "react-redux";
import {patchInitialOpAuction} from "../services/patchInitialOpAuction";
import InitialOpFinished from "../components/InitialOpFinished";
import {postInitialOp} from "../services/postInitialOp";
import {patchInitialOp} from "../services/patchInitialOp";
import {patchCustomer} from "../services/patchCustomer";
import {fetchAuction, fetchAuctionId, fetchAuctionItemId, fetchCustomer} from "../services/fetchId";
import Summary from "./Summary";
import {setSnackbar} from "../state/snackbar";


const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
}));





const SoloOrder = (props) => {

    const [auction, setAuction] = useState();
    const [auctionId, setAuctionId] = useState(!!localStorage.getItem('auctionRef') && localStorage.getItem('auctionRef').slice(14));
    const {id, type} = useParams();
    const classes = useStyles();
    const customerId = localStorage.getItem('customerId');
    const dispatch = useDispatch();
    const [auctionItemId, setAuctionItemId] = useState();

    useEffect(() => {
        let check = [];
        fetchAuctionItemId().subscribe(
            data => {
                if(!!localStorage.getItem('auctionRef')){
                    check = data['hydra:member'].filter(auction => auction.auction === localStorage.getItem('auctionRef'));
                    console.log(check)
                    if(check.length > 0){
                        fetchAuctionItemId().subscribe(
                            data => {
                                setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                            }
                        )
                    }
                }

            }
        )



        /*if(check === []){

        }else{
            fetchAuctionItemId().subscribe(
                data => {
                    setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                }
            )
        }*/

       /* if(!!localStorage.getItem('auctionRef')){
            fetchAuctionItemId().subscribe(
                data => {
                    setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                }
            )
        }*/
    }, [])


    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            localStorage.setItem('user', data.owner);
        })

    }, [])

    useEffect(() => {
        fetchAuction().subscribe(data => {
            setAuction(data)
        })

    }, [])

    const {params} = props.match;
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (params.edit === 'edit'){
            setEdit(true);
        }
    }, [])

/*    const handleNext = (formRef) => {
      formRef.current.isFormValid(false).then((e) => {
            e && setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
        )
    };*/

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };




    function getSteps() {
        if(!edit){
            return ['INFORMACJE WPROWADZAJĄCE', 'NAZWA ZAMÓWIENIA', 'DOFINANSOWANIE UNIJNE', 'KONSULTACJE RYNKOWE', 'RODZAJ ZAMÓWIENIA', 'WARTOŚĆ ZAMÓWIENIA', 'WYBÓR TRYBU', 'INFORMACJE UZUPEŁNIAJĄCE', 'PODSUMOWANIE', 'ZAKOŃCZ'];
        }else if(edit){
            return ['INFORMACJE WPROWADZAJĄCE', 'NAZWA ZAMÓWIENIA', 'DOFINANSOWANIE UNIJNE', 'KONSULTACJE RYNKOWE', 'RODZAJ ZAMÓWIENIA', 'WARTOŚĆ ZAMÓWIENIA', 'WYBÓR TRYBU', 'INFORMACJE UZUPEŁNIAJĄCE', 'ZAKOŃCZ'];
        }

    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return ( <OrderForm handleNext={handleNext} id={params.id} edit={edit}/>
                );
            case 1:
                return (<OrderNameForm handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 2:
                return (<UEFunding handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 3:
                return (<MarketCons handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 4:
                return (<OrderType handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 5:
                return (<OrderValue handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 6:
                return (<OrderMode handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 7:
                return (<AdditionalInfo handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 8:
                if(!edit){
                return (<Summary handleNext={handleNext} handleBack={handleBack}/>)}
            case 9:
                return (<InitialOpFinished
                    handleLast={handleLast}
                    onSubmit={
                        edit ? (
                            (formAuction, form) => {
                                patchInitialOpAuction(formAuction, params.id).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                            window.location.replace('/orderSpecification/form/' + auctionId)}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                                patchInitialOp(form, auctionItemId).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                            }
                        ) : (
                            (formAuction, form) => {
                                postInitialOpAuction(formAuction).subscribe(
                                    (data) => {
                                        localStorage.setItem('auctionRef', data['@id']);
                                        postInitialOp({
                                            ...form,
                                            auction: data['@id']
                                        }).subscribe(
                                            Swal.fire({
                                                    title: 'Sukces',
                                                    text: `Czynności wstępne zostały zapisane.`,
                                                    icon: 'success',
                                                    showCancelButton: false,
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#5a13e6',
                                                    confirmButtonText: 'Przejdź dalej'
                                                },
                                            ).then(okay => {
                                                if (okay) {
                                                    window.location.replace('/orderSpecification/form/' + localStorage.getItem('auctionRef').slice(14))}}),
                                            error => {
                                                return Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: `Wprowadzono błędne dane !`,
                                                })
                                            },
                                        );
                                    }

                                )

                            }
                        )
                    }
                />)

            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();


    const handleLast = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.location.replace('/orderSpecification')
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    useEffect(() => {
        console.log('changed step scrolling to top of window');
        window.scrollTo(0, 0)
    }, [activeStep])


    return(
        <React.Fragment>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <div className={classes.root}>
                        <ContentWithPaddingMd>
                            <HeadingRow >
                                <Heading>Informacje o zamówieniu</Heading>
                            </HeadingRow>
                        </ContentWithPaddingMd>
                        <div className={classes.root}>
                            <Stepper className={classes.name} activeStep={activeStep} orientation="vertical">
                                {steps.map((label, index) => (
                                    <Step className={classes.name} key={label}>
                                        <StepLabel><Typography className={classes.name}>{label}</Typography></StepLabel>
                                        <StepContent >
                                            <TwoColumnStep style={{width: '100%', textAlign: 'left', marginLeft: '0'}}>
                                                <Typography>{getStepContent(index)}</Typography>
                                            </TwoColumnStep>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                </div>

            </div>


        </React.Fragment>
    )
}

export default SoloOrder;