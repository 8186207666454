import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import {makeStyles} from "@material-ui/core/styles";

import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    color: {
        backgroundColor: ' #935bff !important',
        fontSize: '0.8em',
    },
    root: {
        display: 'flex',
    }
}));


const HintsPermanent = (props) => {


    const classes = useStyles();


    return(
        <React.Fragment>
            <div className={classes.root}><Snackbar
                id="hint"
                className={props.className}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open
            ><MuiAlert
                severity="warning"
                elevation={6}
                variant="filled"
                className={classes.color}>
                {props.message}
            </MuiAlert>
            </Snackbar>
            </div>
        </React.Fragment>
    )
}

export default HintsPermanent;