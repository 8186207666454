import React, {useEffect, useState} from "react";
import {Link, withRouter} from 'react-router-dom';
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import MyAccountCard from "../components/Card";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import StorageIcon from '@material-ui/icons/Storage';
import {useDispatch, useSelector} from "react-redux";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "../components/StackedSnackbar";
import ChartCard from "../components/ChartCard";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import {ChartDonut, ChartLabel, ChartThemeColor} from "@patternfly/react-charts";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionId, fetchCustomerId, fetchHint, fetchUser, fetchUserId} from "../services/fetchId";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CardMedia from "@material-ui/core/CardMedia";
import GavelIcon from "@material-ui/icons/Gavel";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import tw from "twin.macro";
import {Linksmenu, Linkssidebar} from "../components/misc/Links";
import '../styles/myAccount.css';
import { useLocation } from 'react-router-dom'

const LinkSidebar = tw(Linkssidebar)`text-center`;
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const LinkHeaders = tw.p`text-primary-700 font-bold`;

function MyAccount() {

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])


        fetchUserId().subscribe(
            data => {
                localStorage.setItem('userId', data['hydra:member'][0].logged.slice(11));
            }
        )

        fetchCustomerId(localStorage.getItem('userId')).subscribe(
            data => {
                localStorage.setItem('user', data.customer);
                localStorage.setItem('customerId', data.customer.slice(15));
            }
        )

    const dispatch = useDispatch();
    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    if(show && isHintOn && !!hints){
        enqueueSnackbar({
            message: <p>{hints[0].content.slice(0,100)}<br/>{hints[0].content.slice(100,160)}</p>,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
                action: key => (
                    <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                ),
            },
        });
        setShow(false);
    }

    const location = useLocation();

    React.useEffect(() => {
        console.log('Location changed');
        closeSnackbar();
    }, [location]);

    const windowWidth = useSelector(state => state.main.windowWidth);
    let useStyles;
    if(windowWidth >= 1300){
        useStyles = makeStyles({
            root: {
                width: 250,
                height: 50,
                backgroundColor: '#6415FF',
                color: 'white',
                textAlign: 'center'
            },
            root1: {
                width: 320,
                height: 250,
                textAlign: 'center',

            },
            root2: {
                width: 250,
                marginTop: 20,
                height: 130
            },
            media: {
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                height: 100,
                textAlign: 'center'
            },
            description: {
                textAlign: 'justify'
            },
            info: {
                fontSize: '1rem'
            },
            infoMore: {
                fontSize: '1.1rem',
                color: '#6415FF'
            },
            infoTitle: {
                textAlign: 'center',
                fontSize: '1.2rem',
            }
        });
    }else if(windowWidth <= 1300){
        useStyles = makeStyles({
            root: {
                width: 250,
                height: 50,
                backgroundColor: '#6415FF',
                color: 'white',
                textAlign: 'center'
            },
            root1: {
                width: 200,
                height: 210,
                textAlign: 'center'
            },
            root2: {
                width: 250,
                marginTop: 20,
                height: 130
            },
            media: {
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                height: 100,
                textAlign: 'center'
            },
            description: {
                textAlign: 'justify'
            },
            info: {
                fontSize: '0.85rem'
            },
            infoMore: {
                fontSize: '1rem',
                color: '#6415FF'
            },
            infoTitle: {
                textAlign: 'center',
                fontSize: '1rem'
            }
        });
    }

    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [userAuctions, setUserAuctions] = useState([]);
    const [closestAuction, setClosestAuction] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchUser(localStorage.getItem('userId')).subscribe(
            data => {
                setUsername(data.username);
            }
        )
    }, []);

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setUserAuctions(data['hydra:member'].filter(auc => auc.owner.slice(11) === localStorage.getItem('userId')));
            }
        )
    }, [])

    let auctionsDates = userAuctions.map(a => a.startDate);
    let auctionsDatesFormat = auctionsDates.map(date => date.slice(0,10));
    const now = new Date();
    let closestDate = Infinity;

    auctionsDatesFormat.forEach(function(d) {
        const date = new Date(d);

        if (date >= now && (date < new Date(closestDate) || date < closestDate)) {
            closestDate = d;
        }
    });

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                console.log("id przetargu: ", data);
                setClosestAuction(data['hydra:member'].filter(auc => auc.owner.slice(11) === localStorage.getItem('userId') && auc.startDate.slice(0,10) === closestDate));
            }
        )
    }, [closestDate])


    function SimpleDialog(props) {
        const classes = useStyles();
        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <List>
                    <ListItem button >
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień krajowych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OPIS POTRZEB I WYMAGAŃ
                            </LinkHeaders>
                            dla zamówień krajowych
                            i unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 130.000 – do progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <Link to="/priorInfoNotice">
                        <ListItem button>
                            <LinkSidebar>
                                <LinkHeaders>
                                    WSTĘPNE OGŁOSZENIE
                                    INFORMACYJNE
                                </LinkHeaders>
                                – w Dzienniku
                                Urzędowym Unii Europejskiej dla zamówień
                                powyżej progów unijnych
                            </LinkSidebar>
                        </ListItem>
                    </Link>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W DZIENNIKU
                                URZĘDOWYM UNII EUROPEJSKIEJ
                            </LinkHeaders>
                            - dla zamówień powyżej progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OŚWIADCZENIA
                                DO SPECYFIKACJI
                            </LinkHeaders>
                        </LinkSidebar>
                    </ListItem>
                </List>
            </Dialog>
        );
    }


    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left" style={{display: 'flex', justifyContent: 'center', alignItems:'center', marginTop: '5%', flexWrap: 'wrap'}}>
                <SimpleDialog open={open} onClose={handleClose} />
                <StackedSnackbar/>
                <div className="parent">
                    <div className="div2">
                        <ChartCard/>
                    </div>
                    <div className="div1">
                        <Card className={classes.root1}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}>
                                    <GavelIcon style={{color: '#6415FF', fontSize: '50px', paddingTop: '16px'}}/>
                                </CardMedia>
                                <CardContent>
                                    { 
                                        !!closestAuction[0] && (
                                            <p style={{fontFamily: 'inherit', textAlign: 'left'}}>
                                                <p className={classes.infoTitle}>Najbliższy przetarg:</p><br/>
                                                <p className={classes.infoMore}>{closestAuction[0].orderName.slice(0,20) + '...'}</p>
                                                <p className={classes.info}>Data rozpoczęcia: {closestAuction[0].startDate.slice(0,10)}</p>
                                                <p className={classes.info}>Numer referencyjny: {closestAuction[0].refNumber}</p>
                                            </p>
                                        )
                                        }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>

                        {/*<div>
                            <Link to='/myProfile'>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardContent>
                                            {username}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                            <Card className={classes.root2}>
                                <CardActionArea>
                                    <CardContent>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>*/}
                        <div className="div3">
                            <Link to="/choosingOrderType">
                                <MyAccountCard
                                    icon={<PostAddIcon style={{fontSize: '60px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                    title={'Rozpocznij nowy przetarg'}
                                />
                            </Link>
                        </div>
                        <div className="div4">
                            <Link to="/myProfile">
                                <MyAccountCard
                                    icon={<PersonIcon style={{fontSize: '60px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                    title={'Dane użytkownika'}
                                />
                            </Link>
                        </div>
                        <div className="div5">
                            <Link to="/orderData">
                                <MyAccountCard
                                    icon={<AssignmentIndIcon style={{fontSize: '60px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                    title={'Dane zamawiającego'}
                                />
                            </Link>
                        </div>
                        <div className="div6">
                            <Link to="/inProgress">
                                <MyAccountCard
                                    icon={<AssignmentIcon style={{fontSize: '60px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                    title={'Moje przetargi'}
                                />
                            </Link>
                        </div>
                        <div className="div7">
                            <MyAccountCard
                                onClick={handleClickOpen}
                                icon={<AttachFileIcon style={{fontSize: '54px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                title={'Pliki PDF'}
                            />
                        </div>
                        <div className="div8">
                            <a href="https://platforma-przetargowa.pl/baza-wiedzy/" target="_blank">
                                <MyAccountCard
                                    icon={<StorageIcon style={{fontSize: '60px', margin: 'auto', color: '#6415FF', paddingTop: '16px'}}/>}
                                    title={'Baza wiedzy'}
                                />
                            </a>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default withRouter(MyAccount);