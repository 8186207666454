import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
// import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './store';
import * as serviceWorker from './serviceWorker';

Modal.setAppElement("#root");

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// const app = (
//   <Provider >
//       <BrowserRouter>
//           <App />
//       </BrowserRouter>
//   </Provider>
// );

// ReactDOM.render(app, document.getElementById('root'));
// serviceWorker.unregister();
