import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionItemId} from "../../services/fetchId";
import {useSelector} from "react-redux";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const Negotiation = ({id}) => {

    const classes = useStyles();
    const [auctionItem, setAuctionItem] = useState('');

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0])
            }
        )
    }, [])

    return(
        <AnimationRevealPage>
            <ValidatorForm >
                {auctionItem.negotiation === 'z' || auctionItem.negotiation === 'możliwość' ?
                    (
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={'Zamawiający przewiduje możliwość prowadzenia negocjacji w celu wyboru najkorzystniejszej oferty.'}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </FormControl>
                        </Form>
                    ) : (
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={'Zamawiający nie przewiduje prowadzenia negocjacji w celu wyboru najkorzystniejszej oferty.'}
                                    variant="outlined"

                                />
                            </FormControl>
                        </Form>
                    )
                }


            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default Negotiation