import React, {useEffect, useRef, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";
import {fetchUsers} from "../services/fetchId";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const Form = tw.div`px-12 py-3 w-128`;
const ButtonPass = tw(PrimaryButton)`mb-8! mt-3! w-full text-base inline-block `;

const EditUsersDataStatus = ({open,  setOpen, user, onSubmit}) => {


    const useStyles = makeStyles({
        form: {
            display: 'flex !important',
            justifyContent: 'center !important',
            alignItems: 'center'
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '2% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
        input: {
            minWidth: '300px',
            marginRight: '0 !important'
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'center',
            color: 'black',
            width: '100%',
        },
    });



    const onSubmit1 = () => {
        const data = {
            userId : user.userId,
            isEnabled: !user.active,
            email : user.email,
            username : user.name,
        };

        onSubmit(data);
        setOpen(false);
    };

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Dialog open={open} onClose={handleClose} className={classes.root}>
                <DialogContent>
                    <Typography className={classes.nameSec}>ZMIEŃ DANE UŻYTKOWNIKA</Typography><br/>
                        {!!user &&
                        <>
                            <Form className={classes.label}>
                                <FormLabel className={classes.label}>Czy na pewno chcesz wprowadzić zmiany?</FormLabel>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '3%'}}>
                                    <ButtonPass onSubmit={onSubmit1} onClick={onSubmit1} type="submit" style={{width: '40%', margin: 'auto'}}>Tak</ButtonPass>
                                    <ButtonPass onClick={handleClose} style={{width: '40%', margin: 'auto'}}>Nie</ButtonPass>
                                </div>
                            </Form>
                        </>
                        }
                </DialogContent>

            </Dialog>
        </>
    )
}

export default EditUsersDataStatus;