import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/error.svg";
import { Link } from "react-router-dom";
import '@sweetalert2/theme-material-ui/material-ui.scss';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-6/12 p-6 sm:p-12 flex flex-col items-center justify-around`;
const LogoLink = tw(Link)`flex justify-center`;
const LogoTitle = tw.h2`h-12 mx-auto text-2xl xl:text-3xl font-extrabold`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h2`text-xl xl:text-xl font-extrabold text-center text-red-600`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full bg-cover bg-center bg-no-repeat shadow`}
`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-red-600 text-gray-100 w-full py-4 rounded-lg hover:bg-red-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none p-5`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export default ({
  // logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "AKtywacja nie powiodła się spróbuj ponownie!",
  // submitButtonText = "Zaloguj się",
  // SubmitButtonIcon = LoginIcon,
  // forgotPasswordUrl = "#",
  // signupUrl = "#",
}) => (
  <AnimationRevealPage>
    <Container>
      <Content>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
        <MainContainer>
          <LogoLink to={'/'}>
            <LogoTitle>ARKAD</LogoTitle>
          </LogoLink>
          <Heading  className="text-center">{headingText}</Heading>
          <MainContent>
                    <SubmitButton as={Link} to="/">Przejdz do strony głównej</SubmitButton>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
);
