import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import {MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Input from '@material-ui/core/Input';
import FileInput from "./FileInput";
import {PrimaryButton} from "./misc/Buttons";
import Typography from "@material-ui/core/Typography";
import HintsInfo from "./HintsInfo";
import Hints from "./Hints";
import {useDispatch, useSelector} from "react-redux";
import {setValues, setValuesAuction, setValuesAuctionParts} from "../state/auction/auctionActions";
import Button from "@material-ui/core/Button";
import {fetchAuctionId, fetchAuctionItemId, fetchAuctionItemPartsId, fetchHint} from "../services/fetchId";
import StackedSnackbar from "./StackedSnackbar";
import CloseIcon from "@material-ui/icons/Close";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";

import CustomizedTooltip from "./CustomizedTooltip";

const Form = tw.div`mb-5 mx-5 mr-3 flex justify-center`;
const ThreeColumn = tw.div`flex flex-col items-center justify-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`lg:w-1/3`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    inputJ: {
        padding: '0 0 0 25px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    label: {
        marginBottom: '1%',
        // marginLeft: '2%',
        // marginRight: '2%',
        fontSize: '1em',
        // textAlign: 'justify',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelJ: {
        marginBottom: '0%',
        marginLeft: '2%',
        marginRight: '2%',
        fontSize: '1em',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelII: {
        marginTop: '0%',
        marginBottom: '1%',
        marginLeft: '2%',
        marginRight: '2%',
        fontSize: '1em',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100% !important',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '3% !important',
        borderBottom: '1px solid black',
        padding: '3%',
        width: '100% !important'
    },
    nameSec2: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '3% !important',
        borderBottom: '1px solid black',
        padding: '3%',
        width: '100% !important'
    },
    one: {
        width: '95%',
    },
}));

const OrderType = (props) => {

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const formAuction = useSelector(state => state.auction.formAuction);
    console.log(formAuction);

    const setValueAuction = (e) => {
        dispatch(setValuesAuction(e))
        console.log(formAuction)
    }

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    console.log(formAuctionParts);

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i))
        console.log(formAuctionParts)
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const classes = useStyles();



    const [orderType, setOrderType] = useState(false);
    const [serviceType, setServiceType] = useState(false);
    const [service361, setService361] = useState(false);
    const [expand, setExpand] = useState(false);
    const [expand2, setExpand2] = useState(false);
    const [cpv, setCpv] = useState(false);
    const [cpvAdd, setCpvAdd] = useState(false);


    const orderTypeHandler = (e) => {
        setOrderType(e.target.value);

        if (e.target.value === "usługi"){
            setExpand(true);
        }else {
            setExpand(false);
        }
    }

    const serviceTypeHandler = (e) => {
        setServiceType(e.target.value);

        if (e.target.value === "społeczne"){
            setExpand2(true);
        }else {
            setExpand2(false);
        }
    }

    const service361Handler = (e) => {
        setService361(e.target.value);
    }
    const handleChangeCpvAdd = (e) =>{
        setCpvAdd(e.target.value);
    }
    const handleChangeCpv = (e) =>{
        setCpv(e.target.value);
    }


    const [parts, setParts] = useState(parseInt(localStorage.getItem('parts')));
    const [form1, setForm1] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [auctionItem, setAuctionItem] = useState();
    const [auction, setAuction] = useState();
    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);

    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[14].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setAuction(data['hydra:member'].find(auction => auction['@id'].slice(14) === props.id))
            }
        )
    }, [])

    useEffect(() => {
        if(!!auctionItem){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].find(auctionItemParts => auctionItemParts.auctionItem.slice(19) === auctionItem['@id'].slice(19)))
                }
            )
        }
    }, [auctionItem])

    let part = [];

    useEffect(() => {
        for (let i=0; i<parts; i++){
            if(props.edit === true && !!auction && !!auctionItem){
                part.push(
                    <>
                        <Typography className={classes.nameSec2}>Część {i+1}</Typography>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Nazwa części {i+1} zamówienia</FormLabel><br/>
                                <TextValidator
                                    defaultValue={auctionItemParts[i].partName}
                                    className={classes.input}
                                    type="text"
                                    value={formAuctionParts[i].partName}
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled
                                    name="partName"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wybierz główny kod CPV
                                    dla części {i+1} zamówienia
                                </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItemParts[i].cpvCode}
                                    className={classes.input}
                                    type="text"
                                    label={"Główny kod CPV dla części " + (i+1) + " zamówenia"}
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    onChange={(e) => setValueAuctionParts(e, i)}
                                    value={formAuctionParts[i].cpvCode}
                                    validators={['matchRegexp:^[0-9]*$']}
                                    errorMessages={['Niedozwolone znaki']}
                                    name="cpvCode">
                                </TextValidator>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wybierz dodatkowy kod CPV
                                    dla części {i+1} zamówienia</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItemParts[i].addCpvCode}
                                    label={"Dodatkowy kod CPV dla części " + (i+1) + " zamówenia"}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    onChange={(e) => setValueAuctionParts(e, i)}
                                    value={formAuctionParts[i].addCpvCode}
                                    validators={['matchRegexp:^[0-9]*$']}
                                    errorMessages={['Niedozwolone znaki']}
                                    name="addCpvCode">
                                </TextValidator>
                            </FormControl>
                        </Form>
                    </>)
            }else if(props.edit === false){
                part.push(
                    <>
                        <Typography className={classes.nameSec2}>Część {i+1}</Typography>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Nazwa części {i+1} zamówienia</FormLabel><br/>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    value={formAuctionParts[i].partName}
                                    variant="outlined"
                                    required
                                    disabled
                                    name="partName"/>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wybierz główny kod CPV
                                    dla części {i+1} zamówienia
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    label={"Główny kod CPV dla części " + (i+1) + " zamówenia"}
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    onChange={(e) => setValueAuctionParts(e, i)}
                                    value={formAuctionParts[i].cpvCode}
                                    validators={['matchRegexp:^[0-9]*$']}
                                    errorMessages={['Niedozwolone znaki']}
                                    name="cpvCode">
                                </TextValidator>
                            </FormControl>
                        </Form>
                        <Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wybierz dodatkowy kod CPV
                                    dla części {i+1} zamówienia</FormLabel>
                                <TextValidator
                                    label={"Dodatkowy kod CPV dla części " + (i+1) + " zamówenia"}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    onChange={(e) => setValueAuctionParts(e, i)}
                                    value={formAuctionParts[i].addCpvCode}
                                    validators={['matchRegexp:^[0-9]*$']}
                                    errorMessages={['Niedozwolone znaki']}
                                    name="addCpvCode">
                                </TextValidator>
                            </FormControl>
                        </Form>
                    </>)
            }
        }
        setForm1(part)
    }, [])



    //const hintsType = useSelector(state => state.hints.hintsType)

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
               {/* <div className="hints">
                    {
                        hintsType.filter(hint => hint.visibility).map((hint, index) =>
                            hint.severity === 'info' ?  <HintsInfo
                                message={hint.message} index={index}/> : <Hints index={index} message={hint.message}/>

                        )
                    }

                </div>*/}
                <ValidatorForm >
                    <Typography className={classes.nameSec}>Wybór rodzaju zamówienia</Typography>
                    {props.edit === true && !!auctionItem
                        ? (
                            <>
                                <Form className={classes.labelRadio} >
                                    <FormControl value={form.orderType} className={classes.label}>
                                        <FormLabel className={classes.labelRadio}>Wybierz główny rodzaj dla całego zamówienia.</FormLabel>
                                        <RadioGroup name="orderType" className={classes.input}  onChange={(e) => {setValue(e); orderTypeHandler(e)}}>
                                            <FormControlLabel value="usługi" name="orderType" control={<Radio color="primary"/>} label="Usługi" />
                                            <FormControlLabel value="dostawy" name="orderType" control={<Radio color="primary"/>} label="Dostawy" />
                                            <FormControlLabel value="budowlane" name="orderType" control={<Radio color="primary"/>} label="Roboty budowlane" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                {
                                    expand && (
                                        <>
                                            <Form className={classes.labelRadio} >
                                                <FormControl value={form.serviceType} className={classes.label}>
                                                    <FormLabel className={classes.labelRadio}>Wybierz rodzaj zamawianej usługi.</FormLabel>
                                                    <RadioGroup name="serviceType" onChange={(e) => {setValue(e); service361Handler(e)}}>
                                                        <FormControlLabel value="klasyczne" name="serviceType" control={<Radio color="primary"/>} label="Usługi klasyczne" />
                                                        <FormControlLabel value="sektorowe" name="serviceType" control={<Radio color="primary"/>} label="Usługi sektorowe" />
                                                        <FormControlLabel value="społeczne" name="serviceType" control={<Radio color="primary"/>} label="Usługi społeczne i inne szczególne usługi" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Form>
                                            {
                                                expand2 && (
                                                    <Form className={classes.labelRadio} >
                                                        <FormControl value={form.service361} className={classes.label}>
                                                            <FormLabel className={classes.labelRadio}>Czy o udzielenie zamówienia na usługi zdrowotne, społeczne oraz kulturalne objęte kodami CPV mogą ubiegać się wyłącznie wykonawcy,
                                                                którzy spełniają łącznie warunki określone w art. 361 ust. 1 </FormLabel>
                                                            <RadioGroup name="service361"  onChange={(e) => {setBooleanValue(e); service361Handler(e)}}>
                                                                <FormControlLabel value='true' name="service361" control={<Radio color="primary"/>} label="Tak" />
                                                                <FormControlLabel value='false' name="service361" control={<Radio color="primary"/>} label="Nie" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Form>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <Typography className={classes.nameSec}>Wybór kodu CPV</Typography>
                                <Form className={classes.labelJ}>
                                    <FormControl className={classes.labelJ}>
                                        <FormLabel  className={classes.labelJ}>Nazwa całego zamówienia</FormLabel><br/>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={formAuction.orderName}
                                            variant="outlined"
                                            disabled
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.labelII}>
                                    <FormControl className={classes.labelJ}>
                                        <FormLabel className={classes.labelJ}>Wybierz główny kod CPV dla całego zamówienia</FormLabel>
                                        <TextValidator
                                            value={formAuction.cpvCode}
                                            defaultValue={auction.cpvCode}
                                            onChange={(e) => {handleChangeCpv(e); setValueAuction(e)}}
                                            validators={['matchRegexp:^[0-9]*$']}
                                            errorMessages={['Niedozwolone znaki']}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            required
                                            name="cpvCode">
                                        </TextValidator>
                                    </FormControl>
                                </Form>
                                <Form className={classes.labelJ}>
                                    <FormControl className={classes.labelJ}>
                                        <FormLabel className={classes.labelJ}>Wybierz dodatkowy kod CPV dla całego zamówienia</FormLabel>
                                        <TextValidator
                                            defaultValue={auction.addCpvCode}
                                            value={formAuction.addCpvCode}
                                            onChange={(e) => {handleChangeCpvAdd(e); setValueAuction(e)}}
                                            validators={['matchRegexp:^[0-9]*$']}
                                            errorMessages={['Niedozwolone znaki']}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            required
                                            name="addCpvCode">
                                        </TextValidator>
                                    </FormControl>
                                </Form>
                            </>
                            ) : (
                            <>
                                <Form className={classes.labelRadio} >
                                    <FormControl value={form.orderType} className={classes.label}>
                                        <FormLabel className={classes.labelRadio}>Wybierz główny rodzaj dla całego zamówienia.</FormLabel>
                                        <RadioGroup name="orderType" className={classes.input} onChange={(e) => {setValue(e); orderTypeHandler(e)}}>
                                            <FormControlLabel value="usługi" name="orderType" control={<Radio required color="primary"/>} label="Usługi" />
                                            <FormControlLabel value="dostawy" name="orderType" control={<Radio color="primary"/>} label="Dostawy" />
                                            <FormControlLabel value="budowlane" name="orderType" control={<Radio color="primary"/>} label="Roboty budowlane" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                {
                                    expand ? (
                                        <React.Fragment>
                                            <Form className={classes.labelRadio} >
                                                <FormControl value={form.serviceType} className={classes.label}>
                                                    <FormLabel className={classes.labelRadio}>Wybierz rodzaj zamawianej usługi.</FormLabel>
                                                    <RadioGroup name="serviceType" className={classes.input} onChange={(e) => {setValue(e); service361Handler(e)}}>
                                                        <FormControlLabel value="klasyczne" name="serviceType" control={<Radio required color="primary"/>} label="Usługi klasyczne" />
                                                        <FormControlLabel value="sektorowe" name="serviceType" control={<Radio color="primary"/>} label="Usługi sektorowe" />
                                                        <FormControlLabel value="społeczne" name="serviceType" control={<Radio color="primary"/>} label="Usługi społeczne i inne szczególne usługi" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Form>
                                            {
                                                expand2 ? (
                                                    <Form className={classes.labelRadio} >
                                                        <FormControl value={form.service361} className={classes.label}>
                                                            <FormLabel className={classes.labelRadio}>Czy o udzielenie zamówienia na usługi zdrowotne, społeczne oraz kulturalne objęte kodami CPV mogą ubiegać się wyłącznie wykonawcy,
                                                                którzy spełniają łącznie warunki określone w art. 361 ust. 1 </FormLabel>
                                                            <RadioGroup name="service361"  onChange={(e) => {setBooleanValue(e); service361Handler(e)}}>
                                                                <FormControlLabel value='true' name="service361" control={<Radio required color="primary"/>} label="Tak" />
                                                                <FormControlLabel value='false' name="service361" control={<Radio color="primary"/>} label="Nie" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Form>
                                                ) : (
                                                    ''
                                                )
                                            }
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )
                                }
                                {/* W polu kodu CPV zamiast classes.label zastosowano 3-krotnie classes.labelJ */}
                                <Typography className={classes.nameSec}>Wybór kodu CPV</Typography>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel  className={classes.labelRadio}>Nazwa całego zamówienia</FormLabel>
                                        <TextValidator
                                            value={formAuction.orderName}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            disabled
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.labelII}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Wybierz główny kod CPV dla całego zamówienia 
                                        <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/kod-cpv/">
                                        <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                        </a>
                                        <a target="_blank" href="https://kody.uzp.gov.pl/">
                                        <CustomizedTooltip content="Link do wyszukiwarki kodów CPV oraz CPC" placement="top" type="link" />
                                        </a>
                                        </FormLabel>
                                        <TextValidator
                                            value={formAuction.cpvCode}
                                            onChange={(e) => {handleChangeCpv(e); setValueAuction(e)}}
                                            validators={['matchRegexp:^[0-9]*$']}
                                            errorMessages={['Niedozwolone znaki']}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            required
                                            name="cpvCode"
                                            placeholder="Sprawdź kod w wyszukiwarce (klinij ikonę linku)">
                                        </TextValidator>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Wybierz dodatkowy kod CPV dla całego zamówienia</FormLabel>
                                        <TextValidator
                                            value={formAuction.addCpvCode}
                                            onChange={(e) => {handleChangeCpvAdd(e); setValueAuction(e)}}
                                            validators={['matchRegexp:^[0-9]*$']}
                                            errorMessages={['Niedozwolone znaki']}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            required
                                            name="addCpvCode">
                                        </TextValidator>
                                    </FormControl>
                                </Form>
                            </>
                            )
                    }
                    {form1}
                    <Button
                        onClick={props.handleBack}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleNext}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}

export default OrderType;