import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const ReverseProcedurePartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }


    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!formAuctionParts[no].ifRreverse && formAuctionParts[no].ifRreverse.toString()} name="ifRreverse">
                            <RadioGroup name="ifRreverse" defaultValue={edit === 'edit' && auctionObject.ifRreverse.toString()} onChange={(e) => setBooleanValueAuctionParts(e, no)}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje odwróconej kolejności oceny ofert" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje odwróconą kolejność oceny ofert" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}


export default ReverseProcedurePartI;