import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../../state/auction/auctionActions";
import {fetchAuctionItemId, fetchAuctionWithId, fetchHint} from "../../services/fetchId";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "../StackedSnackbar";


const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const OrderDescription = ({edit, id}) => {

    const [auction, setAuction] = useState('');
    const [auctionItem, setAuctionItem] = useState('');

    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => {
                setAuction(data);
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auction => auction.auction.slice(14) === id))
            }
        )
    }, [])

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }


    const classes = useStyles();
    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[17].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });

            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };



    return(
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm >
                    {edit ?
                        (
                            <>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Opis przedmiotu zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.auctionDescription}
                                            defaultValue={auctionItem.auctionDescription}
                                            onChange={setValue}
                                            name="auctionDescription"
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            required
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Główny kod CPV</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auction.cpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Dodatkowy kod CPV</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auction.addCpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                            </>
                        ) : (
                            <>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Opis przedmiotu zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.auctionDescription}
                                            onChange={setValue}
                                            name="auctionDescription"
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            required
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Główny kod CPV</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auction.cpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Dodatkowy kod CPV</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auction.addCpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                            </>
                        )
                    }

                </ValidatorForm>
            </AnimationRevealPage>
        </div>

    )
}

export default OrderDescription