
import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {DateTimePicker, DatePicker, TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {fetchCountry1, fetchCustomer, fetchVoivodeship} from "../../services/fetchId";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {MenuItem, TextField, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {
    setDateOpenDateDay,
    setDateOpenDateTime,
    setDateValueAuction,
    setValues
} from "../../state/auction/auctionActions";
import InputMask from "react-input-mask";


const Form = tw.div`mb-5 mx-5 mr-3`;

const OfferOpening = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        labelD: {
            marginBottom: '5%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [country, setCountry] = useState();
    const [voivodeshipForm, setVoivodeshipForm] = useState([])
    const [countryForm, setCountryForm] = useState([])
    const customerId = localStorage.getItem('customerId');
    const [voivodeshipIri, setVoivodeshipIri] = useState();
    const [countryIri, setCountryIri] = useState();
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)) - 1);
        })
        fetchCustomer(customerId).subscribe(data => {
            setCountryId(parseInt(data.addressCountry.slice(15)) - 1);
        })
        fetchVoivodeship().subscribe(data => {
            setVoivodeshipForm(data['hydra:member'])
        })
        fetchCountry1().subscribe(data => {
            setCountryForm(data['hydra:member'])
        })

    }, [])

    useEffect(() => {
        if(!!voivodeshipId){
            fetchVoivodeship().subscribe(data => {
                setVoivodeship(data['hydra:member'][voivodeshipId].name)
                setVoivodeshipIri(data['hydra:member'][voivodeshipId]["@id"])
            })
        }

    }, [voivodeshipId])

    useEffect(() => {
        if(!!countryId){
            fetchCountry1().subscribe(data => {
                setCountry(data['hydra:member'][countryId].englishShortName)
                setCountryIri(data['hydra:member'][countryId]["@id"])
            })
        }

    }, [countryId])



    const classes = useStyles();
    const [date, setDate] = React.useState(new Date());
    const [date1, setDate1] = React.useState(new Date());
    const [postalCode, setPostalCode] = useState();

    const dispatch = useDispatch();

    let form1;

    const handleChangePostalCode = (e) => {
        setPostalCode(e.target.value);
    }

    if(!!customer.addressStreet && !!country && !!voivodeship){
        form1 =
            <>
                <Typography className={classes.nameSec}>Adres otwarcia ofert</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Ulica</FormLabel>
                        <TextValidator
                            value={form.addressOpenStreet}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            defaultValue={edit === 'edit' ? (auctionItemObject.addressOpenStreet) : (customer.addressStreet)}
                            name="addressOpenStreet"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Nr domu</FormLabel>
                        <TextValidator
                            value={form.addressHouseNumber}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            defaultValue={edit === 'edit' ? (auctionItemObject.addressOpenStreet) : (customer.addressHouseNumber)}
                            name="addressOpenHouseNumber"
                        />
                    </FormControl>
                </Form>
{/*                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                        <TextValidator
                            value={form.addressZipCode}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            defaultValue={customer.addressZipCode}
                            name="addressOpenZipCode"
                        />
                    </FormControl>
                </Form>*/}
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                    <InputMask
                        mask="99-999"
                        defaultValue={edit === 'edit' ? (auctionItemObject.addressOpenStreet) : (customer.addressZipCode)}
                        value={form.addressZipCode}
                        onChange={(e) => {setValue(e); handleChangePostalCode(e)}}
                        value={postalCode}
                        disabled={false}
                        maskChar=" "
                    >
                        {() => <TextField
                                          className={classes.input}
                                          type="text"
                                          id="outlined-basic-u"
                                          label="Kod pocztowy"
                                          variant="outlined"
                                          required
                                          name="addressZipCode"/>}
                    </InputMask>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Miejscowość</FormLabel>
                        <TextValidator
                            value={form.addressCity}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            defaultValue={edit === 'edit' ? (auctionItemObject.addressOpenStreet) : (customer.addressCity)}
                            name="addressOpenCity"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Państwo</FormLabel>
                    <TextValidator
                        value={form.addressOpenCountry}
                        onChange={setValue}
                        name="addressOpenCountry"
                        className={classes.input}
                        id="outlined-basic-u"
                        select
                        variant="outlined"
                        defaultValue={edit === 'edit' ? countryIri : ''}
                    >
                        <MenuItem value={countryIri} data-text={country}>{country}</MenuItem>
                        {countryForm.map((c, index) =>{

                                return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                            }
                        )}

                    </TextValidator>

                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Województwo</FormLabel>
                    <TextValidator
                        value={form.addressOpenVoivodeship}
                        onChange={setValue}
                        name="addressOpenVoivodeship"
                        id="outlined-basic-u"
                        className={classes.input}
                        select
                        required
                        variant="outlined"
                        defaultValue={edit === 'edit' ? voivodeshipIri : ''}
                    >
                        <MenuItem value={voivodeshipIri}>{voivodeship}</MenuItem>
                        {voivodeshipForm.map((c, index) =>{
                                return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                            }
                        )}
                    </TextValidator>
                </Form>
                {/*<Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Województwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={voivodeship}
                            name="addressOpenVoivodeship"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Państwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            name="addressOpenCountry"
                            value={country}
                        />
                    </FormControl>
                </Form>*/}
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}> Termin otwarcia ofert</FormLabel>
                            <TextValidator
                                value={form.offerOpening}
                                onChange={setValue}
                                name="offerOpening"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                defaultValue={edit === 'edit' ? (auctionItemObject.offerOpening) : 'Tekst wstawiony przez system'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    {form1}
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Dodatkowe informacje
                                <Tooltip title="Np. nr pokoju" placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <TextValidator
                                value={form.addInfoOfferOpening}
                                defaultValue={edit === 'edit' ? (auctionItemObject.addInfoOfferOpening) : ''}
                                onChange={setValue}
                                name="addInfoOfferOpening"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label={'Dodatkowe informacje'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="openDateDay">
                            <FormLabel className={classes.labelD}>Termin otwarcia ofert</FormLabel>
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} name="openDateDay" utils={DateFnsUtils}>
                                <DatePicker
                                    name="openDateDay"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    value={date}
                                    onChange={(e) => {setDate(e); dispatch(setDateOpenDateDay(e))}}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="openDateTime">
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} name="openDateTime" utils={DateFnsUtils}>
                                <TimePicker
                                    name="openDateTime"
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={date1}
                                    onChange={(e) => {setDate1(e); dispatch(setDateOpenDateTime(e))}}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Form>
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default OfferOpening;