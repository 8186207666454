import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import {createMuiTheme, MenuItem, TextField, Tooltip} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useSelector} from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {fetchCustomer} from "../../services/fetchId";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import {setDateValueAuction} from "../../state/auction/auctionActions";
import InputMask from "react-input-mask";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
export const theme = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '0.175rem',
            }
        }
    }
});

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl `;
const Form = tw.div`flex items-center justify-between`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100%',
        marginRight: '35% !important',
        marginTop: '1%',
        maxWidth: '800px !important',
        minWidth: '350px !important',
    },
    label: {
        fontSize: '1rem !important',
    },
    labelRadio: {
        maxWidth: '750px !important',
        minWidth: '200px !important',
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelNormal: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem !important',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.2em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        borderBottom: '1px solid black',
        padding: '1%',
        marginBottom: '2%',
        marginTop: '2%'
    },
    root: {
        flexGrow: 1,
        alignItems: 'center',
        fontSize: '1em !important'
    },

    control: {
        padding: theme.spacing(2),
    },
}));

const Summary = ({id, auctionItemObject}) => {

    const classes = useStyles();
    const form = useSelector(state => state.auction.form);
    const [customer, setCustomer] = useState([]);
    const customerId = localStorage.getItem('customerId')
    const [isSector, setIsSector] = useState(false);;

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
        })

    }, [])


    return (
        <>
            <ValidatorForm>
                <Typography className={classes.nameSec}>Oferty Wariantowe</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={form.ifVariantOffers}  name="ifVariantOffers">
                        <RadioGroup name="ifVariantOffers" value={form.ifVariantOffers}>
                            <FormControlLabel disabled value="Zamawiający nie dopuszcza składania ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza składania ofert wariantowych" />
                            <FormControlLabel disabled value="Zamawiający dopuszcza składanie ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający dopuszcza składanie ofert wariantowych" />
                            <FormControlLabel disabled value="Zamawiający wymaga złożenia ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający wymaga złożenia ofert wariantowych" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.ifVariantOffers === 'Zamawiający dopuszcza składanie ofert wariantowych' || "Zamawiający wymaga złożenia ofert wariantowych" === 3 && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Informacje o sposobie przedstawiania ofert wariantowych oraz minimalne warunki, jakim muszą odpowiadać oferty wariantowe
                                </FormLabel>
                                <TextValidator
                                    value={form.variantOffers}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    name="variantOffers"
                                    label="Informacje o sposobie przedstawiania ofert wariantowych"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Środki komunikacji elektronicznej</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres poczty elektronicznej, na który wykonawca prześle ofertę</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={auctionItemObject.emailOffer}
                            variant="outlined"
                            disabled/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Informacje o wymaganiach technicznych i organizacyjnych sporządzania, wysyłania i odbierania korespondencji elektronicznej, w tym informacje dotyczące wymogu użycia podpisu elektronicznego</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={auctionItemObject.emailRequirements}
                            variant="outlined"
                            disabled/>
                </Form>
                <Typography className={classes.nameSec}>Inny sposób komunikowania</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Informacje o sposobie komunikowania się zamawiającego z wykonawcami w inny sposób niż przy użyciu środków komunikacji elektronicznej w przypadku zaistnienia jednej z sytuacji określonych w art. 65 ust. 1, art. 66 i art. 69</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value="wnioski o dopuszczenie do udziału w postępowaniu lub konkursie, wnioski, o których mowa w art. 371 ust. 3, oferty lub ich części składa się, pod rygorem nieważności, w formie pisemnej"
                            variant="outlined"
                            multiline
                            row={5}
                            disabled/>
                </Form>
                <Typography className={classes.nameSec}>Osoby uprawnione do komunikowania</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Osoby uprawnione do komunikowania się z wykonawcami</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={customer.contactPerson}
                            variant="outlined"
                            disabled/>
                </Form>
                <Typography className={classes.nameSec}>Termin związania z ofertą</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Termin związania ofertą - ilość dni:
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            value={form.offersDays}
                            type="text"
                            disabled
                            name="offersDays"
                            id="outlined-basic-u"
                            label="Termin związania ofertą - ilość dni"
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Termin związania ofertą - od dnia:</FormLabel>
                    <TextValidator
                        className={classes.input}
                        value={form.offersDate}
                        type="text"
                        disabled
                        name="offersDays"
                        id="outlined-basic-u"
                        variant="outlined"
                    />
                </Form>
                <Typography className={classes.nameSec}>Przygotowanie oferty</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Opis sposobu przygotowania oferty
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            name="offerPreparation"
                            id="outlined-basic-u"
                            value={form.offerPreparation}
                            disabled
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Katalogi elektroniczne</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={form.ifCatalogs} name="ifCatalogs">
                        <RadioGroup name="ifCatalogs" value={form.ifCatalogs}>
                            <FormControlLabel disabled value="Zamawiający nie wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający nie wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                            <FormControlLabel disabled value="Zamawiający dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                            <FormControlLabel disabled value="Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" control={<Radio color="primary"/>} label="Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.infoCatalogs === 'Zamawiający dopuszcza złożenie ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych' || form.infoCatalogs === 'Zamawiający wymaga złożenia ofert w postaci katalogów elektronicznych lub dołączenia do ofert katalogów elektronicznych' && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Informacje dotyczące formatu, parametrów wykorzystywanego sprzętu elektronicznego oraz technicznych warunków i specyfikacji połączenia dotyczących przekazywania zamawiającemu katalogu elektronicznego
                                </FormLabel>
                                <TextValidator
                                    name="infoCatalogs"
                                    value={form.infoCatalogs}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    label="Informacje dotyczące sprzętu elektronicznego"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Składanie ofert</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}> Sposób oraz termin składania ofert
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            value={form.sumbission}
                            name="sumbission"
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres strony internetowej, na który nalezy przesyłać drogą elektroniczną oferty lub wnioski o dopuszczenie do udziału w postępowaniu
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={auctionItemObject.emailOffer}
                            variant="outlined"
                            disabled
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Dodatkowe informacje</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            name="addInfoSubmission"
                            value={form.addInfoSubmission}
                            disabled
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Termin składania ofert</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        name="sumbissionDateDay"
                        value={form.sumbissionDateDay}
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Godzina:</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        name="sumbissionDateTime"
                        value={form.sumbissionDateTime}
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Typography className={classes.nameSec}>Otwarcie ofert</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}> Termin otwarcia ofert</FormLabel>
                        <TextValidator
                            value={form.offerOpening}
                            name="offerOpening"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                {
                        (!!customer.addressStreet) && (
                            <>
                                <Typography className={classes.nameSec}>Adres otwarcia ofert</Typography>
                                <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Ulica</FormLabel>
                                        <TextValidator
                                            value={form.addressOpenStreet}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            disabled
                                            name="addressOpenStreet"
                                        />
                                </Form>
                                <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nr domu</FormLabel>
                                        <TextValidator
                                            value={form.addressHouseNumber}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            disabled
                                            name="addressOpenHouseNumber"
                                        />
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                    <TextValidator
                                        value={form.addressZipCode}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        disabled
                                        name="addressZipCode"
                                    />
                                </Form>
                                <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Miejscowość</FormLabel>
                                        <TextValidator
                                            value={form.addressCity}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            name="addressOpenCity"
                                            disabled
                                        />
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Państwo</FormLabel>
                                    <TextValidator
                                        value={form.addressOpenCountry}
                                        name="addressOpenCountry"
                                        className={classes.input}
                                        id="outlined-basic-u"
                                        disabled
                                        variant="outlined"/>
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Województwo</FormLabel>
                                    <TextValidator
                                        value={form.addressOpenVoivodeship}
                                        name="addressOpenVoivodeship"
                                        id="outlined-basic-u"
                                        className={classes.input}
                                        disabled
                                        variant="outlined"/>
                                </Form>
                            </>
                        )
                }
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Dodatkowe informacje
                            <Tooltip title="Np. nr pokoju" placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            value={form.addInfoOfferOpening}
                            disabled
                            name="addInfoOfferOpening"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label={'Dodatkowe informacje'}
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Termin otwarcia ofert</FormLabel>
                    <TextValidator
                        value={form.openDateDay}
                        disabled
                        name="openDateDay"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Godzina:</FormLabel>
                    <TextValidator
                        value={form.openDateTime}
                        disabled
                        name="openDateTime"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                    />
                </Form>
                <Typography className={classes.nameSec}>Wykluczenie wykonawcy z art. 108</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Podstawy wykluczenia, o których mowa w art. 108 ust. 1
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={'Tekst wstawiony przez program'}
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!form.exclusion && form.exclusion.toString()} name="exclusion">
                        <RadioGroup name="exclusion" value={!!form.exclusion && form.exclusion.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje wykluczenia, o którym mowa w art. 109, ust. 1" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje wykluczenie, o którym mowa w art. 109, ust. 1" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                <Typography className={classes.nameSec}>Wykluczenie wykonawcy z art. 109</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!form.exclusion1 && form.exclusion1.toString()} name="exclusion1">
                        <RadioGroup name="exclusion1" value={!!form.exclusion1 && form.exclusion1.toString()} >
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje wykluczenia, o którym mowa w art. 109, ust. 1" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje wykluczenie, o którym mowa w art. 109, ust. 1" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.exclusion1 === true && (
                        <>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Podstawy wykluczenia, o których mowa w art. 109 ust. 1
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Tekst wstawiony przez program'}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} value={!!form.exclusion2 && form.exclusion2.toString()} name="exclusion2">
                                    <RadioGroup name="exclusion2" value={!!form.exclusion2 && form.exclusion2.toString()}>
                                        <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie będzie badał, czy nie zachodzą wobec podwykonawcy niebędącego podmiotem udostępniającym zasoby podstawy wykluczenia" />
                                        <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający będzie badał, czy nie zachodzą wobec podwykonawcy niebędącego podmiotem udostępniającym zasoby podstawy wykluczenia" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>

                        </>
                    )
                }
                {
                    isSector && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="exclusion3" value={!!form.exclusion3 && form.exclusion3.toString()}>
                                    <RadioGroup name="exclusion3" value={!!form.exclusion3 && form.exclusion3.toString()}>
                                        <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie zastosuje podstaw wykluczenia innych niż określony w art. 108 i art. 109" />
                                        <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający zastosuje podstawy wykluczenia inne niż określone w art. 108 i art. 109" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                form.exclusion3 === true && (
                                    <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Wymagane podmiotowe środki dowodowe
                                            </FormLabel>
                                            <TextValidator
                                                name="exclusion4"
                                                value={form.exclusion4}
                                                disabled
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                label="Wymagane podmiotowe środki dowodowe "
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                            />
                                    </Form>
                                )
                            }
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Rozliczenia w walutach obcych</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!form.currency && form.currency} name="currency">
                        <RadioGroup name="currency" value={!!form.currency && form.currency}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje możliwości prowadzenia rozliczeń między zamawiającym, a wykonawcą w walutach obcych" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość prowadzenia rozliczeń między zamawiającym, a wykonawcą w walutach obcych" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.currency === true && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Warunki przeprowadzenia rozliczeń w walutach obcych
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    value={form.currency1}
                                    disabled
                                    name="currency1"
                                    id="outlined-basic-u"
                                    label="Warunki przeprowadzenia rozliczeń"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Zwrot kosztów udziału w postępowaniu</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!form.refund && form.refund.toString()} name="refund">
                        <RadioGroup name="refund" value={!!form.refund && form.refund.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje możliwości zwrotu kosztów udziału w postępowaniu" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość zwrotu kosztów udziału w postępowaniu" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.refund === true && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Warunki zwrotu kosztów udziału w postępowaniu
                                </FormLabel>
                                <TextValidator
                                    value={form.refundConditions}
                                    name="refundConditions"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    label="Warunki zwrotu kosztów"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Formalności w celu zawarcia umowy</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Informacje o formalnościach, jakie muszą zostać dopełnione po wyborze oferty w celu zawarcia umowy w sprawie zamówienia publicznego
                        </FormLabel>
                        <TextValidator
                            value={form.paperwork}
                            disabled
                            name="paperwork"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Środki ochrony prawnej</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Pouczenie o środkach ochrony prawnej przysługujących wykonawcy
                        </FormLabel>
                        <TextValidator
                            name="legalProtection"
                            value={form.legalProtection}
                            disabled
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                {
                    auctionItemObject.ueFunds === true && (
                        <>
                            <Typography className={classes.nameSec}>Organ odpowiedzialny za procedury odwoławcze</Typography>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Oficjalna nazwa</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Krajowa Izba Odwoławcza'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Adres pocztowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'ul. Postępu 17A'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Miejscowość</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Warszawa'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'02-676'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Państwo</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Polska'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>E-mail</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'odwolania@uzp.gov.pl'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Telefon</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'+48 224587840'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Adres Internetowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'www.uzp.gov.pl/kio'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Faks</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'+48 224587800'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Typography className={classes.nameSec}>Składanie odwołań</Typography>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Dokładne informacje na temat terminów składania odwołań
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        id="outlined-basic-u"
                                        value={'1. Czynność zamawiającego, jeżeli informacje o czynności zamawiającego stanowiącej podstawę wniesienia odwołania zostały przekazane w sposób określony w art. 180 ust. 5 ustawy Pzp - 10 dni. \n' +
                                        '2. Czynność zamawiającego, jeżeli informacje o czynności zamawiającego stanowiącej podstawę wniesienia odwołania zostały przekazane w sposób inny niż określony w art. 180 ust. 5 ustawy Pzp - 15 dni. \n' +
                                        '3. Treść ogłoszenia o zamówieniu od dnia jego publikacji w Dzienniku Urzędowym UE, a jeźeli postępowanie prowadzone jest w trybie przetargu nieograniczonego, także wobec postanowień SIWZ licząc od publikacji ogłoszenia w ww. Dzienniku lub zamieszczenia SIWZ na stronie internetowej - 10 dni. \n' +
                                        '4. Wobec czynności innych niż określone w punktach od 1 do 3 odwołanie wnosi się w terminie liczonym od dnia, w którym powzięto lub przy zachowaniu należytej staranności można było powziąć wiadomość o okolicznościach stanowiących podstawę jego wzniesienia - 10 dni' }
                                        variant="outlined"
                                        multiline
                                        rows={20}
                                    />
                            </Form>
                            <Typography className={classes.nameSec}>Źródło gdzie można uzyskać informacje na temat składania odwołań</Typography>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Oficjalna nazwa</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Krajowa Izba Odwoławcza'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Adres pocztowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'ul. Postępu 17A'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Miejscowość</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Warszawa'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'02-676'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Państwo</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'Polska'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>E-mail</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'odwolania@uzp.gov.pl'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Telefon</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'+48 224587840'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Adres Internetowy</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'www.uzp.gov.pl/kio'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label} >
                                    <FormLabel className={classes.label}>Faks</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={'+48 224587800'}
                                        variant="outlined"
                                        disabled
                                    />
                            </Form>
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Podwykonawcy</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Podwykonawcy
                        </FormLabel>
                        <TextValidator
                            value={form.subcontractors}
                            disabled
                            name="subcontractors"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>RODO</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Informacje dotyczące przetwarzania danych osobowych (RODO)</FormLabel>
                        <TextValidator
                            value={form.rodo}
                            disabled
                            name="rodo"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Postanowienia końcowe</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Informacje, które są pomocne do prawidłowej realizacji zamówienia, a które nie zostały podane w powyższych punktach.
                        </FormLabel>
                        <TextValidator
                            value={form.helpInfo}
                            disabled
                            name="helpInfo"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Pomocne informacje"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Wykaz załączników</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Wykaz załączników do specyfikacji warunków zmaówienia
                        </FormLabel>
                        <TextValidator
                            disabled
                            value={form.attachments}
                            name="attachments"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Wykaz załączników"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Zatwierdzenie specyfikacji</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Miejscowość
                        </FormLabel>
                        <TextValidator
                            disabled
                            value={form.approvalCity}
                            name="approvalCity"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Miejscowość"
                            variant="outlined"
                        />
                </Form>
            </ValidatorForm>
        </>
    )
}

export default Summary;