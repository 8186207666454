import React, {useEffect, useState} from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from 'react-redux';
import {
    closeHint,
    closeHintMyAccount,
    closeHintPriorNotice,
    closeHintSecond,
    toggleHints
} from "../state/hints/hintsActions";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    color: {
        backgroundColor: ' #f56565 !important',
        fontSize: '0.6em',
    },
    root: {
        display: 'flex',
    }
}));


const HintsInfo = (props) => {

    const dispatch = useDispatch();
    const classes = useStyles();


    const handleHintsClosing = () => {
        dispatch(closeHint(props.index))
    }



    return(
        <React.Fragment>
            <div className={classes.root}><Snackbar
                id="hint"
                className={classes.root}
                style={{width: '80%'}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open
            ><MuiAlert
                severity="warning"
                elevation={6}
                variant="filled"
                className={classes.color}
                action={
                    <React.Fragment>
                        <IconButton
                            className={classes.close}
                            aria-label="close"
                            color="inherit"
                            onClick={handleHintsClosing}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }>
                {props.message}
            </MuiAlert>
            </Snackbar>
            </div>
        </React.Fragment>
    )
}

export default HintsInfo;