import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";


const Form = tw.div`mb-5 mx-5 mr-3`;

const LegalProtection = ({form, setValue, auctionItemObject, edit}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    let formUE;

    if(auctionItemObject.ueFunds === true){
        formUE =
            <>
                <Typography className={classes.nameSec}>Organ odpowiedzialny za procedury odwoławcze</Typography>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Oficjalna nazwa</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Krajowa Izba Odwoławcza'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Adres pocztowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'ul. Postępu 17A'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Miejscowość</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Warszawa'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'02-676'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Państwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Polska'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>E-mail</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'odwolania@uzp.gov.pl'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Telefon</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'+48 224587840'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Adres Internetowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'www.uzp.gov.pl/kio'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Faks</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'+48 224587800'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Typography className={classes.nameSec}>Składanie odwołań</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Dokładne informacje na temat terminów składania odwołań
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            disabled
                            id="outlined-basic-u"
                            value={'1. Czynność zamawiającego, jeżeli informacje o czynności zamawiającego stanowiącej podstawę wniesienia odwołania zostały przekazane w sposób określony w art. 180 ust. 5 ustawy Pzp - 10 dni. \n' +
                            '2. Czynność zamawiającego, jeżeli informacje o czynności zamawiającego stanowiącej podstawę wniesienia odwołania zostały przekazane w sposób inny niż określony w art. 180 ust. 5 ustawy Pzp - 15 dni. \n' +
                            '3. Treść ogłoszenia o zamówieniu od dnia jego publikacji w Dzienniku Urzędowym UE, a jeźeli postępowanie prowadzone jest w trybie przetargu nieograniczonego, także wobec postanowień SIWZ licząc od publikacji ogłoszenia w ww. Dzienniku lub zamieszczenia SIWZ na stronie internetowej - 10 dni. \n' +
                            '4. Wobec czynności innych niż określone w punktach od 1 do 3 odwołanie wnosi się w terminie liczonym od dnia, w którym powzięto lub przy zachowaniu należytej staranności można było powziąć wiadomość o okolicznościach stanowiących podstawę jego wzniesienia - 10 dni' }
                            variant="outlined"
                            multiline
                            rows={20}
                        />
                    </FormControl>
                </Form>
                <Typography className={classes.nameSec}>Źródło gdzie można uzyskać informacje na temat składania odwołań</Typography>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Oficjalna nazwa</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Krajowa Izba Odwoławcza'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Adres pocztowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'ul. Postępu 17A'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Miejscowość</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Warszawa'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'02-676'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Państwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Polska'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>E-mail</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'odwolania@uzp.gov.pl'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Telefon</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'+48 224587840'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Adres Internetowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'www.uzp.gov.pl/kio'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label} >
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Faks</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'+48 224587800'}
                            variant="outlined"
                            disabled
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Pouczenie o środkach ochrony prawnej przysługujących wykonawcy
                            </FormLabel>
                            <TextValidator
                                name="legalProtection"
                                value={form.legalProtection}
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                defaultValue={edit === 'edit' ? (auctionItemObject.legalProtection) : 'Tekst wstawiony przez system'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    {formUE}
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default LegalProtection;