import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import {makeStyles, Popover} from "@material-ui/core";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";
import {fetchAuctionItemId} from "../services/fetchId";
import {Link} from "react-router-dom";

const ButtonLink = tw(PrimaryButton)`mb-3! mt-3! w-40 text-base inline-block`;

export default function ChoosingSwz({open, setOpen, rowId, anchorEl}) {
    const useStyles = makeStyles({
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '2% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
    });

    const classes = useStyles();
    const [auction, setAuction] = useState('');
    const [buttons, setButtons] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuction(data['hydra:member'].filter(auction => auction.auction.slice(14) === rowId)[0])
            }
        )
    }, [rowId])

    useEffect(() => {
        if(!!auction){
            if(auction.auctionItemParts.length === 2){
                setButtons(
                    <>
                        <Link to={"orderSpecificationParts/edit/" + rowId + "/0"}>
                            <ButtonLink>SWZ część I</ButtonLink>
                        </Link>
                        <Link to={"orderSpecificationParts/edit/" + rowId + "/1"}>
                            <ButtonLink>SWZ część II</ButtonLink>
                        </Link>
                    </>
                )
            }else if(auction.auctionItemParts.length === 3){
                setButtons(
                    <>
                        <Link to={"orderSpecificationParts/edit/" + rowId + "/0"}>
                            <ButtonLink>SWZ część I</ButtonLink>
                        </Link>
                        <Link to={"orderSpecificationParts/edit/" + rowId + "/1"}>
                            <ButtonLink>SWZ część II</ButtonLink>
                        </Link>
                        <Link to={"orderSpecificationParts/edit/" + rowId + "/2"}>
                            <ButtonLink>SWZ część III</ButtonLink>
                        </Link>

                    </>
                )
            }
            console.log(auction.auctionItemParts)
        }
    }, [auction])

    return (
        <div>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'right',
                    horizontal: 'right',
                }}>
                <div style={{padding: '15px'}}>
                    <Typography className={classes.nameSec}>EDYTUJ</Typography>
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <Link to={"orderSpecification/edit/" + rowId}>
                            <ButtonLink>SWZ</ButtonLink>
                        </Link>
                        {buttons}
                    </div>
                </div>
            </Popover>
        </div>
    );
}