import React, {useEffect, useMemo, useState} from "react";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import {Card, makeStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {
    fetchBipClientType1, fetchBipClientType2,
    fetchBipCoreBusiness, fetchCountry1,fetchCountry6,
    fetchCustomer, fetchCustomers, fetchDuueClientType, fetchDuueCoreBusiness,
    fetchVoivodeship
} from "../services/fetchId";
import tw from "twin.macro";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import {PrimaryButton} from "../components/misc/Buttons";
import {Link} from "react-router-dom";

const Form = tw.div``;
const Button = tw(PrimaryButton)`mb-5! mt-3! w-full text-base inline-block `;

const OrderData = () => {

    const useStyles = makeStyles({
        root: {
            maxWidth: 550,
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '5% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
        inputDiv: {
            display: 'flex',
            alignItems: 'left',
            flexDirection: 'column'
        },
        input: {
            width: '100%',
            marginTop: 10,
            marginBottom:12,
        },
        label: {
            marginTop: 20,
            marginBottom: '1%',
            fontWeight: 'bold',
            fontSize: 'large'
        }
    });

    const classes = useStyles();

    const [customer, setCustomer] = useState([]);
    const [bipClientTypeId, setBipClientTypeId] = useState();
    const [bipClientType, setBipClientType] = useState();
    const [bipCoreBusinessId, setBipCoreBusinessId] = useState();
    const [bipCoreBusiness, setBipCoreBusiness] = useState();
    const [duueClientTypeId, setDuueClientTypeId] = useState();
    const [duueClientType, setDuueClientType] = useState();
    const [duueCoreBusinessId, setDuueCoreBusinessId] = useState();
    const [duueCoreBusiness, setDuueCoreBusiness] = useState();
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [country, setCountry] = useState();
    // const prevCustomerId = localStorage.getItem('customerId');
    // const [customerId, setCustomerId] = useState(localStorage.getItem('customerId'));
    const customerId = localStorage.getItem('customerId');
    // const userId = localStorage.getItem('userId');

    // useEffect(() => {
    //     fetchCustomers().subscribe(data => {
    //         setCustomerId(data['hydra:member'].filter(c => c.owner.slice(11) === userId)[0]['@id'].slice(15))
    //     })
    // }, [])

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
        })

        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)));
        })

        fetchCustomer(customerId).subscribe(data => {
            setBipCoreBusinessId(parseInt(data.bipCoreBusiness.slice(25)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setBipClientTypeId(parseInt(data.bipClientType.slice(22)));
        })

        fetchCustomer(customerId).subscribe(data => {
            setCountryId((parseInt(data.addressCountry.slice(15))%30)-1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setDuueCoreBusinessId(parseInt(data.duueCoreBusiness.slice(26)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setDuueClientTypeId(parseInt(data.duueClientType.slice(23)));
        })

    }, [customerId])


    if(!!bipClientTypeId && bipClientTypeId<=30){
        fetchBipClientType1().subscribe(data => {
            setBipClientType(data['hydra:member'][bipClientTypeId-1].name)
        })
    }

    if(!!bipClientTypeId && bipClientTypeId>30){
        fetchBipClientType2().subscribe(data => {
            setBipClientType(data['hydra:member'][bipClientTypeId-31].name)
        })
    }

    if(!!bipCoreBusinessId){
        fetchBipCoreBusiness().subscribe(data => {
            setBipCoreBusiness(data['hydra:member'][bipCoreBusinessId].name)
        })
    }

    if(!!duueClientTypeId){
        fetchDuueClientType().subscribe(data => {
            setDuueClientType(data['hydra:member'][duueClientTypeId-1].name)
        })
    }

    if(!!duueCoreBusinessId){
        fetchDuueCoreBusiness().subscribe(data => {
            setDuueCoreBusiness(data['hydra:member'][duueCoreBusinessId].name)
        })
    }

    if(!!voivodeshipId){
        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'][voivodeshipId-1].name)
        })
    }

    if(!!countryId){
        fetchCountry6().subscribe(data => {
            setCountry(data['hydra:member'][countryId].polishShortName)
        })
    }


    return(
        <>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <AnimationRevealPage>
                <div className="content content-left" style={{display: 'flex', justifyContent: 'center'}}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <div className={classes.header}>
                                <Typography className={classes.nameSec}>DANE ZAMAWIAJĄCEGO</Typography>
                            </div>
                            <Link to="/EditOrderData">
                                <Button  style={{display: 'flex', justifyContent: 'center', width: '80%', margin: 'auto'}}>
                                    Edytuj Dane Zamawiającego
                                    <RecentActorsIcon style={{marginLeft:'15px'}}/>
                                </Button>
                            </Link>
                            <ValidatorForm>
                                <div className={classes.inputDiv}>
                                    <FormLabel className={classes.label}>Nazwa Zamawiającego</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.name}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Oddział Zamawiającego</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.department}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Ulica</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.addressStreet}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Nr domu</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.addressHouseNumber}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.addressZipCode}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Miejscowość
                                    </FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.addressCity}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Województwo</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={voivodeship}
                                            variant="outlined"
                                            disabled/>
                                    </Form> <FormLabel className={classes.label}>Państwo
                                </FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={country}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Kod NUTS</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.nutsCode}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>NIP</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.nip}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>REGON (krajowy numer identyfikacyjny)</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.regon}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Adres e-mail Zamawiającego</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.email}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Adres strony internetowej Zamawiającego (URL)</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.www}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Osoba do kontaktu</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.contactPerson}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Numer telefonu </FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.phoneNumber}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Numer faksu</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.faxNumber}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Skrzynka ePUAP </FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.epuap}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Informacje dodatkowe</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={customer.additionalInformation}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Rodzaj zamawiającego do ogłoszenia 
                                        w Biuletynie Zamówień Publicznych</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={bipClientType}
                                            variant="outlined"
                                            disabled/>
                                            {/* <div>{bipClientType}</div> */}
                                    </Form>
                                    <FormLabel className={classes.label}>Rodzaj zamawiającego do ogłoszenia 
                                        w Dzienniku Urzędowym Unii Europejskiej</FormLabel>
                                    <Form>
                                        <TextValidator
                                        multiline
                                            className={classes.input}
                                            // type="text"
                                            id="outlined-basic-u"
                                            value={duueClientType}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Przedmiot działalności
                                        Zamawiającego zgodnie z klasyfikacją w
                                        ogłoszeniu w Biuletynie Zamówień
                                        Publicznych</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={bipCoreBusiness}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                    <FormLabel className={classes.label}>Przedmiot działalności
                                        Zamawiającego zgodnie z
                                        klasyfikacją w ogłoszeniu w
                                        Dzienniku Urzędowym Unii
                                        Europejskiej</FormLabel>
                                    <Form>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            value={duueCoreBusiness}
                                            variant="outlined"
                                            disabled/>
                                    </Form>
                                </div>
                            </ValidatorForm>
                        </CardContent>
                    </Card>
                </div>

            </AnimationRevealPage>
        </>
    )
}

export default OrderData