import React, {useEffect, useState} from "react";
import {fetchCustomerId, fetchUserId} from "../services/fetchId";
import Spinner from 'react-spinner-material';


const LoginLoading = () => {

    const [userId, setUserId] = useState();
    const [customerId, setCustomerId] = useState();


   useEffect(() => {
        fetchUserId().subscribe(
            data => {
                const userId = data['hydra:member'][0].logged.slice(11);
                setUserId(userId);
                localStorage.setItem('userId', userId);
                fetchCustomerId(userId).subscribe(
                    data => {
                        localStorage.setItem('user', data.customer)
                        if (!data.customer){
                            window.location.replace('/myAccountReg');
                        } else if (!!data.customer){
                            window.location.replace('/myAccount');
                        }
                        const customerId = data.customer.slice(15);
                        setCustomerId(customerId);
                        localStorage.setItem('customerId', customerId);
                    }
                )
            }
        )


    }, []);



    useEffect(()=>{
        fetchUserId().subscribe(
            data => {
                setUserId(data['hydra:member'][0].logged.slice(11))}
        )
    }, [])



    return (
        <div style={{position: 'absolute', top: '40%', left: '46%'}}>
            <Spinner radius={120} color={'#6415FF'} stroke={10} visible={true}/>
        </div>
    )
}

export default LoginLoading;