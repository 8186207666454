import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setValues, setValuesAuction} from "../../state/auction/auctionActions";


const Form = tw.div`mb-5 mx-5 mr-3`;

const VariantOffers = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState();
    const dispatch = useDispatch();

    const handleChange1 = (e) => {
        if(e.target.value === 'Zamawiający nie dopuszcza składania ofert wariantowych'){
            setAddValue1(1);
        }else if(e.target.value === 'Zamawiający dopuszcza składanie ofert wariantowych'){
            setAddValue1(2);
        }else if(e.target.value === 'Zamawiający wymaga złożenia ofert wariantowych'){
            setAddValue1(3);
        }
    }

    let form1 = '';

    if (addValue1 === 2 || addValue1 === 3){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Informacje o sposobie przedstawiania ofert wariantowych oraz minimalne warunki, jakim muszą odpowiadać oferty wariantowe
                        </FormLabel>
                        <TextValidator
                            value={form.variantOffers}
                            onChange={setValue}
                            defaultValue={edit === 'edit' ? (auctionItemObject.variantOffers) : ''}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            required
                            name="variantOffers"
                            label="Informacje o sposobie przedstawiania ofert wariantowych"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={form.ifVariantOffers} name="ifVariantOffers">
                            <RadioGroup name="ifVariantOffers" defaultValue={edit === 'edit' && auctionItemObject.ifVariantOffers} onChange={(e) => {setValue(e); handleChange1(e)}}>
                                <FormControlLabel value="Zamawiający nie dopuszcza składania ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza składania ofert wariantowych" />
                                <FormControlLabel value="Zamawiający dopuszcza składanie ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający dopuszcza składanie ofert wariantowych" />
                                <FormControlLabel value="Zamawiający wymaga złożenia ofert wariantowych" control={<Radio color="primary"/>} label="Zamawiający wymaga złożenia ofert wariantowych" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default VariantOffers;