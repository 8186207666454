import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Paperwork = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Informacje o formalnościach, jakie muszą zostać dopełnione po wyborze oferty w celu zawarcia umowy w sprawie zamówienia publicznego
                            </FormLabel>
                            <TextValidator
                                value={form.paperwork}
                                onChange={setValue}
                                name="paperwork"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                defaultValue={edit === 'edit' ? (auctionItemObject.paperwork) : 'Tekst wstawiony przez system'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Paperwork;