
import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {DateTimePicker, DatePicker, TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    setDateSubmissionDateDay, setDateSubmissionDateTime,
    setDateValue,
    setDateValueAuction,
    setValues
} from "../../state/auction/auctionActions";
import {useDispatch, useSelector} from "react-redux";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Submission = ({auctionItemObject, setValue, form, edit}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        labelD: {
            marginBottom: '5%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [date, setDate] = React.useState(new Date());
    const dispatch = useDispatch();

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}> Sposób oraz termin składania ofert
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                value={form.sumbission}
                                onChange={setValue}
                                name="sumbission"
                                type="text"
                                id="outlined-basic-u"
                                defaultValue={edit === 'edit' ? (auctionItemObject.sumbission) : 'Tekst wstawiony przez system'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Adres strony internetowej, na który nalezy przesyłać drogą elektroniczną oferty lub wnioski o dopuszczenie do udziału w postępowaniu
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                value={auctionItemObject.emailOffer}
                                variant="outlined"
                                disabled
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Dodatkowe informacje</FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                name="addInfoSubmission"
                                value={form.addInfoSubmission}
                                defaultValue={edit === 'edit' ? (auctionItemObject.addInfoSubmission) : ''}
                                onChange={setValue}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="sumbissionDateDay">
                            <FormLabel className={classes.labelD}>Termin składania ofert
                            </FormLabel>
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                <DatePicker
                                    value={date}
                                    onChange={(e) => {setDate(e); dispatch(setDateSubmissionDateDay(e))}}
                                    inputVariant="outlined"
                                    className={classes.input}
                                    format="yyyy/MM/dd"
                                    style={{width: '100%'}}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="sumbissionDateTime">
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                <TimePicker
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={date}
                                    onChange={(e) => {setDate(e); dispatch(setDateSubmissionDateTime(e))}}
                                    className={classes.input}
                                    style={{width: '100%'}}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Form>
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Submission;