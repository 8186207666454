import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import {MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {useDispatch, useSelector} from "react-redux";
import {setValues, setValuesAuctionParts} from "../state/auction/auctionActions";
import Button from "@material-ui/core/Button";
import PartValue from "./testimonials/PartValue";
import {fetchAuctionId, fetchAuctionItemId, fetchHint} from "../services/fetchId";
import StackedSnackbar from "./StackedSnackbar";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import CloseIcon from "@material-ui/icons/Close";

const Form = tw.div`mb-5 mx-5 mr-3 flex justify-center`;


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    inputJ: {
        padding: '0 0 0 25px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    label: {
        marginBottom: '1%',
        // marginLeft: '2%',
        // marginRight: '2%',
        fontSize: '1em',
        // textAlign: 'justify',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelJ: {
        marginBottom: '1%',
        marginLeft: '2%',
        marginRight: '2%',
        fontSize: '1em',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100% !important',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%',
        width: '100% !important'
    },
    one: {
        width: '95%',
    },
}));

const OrderValue = (props) => {

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const classes = useStyles();


    const [addPercentage, setAddPercentage ] = useState([]);
    const [addPercentageParts, setAddPercentageParts ] = useState([]);
    const [form1, setForm1] = useState();
    const parts = parseInt(localStorage.getItem('parts'));
    const partsValues = Array(parts);
    const [valueWoVat, setValueWoVat] = useState();
    const [auctionItem, setAuctionItem] = useState();
    const [show, setShow] = useState(true);

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )
    }, [])

    useEffect(() => {
        if(!!form.addValue && !!form.basicValue){
            setAddPercentage(((form.addValue.replace(",", ".")) / (form.basicValue.replace(",", ".")) * 100).toFixed(2) + '%')
        }

        if(!!form.addValue && !!form.basicValue && !!form.valueSupply){
            setValueWoVat((parseFloat(form.basicValue.replace(",", ".")) + parseFloat(form.addValue.replace(",", ".")) + parseFloat(form.valueSupply.replace(",", "."))).toFixed(2))
        }
    }, [form])



    let part = [];

console.log(addPercentageParts)

    useEffect(() => {
        for (let i=1; i<=parts; i++){
            part.push(

            )
            console.log(part)
        }
        setForm1(part)

    }, [])

    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn){
            enqueueSnackbar({
                message: <p>{hints[15].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });

            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };


    return (
        <div>
            <AnimationRevealPage>
                <ValidatorForm>
                    {props.edit === true && !!auctionItem ? (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wartość zamówienia podstawowego</FormLabel>
                                    <TextValidator
                                        onChange={(e)=> setValue(e)}
                                        defaultValue={auctionItem.basicValue}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.basicValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość zamówienia podstawowego"
                                        variant="outlined"
                                        required
                                        name="basicValue"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Wartość zamówienia dodatkowego (jeśli dotyczy)</FormLabel>
                                    <TextValidator
                                        defaultValue={auctionItem.addValue}
                                        onChange={(e)=> setValue(e)}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.addValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość zamówienia dodatkowego"
                                        variant="outlined"
                                        name="addValue"/>
                                    <TextValidator
                                        value={addPercentage}
                                        className={classes.inputJ}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Form>
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy) <br/>
                                    </FormLabel><br/>
                                    <TextValidator
                                        defaultValue={auctionItem.valueSupply}
                                        onChange={(e)=> setValue(e)}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.valueSupply}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość dostaw bez VAT"
                                        variant="outlined"
                                        required
                                        name="valueSupply"/>
                                </FormControl>
                            </Form>
                            {/*<Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość zamówienia z uwzględnieniem wartości
                                    dostaw i usług oddanych przez zamawiającego do
                                    dyspozycji wykonawcy bez VAT
                                </FormLabel>
                                <TextValidator
                                    onChange={(e)=> setValue(e)}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={form.valueSupply2}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Wartość dostaw bez VAT"
                                    variant="outlined"
                                    placeholder="Wartość dostaw bez VAT"
                                    required
                                    name="valueSupply2"/>
                            </FormControl>
                        </Form>*/}
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Całkowita wartość zamówienia bez VAT
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        required
                                        disabled
                                        value={form.valueWoVat}
                                        // value={valueWoVat}
                                        name="valueWoVat"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Waluta
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Waluta"
                                        variant="outlined"
                                        disabled
                                        value='PLN'
                                        name="Waluta"/>
                                </FormControl>
                            </Form>
                        </>
                    ) : (
                        <div onMouseOver={handleClick}>
                            <StackedSnackbar/>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wwartość zamówienia podstawowego</FormLabel>
                                    <TextValidator
                                        onChange={(e)=> setValue(e)}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.basicValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość zamówienia podstawowego"
                                        variant="outlined"
                                        required
                                        name="basicValue"/>
                                </FormControl>
                            </Form>
                            {/* W polu poniżej zamiast classes.label zastosowano 3-krotnie classes.labelJ */}
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wartość zamówienia dodatkowego (jeśli dotyczy)</FormLabel>
                                    <TextValidator
                                        onChange={(e)=> setValue(e)}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.addValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość zamówienia dodatkowego"
                                        variant="outlined"
                                        name="addValue"/>
                                    <TextValidator
                                        value={addPercentage}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        // Dodane przez DH
                                        label="... co stanowi (% wartości zamówienia podstawowego)"
                                        disabled
                                    />
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy)
                                    </FormLabel>
                                    <TextValidator
                                        onChange={(e)=> setValue(e)}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        value={form.valueSupply}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wartość dostaw bez VAT"
                                        variant="outlined"
                                        required
                                        name="valueSupply"/>
                                </FormControl>
                            </Form>
                            {/*<Form className={classes.labelJ}>
                            <FormControl className={classes.labelJ}>
                                <FormLabel className={classes.labelJ}>Wartość zamówienia z uwzględnieniem wartości
                                    dostaw i usług oddanych przez zamawiającego do
                                    dyspozycji wykonawcy bez VAT
                                </FormLabel>
                                <TextValidator
                                    onChange={(e)=> setValue(e)}
                                    validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                    errorMessages={['Wprowadź poprawny format: x,xx']}
                                    value={form.valueSupply2}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Wartość dostaw bez VAT"
                                    variant="outlined"
                                    placeholder="Wartość dostaw bez VAT"
                                    required
                                    name="valueSupply2"/>
                            </FormControl>
                        </Form>*/}
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Całkowita wartość zamówienia bez VAT
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.inputJ}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        required
                                        disabled
                                        value={valueWoVat}
                                        // value={form.valueWoVat} / DH 
                                        name="valueWoVat"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.labelJ}>
                                <FormControl className={classes.labelJ}>
                                    <FormLabel className={classes.labelJ}>Waluta
                                    </FormLabel>
                                    <TextValidator
                                        className={classes.inputJ}
                                        type="text"
                                        id="outlined-basic-u"
                                        // label="Waluta"
                                        variant="outlined"
                                        disabled
                                        value='PLN'
                                        name="Waluta"/>
                                </FormControl>
                            </Form>
                        </div>
                    )}
                    {Array.from(Array(parts)).map((a, idx) => <PartValue i={idx}/>)}
                    <Button
                        onClick={() => {props.handleBack(); handleDimissAll()}}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {props.handleNext(); handleDimissAll()}}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}

export default OrderValue;