import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import '@sweetalert2/theme-material-ui/material-ui.scss';
import AccountHeader from "../components/headers/AccountHeader";
import RegisterFinished from "./RegisterFinished";
import RegDataHeader from "../components/headers/RegDataHeader";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {ValidatorForm} from "react-material-ui-form-validator";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -mx-8 -mb-8 mt-6`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex items-center justify-center flex-1`;
const MainContainer = tw.div` sm:p-12 w-auto `;
const LogoLink = tw(Link)`flex justify-center`;
const LogoTitle = tw.h2`h-12 mx-auto text-2xl xl:text-3xl font-extrabold text-primary-900`;
const MainContent = tw.div`mt-0 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-gray-800`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl`;
const FormButton = tw.div`mb-5 mx-5 flex justify-center`;
const Form = tw.div`mb-5 mx-6 ml-10 flex justify-center`;
const SubmitButton = styled.button`
  ${tw`my-3 w-56 font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;



const styleLabel = {
    marginBottom: '5%',
    marginTop: '5%',
    fontWeight: 'bold',
    fontSize: 'large'
};



const RegisterData = ({
                          headingText = "Zarejestruj swoje dane",
                      }) => {

const [isFormFinished, setIsFormFinished] = useState(false);
    const [valueBZP1, setValueBZP1] = useState('');
    const [valueDUUE1, setValueDUUE1] = useState('');
    const [valueBZP2, setValueBZP2] = useState('');
    const [valueDUUE2, setValueDUUE2    ] = useState('');

    const [addInputBZP, setAddInputBZP] = useState(false);
    const [addInputDUUE, setAddInputDUUE] = useState(false);

    const handleChange1 = (event) => {
        setValueBZP1(event.target.value);
        setValueBZP2(event.target.value);

        if (event.target.value === 'c'){
            setAddInputBZP(true);
        }else {
            setAddInputBZP(false);
        }
    };

    const handleChange2 = (event) => {
        setValueDUUE1(event.target.value);
        setValueDUUE2(event.target.value);

        if (event.target.value === 'dd'){
            setAddInputDUUE(true);
        }else {
            setAddInputDUUE(false);
        }
    };
    let form1;

    if (addInputBZP === true && addInputDUUE === false) {

        form1 =
            <TwoColumn>
                <Form>
                    <FormControl component="fieldset3">
                        <FormLabel component="legend3" style={styleLabel}>Wybierz przedmiot działalności
                            Zamawiającego zgodnie z klasyfikacją w
                            ogłoszeniu w Biuletynie Zamówień
                            Publicznych
                        </FormLabel>
                        <RadioGroup aria-label="bzp2" name="bzp2" value={valueBZP2} onChange={handleChange1}>
                            <FormControlLabel value="aaa" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="bbb" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="ccc" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="ddd" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="eee" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="fff" control={<Radio color="primary"/>} label="" />
                            <FormControlLabel value="ggg" control={<Radio color="primary"/>} label="" />
                        </RadioGroup>
                    </FormControl>
                </Form>
            </TwoColumn>

    } else if (addInputBZP === false && addInputDUUE === true) {

        form1 = <TwoColumn>
            <Form>
                <FormControl component="fieldset4">
                    <FormLabel component="legend4" style={styleLabel}>Wybierz przedmiot działalności
                        Zamawiającego zgodnie z klasyfikacją w
                        ogłoszeniu w Biuletynie Zamówień
                        Publicznych
                    </FormLabel>
                    <RadioGroup aria-label="duue2" name="duue2" value={valueDUUE2} onChange={handleChange2}>
                        <FormControlLabel value="aaaa" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="bbbb" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="cccc" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="dddd" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="eeee" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="ffff" control={<Radio color="primary"/>} label="" />
                        <FormControlLabel value="gggg" control={<Radio color="primary"/>} label="" />
                    </RadioGroup>
                </FormControl>
            </Form>
        </TwoColumn>

    }

    return(
        <AnimationRevealPage>
            <RegDataHeader/>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink to={'/'}>
                            <LogoTitle>ARKAD</LogoTitle>
                        </LogoLink>
                        <MainContent>
                            {!isFormFinished ?
                                (   <React.Fragment>
                                        <Heading>{headingText}</Heading>
                                        <ValidatorForm>
                                            <TwoColumn>
                                                <Form>
                                                    <FormControl component="fieldset1">
                                                        <FormLabel component="legend1" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Biuletynie Zamówień Publicznych
                                                        </FormLabel>
                                                        <RadioGroup aria-label="bzp" name="bzp" value={valueBZP1} onChange={handleChange1}>
                                                            <FormControlLabel value="a" control={<Radio color="primary"/>} label="Administracja rządowa centralna" />
                                                            <FormControlLabel value="b" control={<Radio color="primary"/>} label="Administracja rządowa terenowa" />
                                                            <FormControlLabel value="c" control={<Radio color="primary"/>} label="Administracja samorządowa" />
                                                            <FormControlLabel value="d" control={<Radio color="primary"/>} label="Zamawiający udzielający zamówień, o którym mowa w art. 3 ust. 1 pkt 5 ustawy Pzp" />
                                                            <FormControlLabel value="e" control={<Radio color="primary"/>} label="Zamawiający udzielający zamówień sektorowych" />
                                                            <FormControlLabel value="f" control={<Radio color="primary"/>} label="Podmiot prawa publicznego" />
                                                            <FormControlLabel value="g" control={<Radio color="primary"/>} label="Inny" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Form>
                                                <Form>
                                                    <FormControl component="fieldset2">
                                                        <FormLabel component="legend2" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Dzienniku Urzędowym Unii Europejskiej
                                                        </FormLabel>
                                                        <RadioGroup aria-label="duue" name="duue" value={valueDUUE1} onChange={handleChange2}>
                                                            <FormControlLabel value="aa" control={<Radio color="primary"/>} label="Ministerstwo lub inny organ krajowy lub federalny,W tym jednostki regionalne i lokalne" />
                                                            <FormControlLabel value="bb" control={<Radio color="primary"/>} label="Agencja/Urząd krajowy lub federalny" />
                                                            <FormControlLabel value="cc" control={<Radio color="primary"/>} label="Organ władzy federalnej lub lokalnej" />
                                                            <FormControlLabel value="dd" control={<Radio color="primary"/>} label="Agencja/Urząd regionalny lub lokalny" />
                                                            <FormControlLabel value="ee" control={<Radio color="primary"/>} label="Podmiot prawa publicznego" />
                                                            <FormControlLabel value="ff" control={<Radio color="primary"/>} label="Instytucja/agencja europejska lub organizacja międzynarodowa" />
                                                            <FormControlLabel value="gg" control={<Radio color="primary"/>} label="Inny" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Form>
                                            </TwoColumn>
                                            {(addInputBZP && addInputDUUE) ? (
                                                <TwoColumn>
                                                    <Form>
                                                        <FormControl component="fieldset3">
                                                            <FormLabel component="legend3" style={styleLabel}>Wybierz przedmiot działalności
                                                                Zamawiającego zgodnie z klasyfikacją w
                                                                ogłoszeniu w Biuletynie Zamówień
                                                                Publicznych
                                                            </FormLabel>
                                                            <RadioGroup aria-label="bzp2" name="bzp2" value={valueBZP2} onChange={handleChange1}>
                                                                <FormControlLabel value="aaa" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="bbb" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="ccc" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="ddd" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="eee" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="fff" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="ggg" control={<Radio color="primary"/>} label="" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Form>
                                                    <Form>
                                                        <FormControl component="fieldset4">
                                                            <FormLabel component="legend4" style={styleLabel}>Wybierz przedmiot działalności
                                                                Zamawiającego zgodnie z klasyfikacją w
                                                                ogłoszeniu w Biuletynie Zamówień
                                                                Publicznych
                                                            </FormLabel>
                                                            <RadioGroup aria-label="duue2" name="duue2" value={valueDUUE2} onChange={handleChange2}>
                                                                <FormControlLabel value="aaaa" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="bbbb" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="cccc" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="dddd" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="eeee" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="ffff" control={<Radio color="primary"/>} label="" />
                                                                <FormControlLabel value="gggg" control={<Radio color="primary"/>} label="" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Form>
                                                </TwoColumn>
                                            ) : ''}
                                            {form1}
                                            <TwoColumn>
                                                <FormButton>
                                                    <Link to="/registerData">
                                                        <SubmitButton>
                                                            Cofnij
                                                        </SubmitButton>
                                                    </Link>
                                                </FormButton>
                                                <FormButton>
                                                    <Link to="/registerDataCd">
                                                        <SubmitButton type="submit" onClick={() => {setIsFormFinished(true)}}>
                                                            Zapisz
                                                        </SubmitButton>
                                                    </Link>
                                                </FormButton>
                                            </TwoColumn>
                                        </ValidatorForm>
                                    </React.Fragment>
                                ) :
                                <AnimationRevealPage>
                                    <RegisterFinished/>
                                </AnimationRevealPage>
                            }
                        </MainContent>
                    </MainContainer>
                </Content>
            </Container>
        </AnimationRevealPage>)

};

export default RegisterData;
