import React, {useState} from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {Paper, Grid} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {makeStyles} from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import plLocale from "date-fns/locale/pl";



const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#6415FF",
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "white",
                color: "#000000",
            },
        },
    },
});

export const styles = makeStyles(() => ({ //define CSS for different date types
    notInThisMonthDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#f7f9fa",
        margin: "3px",
        boxShadow: "none !important",
        borderRadius: 0,
        padding: "1px",
        textAlign: "center",
        display: "flex",
        alignItems: " center",
        fontFamily: 'Inter'
    },
    normalDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#ececf5",
        margin: "3px",
        borderRadius: 0,
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        alignItems: " center",
        boxShadow: "none !important",
        fontFamily: 'Inter'
    },
    selectedDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#6415FF !important",
        margin: "3px",
        color: "white !important",
        borderRadius: 0,
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "#6415FF",
        padding: "0px",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        alignItems: " center",
        justifyContent: "center",
        fontSize: "15px",
        fontFamily: 'Inter'
    },
    todayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "blue",
        margin: "3px",
        fontFamily: 'Inter',
        borderRadius: 0,
        padding: "1px",
        cursor: "pointer",
        color: " white",
        textAlign: "center",
        display: "flex",
        alignItems: " center"
    },
    auction: {
        backgroundColor: "#ffffff",
        color: "black",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: "50%",
        textAlign: "center",
        fontFamily: 'Inter'

    },

}));


export default function CustomCalendar() {

    const [selectedDate, handleDateChange] = useState(new Date());
    const classes = styles(); // import those CSS
    const today = new Date(); // just Date object of today
    const sunnyDays = [] // array of sunny days 1st,6th etc


    function getDayElement(day, selectedDate, isInCurrentMonth, dayComponent) {
        //generate boolean
        const isSunny = sunnyDays.includes(day.getDate());
        const isSelected = day.getDate() === selectedDate.getDate();
        const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();


        let dateTile
        if (isInCurrentMonth) { //conditionally return appropriate Element of date tile.
            if (isSunny) {
                dateTile = (
                    <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper}>
                        <Grid item><FiberManualRecordIcon style={{ color: "#3e11bc", fontSize: "15px"}}/></Grid>
                        <Grid item>
                            {day.getDate()}
                        </Grid>
                    </Paper>)
            } else {
                dateTile = (
                    <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper}>
                        <Grid item><br/></Grid>
                        <Grid item> {day.getDate()}</Grid>
                    </Paper>)
            }

        } else {
            dateTile = (<Paper className={classes.notInThisMonthDayPaper}>
                <Grid item><br/></Grid>
                <Grid item style={{color: "lightGrey"}}>
                    {day.getDate()}
                </Grid>
            </Paper>)
        }
        return dateTile
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                <ThemeProvider theme={materialTheme}>
                    <div className={classes.auction}>
                        <div className={classes.container}>
                            <p>Planowana data publikacji: </p>
                            <p>{selectedDate.getDate()}.{selectedDate.getMonth()}.{selectedDate.getFullYear()} </p>
                        </div>
                        <div className={classes.container}>
                            <p>Numer referencyjny: </p>
                            <p>[...]</p>
                        </div>
                    </div>
                    <DatePicker
                        format="yyyy/MM/dd"
                        value={selectedDate}
                        onChange={handleDateChange}
                        variant="static"
                        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
    );}
