import React, {useEffect, useState} from 'react';
import Calendar from "react-calendar";
import '../styles/calendar.css';
import moment from "moment";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {makeStyles, setRef} from "@material-ui/core";
import {fetchAuctionId} from "../services/fetchId";
import tw from "twin.macro";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {PrimaryButton} from "../components/misc/Buttons";

const Button = tw(PrimaryButton)`mb-2! w-40 text-sm inline-block `;

export const styles = makeStyles(() => ({ //define CSS for different date types
    auction: {
        backgroundColor: "#ffffff",
        color: "black",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: "50%",
        textAlign: "center",
        fontFamily: 'Inter'

    },

}));

export default function CustomCalendar() {

    const classes = styles();

    const [date, setDate] = useState(new Date());
    const [a, setA] = useState(true);
    const [calendar, setCalendar] = useState();
    const [refNumber, setRefNumber] = useState([]);
    const [show, setShow] = useState(false);
    const [startDates, setStartDates] = useState([]);
    const [startDate, setStartDate] = useState();
    const [closeDates, setCloseDates] = useState([]);
    const [index, setIndex] = useState();
    const [auctionData, setAuctionData] = useState([]);


   useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                    let userAuctions;
                    userAuctions = data['hydra:member'].filter(auc => auc.owner.slice(11) === localStorage.getItem('userId'))
                    const dates = userAuctions.map(auction =>
                        auction.startDate.slice(0,10))
                        setStartDates(dates);
                    const cDates = userAuctions
                        .filter(auction => auction.isClosed === true)
                        .filter(dateItem => dateItem.startDate.slice(0,10) === date.toLocaleDateString('en-CA')).closeDate
                        setCloseDates(cDates);
                    const Aucdata = userAuctions.map(auction => {
                        return [auction.startDate.slice(0,10), auction.refNumber, auction.closeDate]})
                    setAuctionData(Aucdata)
                    const refNumbers = userAuctions.map(auction =>
                        auction.refNumber)
                        setRefNumber(refNumbers);
            })
    }, [])

// console.log(auctionData)
// console.log(date.toLocaleDateString('en-CA'));  
    
    useEffect(() => {
        if(!!startDates){
            setCalendar(
                        <Calendar
                            locale="pl"
                            view="month"
                            onChange={(value, event) => setDate(value)}
                            value={date}
                            tileClassName={({ date, view }) => {
                                if(startDates.find(x => x === moment(date).format("YYYY-MM-DD"))){
                                    return 'highlight'
                                }}}
                            onClickDay={(value, event) =>
                                startDates.includes(value.toLocaleDateString('en-CA')) ? setShow(true) : setShow(false)}
                        />

                )
        }

    }, [startDates])

/*    useEffect(() => {
        setStartDate(startDates.filter(dateItem => { return dateItem === date.toLocaleDateString('en-CA')}))
        console.log(startDate)
    }, [startDate])*/



    let ref; let ref2;
    ref = auctionData.filter(ar => (ar[0] === date.toLocaleDateString('en-CA')))[0]
    if(!!ref){
        ref2 = ref[1]
    }

    return (
       <div>
           {
               show &&
                <div>
                    <Button style={{display: 'flex', justifyContent: 'center', width: '60%', margin: 'auto', fontFamily: 'inherit'}}>Przejdź do przetargu</Button>
                    <div className={classes.auction}>
                        <div className={classes.container}>
                            <p style={{fontSize: '0.9em'}}>Planowana data publikacji: </p>
                            <p style={{fontSize: '0.9em', fontWeight: 'bold'}}>{
                                startDates.filter(dateItem => {
                                return (dateItem === date.toLocaleDateString('en-CA'))})[0]}
                            </p>
                        </div>
                        <div className={classes.container}>
                            <p style={{fontSize: '0.9em'}}>Numer referencyjny: </p>
                            <p style={{fontSize: '0.9em', fontWeight: 'bold'}}>{ref2}</p>
                        </div>
                    </div>

                    <div>
                            {
                                !!auctionData.map(auction => auction[0] === date.toLocaleDateString('en-CA'))[2] && (
                                    <>
                                        <p style={{fontSize: '0.9em', textAlign: 'center', fontFamily: 'Inter'}}>
                                            Przetarg zakończony w dniu:
                                        </p>
                                        <p style={{fontSize: '0.9em', textAlign: 'center', marginBottom: '14px', fontFamily: 'Inter', fontWeight: 'bold'}}>
                                            {
                                                auctionData.filter(dateItem => {
                                                    return (dateItem[0] === date.toLocaleDateString('en-CA'))})[0][2].slice(0,10)
                                            }
                                        </p>
                                    </>


                                )
                            }

                    </div>
                </div>
           }
           {calendar}
       </div>
    );

}
