import React, {useEffect, useRef, useState} from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {SectionHeading} from "./misc/Headings";
import {makeStyles} from "@material-ui/core/styles";
import HintsInfo from "./HintsInfo";
import Hints from "./Hints";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../state/auction/auctionActions";
import Button from "@material-ui/core/Button";
import {fetchBipClientType1, fetchBipClientType2, fetchCustomer, fetchHint} from "../services/fetchId";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "./StackedSnackbar";


const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Form = tw.div`mb-5 mx-5 mr-3`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'justify',
        color: 'black',
        width: '100%',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1.1em',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
    one: {
        width: '95%',
    },
}));

const OrderForm = (props) => {

    const classes = useStyles();
    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();
    const [bipClientTypeId, setBipClientTypeId] = useState();
    const [bipClientTypes1, setBipClientTypes1] = useState('');
    const [bipClientTypes2, setBipClientTypes2] = useState('');
    const [bipClientTypes, setBipClientTypes] = useState([]);
    const [bipClientType, setBipClientType] = useState('');
    const [orderType, setOrderType] = useState("usługi");
    const [serviceType, setServiceType] = useState("społeczne");
    const [valueWoVat, setValueWoVat] = useState(1035000);

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value));
        console.log(form);
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[16].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };



    useEffect(() => {
        fetchCustomer(localStorage.getItem('customerId')).subscribe(
            data => {
                setBipClientTypeId(data.bipClientType.slice(22));
            }
        )
        fetchBipClientType1().subscribe(
            data => {
                setBipClientTypes1(data['hydra:member']);
            }
        )

        fetchBipClientType2().subscribe(
            data => {
                setBipClientTypes2(data['hydra:member']);
            }
        )
    }, [])

    useEffect(() => {
        setBipClientTypes([...bipClientTypes1, ...bipClientTypes2]);
    }, [bipClientTypes1, bipClientTypes2])


    useEffect(() => {
            setBipClientType(bipClientTypes.filter(type => type.id === parseInt(bipClientTypeId))[0]);
    }, [bipClientTypeId, bipClientTypes])

    if(!!form.orderType){
        setOrderType(form.orderType);
    }

    if(!!form.valueWoVat){
        setValueWoVat(form.valueWoVat);
    }

    if(!!form.serviceType){
        setServiceType(form.serviceType);
    }


    const [modeBasic, setModeBasic] = useState('');
    const [mode, setMode] = useState('');
    const [aboveMode, setAboveMode]  = useState('');

    const [optionI, setOptionI]  = useState(
        <Form className={classes.label}>
            <FormControl className={classes.label} value={form.negotiation}>
                <FormLabel className={classes.label}>Tryb podstawowy - tylko dla zamówień poniżej progów unijnych
                </FormLabel>
                <RadioGroup name="negotiation" onChange={(e) => {setValue(e); handleModeBasicChange(e)}}>
                    <FormControlLabel value="bez" control={<Radio color="primary"/>} label="Bez negocjacji" />
                    <FormControlLabel value="możliwość" control={<Radio color="primary"/>} label="Z możliwością negocjacji" />
                    <FormControlLabel value="z" control={<Radio color="primary"/>} label="Z negocjacjami" />
                </RadioGroup>
            </FormControl>
        </Form>
    );
    const [optionII, setOptionII]  = useState(
        <Form className={classes.label}>
            <FormControl className={classes.label} value={form.mode} >
                <FormLabel className={classes.label}>Pozostałe tryby - dla wszystkich zamówień
                </FormLabel>
                <RadioGroup name="mode" onChange={(e) => {setValue(e); handleModeChange(e)}}>
                    <FormControlLabel value="partnerstwo" control={<Radio color="primary"/>} label="Partnerstwo innowacyjne" />
                    <FormControlLabel value="negocjacje" control={<Radio color="primary"/>} label="Negocjacje bez ogłoszenia" />
                    <FormControlLabel value="zWolnejRęki" control={<Radio color="primary"/>} label="Zamówienie z wolnej ręki" />
                </RadioGroup>
            </FormControl>
        </Form>
    );
    const [optionIII, setOptionIII]  = useState(
        <Form className={classes.label}>
            <FormControl className={classes.label} value={form.aboveMode}>
                <FormLabel className={classes.label}>Tryby - tylko dla zamówień powyżej progów unijnych
                </FormLabel>
                <RadioGroup name="aboveMode"  onChange={(e) => {setBooleanValue(e); handleAboveModeChange(e)}}>
                    <FormControlLabel value='true' control={<Radio color="primary"/>} label="Przetarg nieograniczony" />
                    <FormControlLabel value='false' control={<Radio color="primary"/>} label="Konkurs" />
                </RadioGroup>
            </FormControl>
        </Form>
    );
    const [optionIV, setOptionIV]  = useState(
        <Form className={classes.label}>
            <FormControl className={classes.label} value={form.aboveMode}>
                <TextValidator
                    value={'Nie ma obowiązku stosowania ustawy dla tego zamówienia'}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Nazwa Zamawiającego"
                    variant="outlined"
                    disabled
                />
            </FormControl>
        </Form>
    );



    const handleModeBasicChange = (e) => {
        setModeBasic(e.target.value);
    }

    const handleModeChange = (e) => {
        setMode(e.target.value);
    }

    const handleAboveModeChange = (e) => {
        setAboveMode(e.target.value);
    }


    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm >
                    {!!bipClientType &&
                    (((bipClientType.name.includes('uczelnia publiczna')
                        || bipClientType.name.includes('instytucje kultury')
                        || bipClientType.name.includes('jednostka samorządu terytorialnego')
                        || bipClientType.name.includes('związek jednostek samorządu terytorialnego')
                        || bipClientType.name.includes('jednostki sektora finansów publicznych, dla których organem założycielskim lub nadzorującym jest jednostka samorządu terytorialnego')
                        || bipClientType.name.includes('administracja samorządowa')
                        || bipClientType.name.includes('jednostki organizacyjne administracji samorządowej')
                        ) &&
                        (orderType === "dostawy" || serviceType === "klasyczne" ) &&
                        valueWoVat >= 130000 && valueWoVat <= 913629.99 ) &&
                        (optionI
                    ))}
                    {!!bipClientType &&
                    ((bipClientType.name.includes('Zamawiający publiczny') &&
                        (orderType === "usługi" || serviceType === "społeczne" ) &&
                        valueWoVat >= 130000 && valueWoVat <= 3201974.99) &&
                        (optionI
                        ))}
                    {!!bipClientType &&
                    ((bipClientType.name.includes('Zamawiający sektorowy') &&
                        (orderType === "usługi" || serviceType === "społeczne" ) &&
                        valueWoVat >= 130000 && valueWoVat <= 3201974.99) &&
                        (optionI
                        ))}
                    {!!bipClientType &&
                    ((bipClientType.name.includes('Zamawiający publiczny') &&
                        (orderType === "budowlane") &&
                        valueWoVat >= 130000 && valueWoVat <= 22840754.99) &&
                        (optionI
                        ))}
                    <Button
                        onClick={props.handleBack}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleNext}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </div>

    )
}

export default OrderForm;