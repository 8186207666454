import React, {useContext, useEffect, useRef, useState} from "react";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import tw from "twin.macro";
import styled from "styled-components";
import { useStyles } from "../components/materialUI/Stepper/StepperStyles";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import '../styles/globalStyles.css'


const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export function LoginForm(props) {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const classes = useStyles();


    const onSubmit = () => {
        if (!emailRef.current && !passwordRef.current) {
            return;
        }
        const email = emailRef.current.value;
        const password =  passwordRef.current.value;
        props.onSubmit(email, password);
    };

    return (
      <>
        <ValidatorForm onSubmit={onSubmit}>
          <TextValidator
              inputRef={emailRef}
              className={classes.input}
              type="email"
              label="Email"
              variant="outlined"
              placeholder="Email"
              required
              name="email"
          />
           <TextValidator
              inputRef={passwordRef}
              inputProps={{ minLength: 6 }}
              className={classes.input}
              type="password"
              id="outlined-basic"
              label="Hasło"
              variant="outlined"
              placeholder="Hasło"
              name="password"
              required
            />
            {/* <TextValidator type="input" ref={emailRef} type="email" placeholder="Email" required />
            <TextValidator ref={passwordRef} type="password" placeholder="Hasło" required /> */}
            <SubmitButton type="submit">
                <LoginIcon className="icon" />
                <span className="text">{"Zaloguj się"}</span>
            </SubmitButton>
        </ValidatorForm>
      </>
    );
}