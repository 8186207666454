import React, {useEffect, useRef, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";
import {fetchUsers} from "../services/fetchId";
import FormLabel from "@material-ui/core/FormLabel";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";

const Form = tw.div`px-12 py-3 w-128`;
const ButtonPass = tw(PrimaryButton)`mb-8! mt-3! w-full text-base inline-block `;

const EditUsersData = ({open,  setOpen, auction, onSubmit}) => {


    const useStyles = makeStyles({
        form: {
            display: 'flex !important',
            justifyContent: 'center !important',
            alignItems: 'center'
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '2% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
        label: {
            marginBottom: '7%',
            marginTop: '5%',
            fontSize: '1em',
            lineHeight: '1.4',
            textAlign: 'justify',
            color: 'black',
            width: 'auto',
        },
        input: {
            minWidth: '300px',
            marginRight: '0 !important'
        }
    });

    const [date, setDate] = React.useState(new Date());

    const onSubmit1 = () => {
        const data = {
            closeDate : date,
            isClosed : true
        };

        console.log(auction.id)
        onSubmit(data, auction.id);
        setOpen(false);
    };

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Dialog open={open} onClose={handleClose} className={classes.root}>
                <DialogContent>
                    <ValidatorForm
                        onSubmit={onSubmit1}
                        style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                        {!!auction &&
                        <>
                            <FormLabel className={classes.label}>Wpisz datę zakończenia przetargu</FormLabel>
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                <DatePicker
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    value={date}
                                    onChange={setDate}
                                    minDate={auction.startDate}
                                   />
                            </MuiPickersUtilsProvider>
                        </>

                        }

                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <ButtonPass onSubmit={onSubmit1} onClick={onSubmit1} type="submit" style={{width: '70%', margin: 'auto'}}>Zapisz</ButtonPass>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditUsersData;