import React, {useEffect, useState} from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {CardActions} from "@material-ui/core";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {fetchUser} from "../services/fetchId";



const MyAccountCard = (props) => {
    const windowWidth = useSelector(state => state.main.windowWidth);
    let useStyles;
    if(windowWidth >= 1300){
        useStyles = makeStyles({
            root: {
                width: 320,
                height: 200,
            },
            media: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                textAlign: 'center',
                fontSize: '1.2rem'
            },
            description: {
                textAlign: 'justify'
            },
            action: {
                height: '100%'
            }
        });
    }else if(windowWidth <= 1300){
        useStyles = makeStyles({
            root: {
                width: 200,
                height: 120
            },
            media: {
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                height: 100,
                textAlign: 'center',
            },
            description: {
                textAlign: 'justify'
            },
            action: {
                height: '100%'
            }
        });
    }


    const classes = useStyles();

    return(
        <Card className={classes.root} onClick={props.onClick}>
            <CardActionArea className={classes.action}>
                <CardMedia
                    className={classes.media}
                    title="Contemplative Reptile">
                    {props.icon}
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="h7" component="h2" className={classes.title}>
                        {props.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default MyAccountCard;