import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import {fetchHint} from "../../services/fetchId";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "../StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3`;

const Employment96PartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifEmploymentRequirements96 === true && 2));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[27].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }


    let form1;

    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <TextValidator
                            value={formAuctionParts.employmentRequirments96}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            name="employmentRequirments96"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            defaultValue={edit === 'edit' ? (auctionObject.employmentRequirments96) : 'Tekst wstawiony przez system'}
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifEmploymentRequirements96.toString()} name="ifEmploymentRequirements96">
                            <RadioGroup name="ifEmploymentRequirements96" defaultValue={edit === 'edit' && auctionObject.ifEmploymentRequirements96.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie określa wymagań zatrudnienia osób, o których mowa w art.96 ust.2 pkt 2" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający określa wymagania zatrudnienia osób, o których mowa w art.96 ust.2 pkt 2" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}


export default Employment96PartI;