import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import { Link } from "react-router-dom";
import { RegisterStepper } from "components/materialUI/Stepper/RegisterStepper";
import { fetchRegister } from "services/fetchRegister";
import { RegisterForm } from "components/RegisterForm";
import Swal from 'sweetalert2/src/sweetalert2.js';
import '@sweetalert2/theme-material-ui/material-ui.scss';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw(Link)`flex justify-center`;
const LogoTitle = tw.h2`h-12 mx-auto text-2xl xl:text-3xl font-extrabold`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

const loginAlert = Swal.mixin({
  customClass: {
      // confirmButton: 'btn btn-success',
  },
  buttonsStyling: false,
});

export default ({
  illustrationImageSrc = illustration,
  headingText = "Zarejestruj się do Platformy Przetargowej",
}) => (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink to={'/'}>
            <LogoTitle>ARKAD</LogoTitle>
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              {/* <RegisterStepper onSubmit={fetchRegister} /> */}
              <RegisterForm onSubmit={(data) => {
                fetchRegister(data).subscribe(
                  user =>loginAlert.fire({
                    title: 'Sukces',
                    type: 'success',
                    text: `Wiadomość z linkiem aktywacyjnym została wysłana na podany adres e-mail.`,
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                  },
                  // window.location.replace('/login')
                  ),
                  error =>{ return Swal.fire({
                    icon: 'error',
                    type: 'error',
                    title: 'Oops...',
                    text: `Podany adres e-mail lub nazwa użytkownika już istnieje ! `,
                    showConfirmButton: false,
                    timer: 1500,
                  })},
                )
              }}/>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Masz już konto?{" "}
                <Link to={'/login'} tw="border-b border-gray-500 border-dotted">
                  Zaloguj się
                </Link>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
);
