import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const WadiumPartI = ({edit, auctionObject, no, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [value2, setValue2] = useState();
    const [addValue1, setAddValue1] = useState();

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }

    console.log(auctionItemObject)

    let form1;

    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Wysokość wadium w procentach</FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.wadiumPercentage) : ''}
                            value={formAuctionParts.wadiumPercentage}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            type="number"
                            min="0"
                            max="100"
                            step="1"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Wysokość wadium w procentach"
                            name="wadiumPercentage"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kwota</FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.wadiumValue) : ''}
                            value={formAuctionParts.wadiumValue}
                            onChange={(e) => {setValueAuctionParts(e, no); setValue2(e.target.value)}}
                            validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                            errorMessages={['Wprowadź poprawny format: x,xx']}
                            value={value2}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Wartość dostaw bez VAT"
                            variant="outlined"
                            name="wadiumValue"
                           />
                    </FormControl>
                </Form>
                {
                    !!auctionItemObject && (
                        <>
                            {
                                auctionItemObject.ueFunds === true ? (
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label}>
                                            <FormLabel className={classes.label}>Informacja o formach wznoszenia
                                                wadium</FormLabel>
                                            <TextValidator
                                                defaultValue={edit === 'edit' ? (auctionObject.wadiumInfoUe) : 'Tekst wstawiony przez system'}
                                                value={formAuctionParts.wadiumInfoUe}
                                                onChange={(e) => setValueAuctionParts(e, no)}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                variant="outlined"
                                                multiline
                                                name="wadiumInfoUe"
                                                rows={5}
                                            />
                                        </FormControl>
                                    </Form>
                                ) : (
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label}>
                                            <FormLabel className={classes.label}>Informacja o formach wznoszenia
                                                wadium</FormLabel>
                                            <TextValidator
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                defaultValue={edit === 'edit' ? (auctionObject.wadiumInfo) : 'Tekst wstawiony przez system'}
                                                value={formAuctionParts.wadiumInfo}
                                                onChange={(e) => setValueAuctionParts(e, no)}
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                                name="wadiumInfo"
                                            />
                                        </FormControl>
                                    </Form>
                                )
                            }
                        </>)
                }
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!formAuctionParts[no].ifWadium && formAuctionParts[no].ifWadium.toString()} name="ifWadium">
                            <RadioGroup name="ifWadium" defaultValue={edit === 'edit' && auctionObject.ifWadium.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e)}}>
                                <FormControlLabel value="false" control={<Radio color="primary"/>} label="Zamawiający nie przewiduje obowiązku wzniesienia wadium" />
                                <FormControlLabel value="true" control={<Radio color="primary"/>} label="Zamawiający przewiduje obowiązek wzniesienia wadium" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}


export default WadiumPartI;