import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import tw from "twin.macro";
import ListItem from "@material-ui/core/ListItem";
import {Linksmenu, LinksmenuNonActive} from "./misc/Links";
import {PrimaryButton} from "./misc/Buttons";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import {changePass} from "../services/patchPassword";
import {fetchUser} from "../services/fetchId";

const LinkMenuNonActive = tw(LinksmenuNonActive)`p-0! text-center!`;
const Form = tw.div`px-12 py-3`;
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const ButtonPass = tw(PrimaryButton)`mb-8! mt-3! w-full text-base inline-block `;

export default function ChangePassword({isValid, active, ...props}) {
    const useStyles = makeStyles({
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '2% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
    });


    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const oldPasswordRef = useRef(null);
    const passwordRef = useRef(null);
    const repeatPasswordRef = useRef(null);

    const [emailDb, setEmailDb] = useState()
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValidForm, isSetValidForm] = useState(false);
    const userId = localStorage.getItem('userId');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() =>{

        fetchUser(userId).subscribe(data => {
            setEmailDb(data.email)

        })

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== passwordRef.current.value) {
                isSetValidForm(false);
                return false;
            }else{
                return true;
            }

        });
    },[ValidatorForm])

    const onSubmit = () => {
        const data = {
            password : passwordRef.current.value,
            };
        const email = emailDb;
        const oldPassword = oldPasswordRef.current.value

        props.onSubmit(data, email, oldPassword);

        setOpen(false);
    };


    return (
        <div>
            {
                active
                    ? <ListItem onClick={handleClickOpen}><LinkMenu>Zmień hasło</LinkMenu></ListItem>
                    : <ListItem><LinkMenuNonActive>Zmień hasło</LinkMenuNonActive></ListItem>
            }

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Typography className={classes.nameSec}>ZMIEŃ HASŁO</Typography>
                    <ValidatorForm
                        onSubmit={onSubmit}
                        style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}
                    >
                        <br/><Form>
                        <TextValidator
                            autofocus
                            inputRef={oldPasswordRef}
                            inputProps={{ minLength: 6 }}
                            type="password"
                            id="outlined-basic-p"
                            label="Stare hasło"
                            variant="outlined"
                            placeholder="Stare hasło"
                            name="password"
                            required
                        />
                    </Form>
                        <Form>
                            <TextValidator
                                autofocus
                                inputRef={passwordRef}
                                inputProps={{ minLength: 6 }}
                                type="password"
                                id="outlined-basic-p"
                                label="Nowe hasło"
                                variant="outlined"
                                placeholder="Nowe hasło"
                                name="password"
                                required
                            />
                        </Form>
                        <Form>
                            <TextValidator
                                autofocus
                                inputRef={repeatPasswordRef}
                                type="password"
                                id="outlined-basic-pr"
                                label="Potwierdź nowe hasło"
                                variant="outlined"
                                placeholder="Potwierdź nowe hasło"
                                name="repeatpassword"
                                validators={['isPasswordMatch']}
                                required
                                value={repeatPassword}
                                errorMessages={['Hasła nie pasują do siebie']}
                                onChange={(e) => {setRepeatPassword(e.target.value)}}
                            />
                        </Form>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <ButtonPass type="submit" onSubmit={onSubmit} onClick={onSubmit} style={{width: '70%', margin: 'auto'}}> Zmień hasło</ButtonPass>
                </DialogActions>
            </Dialog>
        </div>
    );
}