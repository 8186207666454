import React, {useState, useEffect} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SectionHeading} from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import TitlePage from "../components/OrderSpecification/TitlePage";
import BgInformation from "../components/OrderSpecification/BgInformation";
import OrderContactData from "../components/OrderSpecification/OrderContactData";
import WebPages from "../components/OrderSpecification/WebPages";
import OrderModeSpec from "../components/OrderSpecification/OrderModeSpec";
import Negotiation from "../components/OrderSpecification/Negotiation";
import OrderDescription from "../components/OrderSpecification/OrderDescription";
import PartialOfferts from "../components/OrderSpecification/PartialOfferts";
import PartsForOne from "../components/OrderSpecification/PartsForOne";
import InitialOpFinished from "../components/InitialOpFinished";
import {patchInitialOpAuction} from "../services/patchInitialOpAuction";
import Swal from "sweetalert2";
import {patchInitialOp} from "../services/patchInitialOp";
import {fetchAuctionItemId} from "../services/fetchId";
import SummaryOrderSpecification from "../components/OrderSpecification/SummaryOrderSpecification";
import {useParams} from "react-router-dom";

const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl mb-0 text-left`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row'
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },

}));





const OrderSpecification = (props) => {

    const classes = useStyles();

    const [auctionItemId, setAuctionItemId] = useState();
    const [ifParts, setIfParts] = useState('');
    const [path, setPath] = useState('/we');

    const {params} = props.match;
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (params.edit === 'edit'){
            setEdit(true);
        }
    }, [])


    useEffect(() => {
            fetchAuctionItemId().subscribe(
                data => {
                    setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === params.id)[0]['@id'].slice(19));
                    setIfParts(data['hydra:member'].filter(auction => auction.auction.slice(14) === params.id)[0].numberOfParts);
                }
            );

    }, [])

    useEffect(() => {
        if(edit){
            setPath('/inProgress')
        }else if (params.edit === 'form'){
            if(ifParts === 0){
                setPath('/finalSteps')
            }else if(ifParts === 2 || ifParts === 3){
                setPath('/orderSpecificationParts/form/' + params.id + '/0')
            }
        }
    }, [ifParts])

    function getSteps() {
        if(!edit){
            return ['STRONA TYTUŁOWA', 'INFORMACJE WPROWADZAJĄCE', 'DANE ZAMAWIAJĄCEGO', 'STRONY INTERNETOWE ZAMÓWIENIA', 'TRYB UDZIELANIA ZAMÓWIENIA', 'NEGOCJACJE', 'OPIS PRZEDMIOTU ZAMÓWIENIA', 'OFERTY CZĘŚCIOWE', 'CZĘŚCI ZAMÓWIENIA DLA 1 WYKONAWCY', 'PODSUMOWANIE' ,'ZAKOŃCZ'];
        }else if(edit){
            return ['STRONA TYTUŁOWA', 'INFORMACJE WPROWADZAJĄCE', 'DANE ZAMAWIAJĄCEGO', 'STRONY INTERNETOWE ZAMÓWIENIA', 'TRYB UDZIELANIA ZAMÓWIENIA', 'NEGOCJACJE', 'OPIS PRZEDMIOTU ZAMÓWIENIA', 'OFERTY CZĘŚCIOWE', 'CZĘŚCI ZAMÓWIENIA DLA 1 WYKONAWCY' ,'ZAKOŃCZ'];
        }

    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return ( <TitlePage id={params.id}/>
                );
            case 1:
                return ( <BgInformation id={params.id} edit={edit}/>
                );
            case 2:
                return ( <OrderContactData id={params.id}/>
                );
            case 3:
                return ( <WebPages id={params.id}/>
                );
            case 4:
                return ( <OrderModeSpec id={params.id}/>
                );
            case 5:
                return ( <Negotiation id={params.id}/>
                );
            case 6:
                return ( <OrderDescription id={params.id} edit={edit}/>
                );
            case 7:
                return ( <PartialOfferts id={params.id} edit={edit}/>
                );
            case 8:
                return ( <PartsForOne id={params.id} edit={edit}/>
                );
            case 9:
                if(!edit){
                    return (<SummaryOrderSpecification id={params.id}/>)}
            case 10:
                return (<InitialOpFinished
                    handleLast={handleLast}
                    onSubmit={(formAuction, form) => {
                                patchInitialOpAuction(formAuction, params.id).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                                window.location.replace(path);
                                        }}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                                patchInitialOp(form, auctionItemId).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                            window.location.replace(path);
                                        }}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                            }
                    }
                />)

            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleLast = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.location.replace('/orderSpecificationPartI')
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        console.log('changed step scrolling to top of window');
        window.scrollTo(0, 0)
    }, [activeStep])


    return(
        <React.Fragment>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Specyfikacja warunków zamówienia</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <div>
                        <Stepper className={classes.name} activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step className={classes.name} key={label}>
                                    <StepLabel><Typography className={classes.name}>{label}</Typography></StepLabel>
                                    <StepContent>
                                        <TwoColumnStep>
                                            <Typography>{getStepContent(index)}</Typography>
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    Wróć
                                                </Button>
                                                {activeStep === steps.length - 1 ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleLast}
                                                        className={classes.button}
                                                        type="submit"

                                                    >Zakończ</Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                        className={classes.button}
                                                        type="submit"
                                                    >Dalej</Button>
                                                )}
                                            </div>
                                        </TwoColumnStep>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>Proces zakończony pomyślnie</Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </div>
                </AnimationRevealPage>
            </div>


        </React.Fragment>
    )
}

export default OrderSpecification;