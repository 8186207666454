import React, {useEffect, useState} from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {ChartDonut, ChartLabel, ChartThemeColor, ChartThemeVariant} from '@patternfly/react-charts';
import {fetchAuctionId} from "../services/fetchId";
import {Link} from "react-router-dom";
import EditRoundedIcon from "@material-ui/icons/EditRounded";




const MyAccountCard = (props) => {
    const windowWidth = useSelector(state => state.main.windowWidth);
    let useStyles;
    if(windowWidth >= 1300){
        useStyles = makeStyles({
            root: {
                width: 660,
                height: 250,
            },
            media: {
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                textAlign: 'center'
            },
            description: {
                textAlign: 'justify'
            },
            chart: {
               width: 500,
               height: 200
            }
        });
    }else if(windowWidth <= 1300){
        useStyles = makeStyles({
            root: {
                width: 410,
                height: 210,
            },
            media: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            title: {
                height: 100,
                textAlign: 'center'
            },
            description: {
                textAlign: 'justify'
            },
            chart: {
                width: 350,
                height: 170
            }
        });
    }

    const classes = useStyles();
    let today = new Date().toLocaleDateString('en-CA');
    const [inProgress, setInProgress] = useState(0);
    const [publicated, setPublicated] = useState(0);
    const [closed, setClosed] = useState(0);

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                let userAuctionsInProgress = [];
                let userAuctionsPublicated = [];
                let userAuctionsClosed = [];
                userAuctionsInProgress = data['hydra:member'].filter(auc => auc.owner.slice(11).toString() === localStorage.getItem('userId') && auc.startDate > today)
                userAuctionsPublicated = data['hydra:member'].filter(auc => auc.owner.slice(11).toString() === localStorage.getItem('userId') && auc.startDate <= today && auc.isClosed === false)
                userAuctionsClosed = data['hydra:member'].filter(auc => auc.owner.slice(11).toString() === localStorage.getItem('userId') && auc.startDate <= today && auc.isClosed === true)
                setInProgress(userAuctionsInProgress.length);
                setPublicated(userAuctionsPublicated.length);
                setClosed(userAuctionsClosed.length);
            }
        )
    }, [])



    return(
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <div className={classes.chart}>
                        <ChartDonut
                            ariaDesc="Average number of pets"
                            ariaTitle="Donut chart example"
                            constrainToVisibleArea={true}
                            data={[{ x: 'Przetargi nieogłoszone', y: inProgress }, { x: 'Przetargi ogłoszone', y: publicated }, { x: 'Przetargi zakończone', y: closed }]}
                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                            legendData={[{ name: 'Przetargi nieogłoszone: ' + inProgress }, { name: 'Przetargi ogłoszone: ' + publicated}, { name: 'Przetargi zakończone: ' + closed}]}
                            legendOrientation="vertical"
                            legendPosition="right"
                            padding={{
                                bottom: 20,
                                left: 20,
                                right: 140, // Adjusted to accommodate legend
                                top: 20
                            }}
                            title={inProgress + publicated + closed}
                            titleComponent={ <ChartLabel style={[{ fontSize: 80, fill: 'black' }]} /> }
                            themeColor={ChartThemeColor.purple}
                            width={350}
                        />
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default MyAccountCard;