import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-72 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
                  subheading = "",
                  heading = "Questions",
                  description = "Here are some frequently asked questions about our hotels from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
                  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
                  imageContain = false,
                  imageShadow = true,
                  faqs = null
                }) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Nie popełnisz błędów w nowej dokumentacji przetargowej - wypróbuj system ARKAD.",
      answer:
          "- Nie popełnisz błędów w nowej dokumentacji przetargowej - wypróbuj system ARKAD. \n"
          + "- Skorzystasz z opracowanych wzorów dla specyfikacji warunków zamówienia. \n"
          + "- Wygenerujesz gotową do publikacji specyfikację warunków zamówienia. \n"
          + "- Wygenerujesz ogłoszenia zgodnie ze wzorem ogłoszenia w Biuletynie Zamówień Publicznych, a także zgodnie ze wzorem ogłoszeń w Dzienniku Urzędowym Unii Europejskiej."
    },
    {
      question: "Dlaczego warto skorzystać z pomocy Generatora:",
      answer:
          "Wypełniając pola wskazane przez system jednocześnie uczysz się nowej ustawy, łącząc teorię z praktyką. Nie pominiesz żadnego obowiązkowego elementu specyfikacji warunków zamówienia. Z pomocą Generatora wypełnisz dokumenty konieczne do przygotowania i przeprowadzenia postępowania przetargowego. Skorzystasz ze słownika pojęć (wraz z odwołaniem do aktów prawnych).\n "
          + "Otrzymasz na telefon/email powiadomienia o ważnych terminach w Twoim przetargu. Będziesz mieć dostęp do kalendarza przetargów przez siebie przygotowywanych, gdzie automatycznie zapisywać się będą ważne terminy w każdym z przetargów. Nie musisz korzystać z czasochłonnych szkoleń."
    },
    {
      question: "Dodatkowe zalety Generatora:",
      answer:
          "- łatwość w obsłudze, \n"
          +"- czytelny interfejs, \n"
          +"- intuicyjne podpowiedzi, \n"
          +"- obszerna baza wiedzy, \n"
          +"- możliwość konsultacji z ekspertem. \n"
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
      <Container>
        <Content>
          <TwoColumn>
            <Column tw="hidden lg:block w-3/12 flex-shrink-0">
              <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
            </Column>
            <Column>
              <FAQContent>
                {subheading ? <Subheading>{subheading}</Subheading> : null}
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <FAQSContainer>
                  {faqs.map((faq, index) => (
                      <FAQ
                          key={index}
                          onClick={() => {
                            toggleQuestion(index);
                          }}
                          className="group"
                      >
                        <Question>
                          <QuestionText>{faq.question}</QuestionText>
                          <QuestionToggleIcon>
                            {activeQuestionIndex === index ? <PlusIcon /> : <MinusIcon />}
                          </QuestionToggleIcon>
                        </Question>
                        <Answer
                            variants={{
                              open: { opacity: 1, height: "auto", marginTop: "16px" },
                              collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                            }}
                            initial="open"
                            animate={activeQuestionIndex === index ? "collapsed" : "open"}
                            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                          {faq.answer}
                        </Answer>
                      </FAQ>
                  ))}
                </FAQSContainer>
              </FAQContent>
            </Column>
          </TwoColumn>
        </Content>
      </Container>
  );
};
