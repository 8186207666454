import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {NavLink} from "../headers/light2";
import tw from "twin.macro";
import {SideLogo} from "../misc/Typography";
import {PrimaryButton} from "../misc/Buttons";
import {Popover} from "@material-ui/core";
import {Linksmenu} from "../misc/Links";
import {useDispatch, useSelector} from "react-redux";
import {fetchMe, fetchUser} from "../../services/fetchId";
import {closeSnackbar as closeSnackbarAction} from "../../state/hints1/hintsActions";
import HomeIcon from '@material-ui/icons/Home';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles({
    list: {
        width: 260,
    },
    fullList: {
        width: 'auto',
    },
});
const Logo = styled(NavLink)`
  ${tw`font-black text-3xl! border-b-0 `};`
const ButtonCol = tw.div`flex flex-col justify-center mt-10!`;
const ButtonNew = tw(PrimaryButton)`mb-3 text-base p-5 w-48`
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const ButtonPass = tw(PrimaryButton)`mb-8! mt-3! w-full text-base inline-block `;


const SidebarLeft = () => {

    const windowWidth = useSelector(state => state.main.windowWidth);

    const classes = useStyles();

    const [isOn, setIsOn] = React.useState(false);
    const [permanent, setPermanent] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({left: false});
    const [isAdmin, setIsAdmin] = React.useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    useEffect(() => {
        fetchUser(localStorage.getItem('userId')).subscribe(
            data => {
                setIsAdmin(data.roles.indexOf("ROLE_ADMIN") > -1);
            }
        )
    }, [])

    useEffect(()=>{
        if (windowWidth < 900){
            setPermanent(false);
        } else {
            setPermanent(true);
        }
    },[windowWidth])


    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <AnimationRevealPage>
            <List>
                <Logo to="/myAccount" style={{display: 'flex', justifyContent: 'center'}}>
                    ARKAD
                </Logo>
                <SideLogo>Generator dokumentów przetargowych</SideLogo>
                <Divider />
                <ButtonCol>
                    <List>
                        <Link to='/myAccount'>
                            <ButtonNew><HomeIcon/></ButtonNew>
                        </Link>
                        <Link to='/choosingOrderType'>
                            <ButtonNew onClick={handlePopoverClick}>
                                Nowy przetarg
                            </ButtonNew>
                        </Link>
                        <Popover
                            style={{width: '18rem', textAlign: 'center'}}
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <><br/>
                                <ListItem>
                                    <Link to="/choosingOrderType">
                                        <LinkMenu>Czynności wstępne</LinkMenu>
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/orderSpecification">
                                        <LinkMenu>Specyfikacja Warunków zamówienia</LinkMenu>
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/orderSpecificationPartI">
                                        <LinkMenu>Specyfikacja Warunków zamówienia część I</LinkMenu>
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/finalSteps">
                                        <LinkMenu>??Czynności kończące??</LinkMenu>
                                    </Link>
                                </ListItem>
                                <br/>

                            </>
                        </Popover>
                        <Link to='/inProgress'>
                            <ButtonNew>Przetarg w trakcie przygotowywania - nieogłoszony</ButtonNew>
                        </Link>
                        <Link to='/PublicatedAuctions'>
                            <ButtonNew>Przetarg ogłoszony - niezakończony</ButtonNew>
                        </Link>
                        <Link to='/ClosedAuctions'>
                            <ButtonNew>Archiwum moich przetargów</ButtonNew>
                        </Link>

                        {
                            isAdmin && (
                                <Link to="/users">
                                    <ButtonNew>Użytkownicy</ButtonNew>
                                </Link>
                            )
                        }
                    </List>
                </ButtonCol>
            </List>
        </AnimationRevealPage>
        </div>

    );

    const [logged, setLogged] = useState();
    const [open, setOpen] = useState();

    useEffect(() => {
        fetchMe().subscribe(
            data => {
                setLogged(data['hydra:member'][0].logged);
            }
        )
    }, [])

    const handleClose = () => {
        setOpen(false);
        window.location.replace('/login');
    };

    return (
        <div>
            {logged === null && (
                <Dialog open={true}>
                    <DialogContent>
                        Twoja sesja została przerwana, aby kontynuować pracę w systemie zaloguj się ponownie.
                    </DialogContent>
                    <DialogActions>
                        <ButtonPass onClick={handleClose} style={{width: '70%', margin: 'auto'}}>Zaloguj się</ButtonPass>
                    </DialogActions>
                </Dialog>
            )}
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        permanent ? (
                            <Drawer className="scrollhost" anchor={anchor} variant="permanent">
                                {list(anchor)}
                            </Drawer>

                        ) : (
                            <>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(anchor, true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon style={{fontSize: '50px', textAlign: 'left'}}/>
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </div>
    );
}

export default SidebarLeft;