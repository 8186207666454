import "tailwindcss/dist/base.css";
import "./styles/globalStyles.css";
import React, {Component, useEffect, useState} from 'react'
import ProtectedLayout from './components/Layout/protectedLayout';
import NonAuthLayout from "./components/Layout/NonAuthLayout";
import { SnackbarProvider } from 'notistack';
// import { HashRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import {BrowserRouter as Router, Link, Route, Switch, useLocation} from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import AppRoute from "./routes/route"
import {Provider, useDispatch} from 'react-redux';
import {makeStore} from "./state/State";
import SoloOrder from "./pages/SoloOrder";
import CentralOrder from "./pages/CentralOrder";
import JointOrder from "./pages/JointOrder";
import ProxyOrder from "./pages/ProxyOrder";
import {makeStyles} from "@material-ui/core/styles";


const App = () => {

    const useStyles = makeStyles({
        warning: { backgroundColor: '#f5f0dd', color: '#c83351',fontSize: '12px', maxWidth: '1200px'},
        info: { backgroundColor: '#f5f0dd', color: '#dd75e9', fontSize: '12px', maxWidth: '1200px' },
        success: { backgroundColor: '#f5f0dd', color: 'black', fontSize: '12px', maxWidth: '1200px' },
    });

    const classes = useStyles();

    const store = makeStore();
    return (
        <Provider store={store}>
            <SnackbarProvider
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
                preventDuplicate = {true}
                autoHideDuration = {null}
                dense
                maxSnack={4}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
            }}>
                <Router>
                    <Switch>
                        <Route path="/soloOrder/:edit?/:id?" component={SoloOrder} />
                        <Route path="/centralOrder/:edit?/:id?" component={CentralOrder} />
                        <Route path="/jointOrder/:edit?/:id?" component={JointOrder} />
                        <Route path="/proxyOrder/:edit?/:id?" component={ProxyOrder} />
                        {authProtectedRoutes.map((route, idx) => (

                            <AppRoute
                                path={route.path}
                                layout={ProtectedLayout}
                                component={route.component}
                                key={`${idx} protected`}
                                isAuthProtected={true}
                            />
                        ))}

                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />

                        ))}

                        {/* <Route exact path="/" component={Home} />
              <Route path="/verify-success" component={SuccessActivateAccountPage} />
              <Route path="/verify-error" component={ErrorActivateAccountPage} />
              <Route path="/verification" component={Verification} />
              <Route path="/knowledge" component={KnowledgePage} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Signup} /> */}
                    </Switch>
                </Router>
            </SnackbarProvider>

        </Provider>
    )
  }


export  default App;