import {combineReducers, createStore} from "redux";
import snackbarReducer from './snackbar'
import {auctionReducer} from "./auction/auctionReducer";
import {mainReducer} from "./main/mainReducer";
import reducers from '../state/hints1/hintsReducer';
import toggleHintsReducer from "./toggleHints/toggleHintsReducer";


export const makeStore = () => {
    return createStore(
        combineReducers({
            toggleHints: toggleHintsReducer,
            snackbar: snackbarReducer,
            auction: auctionReducer,
            main: mainReducer,
            app: reducers
        }),  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
}