import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const ProvisionsPartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }


    const [dateE, setDateE] = React.useState(new Date());
    const [dateB, setDateB] = React.useState(new Date());
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifChanges === true && 2));
    const [addValue2, setAddValue2] = useState(edit === 'edit' && (auctionObject.ifAdvance === true && 2));

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }

    const handleChange2 = (e) => {
        if(e.target.value === 'false'){
            setAddValue2(1);
        }else if(e.target.value === 'true'){
            setAddValue2(2);
        }
    }

    let form1;
    let form2;


    if(addValue2 === 2){
        form2 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Określenie rodzaju i zakresu zmian oraz warunków ich wprowadzenia</FormLabel>
                        <TextValidator
                            value={formAuctionParts.infoChanges}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.infoChanges) : ''}
                            name="infoChanges"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Określenie rodzaju i zakresu zmian"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Informacje na temat zaliczek na poczet wykonania zamówienia</FormLabel>
                        <TextValidator
                            value={formAuctionParts.infoAdvance}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.infoAdvance) : ''}
                            name="infoAdvance"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Informacje na temat zaliczek"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }else if(addValue1 === 1){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>
                            Pozostałe istotne postanowienia umowy w sprawie zamówienia publicznego, które zostaną również wprowadzone do treści tej umowy.
                            <Tooltip title="Np. przedmioty umowy, obowiązki stron umowy, wartość umowy i zasady rozliczenia, termin obowiązywania umowy, kary umowne, odstąpienie od umowy, inne postanowienia umowy" placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            value={formAuctionParts.otherTerms}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.otherTerms) : ''}
                            name="otherTerms"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Pozostałe istotne postanowienia umowy"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="ifAdvance" value={formAuctionParts[no].ifAdvance.toString()}>
                            <RadioGroup name="ifAdvance" defaultValue={edit === 'edit' && auctionObject.ifAdvance.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje możliwości udzielenia zaliczek"/>
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość udzielenia zaliczek"/>
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifChanges.toString()} name="ifChanges">
                            <RadioGroup name="ifChanges"  defaultValue={edit === 'edit' && auctionObject.ifChanges.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange2(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje zmian umowy"/>
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje zmiany umowy"/>
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form2}
                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}


export default ProvisionsPartI;