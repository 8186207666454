

import React from "react";

class LoginService extends React.Component {

    constructor(networkingService) {
        super(networkingService);
        this.networkingService = networkingService;
    }
    login(email, password) {

        const body = {
            email,
            password,
        };
        const headers = {
            'Content-type': 'application/json; charset=UTF-8',
        };

        return this.networkingService.post('login', body, headers);
    }
    render(){
        return <></>
    }
}

export default LoginService;