

export class InitialOpService {
    constructor(networkingService) {
        this.networkingService = networkingService;
        this.userId = localStorage.getItem('userId')
        this.auctionId = localStorage.getItem('auctionId')
    }

    postInitialOp(data) {
        const body = { ...data };
        const headers = {
            'Content-type': 'application/ld+json; charset=UTF-8',
            'Accept': 'application/ld+json',
        };

        return this.networkingService.post('api/auction_items', body, headers);
    }

    postInitialOpAuction(data) {
        const body = { ...data };
        const headers = {
            'Content-type': 'application/ld+json; charset=UTF-8',
            'Accept': 'application/ld+json',
        };

        return this.networkingService.post('api/auctions', body, headers);
    }

    patchInitialOpAuction(data, auctionId) {
        const body = { ...data };
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch('api/auctions/' + auctionId, body, headers);
    }

    patchInitialOp(data, auctionId) {
        const body = { ...data };
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch('api/auction_items/' + auctionId, body, headers);
    }
}
