import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {MenuItem, Paper, Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from '@material-ui/core';
import {
    fetchAuctionItemId, fetchAuctionItemPartsId,
    fetchAuctionWithId,
    fetchCountry1, fetchCountry9,
    fetchCustomer, fetchEuProject,
    fetchVoivodeship
} from "../../services/fetchId";
import {fetchAllCountries} from "../../services/fetchCountries";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export const theme = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '0.175rem',
            }
        }
    }
});

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl `;
const Form = tw.div`flex items-center justify-between`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100%',
        marginRight: '35% !important',
        marginTop: '1%',
        maxWidth: '800px !important',
        minWidth: '350px !important',
    },
    label: {
        fontSize: '1rem !important',
    },
    labelRadio: {
        maxWidth: '750px !important',
        minWidth: '200px !important',
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelNormal: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem !important',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.2em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        borderBottom: '1px solid black',
        padding: '1%',
        marginBottom: '2%',
        marginTop: '2%'
    },
    root: {
        flexGrow: 1,
        alignItems: 'center',
        fontSize: '1em !important'
    },

    control: {
        padding: theme.spacing(2),
    },
}));

const SummaryOrderSpecificationPart = ({negotiation, no, edit, auctionObject, ueFunds}) => {

    const [customer, setCustomer] = useState([]);
    const [voivodeshipId, setVoivodeshipId] = useState('');
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState('');
    const [country, setCountry] = useState();
    const customerId = localStorage.getItem('customerId');
    const [value3, setValue3] = useState(false);

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);


    console.log(formAuctionParts[no])

    useEffect(() => {
        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'][(formAuctionParts[no].serviceAddressVoivodeship).slice(18) - 1].name)
        })
    }, [])

    useEffect(() => {
        fetchCountry1().subscribe(data => {
            setCountry(data['hydra:member'][(formAuctionParts[no].serviceAddressCountry).slice(15) - 1].polishShortName)
        })
    }, [])



    const classes = useStyles();


    return (
        <>
            <ValidatorForm >
                {
                    (negotiation === 'z' || negotiation === 'możliwość') &&
                    (
                        <>
                            <Typography className={classes.nameSec}>Negocjacje</Typography>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}  name="negotiationLimit">
                                    <RadioGroup name="negotiationLimit" value={formAuctionParts[no].negotiationLimit.toString()}>
                                        <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje ograniczenia liczby wykonawców, których zaprosi do negocjacji" />
                                        <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje ograniczenia liczby wykonawców, których zaprosi do negocjacji, stosując kryteria oceny ofert" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                formAuctionParts[no].negotiationLimit === true && (
                                    <><br/>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Maksymalna ilość wykonawców, których Zamawiający zaprosi do negacjacji ofert</FormLabel>
                                            <TextValidator
                                                value={formAuctionParts[no].maxNumberNegotiation}
                                                name="maxNumberNegotiation"
                                                className={classes.input}
                                                type="number"
                                                id="outlined-basic-u"
                                                disabled
                                                label="Maksymalna ilość wykonawców"
                                                variant="outlined"
                                            />
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Kryteria oceny ofert, które Zamawiający zamierza stosować w celu ograniczenia liczby wykonawców zapraszanych do negocjacji ofert</FormLabel>
                                            <TextValidator
                                                value={formAuctionParts[no].negotiationRequirements}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                disabled
                                                label="Kryteria oceny ofert"
                                                name="negotiationRequirements"
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                            />
                                        </Form>
                                    </>
                                )
                            }
                        </>
                    )
                }<br/>
                <Typography className={classes.nameSec}>Opis przedmiotu zamówienia</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Krótki opis przedmiotu zamówienia częsci {parseInt(no) + 1}
                            <Tooltip title="(wielkość, zakres, rodzaj i ilość dostaw, usług lub robót budowlanych)"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            value={formAuctionParts.shortDescriptionPart}
                            name="shortDescriptionPart"
                            disabled
                            type="text"
                            id="outlined-basic-u"
                            label="Krótki opis przedmiotu zamówienia częsci"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Główny kod CPV dla częsci {parseInt(no) + 1} zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionObject.cpvCode}
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Dodatkowy kod CPV dla częsci {parseInt(no) + 1} zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionObject.addCpvCode}
                            variant="outlined"
                        />
                </Form>
                <Typography className={classes.nameSec}>Miejsce świadczenia usług</Typography>
                <Form>
                    <FormLabel className={classes.label}>Ulica</FormLabel>
                    <TextValidator
                        value={formAuctionParts[no].serviceAddressStreet}
                        name="serviceAddressStreet"
                        className={classes.input}
                        type="text"
                        disabled
                        id="outlined-basic-u"
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Nr domu</FormLabel>
                    <TextValidator
                        value={formAuctionParts[no].serviceAddressHouseNumber}
                        name="serviceAddressHouseNumber"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                    <TextValidator
                        value={formAuctionParts[no].serviceAddressZipCode}
                        name="serviceAddressZipCode"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Miejscowość</FormLabel>
                    <TextValidator
                        value={formAuctionParts[no].serviceAddressCity}
                        name="serviceAddressCity"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Państwo</FormLabel>
                    <TextValidator
                        value={country}
                        name="serviceAddressCountry"
                        className={classes.input}
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Województwo</FormLabel>
                    <TextValidator
                        value={voivodeship}
                        name="serviceAddressVoivodeship"
                        id="outlined-basic-u"
                        className={classes.input}
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Form>
                    <FormLabel className={classes.label}>Kod NUTS</FormLabel>
                    <TextValidator
                        value={formAuctionParts[no].serviceNutsCode}
                        name="serviceNutsCode"
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                    />
                </Form>
                <Typography className={classes.nameSec}>Dodatkowe wymagania</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifDisabilityRequirements">
                        <FormLabel className={classes.label}>Wymagania w zakresie dostępności dla osób niepełnosprawnych
                            <Tooltip title="w zakresie dostępności dla osób niepełnosprawnych oraz projektowania z przeznaczeniem dla wszystkich użytkowników, zgodnie z art. 100 ust. 1 ustawy, jeżeli zamawiający je przewiduje"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <RadioGroup name="ifDisabilityRequirements" value={formAuctionParts[no].ifDisabilityRequirements.toString()} >
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań dostępności dla osób niepełnosprawnych i przeznaczenia dla wszystkich użytkowników." />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania w zakresie dostępności dla osób niepełnosprawnych i przeznaczenia dla wszystkich użytkowników." />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {
                    formAuctionParts[no].ifDisabilityRequirements === true && (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Wymagania w zakresie dostępności dla osób niepełnosprawnych</FormLabel>
                            <TextValidator
                                value={formAuctionParts[no].disabilityRequirements}
                                className={classes.input}
                                name="disabilityRequirements"
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                label="Wymagania w zakresie dostępności dla osób niepełnosprawnych"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    )
                }
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifPerformanceRequirements">
                        <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności
                            <Tooltip title="Informacja o wymaganiach dotyczących wydajności lub funkcjonalności, zgodnie z art. 101 ust. 1 ustawy"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <RadioGroup name="ifPerformanceRequirements" value={formAuctionParts[no].ifPerformanceRequirements.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań dotyczących wydajności lub funkcjonalności." />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania dotyczące wydajności lub funkcjonalności." />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {
                    formAuctionParts[no].ifPerformanceRequirements === true && (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności</FormLabel>
                            <TextValidator
                                value={formAuctionParts[no].performanceRequirements}
                                className={classes.input}
                                type="text"
                                name="performanceRequirements"
                                id="outlined-basic-u"
                                disabled
                                label="Wymagania dotyczące wydajności lub funkcjonalności"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    )
                }
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifEtiqRequirements">
                        <FormLabel className={classes.label}>Wymagania dotyczące etykiety
                            <Tooltip title="Informacja czy zamawiający żąda określonej etykiety lub wskazuje mające zastosowanie wymagania określonej etykiety, zgodnie z art. 104 ustawy"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <RadioGroup  name="ifEtiqRequirements" value={formAuctionParts[no].ifEtiqRequirements.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie żąda okreslonej etykiety i nie wskazuje wymagań określonej etykiety, zgodnie z art. 104 ustawy." />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający żąda okreslonej etykiety i wskazuje mające zastosowanie wymagania określonej etykiety, zgodnie z art. 104 ustawy." />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifEtiqRequirements === true && (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Wymagania dotyczące wydajności lub funkcjonalności</FormLabel>
                            <TextValidator
                                value={formAuctionParts[no].etiqRequirements}
                                name="etiqRequirements"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                label="Wymagania dotyczące etykiety"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Warunki udziału w postępowaniu</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifConditions393">
                        <RadioGroup name="ifConditions393" value={formAuctionParts[no].ifConditions393.toString()} >
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań w zakresie udziału 50% dostaw zgodnie z art. 393 ust. 1, pkt 4" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania w zakresie udziału 50% dostaw zgodnie z art. 393 ust. 1, pkt 4" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifConditions">
                        <RadioGroup name="ifConditions" value={formAuctionParts[no].ifConditions.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje warunków udziału w postępowaniu" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający wymaga spełnienia określonych warunków udziału w postępowaniu" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifConditions === true && (
                        <>
                            <Typography className={classes.nameSec}>Warunki</Typography>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Warunki zdolności do występowania w obrocie gospodarczym</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].conditions1}
                                        name="conditions1"
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Warunki zdolności do występowania w obrocie gospodarczym"
                                        variant="outlined"
                                        multiline
                                        disabled
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Warunki uprawnień do prowadzenia określonej działalności gospodarczej lub zawodowej</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].conditions2}
                                        name="conditions2"
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Warunki uprawnień do prowadzenia określonej działalności"
                                        variant="outlined"
                                        multiline
                                        disabled
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Warunki sytuacji ekonomicznej i finansowej</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].conditions3}
                                        className={classes.input}
                                        name="conditions3"
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Warunki sytuacji ekonomicznej i finansowej"
                                        variant="outlined"
                                        multiline
                                        disabled
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Warunki zdolności technicznej lub zawodowej</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].conditions4}
                                        className={classes.input}
                                        name="conditions4"
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Warunki zdolności technicznej lub zawodowej"
                                        variant="outlined"
                                        multiline
                                        disabled
                                        rows={5}
                                    />
                            </Form>
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Wizja lokalna</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifLocal">
                        <RadioGroup name="ifLocal" value={formAuctionParts[no].ifLocal} >
                            <FormControlLabel disabled value="1" control={<Radio color="primary"/>} label="Zamawiający nie wymaga przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                            <FormControlLabel disabled value="2" control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                            <FormControlLabel disabled value="3" control={<Radio color="primary"/>} label="Zamawiający wymaga przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifLocal === '1' || formAuctionParts[0].ifLocal === '2' && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Opis warunków przeprowadzenia wizji lokalnej
                                    <Tooltip title="Zamawiający opisuje warunki przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez neigo dokumentów niezbędnych do realizacji zamówienia"
                                             placement="bottom">
                                        <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                    </Tooltip>
                                </FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].localDescription}
                                    name="localDescription"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    label="Opis warunków przeprowadzenia wizji lokalnej"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Podmiotowe środki dowodowe</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifSubjectiveMeans2">
                        <RadioGroup name="ifSubjectiveMeans2" value={formAuctionParts[no].ifSubjectiveMeans2.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie będzie wymagał złożenia podmiotowych środków dochodowych" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający będzie wymagał złożenia podmiotowych środków dochodowych" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {
                    formAuctionParts[no].ifSubjectiveMeans2 === true && (
                        <>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Potwierdzenie braku podstaw do wykluczenia - wykaz wymaganych podmiotowych środków dowodowych</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].subjectiveMeansList2}
                                        className={classes.input}
                                        name="subjectiveMeansList2"
                                        type="text"
                                        id="outlined-basic-u"
                                        disabled
                                        label="wykaz wymaganych podmiotowych środków dowodowych"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Spełnienie warunków udziału w postępowaniu lub kryteriów selekcji - wykaz wymaganych podmiotowych środków dowodowych</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].subjectiveMeansList3}
                                        name="subjectiveMeansList3"
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        id="outlined-basic-u"
                                        label="wykaz wymaganych podmiotowych środków dowodowych"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Wykaz wymaganych przedmiotowych środków dowodowych</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].subjectiveMeansList4}
                                        name="subjectiveMeansList4"
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        id="outlined-basic-u"
                                        label="wykaz wymaganych podmiotowych środków dowodowych"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Wykaz innych wymaganych oświadczeń lub dokumentów</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        value={formAuctionParts[no].subjectiveMeansList5}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Wykaz innych wymaganych oświadczeń lub dokumentów"
                                        variant="outlined"
                                        multiline
                                        disabled
                                        rows={5}
                                        name="subjectiveMeansList5"
                                    />
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="ifSubjectiveMeans1">
                                    <RadioGroup name="ifSubjectiveMeans1" value={formAuctionParts[no].ifSubjectiveMeans1.toString()}>
                                        <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje uzupełnienia przedmiotowych środków dowodowych po złożeniu oferty zgodnie z art. 107 ust. 2 i 3" />
                                        <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje uzupełnienie przedmiotowych środków dowodowych po złożeniu oferty zgodnie z art. 107 ust. 2 i 3" />
                                    </RadioGroup>
                                </FormControl>
                            </Form><br/>
                            {
                                formAuctionParts[no].ifSubjectiveMeans1 === true && (
                                    <>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Wykaz wymaganych przedmiotowych środków dowodowych</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].meansList}
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    label="wykaz wymaganych podmiotowych środków dowodowych"
                                                    variant="outlined"
                                                    multiline
                                                    disabled
                                                    name="meansList"
                                                    rows={5}
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Wykaz innych wymaganych oświadczeń lub dokumentów</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].otherMeansList}
                                                    name="otherMeansList"
                                                    className={classes.input}
                                                    type="text"
                                                    disabled
                                                    id="outlined-basic-u"
                                                    label="wykaz innych wymaganych oświadczeń lub dokumentów"
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Wykaz przedmiotowych środków dowodowych podlegających uzupełnieniu</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].subjectiveMeansList1}
                                                    name="subjectiveMeansList1"
                                                    disabled
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    label="Wykaz przedmiotowych środków dowodowych"
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                        </Form>
                                    </>
                                )
                            }
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Możliwość opcji</Typography>
                {
                    !!auctionObject && (
                        <>
                            {auctionObject.partValueOp === 0 ?
                                (
                                    <Form className={classes.label}>
                                            <TextValidator
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                disabled
                                                value={"Zamówienie nie obejmuje opcji"}
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                            />
                                    </Form>
                                ) :
                                (
                                    <>
                                        <Form className={classes.label}>
                                                <TextValidator
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    disabled
                                                    value={"Zamówienie obejmuje opcje"}
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Rodzaj opcji</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].optionType}
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    label="Rodzaj opcji"
                                                    variant="outlined"
                                                    multiline
                                                    disabled
                                                    name="optionType"
                                                    rows={5}
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Maksymalna wartość opcji</FormLabel>
                                                <TextValidator
                                                    value={auctionObject.partValueOp}
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    disabled
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Okoliczności skorzystania z opcji</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].optionCircumstances}
                                                    className={classes.input}
                                                    type="text"
                                                    disabled
                                                    name="optionCircumstances"
                                                    id="outlined-basic-u"
                                                    label="Okoliczności skorzystania z opcji"
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                        </Form>
                                    </>
                                )}
                        </>
                    )

                }
                <Typography className={classes.nameSec}>Osobiste wykonanie kluczowych zadań</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifObligation">
                        <RadioGroup name="ifObligation" value={formAuctionParts[no].ifObligation.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje obowiązku osobistego wykonanaia przez wykonawcę kluczowych zadań" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje obowiązek osobistego wykonanaia przez wykonawcę kluczowych zadań" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {formAuctionParts[no].ifObligation === true && (
                    <>
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Zakres dotyczący obowiązku osobistego wykonania przez wykonawcę kluczowych zadań dotyczących zamówień na roboty budowlane i usługi</FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].obligation1}
                                    name="obligation1"
                                    className={classes.input}
                                    type="text"
                                    disabled
                                    id="outlined-basic-u"
                                    label="Zakres dotyczący obowiązku osobistego wykonania"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Zakres dotyczący obowiązku osobistego wykonania przez wykonawcę kluczowych zadań dotyczących prac związanych z rozmieszczeniem i instalacją, w ramach zamówienia na dostawy</FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].obligation2}
                                    name="obligation2"
                                    className={classes.input}
                                    type="text"
                                    disabled
                                    id="outlined-basic-u"
                                    label="Zakres dotyczący obowiązku osobistego wykonania"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    </>
                )}
                <Typography className={classes.nameSec}>Zatrudnienie na podstawie stosunku pracy</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifEmploymentRequirements">
                        <RadioGroup name="ifEmploymentRequirements" value={formAuctionParts[no].ifEmploymentRequirements.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie określa wymagań zatrudnienia na podstawie stosunku pracy" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający określa wymagania zatrudnienia na podstawie stosunku pracy (tylko usługi i roboty budowlane)" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {formAuctionParts[no].ifEmploymentRequirements === true && (
                    <>
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Zamawiający wymaga zatrudnienia na podstawie umowy o pracę przez wykonawcę lub podwykonawcę osób wykonujących wskazane poniżej czynności w trakcie realizacji zamówienia:</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    value={formAuctionParts[no].employmentRequirments1}
                                    type="text"
                                    name="employmentRequirments1"
                                    id="outlined-basic-u"
                                    disabled
                                    label="Rodzaj czynności"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>W trakcie realizacji zamówienia zamawiający uprawniony jest do kontroli spełniania przez wykonawcę lub podwykonawcę wymogu zatrudnienia na podstawie umowy o pracę osób wykonujących wskazane czynności. Zamawiający uprawniony jest w szczególności do:</FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].requirementCheck}
                                    name="requirementCheck"
                                    className={classes.input}
                                    type="text"
                                    disabled
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Uprawnienia zamawiającego w zakresie kontroli spełniania przez wykonawcę wymagañ związanych z zatrudnianiem tych osób oraz sankcji z tytułu niespełnienia tych wymagań: </FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].requirementCheck1}
                                    className={classes.input}
                                    disabled
                                    name="requirementCheck1"
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    </>
                )}
                <Typography className={classes.nameSec}>Zatrudnienie osób z art. 96</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].ifEmploymentRequirements96.toString()} name="ifEmploymentRequirements96">
                        <RadioGroup name="ifEmploymentRequirements96" value={formAuctionParts[no].ifEmploymentRequirements96.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie określa wymagań zatrudnienia osób, o których mowa w art.96 ust.2 pkt 2" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający określa wymagania zatrudnienia osób, o których mowa w art.96 ust.2 pkt 2" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifEmploymentRequirements96 === true && (
                        <Form className={classes.label}>
                                <TextValidator
                                    value={formAuctionParts[no].employmentRequirments96}
                                    name="employmentRequirments96"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Wykonawcy, o których mowa w art. 94</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifContractors94">
                        <RadioGroup name="ifContractors94"  value={formAuctionParts[no].ifContractors94.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie zastrzega możlwiości ubiegania się o udzielenie zamówienia wyłącznie przez wykonawców, o których mowa w art. 94" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający zastrzega możlwiość ubiegania się o udzielenie zamówienia wyłącznie przez wykonawców, o których mowa w art. 94" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifContractors94 === true && (
                        <Form className={classes.label}>
                                <TextValidator
                                    value={formAuctionParts[no].contractors94}
                                    className={classes.input}
                                    type="text"
                                    name="contractors94"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    multiline
                                    disabled
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Termin wykonania zamówienia</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="deadline">
                        <FormLabel className={classes.label}>Termin wykonania zamówienia:</FormLabel>
                        <RadioGroup name="deadline" value={formAuctionParts[no].deadline}>
                            <FormControlLabel disabled value="Określony długością obowiązywania umowy" control={<Radio color="primary"/>} label="Określony długością obowiązywania umowy"/>
                            <FormControlLabel disabled value="Określony przy pomocy daty" control={<Radio color="primary"/>} label="Określony przy pomocy daty"/>
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].deadline === 'Określony długością obowiązywania umowy' && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="deadline1">
                                    <FormLabel className={classes.label}>Okres określony zostanie w:</FormLabel>
                                    <RadioGroup name="deadline1" value={formAuctionParts[no].deadline1}>
                                        <FormControlLabel value="Miesiącach" control={<Radio color="primary"/>} label="Miesiącach" />
                                        <FormControlLabel value="Dniach" control={<Radio color="primary"/>} label="Dniach" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                formAuctionParts[no].deadline1 === 'Miesiącach' && (
                                    <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Podaj okres określony w miesiącach:</FormLabel>
                                            <TextValidator
                                                value={formAuctionParts[no].deadlineMonths}
                                                className={classes.input}
                                                name="deadlineMonths"
                                                type="number"
                                                disabled
                                                id="outlined-basic-u"
                                                label="Okres określony w miesiącach"
                                                variant="outlined"
                                            />
                                    </Form>
                                )
                            }
                            {
                                formAuctionParts[no].deadline1 === 'Dniach' && (
                                    <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Podaj okres określony w dniach:</FormLabel>
                                            <TextValidator
                                                value={formAuctionParts[no].deadlineDays}
                                                name="deadlineDays"
                                                disabled
                                                className={classes.input}
                                                type="number"
                                                id="outlined-basic-u"
                                                label="Okres określony w dniach"
                                                variant="outlined"
                                            />
                                    </Form>
                                )
                            }
                        </>
                    )
                }
                {
                    formAuctionParts[no].deadline === 'Określony przy pomocy daty' && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} value={formAuctionParts[no].deadlineDate} name="deadlineDate">
                                    <FormLabel className={classes.label}>Termin określony przy pomocy daty:</FormLabel>
                                    <RadioGroup name="deadlineDate" value={formAuctionParts[no].deadlineDate}>
                                        <FormControlLabel disabled value="Rozpoczęcia" control={<Radio color="primary"/>} label="Rozpoczęcia" />
                                        <FormControlLabel disabled value="Zakończenia" control={<Radio color="primary"/>} label="Zakończenia" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                formAuctionParts[no].deadlineDate === "Rozpoczęcia" && (
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label} name="startDate">
                                            <FormLabel className={classes.label}>Wybierz datę rozpoczęcia:</FormLabel>
                                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                                <DatePicker inputVariant="outlined" format="yyyy/MM/dd" value={formAuctionParts.startDate}/>
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Form>
                                )
                            }
                            {
                                formAuctionParts[no].deadlineDate === "Zakończenia" && (
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label} name="endDate">
                                            <FormLabel className={classes.label}>Wybierz datę zakończenia:</FormLabel>
                                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                                <DatePicker format="yyyy/MM/dd" value={formAuctionParts.endDate}/>
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Form>
                                )
                            }
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Istotne zapisy umowy</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifAdvance" value={formAuctionParts[no].ifAdvance.toString()}>
                        <RadioGroup name="ifAdvance" value={formAuctionParts[no].ifAdvance.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje możliwości udzielenia zaliczek"/>
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość udzielenia zaliczek"/>
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifAdvance && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Informacje na temat zaliczek na poczet wykonania zamówienia</FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].infoAdvance}
                                    name="infoAdvance"
                                    disabled
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Informacje na temat zaliczek"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifChanges">
                        <RadioGroup name="ifChanges" value={formAuctionParts[no].ifChanges.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje zmian umowy"/>
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje zmiany umowy"/>
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifChanges && (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Określenie rodzaju i zakresu zmian oraz warunków ich wprowadzenia</FormLabel>
                            <TextValidator
                                value={formAuctionParts[no].infoChanges}
                                name="infoChanges"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                label="Określenie rodzaju i zakresu zmian"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Form>
                    )
                }
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>
                            Pozostałe istotne postanowienia umowy w sprawie zamówienia publicznego, które zostaną również wprowadzone do treści tej umowy.
                            <Tooltip title="Np. przedmioty umowy, obowiązki stron umowy, wartość umowy i zasady rozliczenia, termin obowiązywania umowy, kary umowne, odstąpienie od umowy, inne postanowienia umowy" placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            value={formAuctionParts[no].otherTerms}
                            disabled
                            name="otherTerms"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Pozostałe istotne postanowienia umowy"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Wadium</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}  name="ifWadium">
                        <RadioGroup name="ifWadium" value={!!formAuctionParts[no].ifWadium && formAuctionParts[no].ifWadium.toString()}>
                            <FormControlLabel disabled value="false" control={<Radio color="primary"/>} label="Zamawiający nie przewiduje obowiązku wzniesienia wadium" />
                            <FormControlLabel disabled value="true" control={<Radio color="primary"/>} label="Zamawiający przewiduje obowiązek wzniesienia wadium" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    !!formAuctionParts[no].ifWadium && formAuctionParts[no].ifWadium === true && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wysokość wadium w procentach</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].wadiumPercentage}
                                        disabled
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="1"
                                        className={classes.input}
                                        id="outlined-basic-u"
                                        label="Wysokość wadium w procentach"
                                        name="wadiumPercentage"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Kwota</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].wadiumValue}
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        id="outlined-basic-u"
                                        label="Wartość dostaw bez VAT"
                                        variant="outlined"
                                        name="wadiumValue"
                                    />
                                </FormControl>
                            </Form>
                            {
                                            ueFunds === true ? (
                                                <Form className={classes.label}>
                                                    <FormControl className={classes.label}>
                                                        <FormLabel className={classes.label}>Informacja o formach wznoszenia
                                                            wadium</FormLabel>
                                                        <TextValidator
                                                            value={formAuctionParts[no].wadiumInfoUe}
                                                            className={classes.input}
                                                            type="text"
                                                            disabled
                                                            id="outlined-basic-u"
                                                            variant="outlined"
                                                            multiline
                                                            name="wadiumInfoUe"
                                                            rows={5}
                                                        />
                                                    </FormControl>
                                                </Form>
                                            ) : (
                                                <Form className={classes.label}>
                                                    <FormControl className={classes.label}>
                                                        <FormLabel className={classes.label}>Informacja o formach wznoszenia
                                                            wadium</FormLabel>
                                                        <TextValidator
                                                            className={classes.input}
                                                            type="text"
                                                            id="outlined-basic-u"
                                                            value={formAuctionParts[no].wadiumInfo}
                                                            variant="outlined"
                                                            disabled
                                                            multiline
                                                            rows={5}
                                                            name="wadiumInfo"
                                                        />
                                                    </FormControl>
                                                </Form>
                                            )

                            }
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Aukcja elektroniczna</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].ifEAuction.toString()} name="ifEAuction">
                        <RadioGroup name="ifEAuction" value={formAuctionParts[no].ifEAuction.toString()}>
                            <FormControlLabel disabled value="false" control={<Radio color="primary"/>} label="Zamawiający nie przewiduje aukcji elektronicznej" />
                            <FormControlLabel disabled value="true" control={<Radio color="primary"/>} label="Zamawiający przewiduje aukcję elektroniczną" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifEAuction === true && (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>
                                    Informacje określające sposób i warunki przeprowadzenia aukcji elektronicznej
                                </FormLabel>
                                <TextValidator
                                    value={formAuctionParts[no].conditionsEAuction}
                                    className={classes.input}
                                    type="text"
                                    disabled
                                    name="conditionsEAuction"
                                    id="outlined-basic-u"
                                    label="Informacje nt. aukcji elektronicznej"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Sposób obliczania ceny</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>
                            Sposób obliczania ceny
                        </FormLabel>
                        <TextValidator
                            value={formAuctionParts[no].pricing}
                            name="pricing"
                            className={classes.input}
                            type="text"
                            disabled
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Typography className={classes.nameSec}>Kryteria oceny ofert</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="criterion">
                        <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                        <RadioGroup name="criterion" value={formAuctionParts[no].criterion}>
                            <FormControlLabel disabled value="Kryteriów jakościowych oraz ceny lub kosztu" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz ceny lub kosztu" />
                            <FormControlLabel disabled value="Kryterium ceny lub kosztu" control={<Radio color="primary"/>} label="Kryterium ceny lub kosztu" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].criterion === "Kryterium ceny lub kosztu" && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="criterion2">
                                    <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                                    <RadioGroup name="criterion2" value={formAuctionParts[no].criterion2}>
                                        <FormControlLabel disabled value="Kryterium ceny" control={<Radio color="primary"/>} label="Kryterium ceny" />
                                        <FormControlLabel disabled value="Kryterium kosztu" control={<Radio color="primary"/>} label="Kryterium kosztu" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                formAuctionParts[no].criterion2 ===  "Kryterium ceny" && (
                                    <>
                                        <Typography className={classes.nameSec}>Kryterium ceny</Typography>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: Cena </FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight5}
                                                    name="weight5"
                                                    type="number"
                                                    disabled
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga"
                                                    variant="outlined"
                                                />
                                        </Form>
                                    </>
                                )
                            }
                            {
                                formAuctionParts[no].criterion2 ===  "Kryterium kosztu" && (
                                    <>
                                        <Typography className={classes.nameSec}>Kryterium kosztu</Typography>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: Cena </FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight5}
                                                    name="weight5"
                                                    type="number"
                                                    disabled
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: Cena"
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: Koszt użytkowania </FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight6}
                                                    name="weight6"
                                                    type="number"
                                                    disabled
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga Koszt użytkowania"
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormControl className={classes.label}>
                                                <RadioGroup value={value3} onChange={setValue3(true)}>
                                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Inne" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Form>
                                        {value3 && (
                                            <>
                                                <Form className={classes.label}>
                                                        <FormLabel className={classes.label}>Kryterium</FormLabel>
                                                        <TextValidator
                                                            value={formAuctionParts[no].criterion4}
                                                            name="criterion4"
                                                            type="text"
                                                            className={classes.input}
                                                            id="outlined-basic-u"
                                                            label="Kryterium"
                                                            disabled
                                                            variant="outlined"
                                                        />
                                                </Form>
                                                <Form className={classes.label}>
                                                        <FormLabel className={classes.label}>Waga:</FormLabel>
                                                        <TextValidator
                                                            value={formAuctionParts[no].weight7}
                                                            type="number"
                                                            name="weight7"
                                                            className={classes.input}
                                                            id="outlined-basic-u"
                                                            label="Waga"
                                                            disabled
                                                            variant="outlined"
                                                        />
                                                </Form>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    formAuctionParts[no].criterion === 'Kryteriów jakościowych oraz ceny lub kosztu' && (
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="criterion3">
                                    <FormLabel className={classes.label}>Opis kryteriów oceny ofert, wraz z podaniem wag tych kryteriów, i sposobu oceny ofert</FormLabel>
                                    <RadioGroup name="criterion3" value={formAuctionParts[no].criterion3}>
                                        <FormControlLabel value="Kryteriów jakościowych oraz ceny" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz ceny" />
                                        <FormControlLabel value="Kryteriów jakościowych oraz kosztu" control={<Radio color="primary"/>} label="Kryteriów jakościowych oraz kosztu" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                formAuctionParts[no].criterion3 === "Kryteriów jakościowych oraz ceny" && (
                                    <>
                                        <Typography className={classes.nameSec}>Kryterium ceny</Typography>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: cena</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight5}
                                                    name="weight5"
                                                    type="number"
                                                    disabled
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: cena"
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Typography className={classes.nameSec}>Kryteria jakościowe</Typography>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: parametry techniczne</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight4}
                                                    disabled
                                                    name="weight4"
                                                    type="number"
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: parametry techniczne"
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: integracja zawodowa i społeczna</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight3}
                                                    name="weight3"
                                                    type="number"
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: integracja zawodowa i społeczna"
                                                    disabled
                                                    variant="outlined"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: efektywność energetyczna</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight2}
                                                    type="number"
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: efektywność energetyczna"
                                                    disabled
                                                    variant="outlined"
                                                    name="weight2"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Waga: innowacyjność</FormLabel>
                                                <TextValidator
                                                    value={formAuctionParts[no].weight1}
                                                    type="number"
                                                    className={classes.input}
                                                    id="outlined-basic-u"
                                                    label="Waga: innowacyjność"
                                                    disabled
                                                    variant="outlined"
                                                    name="weight1"
                                                />
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormControl className={classes.label}>
                                                <RadioGroup value={value3} onChange={setValue3(true)}>
                                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Inne" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Form>
                                        {
                                            value3 && (
                                                <>
                                                    <Form className={classes.label}>
                                                            <FormLabel className={classes.label}>Kryterium</FormLabel>
                                                            <TextValidator
                                                                value={formAuctionParts[no].criterion4}
                                                                name="criterion4"
                                                                type="text"
                                                                className={classes.input}
                                                                id="outlined-basic-u"
                                                                label="Kryterium"
                                                                disabled
                                                                variant="outlined"
                                                            />
                                                    </Form>
                                                    <Form className={classes.label}>
                                                            <FormLabel className={classes.label}>Waga:</FormLabel>
                                                            <TextValidator
                                                                value={formAuctionParts[no].weight7}
                                                                type="number"
                                                                name="weight7"
                                                                disabled
                                                                className={classes.input}
                                                                id="outlined-basic-u"
                                                                label="Waga"
                                                                variant="outlined"
                                                            />
                                                    </Form>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    ueFunds === true && (
                        <>
                            <Typography className={classes.nameSec}>Procedura odwrócona</Typography>
                            <Form className={classes.label}>
                                <FormControl className={classes.label} name="ifRreverse">
                                    <RadioGroup name="ifRreverse" value={formAuctionParts[no].ifRReverse.toString()}>
                                        <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje odwróconej kolejności oceny ofert" />
                                        <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje odwróconą kolejność oceny ofert" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Zabezpieczenie należytego wykonania umowy</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifProtection">
                        <RadioGroup name="ifProtection" value={formAuctionParts[no].ifProtection.toString()}>
                            <FormControlLabel disabled value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje zabezpieczenia należytego wykonania umowy" />
                            <FormControlLabel disabled value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje zabezpieczenie należytego wykonania umowy" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifProtection === true && (
                        <>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Wysokość zabezpieczenia należytego wykonania umowy w procentach</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].protectionValue}
                                        name="protectionValue"
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="1"
                                        className={classes.input}
                                        id="outlined-basic-u"
                                        label="Wysokość zabezpieczenia w procentach"
                                        disabled
                                        variant="outlined"
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Kwota</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].protectionValue1}
                                        validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                                        errorMessages={['Wprowadź poprawny format: x,xx']}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Kwota"
                                        variant="outlined"
                                        name="protectionValue1"
                                        disabled
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Informacja o formach wnoszenia zabezpieczenia należytego wykonania umowy</FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].protectionInfo}
                                        name="protectionInfo"
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                            </Form>
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Umowa ramowa</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="ifFrameworkAgreement">
                        <RadioGroup name="ifFrameworkAgreement" value={formAuctionParts[no].ifFrameworkAgreement.toString()}>
                            <FormControlLabel disabled value="false" control={<Radio color="primary"/>} label="Zamawiający nie przewiduje zawarcia umowy ramowej" />
                            <FormControlLabel disabled value="true" control={<Radio color="primary"/>} label="Zamawiający przewiduje zawarcie umowy ramowej" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    formAuctionParts[no].ifFrameworkAgreement === true && (
                        <>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Maksymalna liczba wykonawców, z którymi zamawiający zawrze umowę ramową </FormLabel>
                                    <TextValidator
                                        value={formAuctionParts[no].maxConstructors}
                                        name="maxConstructors"
                                        className={classes.input}
                                        type="number"
                                        id="outlined-basic-u"
                                        label="Maksymalna liczba wykonawców"
                                        disabled
                                        variant="outlined"
                                    />
                            </Form>
                        </>
                    )
                }
            </ValidatorForm>
        </>

    )
}

export default SummaryOrderSpecificationPart;