import React, {useEffect, useState} from "react";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {fetchCustomer, fetchId, fetchPassword, fetchUser} from "../services/fetchId";
import {Card, makeStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {Link} from "react-router-dom";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import {PrimaryButton} from "../components/misc/Buttons";
import {Document, Page, PDFDownloadLink, StyleSheet, Text, View, Font} from '@react-pdf/renderer';
import MyProfilePDF from "../components/PDFs/PDF"


const Form = tw.div``;
const Button = tw(PrimaryButton)`mb-3! mt-3! w-full text-base inline-block `;

const MyProfile = () => {

    const useStyles = makeStyles({
        root: {
            minWidth: 450,
            maxWidth: 550,
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '5% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },
        inputDiv: {
            display: 'flex',
            alignItems: 'left',
            flexDirection: 'column'
        },
        input: {
            width: '100%',
            marginTop: 10,
            marginBottom:12,
        },
        label: {
            marginTop: 20,
            marginBottom: '5%',
            fontWeight: 'bold',
            fontSize: 'large'
        }
    });


    const classes = useStyles();

    const [customer, setCustomer] = useState([])
    const [me, setMe] = useState([])
    const [pass, setPass] = useState([])
    const userId = localStorage.getItem('userId');
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        fetchUser(userId).subscribe(data => {
            setMe(data)
        })

        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
        })
    }, [])

    console.log("userID znaczy" , userId)
    console.log("customerID znaczy" , customerId)

    return(
        <>
            <SidebarLeftAccount/>
            <SidebarRightAccount/>
            <AnimationRevealPage>
                <div className="content content-left" style={{display: 'flex', justifyContent: 'center'}}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <div className={classes.header}>
                                <Typography className={classes.nameSec}>DANE UŻYTKOWNIKA</Typography>
                            </div>
                            <Link to="/EditMyProfile">
                                <Button  style={{display: 'flex', justifyContent: 'center', width: '80%', margin: 'auto'}}>
                                    Edytuj Dane Użytkownika
                                    <RecentActorsIcon style={{marginLeft:'15px'}}/>
                                </Button>
                            </Link><br/>
                            <ValidatorForm>
                                <div className={classes.inputDiv}>
                                    <div>
                                        <FormLabel className={classes.label}>Nazwa Użytkownika</FormLabel>
                                        <Form>
                                            <TextValidator
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                value={me.username}
                                                variant="outlined"
                                                disabled/>
                                        </Form>
                                    </div>
                                    <div>
                                        <FormLabel className={classes.label}>Adres email</FormLabel>
                                        <Form>
                                            <TextValidator
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                label={me.email}
                                                variant="outlined"
                                                placeholder={me.email}
                                                disabled/>
                                        </Form>
                                    </div>
                                </div>

                        </ValidatorForm>
                        </CardContent>
                    </Card>
                </div>
            </AnimationRevealPage>
        </>
    )
}

export default MyProfile;