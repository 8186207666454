import React, {useEffect, useRef, useState} from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import tw from "twin.macro";
import {SectionHeading} from "./misc/Headings";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../state/auction/auctionActions";
import {fetchAuctionItemId} from "../services/fetchId";


const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Form = tw.div`mb-5 mx-5 mr-3`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        color: 'black',
        width: '100%',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const OrderForm = (props) => {

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const [BZPName, setBZPName] = useState('');
    const [BZPPublic, setBZPPublic] = useState('');
    const [plan, setPlan] = useState('');
    const [auctionItem, setAuctionItem] = useState();
    const [bzpNameVal, setBzpNameVal] = useState('');
    const [bzpNameForm, setBzpNameForm] = useState();
    const [bzpPublicVal, setBzpPublicVal] = useState('');
    const [bzpPublicForm, setBzpPublicForm] = useState();
    const [planVal, setPlanVal] = useState('');
    const [planForm, setPlanForm] = useState();

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )

        if(!!auctionItem){
            setBzpNameVal(auctionItem.bzpName);
            setBzpPublicVal(auctionItem.bzpPublic.toString());
            setPlanVal(auctionItem.plan.toString());
        }
    }, [auctionItem])

    useEffect(() => {
        setBzpNameForm(
            <Form className={classes.label}>
                <FormControl value={form.bzpName} className={classes.label}>
                    <FormLabel  className={classes.label}>Nazwa ogłoszenia w BZP
                    </FormLabel>
                    <RadioGroup name="bzpName" className={classes.input} value={bzpNameVal} onChange={(e) => {setValue(e); handleBZPNameChange(e); setBzpNameVal(e.target.value)}}>
                        <FormControlLabel value="Obowiązkowe" control={<Radio color="primary"/>} label="Obowiązkowe" />
                        <FormControlLabel value="Nieobowiązkowe" control={<Radio color="primary"/>} label="Nieobowiązkowe" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setBzpPublicForm(
            <Form className={classes.label}>
                <FormControl value={form.bzpPublic} className={classes.label}>
                    <FormLabel className={classes.label}>Czy ogłoszenie w BZP dotyczy zamówienia publicznego albo umowy ramowej
                    </FormLabel>
                    <RadioGroup  name="bzpPublic" className={classes.input} value={bzpPublicVal} onChange={(e) => {setBooleanValue(e); handleBZPPublicChange(e); setBzpPublicVal(e.target.value)}}>
                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setPlanForm(
            <Form className={classes.label}>
                <FormControl value={form.plan} className={classes.label}>
                    <FormLabel className={classes.label}>Czy zamówienie było ujęte w planie postępowań o udzielenie zamówień
                    </FormLabel>
                    <RadioGroup  name="plan" className={classes.input} value={planVal} onChange={(e) => {setBooleanValue(e); handlePlanChange(e); setPlanVal(e.target.value)}}>
                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
    }, [bzpNameVal, bzpPublicVal, planVal])

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const classes = useStyles();

    const handleBZPNameChange = (e) => {
        setBZPName(e.target.value);
    }

    const handleBZPPublicChange = (e) => {
        setBZPPublic(e.target.value);
    }

    const handlePlanChange = (e) => {
        setPlan(e.target.value);
    }

    const formAuction = useSelector(state => state.auction.formAuction);


    return (
        <React.Fragment>
            <AnimationRevealPage>
                <ValidatorForm >
                    {props.edit === true && !!auctionItem ? (
                            <>
                                {bzpNameForm}
                                {bzpPublicForm}
                                {planForm}
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Jeżeli zamówienie było ujęte w planie postępowań, wpisz numer w BZP, pod którym został
                                            zamieszczony plan postępowań
                                            o udzielenie zamówień</FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.bzpNumer}
                                            className={classes.input}
                                            value={form.bzpNumer}
                                            onChange={setValue}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer BZP"
                                            variant="outlined"
                                            placeholder="Numer BZP"
                                            required
                                            name="bzpNumber"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Jeżeli zamówienie było ujęte w planie postępowań, wpisz dentyfikator pozycji planu
                                            postępowań</FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.planId}
                                            value={form.planId}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Identyfikator planu postępowań"
                                            variant="outlined"
                                            required
                                            name="planId"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacja o podstawie prawnej prowadzonego postępowania</FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.legalBasis}
                                            value={form.legalBasis}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Podstawa prawna postępowania"
                                            variant="outlined"
                                            placeholder="Podstawa prawna postępowania"
                                            required
                                            name="legalBasis"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacja, czy wartość szacunkowa zamówienia mieści się poniżej progów ustalonych
                                            na podstawie art. 3 ust 2</FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.belowThresholds}
                                            value={form.belowThresholds}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Informacja"
                                            variant="outlined"
                                            placeholder="Informacja"
                                            required
                                            name="belowThresholds"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacje dodatkowe</FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.addInfoInfo}
                                            value={form.addInfoInfo}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Dodatkowe informacje"
                                            variant="outlined"
                                            placeholder="Dodatkowe informacje"
                                            required
                                            name="addInfoInfo"/>
                                    </FormControl>
                                </Form>
                            </>
                        ) : (
                            <>
                                <Form className={classes.label}>
                                    <FormControl value={form.bzpName} className={classes.label}>
                                        <FormLabel  className={classes.label}>Nazwa ogłoszenia w BZP
                                        </FormLabel>
                                        <RadioGroup name="bzpName"  className={classes.input} onChange={(e) => {setValue(e); handleBZPNameChange(e)}}>
                                            <FormControlLabel value="Obowiązkowe" name="bzpName" control={<Radio required color="primary"/>} label="Obowiązkowe" />
                                            <FormControlLabel value="Nieobowiązkowe" name="bzpName" control={<Radio color="primary"/>} label="Nieobowiązkowe" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl value={form.bzpPublic} className={classes.label}>
                                        <FormLabel className={classes.label}>Czy ogłoszenie w BZP dotyczy zamówienia publicznego albo umowy ramowej
                                        </FormLabel>
                                        <RadioGroup  name="bzpPublic"  className={classes.input} onChange={(e) => {setBooleanValue(e); handleBZPPublicChange(e)}}>
                                            <FormControlLabel value='true' name="bzpPublic" control={<Radio required color="primary"/>} label="Tak" />
                                            <FormControlLabel value='false' name="bzpPublic" control={<Radio color="primary"/>} label="Nie" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl value={form.plan} className={classes.label}>
                                        <FormLabel className={classes.label}>Czy zamówienie było ujęte w planie postępowań o udzielenie zamówień
                                        </FormLabel>
                                        <RadioGroup  name="plan"  className={classes.input} onChange={(e) => {setBooleanValue(e); handlePlanChange(e)}}>
                                            <FormControlLabel value='true' name="plan" control={<Radio required color="primary"/>} label="Tak" />
                                            <FormControlLabel value='false' name="plan" control={<Radio color="primary"/>} label="Nie" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Jeżeli zamówienie było ujęte w planie postępowań, wpisz numer w BZP, pod którym został
                                            zamieszczony plan postępowań
                                            o udzielenie zamówień</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            value={form.bzpNumer}
                                            onChange={setValue}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer BZP"
                                            variant="outlined"
                                            placeholder="Numer BZP"
                                            required
                                            name="bzpNumber"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Jeżeli zamówienie było ujęte w planie postępowań, wpisz dentyfikator pozycji planu
                                            postępowań</FormLabel>
                                        <TextValidator
                                            value={form.planId}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Identyfikator planu postępowań"
                                            variant="outlined"
                                            required
                                            name="planId"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacja o podstawie prawnej prowadzonego postępowania</FormLabel>
                                        <TextValidator
                                            value={form.legalBasis}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Podstawa prawna postępowania"
                                            variant="outlined"
                                            placeholder="Podstawa prawna postępowania"
                                            required
                                            name="legalBasis"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacja, czy wartość szacunkowa zamówienia mieści się poniżej progów ustalonych
                                            na podstawie art. 3 ust 2</FormLabel>
                                        <TextValidator
                                            value={form.belowThresholds}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Informacja"
                                            variant="outlined"
                                            placeholder="Informacja"
                                            required
                                            name="belowThresholds"/>
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Informacje dodatkowe</FormLabel>
                                        <TextValidator
                                            value={form.addInfoInfo}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Dodatkowe informacje"
                                            variant="outlined"
                                            placeholder="Dodatkowe informacje"
                                            required
                                            name="addInfoInfo"/>
                                    </FormControl>
                                </Form>
                            </>
                    )
                    }
                    <Button
                        onClick={props.handleBack}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleNext}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </React.Fragment>

    )
}

export default OrderForm;