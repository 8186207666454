import {auctionInitialState} from "./auctionInitialState";
import {handleActions} from 'redux-actions';

export const auctionReducer = handleActions({


        SET_VALUES: (state, action) => {
            const formCopy = {
                ...state.form,
                [action.payload.name]: action.payload.value
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_VALUES_AUCTION_PARTS: (state, action) => {
            const arrayFormCopy = [
                ...state.form.auctionItemParts
            ]

            arrayFormCopy[action.payload.partIndex] = {
                ...state.form.auctionItemParts[action.payload.partIndex],
                [action.payload.name]: action.payload.value
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    auctionItemParts: arrayFormCopy
                }
            }
        },

        SET_BOOLEAN_VALUES_AUCTION_PARTS: (state, action) => {
            const arrayFormCopy = [
                ...state.form.auctionItemParts
            ]

            arrayFormCopy[action.payload.partIndex] = {
                ...state.form.auctionItemParts[action.payload.partIndex],
                [action.payload.name]: ![action.payload.name]
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    auctionItemParts: arrayFormCopy
                }
            }
        },

        SET_VALUES_API: (state, action) => {
            const properties = Object.entries(action.payload.data)
                .map(([key, value]) =>
                    state.intialValuesNamesMap[key] ? [state.intialValuesNamesMap[key], value] : [key, value])
                .reduce((obj, [key, value]) => {obj[key] = value;return obj;}, {})
            return(
            {
            ...state,
            form: {
                ...state.form,
                ...properties
                }})
        },

        SET_VALUES_AUCTION: (state, action) => {
            const target = action.payload.e.target
            const formCopy = {
                ...state.formAuction,
                [target.name]: target.value
            }
            return {
                ...state,
                formAuction: formCopy
            }
        },

        SET_DATE_VALUE: (state, action) => {
            const formCopy = {
                ...state.formAuction,
                startDate: action.payload.date
            }
            return {
                ...state,
                formAuction: formCopy
                }
            },

        SET_DATE_VALUE_AUCTION_PART: (state, action) => {
            const formCopy = {
                ...state.form.auctionItemParts,
                [action.payload.name]: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_VALUE_AUCTION: (state, action) => {
            const formCopy = {
                ...state.form,
                startDate: action.payload.date
            }
            return {
                ...state,
                [action.payload.name]: formCopy
            }
        },

        SET_DATE_PART_START_DATE: (state, action) => {
            const formCopy = {
                ...state.form,
            }
            formCopy.auctionItemParts[action.payload.no].startDate = action.payload.date
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_PART_END_DATE: (state, action) => {
            const formCopy = {
                ...state.form,
            }
            formCopy.auctionItemParts[action.payload.no].endDate = action.payload.date
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_OFFERS_DATE: (state, action) => {
            const formCopy = {
                ...state.form,
                offersDate: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_SUBMISSION_DATE_DAY: (state, action) => {
            const formCopy = {
                ...state.form,
                sumbissionDateDay: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_SUBMISSION_DATE_TIME: (state, action) => {
            const formCopy = {
                ...state.form,
                sumbissionDateTime: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_OPEN_DATE_DAY: (state, action) => {
            const formCopy = {
                ...state.form,
                openDateDay: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

        SET_DATE_OPEN_DATE_TIME: (state, action) => {
            const formCopy = {
                ...state.form,
                openDateTime: action.payload.date
            }
            return {
                ...state,
                form: formCopy
            }
        },

}



, auctionInitialState)

