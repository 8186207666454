import React, {useEffect, useMemo, useRef, useState} from "react";
import tw from "twin.macro";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import styled from "styled-components";
import {Info} from '../misc/Typography'
import {Link} from 'react-router-dom';
import {useStyles} from "../materialUI/Stepper/StepperStyles";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {
    fetchVoivodeship,
    fetchId,
    fetchCountry1,fetchCountry6,
    fetchBipCoreBusiness,
    fetchDuueClientType,
    fetchDuueCoreBusiness,
    fetchCustomer, fetchBipClientType1, fetchCustomers, fetchBipClientType2
} from "../../services/fetchId";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import RegisterFinished from "../../pages/RegisterFinished";

const SectionDesc = tw(Info)`text-center mb-5 text-lg text-primary-900 uppercase`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl`;
const Form = tw.div`mb-5`;
const FormButton = tw.div`mb-5 mx-5 flex justify-center`;
const SubmitButton = styled.button`
  ${tw`my-3 w-32 font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;
const width = {
    width: '100%',
};

const styleLabel = {
    marginBottom: '5%',
    fontWeight: 'bold',
    fontSize: 'medium',
};


const EditOrderDataForm = ({...props}) => {

    const [customer, setCustomer] = useState([])
    const [duueCoreBusinessForm, setDuueCoreBusinessForm] = useState([])
    const [duueClientTypeForm, setDuueClientTypeForm] = useState([])
    const [bipClientTypeForm1, setBipClientTypeForm1] = useState([])
    const [bipClientTypeForm2, setBipClientTypeForm2] = useState([])
    const [bipCoreBusinessForm, setBipCoreBusinessForm] = useState([])
    const [voivodeshipForm, setVoivodeshipForm] = useState([])
    const [countryForm, setCountryForm] = useState([])
    const [id, setId] = useState([])
    const [isFinished, setIsFinished] = useState(false);
    const [bipClientTypeId, setBipClientTypeId] = useState();
    // const [bipClientType, setBipClientType] = useState();
    const [bipClientTypeValue, setBipClientTypeValue] = useState();
    const [bipCoreBusinessId, setBipCoreBusinessId] = useState();
    const [bipCoreBusiness, setBipCoreBusiness] = useState();
    const [duueClientTypeId, setDuueClientTypeId] = useState();
    const [duueClientType, setDuueClientType] = useState();
    const [duueCoreBusinessId, setDuueCoreBusinessId] = useState();
    const [duueCoreBusiness, setDuueCoreBusiness] = useState();
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeshipIri, setVoivodeshipIri] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [countryIri, setCountryIri] = useState();
    const [country, setCountry] = useState();
    // const prevCustomerId = localStorage.getItem('customerId');
    // const [customerId, setCustomerId] = useState(localStorage.getItem('customerId'));
    const customerId = localStorage.getItem('customerId');
    // const userId = localStorage.getItem('userId');

    // useEffect(() => {
    //     fetchCustomers().subscribe(data => {
    //         console.log('hydramember do ustalania ID: ', data['hydra:member'])
    //         setCustomerId(data['hydra:member'].filter(c => c.owner.slice(11) === userId)[0]['@id'].slice(15))
    //     })
    // }, [])

    useEffect(() => {
        fetchVoivodeship().subscribe(data => {      
            setVoivodeshipForm(data['hydra:member'])
        })

        fetchBipClientType1().subscribe(data => {
            setBipClientTypeForm1(data['hydra:member'])
        })

        fetchBipClientType2().subscribe(data => {
            setBipClientTypeForm2(data['hydra:member'])
        })

        fetchDuueClientType().subscribe(data => {
            setDuueClientTypeForm(data['hydra:member'])
        })


        fetchBipCoreBusiness().subscribe(data => {
            setBipCoreBusinessForm(data['hydra:member'])
        })

        fetchDuueCoreBusiness().subscribe(data => {
            setDuueCoreBusinessForm(data['hydra:member'])
        })

        fetchId().subscribe(data => {
            setId(data['hydra:member'][0].logged)
        })

        // fetchCustomer(customerId).subscribe( data => {

        //     setCustomer(data)
        // })

        fetchCountry6().subscribe(data => {
            setCountryForm(data['hydra:member'])
        })
    }, [customerId]
    )

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)));
        })

        fetchCustomer(customerId).subscribe(data => {
            setBipCoreBusinessId(parseInt(data.bipCoreBusiness.slice(25)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setBipClientTypeId(parseInt(data.bipClientType.slice(22)));
        })

        fetchCustomer(customerId).subscribe(data => {
            setCountryId((parseInt(data.addressCountry.slice(15))%30)-1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setDuueCoreBusinessId(parseInt(data.duueCoreBusiness.slice(26)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setDuueClientTypeId(parseInt(data.duueClientType.slice(23)));
        })

    }, [customerId])

            // if(!!duueClientTypeId){
            //     fetchDuueClientType().subscribe(data => {
            //         setDuueClientType(data['hydra:member'][duueClientTypeId-1]['@id'])
            //     })
            // }

    // if(!!bipClientTypeId){
    //     fetchBipClientType1().subscribe(data => {
    //         setBipClientType(data['hydra:member'][bipClientTypeId-1]['@id'])
    //     })
    // }

            // if(!!bipCoreBusinessId){
            //     fetchBipCoreBusiness().subscribe(data => {
            //         setBipCoreBusiness(data['hydra:member'][bipCoreBusinessId]['@id'])
            //     })
            // }

            // if(!!duueCoreBusinessId){
            //     fetchDuueCoreBusiness().subscribe(data => {
            //         setDuueCoreBusiness(data['hydra:member'][duueCoreBusinessId]['@id'])
            //     })
            // }

    // if(!!voivodeshipId){
    //     fetchVoivodeship().subscribe(data => {    
    //         setVoivodeship(data['hydra:member'][voivodeshipId-1]['@id'])
    //         // setVoivodeshipIri(data['hydra:member'][voivodeshipId-1]["@id"])
    //     })
    // }

    if(!!countryId){
        fetchCountry6().subscribe(data => {
            setCountry(data['hydra:member'][countryId]["@id"])
            setCountryIri(data['hydra:member'][countryId]["@id"])
        })
    }
    // if(!!countryId){
    //     fetchCountry6().subscribe(data => {
    //         setCountry(data['hydra:member'][countryId].polishShortName)
    //         console.log(data['hydra:member'][countryId])
    //     })
    // }


    const nameRef = useRef(null);
    const departmentRef = useRef(null);
    const streetRef = useRef(null);
    const houseNumberRef = useRef(null);
    const postalCodeRef = useRef(null);
    const cityRef = useRef(null);
    const voivodeshipRef = useRef(null);
    const addressCountryRef = useRef(null);
    const nutsCodeRef = useRef(null);
    const nipRef = useRef(null);
    const regonRef = useRef(null);
    const emailRef = useRef(null);
    const urlRef = useRef(null);
    const contactPersonRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const faxNumberRef = useRef(null);
    const epuapRef = useRef(null);
    const addInfoRef = useRef(null);
    const valueBZP1Ref = useRef(null);
    const valueBZP2Ref = useRef(null);
    const valueDUUE1Ref = useRef(null);
    const valueDUUE2Ref= useRef(null);


    const classes = useStyles();
    const onSubmit = () => {
        const data = {
            name: nameRef.current.value,
            department: departmentRef.current.value,
            addressStreet: streetRef.current.value,
            addressHouseNumber: houseNumberRef.current.value,
            addressZipCode: postalCodeRef.current.value,
            addressCity: cityRef.current.value,
            addressVoivodeship: voivodeshipRef.current.value,
            addressCountry:addressCountryRef.current.value,
            nutsCode: nutsCodeRef.current.value,
            nip: nipRef.current.value,
            regon: regonRef.current.value,
            email: emailRef.current.value,
            www: urlRef.current.value,
            contactPerson: contactPersonRef.current.value,
            phoneNumber: phoneNumberRef.current.value,
            faxNumber: faxNumberRef.current.value,
            epuap: epuapRef.current.value,
            additionalInformation: addInfoRef.current.value,
            bipClientType: valueBZP1Ref.current.value,
            bipCoreBusiness: valueBZP2Ref.current.value,
            duueClientType: valueDUUE1Ref.current.value,
            duueCoreBusiness: valueDUUE2Ref.current.value,
            // owner: id

        }
        setIsFinished(true)
        props.onSubmit(data);
    };



    const [postalCode, setPostalCode] = useState();
    const [nipNumber, setNipNumber] = useState();
    const [regonNumber, setRegonNumber] = useState();
    const [nuts, setNuts] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [faxNumber, setFaxNumber] = useState();

    const handleChangePostalCode = (e) => {
        setPostalCode(e.target.value);
    }
    const handleChangeNipNumber = (e) => {
        setNipNumber(e.target.value);
    }
    const handleChangeRegonNumber = (e) => {
        setRegonNumber(e.target.value);
    }
    const handleChangeNuts = (e) => {
        setNuts(e.target.value);
    }
    const handleChangePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    }
    const handleChangeFaxNumber = (e) => {
        setFaxNumber(e.target.value);
    }



    let form;

    if(!!customer.name && !!country){
        form = <ValidatorForm onSubmit={onSubmit}>
            <Form>
                <TextValidator
                    defaultValue={customer.name}
                    inputRef={nameRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Nazwa Zamawiającego"
                    variant="outlined"
                    placeholder="Nazwa Zamawiającego"
                    required/>
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.department}
                    inputRef={departmentRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Oddział zamawiającego"
                    variant="outlined"
                    placeholder="Oddział zamawiającego"
                    name="department"/>
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.nutsCode}
                    inputRef={nutsCodeRef}
                    className={classes.input}
                    type="textnumber"
                    id="outlined-basic-u"
                    label="kodNUTS"
                    variant="outlined"
                    placeholder="kodNUTS"
                    required
                    value={nuts}
                    onChange={handleChangeNuts}
                    validators={['matchRegexp:^PL(0|[1-9][0-9]*)$']}
                    errorMessages={['Niedozwolone znaki']}
                    name="nutsCode"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.nip}
                    inputRef={nipRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="NIP"
                    variant="outlined"
                    placeholder="NIP"
                    required
                    name="nip"
                    value={nipNumber}
                    onChange={handleChangeNipNumber}
                    validators={['matchRegexp:^PL[0-9]{10}$']}
                    errorMessages={['Wprowadź poprawny numer PL(10 cyfr)']}
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.regon}
                    inputRef={regonRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="REGON"
                    variant="outlined"
                    placeholder="REGON"
                    required
                    name="regon"
                    value={regonNumber}
                    onChange={handleChangeRegonNumber}
                    validators={['matchRegexp:^[0-9]{9}$']}
                    errorMessages={['Wprowadź poprawny nymer (9 cyfr)']}
                />
            </Form>
            <SectionDesc>Adres</SectionDesc>
            <Form>
                <TextValidator
                    defaultValue={customer.addressStreet}
                    inputRef={streetRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Ulica"
                    variant="outlined"
                    placeholder="Ulica"
                    required
                    name="street"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.addressHouseNumber}
                    inputRef={houseNumberRef}
                    className={classes.input}
                    width={0.5}
                    type="text"
                    id="outlined-basic-u"
                    label="Nr domu"
                    variant="outlined"
                    placeholder="Nr domu"
                    required
                    name="houseNumber"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.addressZipCode}
                    inputRef={postalCodeRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    value={postalCode}
                    label="Kod pocztowy"
                    variant="outlined"
                    placeholder="Kod pocztowy"
                    required
                    name="postalCode"
                    onChange={handleChangePostalCode}
                    validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                    errorMessages={['Wprowadź poprawny format: xx-xxx']}
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.addressCity}
                    inputRef={cityRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Miejscowość"
                    variant="outlined"
                    placeholder="Miejscowość"
                    required
                    name="city"
                />
            </Form>
            <Form>
                <TextValidator
                    inputRef={voivodeshipRef}
                    id="outlined-basic-u"
                    className={classes.input}
                    label="Województwo"
                    select
                    required
                    variant="outlined"
                    defaultValue={customer.addressVoivodeship}
                >
                    {/* <MenuItem value={voivodeshipIri}>{voivodeship}</MenuItem> */}
                    {voivodeshipForm.map((c, index) =>{
                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                        }
                    )}
                </TextValidator>
            </Form>
            <Form>
                <TextValidator
                    inputRef={addressCountryRef}
                    id="outlined-basic-u"
                    className={classes.input}
                    label="Kraj"
                    select
                    disabled
                    // name="country"
                    variant="outlined"
                    defaultValue={customer.addressCountry}
                >
                    {countryForm.map((c, index) =>{
                            return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                        }
                    )}
                    </ TextValidator>
            </Form>
            <SectionDesc>Dane kontaktowe</SectionDesc>
            <Form>
                <TextValidator
                    defaultValue={customer.email}
                    inputRef={emailRef}
                    className={classes.input}
                    type="email"
                    id="outlined-basic-u"
                    label="Adres e-mail"
                    variant="outlined"
                    placeholder="Adres e-mail"
                    required
                    name="email"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.www}
                    inputRef={urlRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Adres strony internetowej"
                    variant="outlined"
                    placeholder="Adres strony internetowej"
                    required
                    name="url"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.contactPerson}
                    inputRef={contactPersonRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Osoba do kontaktu"
                    variant="outlined"
                    placeholder="Osoba do kontaktu"
                    required
                    name="contactPerson"
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.phoneNumber}
                    inputRef={phoneNumberRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Numer telefonu"
                    variant="outlined"
                    placeholder="Numer telefonu"
                    required
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChangePhoneNumber}
                    validators={['matchRegexp:^(0|[1-9][0-9]*)$']}
                    errorMessages={['Niedozwolone znaki']}
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.faxNumber}
                    inputRef={faxNumberRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Numer faksu"
                    variant="outlined"
                    placeholder="Numer faksu"
                    required
                    name="faxNumber"
                    value={faxNumber}
                    onChange={handleChangeFaxNumber}
                    validators={['matchRegexp:^(0|[1-9][0-9]*)$']}
                    errorMessages={['Niedozwolone znaki']}
                />
            </Form>
            <Form>
                <TextValidator
                    defaultValue={customer.epuap}
                    inputRef={epuapRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Skrzynka ePUAP"
                    variant="outlined"
                    placeholder="Skrzynka ePUAP"
                    required
                    name="epuap"
                />
            </Form>
            <SectionDesc>Informacje dodatkowe</SectionDesc>
            <Form>
                <TextValidator
                    defaultValue={customer.additionalInformation}
                    inputRef={addInfoRef}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    label="Informacje dodatkowe"
                    variant="outlined"
                    placeholder="Informacje dodatkowe"
                    required
                    name="addInfo"
                    style={width}
                />
            </Form>
            <Form>
                    <FormLabel component="legend1" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Biuletynie Zamówień Publicznych
                    </FormLabel>
                    <TextValidator
                        inputRef={valueBZP1Ref}
                        labelId="emo-simple-select-outlined-label"
                        id="outlined-basic-u"
                        className={classes.input}
                        label="Wybierz rodzaj zamawiającego"
                        select
                        variant="outlined"
                        defaultValue={customer.bipClientType}
                    >
                        {bipClientTypeForm1.map((c, index) =>{
                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                        })}
                        {bipClientTypeForm2.map((c, index) =>{
                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                        })}
                    </TextValidator>
            </Form>
            <Form>
                    <FormLabel component="legend2" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Dzienniku Urzędowym Unii Europejskiej
                    </FormLabel>
                    <TextValidator
                        inputRef={valueDUUE1Ref}
                        labelId="emo-simple-select-outlined-label"
                        id="outlined-basic-u"
                        className={classes.input}
                        label="Wybierz rodzaj zamawiającego"
                        select
                        variant="outlined"
                        // defaultValue="/api/duue_client_types/2"
                        defaultValue={customer.duueClientType}
                    >
                        {duueClientTypeForm.map((c, index2) =>{
                            return <MenuItem key={index2} value={c["@id"]}>{c.name}</MenuItem>
                        })}
                    </TextValidator>
            </Form>
                    <Form>
                            <FormLabel component="legend3" style={styleLabel}>Wybierz przedmiot działalności
                                Zamawiającego zgodnie z klasyfikacją w
                                ogłoszeniu w Biuletynie Zamówień
                                Publicznych
                            </FormLabel>
                            <TextValidator
                                inputRef={valueBZP2Ref}
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz przedmiot działalności "
                                defaultValue={customer.bipCoreBusiness}
                                select
                                variant="outlined"
                            >
                                {bipCoreBusinessForm.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                    </Form>
                    <Form>
                            <FormLabel component="legend4" style={styleLabel}>Wybierz przedmiot działalności
                                Zamawiającego zgodnie z klasyfikacją w ogłoszeniu w Dzienniku Urzędowym Unii Europejskiej
                            </FormLabel>
                            <TextValidator
                                inputRef={valueDUUE2Ref}
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz przedmiot działalności"
                                defaultValue={customer.duueCoreBusiness}
                                select
                                variant="outlined"
                            >
                                {duueCoreBusinessForm.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                    </Form>
            <TwoColumn>
                <FormButton>
                    <Link to="/myAccount">
                        <SubmitButton>
                            Cofnij
                        </SubmitButton>
                    </Link>
                </FormButton>
                <FormButton>
                    <SubmitButton onSubmit={onSubmit} type="submit">
                        Zapisz
                    </SubmitButton>
                </FormButton>
            </TwoColumn>
        </ValidatorForm>
    } else {
        form = ''
    }


    return (
        <>
            {form}
        </>
    )
}

export default EditOrderDataForm;