import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {fetchAuctionItemId} from "../../services/fetchId";

const Form = tw.div`mb-5 mx-5 mr-3`;

const ExecutionPartI = ({no, edit, auctionObject, id}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifObligation === true && 2));
    const [auctionItem, setAuctionItem] = useState('');

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )
    }, [])

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }


    let form1;

    if (addValue1 === 2){
        form1 =
            <>
                {
                    auctionItem.orderType === "usługi" || auctionItem.orderType === "budowlane" && (
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <FormLabel className={classes.label}>Zakres dotyczący obowiązku osobistego wykonania przez wykonawcę kluczowych zadań dotyczących zamówień na roboty budowlane i usługi</FormLabel>
                                <TextValidator
                                    value={formAuctionParts.obligation1}
                                    onChange={(e) => setValueAuctionParts(e, no)}
                                    defaultValue={edit === 'edit' ? (auctionObject.obligation1) : ''}
                                    name="obligation1"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Zakres dotyczący obowiązku osobistego wykonania"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                            </FormControl>
                        </Form>
                    )
                }
                {
                    auctionItem.orderType === "dostawy"  && (
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <FormLabel className={classes.label}>Zakres dotyczący obowiązku osobistego wykonania przez wykonawcę kluczowych zadań dotyczących prac związanych z rozmieszczeniem i instalacją, w ramach zamówienia na dostawy</FormLabel>
                                <TextValidator
                                    value={formAuctionParts.obligation2}
                                    onChange={(e) => setValueAuctionParts(e, no)}
                                    defaultValue={edit === 'edit' ? (auctionObject.obligation2) : ''}
                                    name="obligation2"
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    label="Zakres dotyczący obowiązku osobistego wykonania"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                            </FormControl>
                        </Form>
                    )
                }
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifObligation.toString()} name="ifObligation">
                            <RadioGroup name="ifObligation" defaultValue={edit === 'edit' && auctionObject.ifObligation.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje obowiązku osobistego wykonanaia przez wykonawcę kluczowych zadań" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje obowiązek osobistego wykonanaia przez wykonawcę kluczowych zadań" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}


export default ExecutionPartI;