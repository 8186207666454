import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import tw from "twin.macro";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styled from "styled-components";
import {
    TextField,
  } from "@material-ui/core";
import { useStyles } from "../components/materialUI/Stepper/StepperStyles";
import {makeStyles} from "@material-ui/core/styles";

const Form = tw.div`mx-auto max-w-xs`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export function RegisterForm({isValid, ...props}) {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px',
            width: '100%'
        },

    }));
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const usernameRef = useRef(null);
    const repeatPasswordRef = useRef(null);
    const classes = useStyles();

    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValidForm, isSetValidForm] = useState(false);

    useEffect(() =>{
      ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== passwordRef.current.value) {
          isSetValidForm(false);
          return false;
        }else{
          return true;
        }

    });
    },[ValidatorForm])

    const onSubmit = () => {
      const data = {
        email: emailRef.current.value,
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      }
      props.onSubmit(data, isValidForm);
    };

    return (
        <ValidatorForm
          onSubmit={onSubmit}
        >
            <TextValidator
              inputRef={usernameRef}
              className={classes.input}
              type="text"
              id="outlined-basic-u"
              label="Nazwa Użytkownika"
              variant="outlined"
              placeholder="Nazwa Użytkownika"
              required
              name="username"
            />
            <TextValidator
              inputRef={emailRef}
              className={classes.input}
              type="email"
              label="Email"
              variant="outlined"
              placeholder="Email"
              required
              name="email"
            />
            <TextValidator
              inputRef={passwordRef}
              inputProps={{ minLength: 6 }}
              className={classes.input}
              type="password"
              id="outlined-basic-p"
              label="Hasło"
              variant="outlined"
              placeholder="Hasło"
              name="password"
              required
            />
            <TextValidator
              inputRef={repeatPasswordRef}
              className={classes.input}
              type="password"
              id="outlined-basic-pr"
              label="Potwierdź Hasło"
              variant="outlined"
              placeholder="Potwierdź Hasło"
              name="repeatpassword"
              validators={['isPasswordMatch']}
              required
              value={repeatPassword}
              errorMessages={['Hasła nie pasują do siebie']}
              onChange={(e) => {setRepeatPassword(e.target.value)}}
            />
            <SubmitButton
              type="submit"
            >
                <LoginIcon className="icon" />
                <span className="text">{"Zarejestruj się"}</span>
            </SubmitButton>
        </ValidatorForm>
    );
}