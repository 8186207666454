import {mainInitialState} from "./mainInitialState";
import {handleActions} from 'redux-actions';

export const mainReducer = handleActions({


    SET_WIDTH: (state, {payload}) => ({
        ...state,
        windowWidth: payload.width
    })


}, mainInitialState)

