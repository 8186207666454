import React, {useState, useEffect} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SectionHeading} from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import VariantOffers from "../components/CD/VariantOffers";
import Communication from "../components/CD/Communication";
import OtherCommunication from "../components/CD/OtherCommunication";
import CommunicationPerson from "../components/CD/CommunicationPerson";
import DeadlineOffer from "../components/CD/DeadlineOffer";
import OfferPreparation from "../components/CD/OfferPreparation";
import Catalogs from "../components/CD/Catalogs";
import Submission from "../components/CD/Submission";
import OfferOpening from "../components/CD/OfferOpening";
import Exclusion108 from "../components/CD/Exclusion108";
import Exlusion109 from "../components/CD/Exlusion109";
import Accounts from "../components/CD/Accounts";
import Refund from "../components/CD/Refund";
import Paperwork from "../components/CD/Paperwork";
import LegalProtection from "../components/CD/LegalProtection";
import Subcontractors from "../components/CD/Subcontractors";
import RODO from "../components/CD/RODO";
import FinalProvisions from "../components/CD/FinalProvisions";
import Attachments from "../components/CD/Attachments";
import Approval from "../components/CD/Approval";
import {fetchAuctionId, fetchAuctionItemId} from "../services/fetchId";
import {setValues} from "../state/auction/auctionActions";
import {useDispatch, useSelector} from "react-redux";
import SummaryFinalSteps from "../components/CD/SummaryFinalSteps";
import InitialOpFinished from "../components/InitialOpFinished";
import {patchInitialOpAuction} from "../services/patchInitialOpAuction";
import Swal from "sweetalert2";
import FinalStepsFinished from "../components/FinalStepsFinished";
import {patchInitialOp} from "../services/patchInitialOp";


const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row'
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },

}));





const FinalSteps = (props) => {

    const classes = useStyles();
    const [auctionItem, setAuctionItem] = useState('');
    const {params} = props.match;

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === params.id)[0]);
            }
        )
    }, [])


    function getSteps() {
        if(params.edit === 'edit'){
            return ['OFERTY WARIANTOWE',
                'ŚRODKI KOMUNIKACJI ELEKTRONICZNEJ',
                'INNY SPOSÓB KOMUNIKOWANIA',
                'OSOBY UPRAWNIONE DO KOMUNIKOWANIA',
                'TERMIN ZWIĄZANIA Z OFERTĄ',
                'PRZYGOTOWANIE OFERTY',
                'KATALOGI ELEKTRONICZNE',
                'SKŁADANIE OFERT',
                'OTWARCIE OFERT',
                'WYKLUCZENIE WYKONAWCY Z ART. 108',
                'WYKLUCZENIE WYKONAWCY Z ART. 109',
                'ROZLICZENIA W WALUTACH OBCYCH',
                'ZWROT KOSZTÓW UDZIAŁU W POSTĘPOWANIU',
                'FORMALNOŚCI W CELU ZAWARCIA UMOWY',
                'ŚRODKI OCHRONY PRAWNEJ',
                'PODWYKONAWCY',
                'RODO',
                'POSTANOWIENIA KOŃCOWE',
                'WYKAZ ZAŁĄCZNIKÓW',
                'ZATWIERDZENIE SPECYFIKACJI',
                'ZAKOŃCZ'];
        }else if (params.edit === 'form'){
            return ['OFERTY WARIANTOWE',
                'ŚRODKI KOMUNIKACJI ELEKTRONICZNEJ',
                'INNY SPOSÓB KOMUNIKOWANIA',
                'OSOBY UPRAWNIONE DO KOMUNIKOWANIA',
                'TERMIN ZWIĄZANIA Z OFERTĄ',
                'PRZYGOTOWANIE OFERTY',
                'KATALOGI ELEKTRONICZNE',
                'SKŁADANIE OFERT',
                'OTWARCIE OFERT',
                'WYKLUCZENIE WYKONAWCY Z ART. 108',
                'WYKLUCZENIE WYKONAWCY Z ART. 109',
                'ROZLICZENIA W WALUTACH OBCYCH',
                'ZWROT KOSZTÓW UDZIAŁU W POSTĘPOWANIU',
                'FORMALNOŚCI W CELU ZAWARCIA UMOWY',
                'ŚRODKI OCHRONY PRAWNEJ',
                'PODWYKONAWCY',
                'RODO',
                'POSTANOWIENIA KOŃCOWE',
                'WYKAZ ZAŁĄCZNIKÓW',
                'ZATWIERDZENIE SPECYFIKACJI',
                'PODSUMOWANIE',
                'ZAKOŃCZ'];
        }

    }

    const dispatch = useDispatch();
    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const form = useSelector(state => state.auction.form);
    console.log(form);


    function getStepContent(step) {
        if(!!auctionItem){
            switch (step) {
                case 0:
                    return ( <VariantOffers setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 1:
                    return ( <Communication auctionItemObject={auctionItem} edit={params.edit}/>
                    );
                case 2:
                    return ( <OtherCommunication/>
                    );
                case 3:
                    return ( <CommunicationPerson/>
                    );
                case 4:
                    return ( <DeadlineOffer setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 5:
                    return ( <OfferPreparation setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 6:
                    return ( <Catalogs setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 7:
                    return ( <Submission auctionItemObject={auctionItem} setValue={setValue} form={form} edit={params.edit}/>
                    );
                case 8:
                    return ( <OfferOpening setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 9:
                    return ( <Exclusion108 setBooleanValue={setBooleanValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 10:
                    return ( <Exlusion109 setBooleanValue={setBooleanValue} setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 11:
                    return ( <Accounts setBooleanValue={setBooleanValue} setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 12:
                    return ( <Refund setBooleanValue={setBooleanValue} setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 13:
                    return ( <Paperwork setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 14:
                    return ( <LegalProtection setValue={setValue} form={form} auctionItemObject={auctionItem} edit={params.edit}/>
                    );
                case 15:
                    return ( <Subcontractors setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 16:
                    return ( <RODO setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 17:
                    return ( <FinalProvisions setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 18:
                    return ( <Attachments setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 19:
                    return ( <Approval setValue={setValue} form={form} edit={params.edit} auctionItemObject={auctionItem}/>
                    );
                case 20:
                    if(params.edit === 'form'){
                    return (<SummaryFinalSteps auctionItemObject={auctionItem}/>)
                    }
                case 21:
                    return (<FinalStepsFinished
                        onSubmit={(form) => {
                            patchInitialOp(form, auctionItem['@id'].slice(19)).subscribe(
                                Swal.fire({
                                        title: 'Sukces',
                                        text: `Dane zostały zapisane.`,
                                        icon: 'success',
                                        showCancelButton: false,
                                        showConfirmButton: true,
                                        confirmButtonColor: '#5a13e6',
                                        confirmButtonText: 'Przejdź dalej'
                                    },
                                ).then(okay => {
                                    if (okay) {
                                        window.location.replace('/inProgress');
                                    }}),
                                error => {
                                    return Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: `Wprowadzono błędne dane !`,
                                    })
                                },
                            );
                        }
                        }
                    />)
                default:
                    return 'Unknown step';
            }
        }

    }

    const [activeStep, setActiveStep] = useState(8);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        console.log('changed step scrolling to top of window');
        window.scrollTo(0, 0)
    }, [activeStep])


    return(
        <React.Fragment>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Specyfikacja warunków zamówienia</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <div>
                        <Stepper className={classes.name} activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step className={classes.name} key={label}>
                                    <StepLabel><Typography className={classes.name}>{label}</Typography></StepLabel>
                                    <StepContent className={classes.step}>
                                        <TwoColumnStep>
                                            <Typography>{getStepContent(index)}</Typography>
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    Wróć
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    type="submit"
                                                >
                                                    {activeStep === steps.length - 1 ? 'Zakończ' : 'Dalej'}
                                                </Button>
                                            </div>
                                        </TwoColumnStep>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>Proces zakończony pomyślnie</Typography>
                            </Paper>
                        )}
                    </div>
                </AnimationRevealPage>
            </div>


        </React.Fragment>
    )
}

export default FinalSteps;