
import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {DateTimePicker, DatePicker, TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {fetchCountry, fetchCustomer, fetchVoivodeship} from "../../services/fetchId";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";
import {setValues} from "../../state/auction/auctionActions";
import {useDispatch, useSelector} from "react-redux";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Exclusion108 = ({form, setBooleanValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        labelD: {
            marginBottom: '5%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState();

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Podstawy wykluczenia, o których mowa w art. 108 ust. 1
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                value={'Tekst wstawiony przez program'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!form.exclusion && form.exclusion.toString()} name="exclusion">
                            <RadioGroup name="exclusion" defaultValue={edit === 'edit' && auctionItemObject.exclusion.toString()} onChange={(e) => {setBooleanValue(e); handleChange1(e)}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje wykluczenia, o którym mowa w art. 109, ust. 1" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje wykluczenie, o którym mowa w art. 109, ust. 1" />
                            </RadioGroup>
                        </FormControl>
                    </Form>

                </ValidatorForm>
            </AnimationRevealPage>
        </>
    )
}


export default Exclusion108;