import React, {useEffect, useRef, useState} from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {TextValidator, SelectValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, Select} from "@material-ui/core";
import {setValues, setValuesAuction, setValuesAuctionParts} from "../state/auction/auctionActions";
import {useDispatch, useSelector} from "react-redux";
import {fetchAuctionId, fetchAuctionItemId, fetchAuctionItemPartsId} from "../services/fetchId";
import {setSnackbar} from "../state/snackbar";


const Form = tw.div`mb-5 mx-5 mr-3`;


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        // width: '100% !important', // DH
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
        // marginTop: '3%' // DH
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'justify',
        lineHeight: '1.4',
        color: 'black',
        width: '100%',

    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        width: '100% !important',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        width: '100% !important',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const OrderNameForm = (props) => {

    const [partsnum, setPartsnum] = useState(0);
    const [parts, setParts] = useState();
    const [noParts, setNoParts] = useState();
    const [auction, setAuction] = useState();
    const [auctionItem, setAuctionItem] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [edit, setEdit] = useState(props.edit);

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setAuction(data['hydra:member'].find(auction => auction['@id'].slice(14) === props.id))
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )
    }, [])

    useEffect(() => {
        if(!!auctionItem){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].find(auctionItemParts => auctionItemParts.auctionItem.slice(19) === auctionItem['@id'].slice(19)))
                }
            )
        }
    }, [auctionItem])


    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();
    console.log(form)

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
    }

    const formAuction = useSelector(state => state.auction.formAuction);
    console.log(formAuction);

    const setValueAuction = (e) => {
        dispatch(setValuesAuction(e))
        console.log(formAuction)
    }

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    console.log(formAuctionParts);

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    dispatch(setSnackbar(
        true,
        "success",
        "Your engineer application has been successfully submitted!")
    )


    const classes = useStyles();


    let part = [];

    useEffect(() => {
        localStorage.setItem('parts', partsnum);
        if (partsnum === 0){
            setNoParts(<>
                {props.edit && !!auction && !!auctionItem ? (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>W przypadku niedokonania podziału na części wskaż powody takiej decyzji art 91, 25, ust 2, 24, ust 4                                                  </FormLabel>
                            <TextValidator
                                value={form.noPart}
                                defaultValue={auctionItemParts.noPart}
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Powód braku podziału"
                                variant="outlined"
                                placeholder="Powód braku podziału"
                                name="noPart"/>
                        </Form>) : (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>W przypadku niedokonania podziału na części wskaż powody takiej decyzji art 91, 25, ust 2, 24, ust 4                                                  </FormLabel>
                            <TextValidator
                                value={form.noPart}
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Powód braku podziału"
                                variant="outlined"
                                placeholder="Powód braku podziału"
                                name="noPart"/>
                        </Form>
                            )}
            </>)
        }else {
            setNoParts('');
        }
        for (let i=0; i<partsnum; i++){
                if(props.edit && !!auction && !!auctionItemParts){
                    part.push(
                    <Form className={classes.label}>
                        <FormLabel className={classes.label}>Nazwa części {i + 1} zamówienia</FormLabel>
                        <TextValidator
                            defaultValue={auctionItemParts.partName}
                            value={formAuctionParts.partName}
                            name="partName"
                            onChange={(e) => setValueAuctionParts(e,i)}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label={"Nazwa " + (i+1) + " częsci"}
                            variant="outlined"
                            required/>
                    </Form>
                    )}else if(props.edit === false){
                    part.push(
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Nazwa części {i + 1} zamówienia</FormLabel>
                            <TextValidator
                                value={formAuctionParts.partName}
                                name="partName"
                                onChange={(e) => setValueAuctionParts(e,i)}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label={"Nazwa " + (i+1) + " częsci"}
                                variant="outlined"
                                required/>
                        </Form>)
                }
            console.log(part)
        }
        setParts(part)
    }, [partsnum])

    return (
        <React.Fragment>
            <AnimationRevealPage>
                <ValidatorForm >
                    {props.edit === true && !!auction && !!auctionItem ? (
                        <>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Nazwa zamówienia nadana przez Zamawiającego</FormLabel>
                                    <TextValidator
                                        value={formAuction.orderName}
                                        defaultValue={auction.orderName}
                                        onChange={setValueAuction}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Nazwa zamówienia"
                                        variant="outlined"
                                        required
                                        name="orderName"/>
                                </FormControl>
                            </Form>

                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Wskaż na ile części podzielone jest zamówienie</FormLabel>
                                    <SelectValidator
                                        onChange={(e) => {setPartsnum(e.target.value); setValue(e) }}
                                        value={form.numberOfParts}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        required
                                        name="numberOfParts">
                                        <MenuItem selected={true} value={0}>brak podziału</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </SelectValidator>
                                </FormControl>

                            </Form>
                        </>
                    ) : (
                        <>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Nazwa zamówienia nadana przez Zamawiającego</FormLabel>
                                    <TextValidator
                                        value={formAuction.orderName}
                                        onChange={setValueAuction}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Nazwa zamówienia"
                                        variant="outlined"
                                        required
                                        name="orderName"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Wskaż na ile części podzielone jest zamówienie</FormLabel>
                                    <SelectValidator
                                        onChange={(e) => {setPartsnum(e.target.value); setValue(e)}}
                                        value={form.numberOfParts}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        required
                                        name="numberOfParts">
                                        <MenuItem selected={true} value={0}>brak podziału</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </SelectValidator>
                                </FormControl>
                            </Form>
                        </>
                    )}
                    {parts}
                    {noParts}
                    <Button
                        onClick={props.handleBack}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleNext}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>

                </ValidatorForm>
            </AnimationRevealPage>
        </React.Fragment>

    )
}

export default OrderNameForm;