

export const auctionInitialState = {
   form : {
      auction: localStorage.getItem('auctionRef'),
      auctionItemParts: [{
         partName: "string",
         cpvCode: "string",
         addCpvCode: "string",
         partValue: 0,
         partValueSim: 0,
         partValueOp: 0,
         no: 0,
         negotiationRequirements: "string",
         negotiationLimit: false,
         serviceAddressStreet: "string",
         serviceAddressHouseNumber: "string",
         serviceAddressZipCode: "string",
         serviceAddressCity: "string",
         serviceAddressVoivodeship: "/api/voivodeships/9",
         serviceAddressCountry: "/api/countries/6",
         serviceNutsCode: "string",
         disabilityRequirements: "string",
         performanceRequirements: "string",
         etiqRequirements: "string",
         ifDisabilityRequirements: false,
         ifPerformanceRequirements: false,
         ifEtiqRequirements: false,
         conditions: "string",
         conditions393: true,
         conditions112: true,
         conditions1: "string",
         conditions2: "string",
         conditions3: "string",
         conditions4: "string",
         ifConditions393: false,
         ifConditions: false,
         localDescription: "string",
         ifLocal: "string",
         meansList: "string",
         otherMeansList: "string",
         subjectiveMeansList1: "string",
         subjectiveMeansList2: "string",
         subjectiveMeansList3: "string",
         subjectiveMeansList4: "string",
         subjectiveMeansList5: "string",
         ifSubjectiveMeans1: false,
         ifSubjectiveMeans2: false,
         optionCircumstances: "string",
         obligation1: "string",
         obligation2: "string",
         ifObligation: "string",
         ifEmploymentRequirements: false,
         employmentRequirements1: "string",
         employmentRequirements2: "string",
         employmentRequirements3: "string",
         ifEmploymentRequirements96: false,
         employmentRequirments96: "string",
         ifContractors94: false,
         contractors94: "string",
         deadline: "2021-08-31T09:17:44.438Z",
         deadlineDate: "2021-08-31T09:17:44.438Z",
         deadline1: 0,
         deadlineDays: 0,
         deadlineMonths: 0,
         endDate: 0,
         startDate: "2021-08-31T09:17:44.438Z",
         ifChanges: false,
         ifAdvance: false,
         infoAdvance: "string",
         infoChanges: "string",
         otherTerms: "string",
         ifEAuction: "string",
         conditionsEAuction: "string",
         pricing: "string",
         criterion: "string",
         criterion1: "string",
         criterion2: "string",
         criterion3: "string",
         weight1: 0,
         weight2: 0,
         weight3: 0,
         weight4: 0,
         weigth5: 0,
         weight6: 0,
         weight7: 0,
         criterion4: "string",
         ifProtection: false,
         protectionValue: 0,
         protectionValue1: 0,
         protectionInfo: "string",
         ifFrameworkAgreement: false,
         maxConstructors: 0,
         ifRReverse: true,
         valueSupplyPart: 0,
         valueWoVatPart: 0
      }, {
         partName: "string",
         cpvCode: "string",
         addCpvCode: "string",
         partValue: 0,
         partValueSim: 0,
         partValueOp: 0,
         no: 0,
         negotiationRequirements: "string",
         negotiationLimit: true,
         serviceAddressStreet: "string",
         serviceAddressHouseNumber: "string",
         serviceAddressZipCode: "string",
         serviceAddressCity: "string",
         serviceAddressVoivodeship: "/api/voivodeships/9",
         serviceAddressCountry: "/api/countries/6",
         serviceNutsCode: "string",
         disabilityRequirements: "string",
         performanceRequirements: "string",
         etiqRequirements: "string",
         ifDisabilityRequirements: true,
         ifPerformanceRequirements: true,
         ifEtiqRequirements: true,
         conditions: "string",
         conditions393: true,
         conditions112: true,
         conditions1: "string",
         conditions2: "string",
         conditions3: "string",
         conditions4: "string",
         ifConditions393: true,
         ifConditions: true,
         localDescription: "string",
         ifLocal: "string",
         meansList: "string",
         otherMeansList: "string",
         subjectiveMeansList1: "string",
         subjectiveMeansList2: "string",
         subjectiveMeansList3: "string",
         subjectiveMeansList4: "string",
         subjectiveMeansList5: "string",
         ifSubjectiveMeans1: true,
         ifSubjectiveMeans2: true,
         optionCircumstances: "string",
         obligation1: "string",
         obligation2: "string",
         ifObligation: "string",
         ifEmploymentRequirements: true,
         employmentRequirements1: "string",
         employmentRequirements2: "string",
         employmentRequirements3: "string",
         ifEmploymentRequirements96: true,
         employmentRequirments96: "string",
         ifContractors94: true,
         contractors94: "string",
         deadline: "2021-08-31T09:17:44.438Z",
         deadlineDate: "2021-08-31T09:17:44.438Z",
         deadline1: 0,
         deadlineDays: 0,
         deadlineMonths: 0,
         endDate: 0,
         startDate: "2021-08-31T09:17:44.438Z",
         ifChanges: true,
         ifAdvance: true,
         infoAdvance: "string",
         infoChanges: "string",
         otherTerms: "string",
         ifEAuction: "string",
         conditionsEAuction: "string",
         pricing: "string",
         criterion: "string",
         criterion1: "string",
         criterion2: "string",
         criterion3: "string",
         weight1: 0,
         weight2: 0,
         weight3: 0,
         weight4: 0,
         weigth5: 0,
         weight6: 0,
         weight7: 0,
         criterion4: "string",
         ifProtection: true,
         protectionValue: 0,
         protectionValue1: 0,
         protectionInfo: "string",
         ifFrameworkAgreement: true,
         maxConstructors: 0,
         ifRReverse: true,
         valueSupplyPart: 0,
         valueWoVatPart: 0
      }]
   },

   formAuction : {
      isClosed: false,
      startDate: new Date()
   },
   intialValuesNamesMap: {
      name: 'nameOrdering',
   }
}
