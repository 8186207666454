import React from 'react';
import Header, {NavLink, NavLinks, PrimaryLink} from "../components/headers/light";

const links = [
    <NavLinks key={1}>
        <NavLink to="/#">Przygotuj przetarg</NavLink>
        <NavLink to="/#">Baza wiedzy</NavLink>
        <NavLink to="/#">Kontakt</NavLink>
        <NavLink to="/login" tw="lg:ml-12!">
            Logowanie
        </NavLink>
        <PrimaryLink to="/register">
            Rejestracja
        </PrimaryLink>
    </NavLinks>
];

const Logout = () => {
    return (
        <React.Fragment>
        <Header links={links}/>
        <p>noeifeoho</p>
        </React.Fragment>
    )
}
export default Logout;
