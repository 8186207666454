import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {fetchHint} from "../../services/fetchId";
import StackedSnackbar from "../StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3`;

const ConditionsPartI = ({no, edit, auctionObject, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    console.log(auctionItemObject)

    const [isSector, setIsSector] = useState(auctionItemObject.serviceType === "usługi sektorowe");
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifConditions === true && true));
    const [addValue3, setAddValue3] = useState(edit === 'edit' && (auctionObject.conditions112 === true && true));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[19].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[20].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#c83351'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[21].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#c83351'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    const handleChange2 = (e) => {
        if(e.target.value === 'true'){
            setAddValue1(true)
        }else if(e.target.value === 'false'){
            setAddValue1(false)
        }
    }
    const handleChange3 = (e) => {
        if(e.target.value === 'true'){
            setAddValue3(true)
        }
    }

    let form;
    let formSector;
    let form3;

    if(addValue3 === true){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}> Informacja o warunkach udziału w postępowaniu innych niż określone art. 112, jeżeli zamawiający je przewiduje
                        </FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.conditions) : ''}
                            value={formAuctionParts.conditions}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            name="conditions"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Warunki "
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    if(isSector === true){
        formSector =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].conditions393.toString()} name="conditions393">
                        <RadioGroup name="conditions393" defaultValue={edit === 'edit' && auctionObject.conditions393.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no);}}>
                            <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań w zakresie udziału 50% dostaw zgonie z art. 393 ust.1, pkt4" />
                            <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania w zakresie udziału 50% dostaw zgonie z art. 393 ust.1, pkt4" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].conditions112.toString()} name="conditions112">
                        <RadioGroup name="conditions112" defaultValue={edit === 'edit' && auctionObject.conditions112.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange3(e);}}>
                            <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie zastosuje warunków udziału w postępowaniu innych niż określone art. 112" />
                            <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający zastosuje warunki udziału w postępowaniu innych niż określone art. 112" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }



    if(addValue1 === true){
        form =
            <>
                <Typography className={classes.nameSec}>Warunki</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Warunki zdolności do występowania w obrocie gospodarczym</FormLabel>
                        <TextValidator
                            value={formAuctionParts.conditions1}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.conditions1) : ''}
                            name="conditions1"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Warunki zdolności do występowania w obrocie gospodarczym"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Warunki uprawnień do prowadzenia określonej działalności gospodarczej lub zawodowej</FormLabel>
                        <TextValidator
                            value={formAuctionParts.conditions2}
                            defaultValue={edit === 'edit' ? (auctionObject.conditions2) : ''}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            name="conditions2"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Warunki uprawnień do prowadzenia określonej działalności"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Warunki sytuacji ekonomicznej i finansowej</FormLabel>
                        <TextValidator
                            value={formAuctionParts.conditions3}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.conditions3) : ''}
                            className={classes.input}
                            name="conditions3"
                            type="text"
                            id="outlined-basic-u"
                            label="Warunki sytuacji ekonomicznej i finansowej"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Warunki zdolności technicznej lub zawodowej</FormLabel>
                        <TextValidator
                            value={formAuctionParts.conditions4}
                            defaultValue={edit === 'edit' ? (auctionObject.conditions4) : ''}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            className={classes.input}
                            name="conditions4"
                            type="text"
                            id="outlined-basic-u"
                            label="Warunki zdolności technicznej lub zawodowej"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }


    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifConditions393.toString()} name="ifConditions393">
                            <RadioGroup name="ifConditions393" defaultValue={edit === 'edit' && auctionObject.ifConditions393.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie uwzględnił wymagań w zakresie udziału 50% dostaw zgodnie z art. 393 ust. 1, pkt 4" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający uwzględnił wymagania w zakresie udziału 50% dostaw zgodnie z art. 393 ust. 1, pkt 4" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                value={'Wykonawca zobowiązany jest dołączyć oświadczenie o niepodleganiu wykluczeniu i spełnianiu warunków udziału w postępowaniu, w zakresie wskazanym przez zamawiającego.'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                value={'Wykonawca zobowiązany jest dołączyć oświadczenie o niepodleganiu wykluczeniu i spełnianiu kryteriów selekcji, w zakresie wskazanym przez zamawiającego.'}
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifConditions.toString()} name="ifConditions">
                            <RadioGroup defaultValue={edit === 'edit' && auctionObject.ifConditions.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange2(e);}} name="ifConditions">
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje warunków udziału w postępowaniu" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający wymaga spełnienia określonych warunków udziału w postępowaniu" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form}
                    {formSector}
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}


export default ConditionsPartI;