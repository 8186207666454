import React, {useEffect, useState} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import {SectionHeading} from "../components/misc/Headings";
import {Link} from 'react-router-dom';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import styled from "styled-components";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuction} from "../state/auction/auctionActions";
import {fetchAuction, fetchAuctionId, fetchAuctionItemId, fetchCountry} from "../services/fetchId";
import {postInitialOpAuction} from "../services/postInitialOpAuction";
import {ValidatorForm} from "react-material-ui-form-validator";

const Heading = tw(SectionHeading)`text-gray-800 text-4xl mt-0!`;
const HeadingRow = tw.div`flex p-0! mb-24 mt-0`;
const Forms = tw.div`mb-5 flex justify-center `;
const FormButtonSubmit = tw.div`mb-5 flex justify-center`;
const SubmitFormButton = styled.button`
  ${tw`my-3 w-80 p-10 font-semibold mt-8 bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;

const styleLabel = {
    marginBottom: '4%',
    fontWeight: 'bold',
    fontSize: 'x-large',
    textAlign: 'center',
    color: 'black'
};






const ChoosingOrderType = () => {

    const formAuction = useSelector(state => state.auction.formAuction);
    console.log(formAuction);

    const dispatch = useDispatch();

    const setValueAuction = (e) => {
        dispatch(setValuesAuction(e))
        console.log(formAuction)
    }

    const [procedureType, setProcedureType] = useState('');

    const handleChangeProcedureType = (e) => {
        setProcedureType(e.target.value);
    }


    return(
        <React.Fragment>
            <SidebarLeftAccount/>
            <SidebarRightAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Nowy przetarg</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <ValidatorForm>
                        <Forms>
                            <FormControl component="fieldset3" >
                                <FormLabel component="legend3" style={styleLabel}>Wybierz w jaki sposób udzielisz zamówienia.
                                </FormLabel>
                                <RadioGroup name="procedureType" value={formAuction.procedureType} onChange={(e) => {handleChangeProcedureType(e); setValueAuction(e)}}>
                                    <FormControlLabel value="Samodzielnie" control={<Radio color="primary"/>} label="Samodzielnie" />
                                    <FormControlLabel value="Jako centralny zamawiający" control={<Radio color="primary"/>} label="Jako centralny zamawiający" />
                                    <FormControlLabel value="Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi" control={<Radio color="primary"/>} label="Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi"/>
                                    <FormControlLabel value="Jako podmiot, któremu zamawiający powierzył/powierzyli przeprowadzenie postępowania" control={<Radio color="primary"/>} label="Jako podmiot, któremu zamawiający powierzył/powierzyli przeprowadzenie postępowania" />
                                </RadioGroup>
                            </FormControl>
                        </Forms>
                        {formAuction.procedureType ===  "Jako centralny zamawiający" && (
                            <FormButtonSubmit>
                                <Link to="/centralOrder/form">
                                    <SubmitFormButton
                                        type="submit"
                                       /* onSubmit={postInitialOpAuction(formAuction).subscribe()}>*/
                                        >Dalej
                                    </SubmitFormButton>
                                </Link>
                            </FormButtonSubmit>
                        )
                        }
                        {formAuction.procedureType ===  "Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi" && (
                            <FormButtonSubmit>
                                <Link to="/jointOrder/form">
                                    <SubmitFormButton
                                        type="submit"
                                        /*onSubmit={postInitialOpAuction(formAuction).subscribe()}>*/
                                       > Dalej
                                    </SubmitFormButton>
                                </Link>
                            </FormButtonSubmit>
                        )
                        }
                        {formAuction.procedureType === "Jako podmiot, któremu zamawiający powierzył/powierzyli przeprowadzenie postępowania" && (
                            <FormButtonSubmit>
                                <Link to="/proxyOrder/form">
                                    <SubmitFormButton
                                        type="submit"
                                        /*onSubmit={postInitialOpAuction(formAuction).subscribe()}>*/>
                                        Dalej
                                    </SubmitFormButton>
                                </Link>
                            </FormButtonSubmit>
                        )
                        }
                        {formAuction.procedureType === "Samodzielnie" && (
                            <FormButtonSubmit>
                                <Link to="/soloOrder/form">
                                    <SubmitFormButton
                                        type="submit"
                                        /*onSubmit={postInitialOpAuction(formAuction).subscribe()}>*/>
                                        Dalej
                                    </SubmitFormButton>
                                </Link>
                            </FormButtonSubmit>
                        )
                        }
                    </ValidatorForm>

                </AnimationRevealPage>
            </div>

        </React.Fragment>
    )
}

export default ChoosingOrderType;