import React from "react";
import MyAccount from "./MyAccount";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import {SectionHeading} from "../components/misc/Headings";
import {PrimaryButton} from "../components/misc/Buttons";
import {Link} from 'react-router-dom';
import SidebarRight from "../components/SidebarRight";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Hints from "../components/Hints";
import {useSelector} from "react-redux";
import HintsInfo from "../components/HintsInfo";

const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;
const ButtonCol = tw.div`flex flex-col justify-center`;
const ButtonNew = tw(PrimaryButton)`mb-3 text-base p-5 w-64`


const MyTenders = () => {

    const hintsMyAccount = useSelector(state => state.hints.hintsMyAccount)

    return(
        <React.Fragment>
            <SidebarRight/>
            <AnimationRevealPage>
                <ContentWithPaddingMd>
                    <HeadingRow >
                        <Heading>Moje przetargi</Heading>
                    </HeadingRow>
                </ContentWithPaddingMd>
                <ButtonCol>
                    <Link to="/choosingOrderType">
                        <ButtonNew>Nowy przetarg</ButtonNew>
                    </Link>
                    <ButtonNew>Przetarg w trakcie przygotowywania - nieogłoszony</ButtonNew>
                    <ButtonNew>Przetarg ogłoszony - niezakończony</ButtonNew>
                    <ButtonNew>Archiwum moich przetargów</ButtonNew>
                    <ButtonNew>Archiwum moich ogłoszeń</ButtonNew>
                </ButtonCol>
            </AnimationRevealPage>
            <div className="hints">
                {
                    hintsMyAccount.filter(hint => hint.visibility).map((hint, index) =>
                        hint.severity === 'info' ?  <HintsInfo
                            message={hint.message} index={index}/> : <Hints index={index} message={hint.message}/>

                    )
                }

            </div>
        </React.Fragment>
    )
}

export default MyTenders;