import React, {useEffect, useState} from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import jsPDF from 'jspdf'
import font from '../../styles/fonts/Roboto-Regular.ttf'
import fontBold from '../../styles/fonts/Roboto-Bold.ttf'
import {
    fetchAuctionItemId, fetchAuctionItemParts, fetchAuctionItemPartsId,
    fetchAuctionWithId, fetchBipClientType1, fetchBipClientType2, fetchBipCoreBusinessId, fetchCountryId,
    fetchCustomer,
    fetchCustomerId,
    fetchUserId, fetchVoivodeshipId
} from "../../services/fetchId";
import {Text} from "@react-pdf/renderer";
import GetAppIcon from '@material-ui/icons/GetApp';

const PDFViewer = ({id, setRowId}) => {

    const [auctionItem, setAuctionItem] = useState('');
    const [auction, setAuction] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [customer, setCustomer] = useState('');
    const [voivodeship, setVoivodeship] = useState('');
    const [country, setCountry] = useState('');
    const [bipClientTypeId, setBipClientTypeId] = useState();
    const [bipClientTypes1, setBipClientTypes1] = useState('');
    const [bipClientTypes2, setBipClientTypes2] = useState('');
    const [bipClientTypes, setBipClientTypes] = useState([]);
    const [bipClientType, setBipClientType] = useState('');
    const [clientType, setClientType] = useState('');
    const [coreBusiness, setCoreBusiness] = useState('');
    const [y7, setY7] = useState(0);


    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0])
        )
    }, [id])

    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => setAuction(data)
        )
    }, [id])



    useEffect(() => {
        if(!!auctionItem){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].filter(auc => auc.auctionItem === auctionItem['@id']))
                }
            )
        }
    }, [auctionItem])


    useEffect(() => {
        fetchUserId().subscribe(
            data => {
                const userId = data['hydra:member'][0].logged.slice(11);
                fetchCustomerId(userId).subscribe(
                    data => {
                        const customerId = data.customer.slice(15);
                        fetchCustomer(customerId).subscribe(
                            data => {
                                setBipClientTypeId(data.bipClientType.slice(22));
                                setCustomer(data);
                                fetchVoivodeshipId(data.addressVoivodeship.slice(18)).subscribe(
                                    data => setVoivodeship(data.name)
                                )
                                fetchCountryId(data.addressCountry.slice(15)).subscribe(
                                    data => setCountry(data.polishShortName)
                                )
                                fetchBipCoreBusinessId(data.bipCoreBusiness.slice(25)).subscribe(
                                    data => setCoreBusiness(data.name)
                                )
                            }
                        )
                    }
                )
            }
        )
    }, [id]);

    useEffect(() => {
        if(!!customer.addressVoivodeship){
            fetchVoivodeshipId(customer.addressVoivodeship.slice(17)).subscribe(
                data => console.log(data)
            )
        }
    }, [customer, id])

    useEffect(() => {
        fetchBipClientType1().subscribe(
            data => {
                setBipClientTypes1(data['hydra:member']);
            }
        )

        fetchBipClientType2().subscribe(
            data => {
                setBipClientTypes2(data['hydra:member']);
            }
        )
    }, [])

    useEffect(() => {
        setBipClientTypes([...bipClientTypes1, ...bipClientTypes2]);
    }, [bipClientTypes1, bipClientTypes2])


    useEffect(() => {
        setBipClientType(bipClientTypes.filter(type => type.id === parseInt(bipClientTypeId))[0]);
    }, [bipClientTypeId, bipClientTypes])

    const [procedureType, setProcedureType] = useState();

    useEffect(() => {

        if(!!auction){
            if(auction.procedureType === "Samodzielnie"){
                setProcedureType("Postępowanie prowadzone jest samodzielnie przez zamawiającego")
            }else if(auction.procedureType === "Jako centralny zamawiający"){
                setProcedureType("Postępowanie prowadzi zamawiający centralny")
            }else if(auction.procedureType === "Jako podmiot, któremu zamawiający powierzył przeprowadzenie postępowania"){
                setProcedureType("Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania")
            }else if(auction.procedureType === "Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi"){
                setProcedureType("Postępowanie prowadzą zamawiający działający wspólnie")
            }
        }
    }, [auction])

    const pdfGenerate = () => {

        let document = new jsPDF('portrait', 'px', 'a4', false, {
            pageSplit: true
        });
        document.addFont(font, 'Roboto', 'normal')
        document.addFont(fontBold, 'RobotoBold', 'normal')
        document.setFont('Roboto');

        //Header
        document.setFontSize(8);
        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
        document.line(15,16,430, 16)
        //Header

        //Title
        document.setFontSize(12);
        document.text("Specyfikacja Warunków Zamówienia", document.internal.pageSize.getWidth()/2, 40, { align: "center" })

        let splitTitle = document.splitTextToSize(auction.orderName, 300);
        {
            document.text(splitTitle,document.internal.pageSize.getWidth()/2, 60, { align: "center" })
            document.text(document.splitTextToSize(auction.refNumber, 420), document.internal.pageSize.getWidth()/2, 120, { align: "center" })
            document.text(document.splitTextToSize(auctionItem.nameOrdering, 420), document.internal.pageSize.getWidth()/2, 140, { align: "center" })
            document.text(document.splitTextToSize(auctionItem.ecommunication ? 'Postępowanie prowadzone jest przy użyciu środków komunikacji elektronicznej. Składanie ofert następuje za pośrednictwem platformy zakupowej dostępnej pod adresem internetowym: ' : '', 420), document.internal.pageSize.getWidth()/2, 180, { align: "center" })
            document.text(document.splitTextToSize('Adres strony internetowej prowadzonego postępowania, na której będą udostępniane dokumenty zamówienia, w tym specyfikacja: ' + auctionItem.urlDocuments, 420), document.internal.pageSize.getWidth()/2, 220, { align: "center" })
        }
        //Footer
        document.setFontSize(8);
        document.line(15,614,430, 614)
        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
        //Footer
        //Title

        document.addPage()

        //Definicje

        let words = [
            'Cena - ',
            'Dokumenty zamówienia - ',
            'Dostawy - ',
            'JEDZ - ',
            'Oferta częściowa - ',
            'Pisemność - ',
            'Podmiotowe środki dowodowe - ',
            'Protokół postępowania - ',
            'Przedmiotowe środki dowodowe - ',
            'Środki komunikacji elektronicznej - ',
            'Środki publiczne - ',
            'Udzielenie zamówienia - ',
            'Umowa o podwykonawstwo - ',
            'Usługi - ',
            'Warunki zamówienia - ',
            'Wykonawca - ',
            'Zamawiający - ',
            'Zamówienie - ',
            'Inne: ',
        ]

        let defs = [
            'należy przez to rozumieć cenę w rozumieniu art. 3 ust. 1 pkt 1 i ust. 2 ustawy z dnia 9 maja 2014 r. o informowaniu o cenach towarów i usług (Dz. U. z 2019 r. poz. 178), nawet jeżeli jest płacona na rzecz osoby niebędącej przedsiębiorcą;',
            'należy przez to rozumieć dokumenty sporządzone przez zamawiającego lub dokumenty, do których zamawiający odwołuje się, inne niż ogłoszenie, służące do określenia lub opisania warunków zamówienia, w tym specyfikacja warunków zamówienia oraz opis potrzeb i wymagań;',
            'należy przez to rozumieć nabywanie produktów, którymi są rzeczy ruchome, energia, woda oraz prawa majątkowe, jeżeli mogą być przedmiotem obrotu, w szczególności na podstawie umowy sprzedaży, dostawy, najmu, dzierżawy oraz leasingu z opcją lub bez opcji zakupu, które może obejmować dodatkowo rozmieszczenie lub instalację;',
            'należy przez to rozumieć oświadczenie składane przez wykonawcę, o którym mowa w art. 125 ust 1 (Jednolity Europejski Dokument Zamówienia)',
            'należy przez to rozumieć ofertę przewidującą, zgodnie z dokumentami zamówienia, wykonanie części zamówienia;',
            'należy przez to rozumieć sposób wyrażenia informacji przy użyciu wyrazów, cyfr lub innych znaków pisarskich, które można odczytać i powielić, w tym przekazywanych przy użyciu środków komunikacji elektronicznej;',
            'należy przez to rozumieć środki służące potwierdzeniu braku podstaw wy-kluczenia, spełniania warunków udziału w postępowaniu lub kryteriów selekcji, z wyjątkiem oświadczenia, o którym mowa w art. 125 ust. 1;',
            'należy przez to rozumieć dokument sporządzany przez zamawiającego, który potwierdza przebieg postępowania o udzielenie zamówienia;',
            'należy przez to rozumieć środki służące potwierdzeniu zgodności ofero-wanych dostaw, usług lub robót budowlanych z wymaganiami, cechami lub kryteriami określonymi w opisie przed-miotu zamówienia lub opisie kryteriów oceny ofert, lub wymaganiami związanymi z realizacją zamówienia;',
            'należy przez to rozumieć środki komunikacji elektronicznej w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2019 r. poz. 123 i 730);',
            'należy przez to rozumieć środki publiczne w rozumieniu przepisów ustawy z dnia 27 sierpnia 2009 r. o finansach publicznych;',
            'należy przez to rozumieć zawarcie umowy w sprawie zamówienia publicznego;',
            'należy przez to rozumieć umowę w formie pisemnej o charakterze odpłatnym, zawartą między wykonawcą a podwykonawcą, a w przypadku zamówienia na roboty budowlane innego niż zamówienie w dziedzinach obronności i bezpieczeństwa, także między podwykonawcą a dalszym podwykonawcą lub między dalszymi podwykonawcami, na mocy której odpowiednio podwykonawca lub dalszy podwykonawca, zobowiązuje się wykonać część zamówienia;',
            'należy przez to rozumieć wszelkie świadczenia, które nie są robotami budowlanymi lub dostawami;',
            'należy przez to rozumieć warunki, które dotyczą zamówienia lub postępowania o udzielenie zamówienia, wynikające w szczególności z opisu przedmiotu zamówienia, wymagań związanych z realizacją zamówienia, kryteriów oceny ofert, wymagań proceduralnych lub projektowanych postanowień umowy w sprawie zamówienia publicznego;',
            'należy przez to rozumieć osobę fizyczną, osobę prawną albo jednostkę organizacyjną nieposiadającą osobowości prawnej, która oferuje na rynku wykonanie robót budowlanych lub obiektu budowlanego, dostawę pro-duktów lub świadczenie usług lub ubiega się o udzielenie zamówienia, złożyła ofertę lub zawarła umowę w sprawie zamówienia publicznego;',
            'należy przez to rozumieć osobę fizyczną, osobę prawną albo jednostkę organizacyjną nieposiadającą osobowości prawnej, obowiązaną na podstawie ustawy do jej stosowania;',
            'należy przez to rozumieć umowę odpłatną zawieraną między zamawiającym a wykonawcą, której przedmiotem jest nabycie przez zamawiającego od wybranego wykonawcy robót budowlanych, dostaw lub usług.',
            'definicja inne',
        ]

        //Header
        document.setFontSize(8);
        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
        document.line(15,16,430, 16)
        document.setFontSize(12);
        //Header

        let y0 = 50;

        document.text(document.splitTextToSize('Definicje', 420), document.internal.pageSize.getWidth()/2, 40, { align: "center" })

        for (let j = 0; j<words.length; j++){
            if(!!defs[j]) {
                document.setFont('RobotoBold');
                document.text(document.splitTextToSize(words[j], 390), 30, y0)
                document.setFont('Roboto');
                document.text(document.splitTextToSize(defs[j], 390), 40, y0 + 15)
                document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(defs[j].toString());
                    let lines = width / 390;
                    if (lines > 0 && lines < 1) {
                        y0 = y0 + 30;
                    } else if (lines > 1 && lines < 2) {
                        y0 = y0 + 40;
                    } else if (lines > 2 && lines < 3) {
                        y0 = y0 + 60;
                    } else if (lines > 3 && lines < 4) {
                        y0 = y0 + 70;
                    } else if (lines > 4 && lines < 5) {
                        y0 = y0 + 80;
                    } else if (lines > 5 && lines < 6) {
                        y0 = y0 + 100;
                    } else if (lines > 6 && lines < 7) {
                        y0 = y0 + 100;
                    } else if (lines > 7 && lines < 8) {
                        y0 = y0 + 160;
                    }else {
                        y0 = y0 + 20;
                }
            }

            if(y0 > 590){
                //Footer
                document.setFontSize(8);
                document.line(15,614,430, 614)
                document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                //Footer

                document.setFontSize(12);
                document.addPage();

                //Header
                document.setFontSize(8);
                document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                document.line(15,16,430, 16)
                //Header

                document.setFontSize(12);
                y0 = 30;

                //Footer
                document.setFontSize(8);
                document.line(15,614,430, 614)
                document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                document.setFontSize(12);
                //Footer
            }
        }
        //Definicje

        //SpisTreści
        document.addPage();
        //Header
        document.setFontSize(8);
        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
        document.line(15,16,430, 16)
        document.setFontSize(12);
        //Header

        document.text(document.splitTextToSize('Spis treści', 420), document.internal.pageSize.getWidth()/2, 40, { align: "center" });
        document.text(document.splitTextToSize('SEKCJA I - ZAMAWIAJĄCY', 390), 30, 70)
        document.text(document.splitTextToSize('SEKCJA II - INFORMACJE PODSTAWOWE', 390), 30, 85)
        document.text(document.splitTextToSize('SEKCJA III - UDOSTĘPNIANIE DOKUMENTÓW ZAMÓWIENIA I KOMUNIKACJA', 390), 30, 100)
        document.text(document.splitTextToSize('SEKCJA IV - PRZEDMIOT ZAMÓWIENIA', 390), 30, 115)
        if(auctionItem.numberOfParts !== 0){
            document.text(document.splitTextToSize('SEKCJA V - KWALIFIKACJA WYKONAWCÓW', 390), 30, 130)
            document.text(document.splitTextToSize('SEKCJA VI - WARUNKI ZAMÓWIENIA', 390), 30, 145)
            document.text(document.splitTextToSize('SEKCJA VII - PROJEKTOWANIE POSTANOWIENIA UMOWY', 390), 30, 160)
            document.text(document.splitTextToSize('SEKCJA VIII - PROCEDURA', 390), 30, 175)
            document.text(document.splitTextToSize('SEKCJA IX - POZOSTAŁE INFORMACJE', 390), 30, 190)
            document.text(document.splitTextToSize('Załączniki do SWZ', 390), 30, 205)
        }else{
            document.text(document.splitTextToSize('SEKCJA VIII - PROCEDURA', 390), 30, 130)
            document.text(document.splitTextToSize('SEKCJA IX - POZOSTAŁE INFORMACJE', 390), 30, 145)
            document.text(document.splitTextToSize('Załączniki do SWZ', 390), 30, 160)
        }



        //Footer
        document.setFontSize(8);
        document.line(15,614,430, 614)
        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
        document.setFontSize(12);
        //Footer

        //SpisTreści

        document.addPage();

        //Header
        document.setFontSize(8);
        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
        document.line(15,16,430, 16)
        //Header

        document.setFontSize(12);

        //Section1
        document.setDrawColor(243, 240, 240);
        document.setLineWidth(16.0);
        document.line(15, 30, 430, 30);
        document.text("SEKCJA I - ZAMAWIAJĄCY", 30, 33)
        document.setLineWidth(0.1);
        document.setDrawColor(0, 0, 0);

        let formSectionI = [
            "1.1.) Rola zamawiającego: ",
            "1.2.) Nazwa zamawiającego: ",
            "1.3.) Oddział zamawiającego: ",
            "1.4.) Krajowy Numer Identyfikacyjny: ",
            "1.5.) Adres zamawiającego",
            "1.5.1.) Ulica: ",
            "1.5.2.) Miejscowość: ",
            "1.5.3.) Kod pocztowy: ",
            "1.5.4.) Województwo: ",
            "1.5.5.) Kraj: ",
            "1.5.6.) Lokalizacja NUTS: ",
            "1.5.7.) Numer telefonu: ",
            "1.5.9.) Adres poczty elektronicznej: ",
            "1.5.10.) Adres strony internetowej: ",
            "1.5.12.) Osoba do kontaktu: ",
            "1.5.13.) Informacje dodatkowe: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.) Dane podmiotu, któremu zamawiający powierzył przeprowadzenie postępowania",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.1) Nazwa podmiotu prowadzącego postępowanie: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.2) Krajowy Numer Identyfikacyjny: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.3) Ulica: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.4) Miejscowość: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.5) Kod pocztowy: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.6) Województwo: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.7) Państwo: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.8) Lokalizacja NUTS: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.9) Numer telefonu: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.11) Adres poczty elektronicznej: ",
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && "1.11.12) Adres strony internetowej: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.8.) Informacje o pozostałych zamawiających działających wspólnie",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.2.) Nazwa drugiego zamawiającego: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.3.) Oddział drugiego zamawiającego: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.4.) Krajowy Numer Identyfikacyjny drugiego zamawiającego: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.) Adres drugiego zamawiającego: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.1.) Ulica: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.2.) Miejscowość: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.3.) Kod pocztowy: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.4.) Województwo: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.5.) Kraj: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.6.) Lokalizacja NUTS: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.7.) Numer telefonu: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.9.) Adres poczty elektronicznej: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.5.10.) Adres strony internetowej: ",
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && "1.9.) Podział obowiązkow - zamówienie wspólne: "
        ];

        let answerSectionI = [
            procedureType,
            auctionItem.nameOrdering,
            customer.department ,
            customer.regon,
            ' ',
            auctionItem.addressStreet,
            auctionItem.addressCity,
            auctionItem.addressZipCode,
            auctionItem.addressVoivodeship,
            auctionItem.addressCountry,
            customer.nutsCode,
            customer.phoneNumber,
            customer.email,
            customer.www,
            customer.contactPerson,
            '????',
            procedureType === "Postępowanie prowadzi podmiot, któremu powierzono przeprowadzenie postępowania" && " ",
            auctionItem.nameProxy,
            auctionItem.kniProxy,
            auctionItem.adressProxyStreet,
            auctionItem.adressProxyCity,
            auctionItem.adressProxyZipCode,
            auctionItem.adressProxyVoivodeship,
            auctionItem.adressProxyCountry,
            auctionItem.nutsProxy,
            auctionItem.numberProxy,
            auctionItem.emailProxy,
            auctionItem.urlProxy,
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && " ",
            auctionItem.nameSecOrdering,
            auctionItem.departmentSecOrdering,
            auctionItem.kni,
            procedureType === "Postępowanie prowadzą zamawiający działający wspólnie" && " ",
            auctionItem.adressSecOrderingStreet,
            auctionItem.adressSecOrderingCity,
            auctionItem.adressSecOrderingZipCode,
            auctionItem.adressSecOrderingVoivodeship,
            auctionItem.adressSecOrderingCountry,
            auctionItem.secOrderingNuts,
            auctionItem.numberSec,
            auctionItem.emailSec

        ];
        let y = 50;

        for (let i = 0; i<formSectionI.length; i++){
            if(!!answerSectionI[i]){
                document.setFont('RobotoBold');
                document.text(document.splitTextToSize(formSectionI[i], 420), 30, y)
                document.setFont('Roboto');
                if(answerSectionI[i] != ' '){
                    document.text(document.splitTextToSize(answerSectionI[i], 420), 40, y + 15)
                }
                if(formSectionI[i] === "1.6.) Rodzaj zamawiającego: " && procedureType === "Jako podmiot, któremu zamawiający powierzył przeprowadzenie postępowania"){
                    y = y + 45;
                }else if(formSectionI[i] === "1.5.) Adres zamawiającego"){
                    y = y + 15;
                } else{
                    y = y + 30;
                }
            }
            if(y > 590){
                //Footer
                document.setFontSize(8);
                document.line(15,614,430, 614)
                document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                //Footer

                document.setFontSize(12);
                document.addPage();

                //Header
                document.setFontSize(8);
                document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                document.line(15,16,430, 16)
                //Header

                document.setFontSize(12);
                y=40;
            }

        }

        //Section2
        document.setDrawColor(243, 240, 240);
        document.setLineWidth(16.0);
        document.line(15, y + 5 , 430, y + 5);
        document.text("SEKCJA II - INFORMACJE PODSTAWOWE", 30, y + 8)
        document.setLineWidth(0.1);
        document.setDrawColor(0, 0, 0);


        let formSectionII = [
            "2.3.) Nazwa zamówienia: ",
            "2.4.) Identyfikator postępowania:",
            "2.5.) Numer ogłoszenia w Biuletynie Zamówień Publicznych: ",
            "2.5.) Numer ogłoszenia w dzienniku Urzędowym Unii Europejskiej: ",
            "2.14.) Czy zamówienie albo umowa ramowa dotyczy projektu lub programu współfinansowanego ze środków Unii Europejskiej: ",
            "2.15.) Nazwa projektu lub programu: ",
            "2.16.) Tryb udzielenia zamówienia wraz z podstawą prawną: ",
            "2.17.) Wartość szacunkowa zamówienia przekracza kwotę określoną w przepisach wydanych na podstawie art. 3, ust 1: ",
            "2.18.) Informacje o przewidywanym wyborze najkorzystniejszej oferty z zastosowaniem aukcji elektronicznej: ",
            "2.19.) Adres internetowy aukcji: ",
            "2.20.) Opis aukcji elektronicznej: ",
            "2.21.) Informacje dotyczące walut obcych, w jakich mogą być prowadzone rozliczenia pomiędzy zamawiającym a wykonawcą: ",
            "2.22.) Informacje dotyczące zwrotu kosztów udziału w postępowaniu: ",
            "2.23.) Informacje dodatkowe: ",
            "2.24.) Główny kod CPV: ",
            "2.25.) Rodzaj zamówienia: ",
            "2.26.) Informacja o procedurze odwróconej (art. 139, ust 1): ",
            "2.27.) Informacje dodatkowe: ",
        ];
        let answerSectionII = [
            auction.orderName,
            '?????',
            auctionItem.bzpNumber,
            '????',
            auctionItem.ueFunds ? 'Tak' : 'Nie',
            auctionItem.ueFunds ? auctionItem.nameUe : '',
            '?????',
            '?????',
            '?????',
            '?????',
            '?????',
            '?????',
            auctionItem.refund ? 'Zamawiający przewiduje możliwość zwrotu kosztów udziału w postępowaniu' : 'Zamawiający nie przewiduje możliwości zwrotu kosztów udziału w postępowaniu',
            auction.cpvCode,
            auction.addCpvCode,
            auctionItem.orderType,
            '?????',
            '?????',
        ];

        let y2 = y+33;

        for (let j = 0; j<formSectionII.length; j++){
            if(!!answerSectionII[j]) {
                document.setFont('RobotoBold');
                document.text(document.splitTextToSize(formSectionII[j], 420), 30, y2)
                document.setFont('Roboto');

                let widthForm = document.getTextWidth(formSectionII[j].toString());
                let linesForm = widthForm / 420;

                if (linesForm > 0 && linesForm <= 1){
                    y2 = y2 + 15;
                    document.text(document.splitTextToSize(answerSectionII[j], 420), 40, y2)
                }else if(linesForm > 1 && linesForm < 2){
                    y2 = y2 + 25;
                    document.text(document.splitTextToSize(answerSectionII[j], 420), 40, y2)
                }else if(linesForm > 2 && linesForm < 3){
                    y2 = y2 + 40;
                    document.text(document.splitTextToSize(answerSectionII[j], 420), 40, y2)
                }

                document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(answerSectionII[j].toString());
                    let lines = width / 420;

                let temp = y2;
                for (let i = 0; i < 14; i++) {
                    temp += 15;
                    if (lines > i && lines < i + 1) {
                        y2 = temp;
                        break;
                    }
                }
                }

                if(y2 > 590 - document.getTextWidth(answerSectionII[formSectionII.length - 1].toString())/420 - document.getTextWidth(formSectionII[formSectionII.length - 1].toString())/420){
                    //Footer
                    document.setFontSize(8);
                    document.line(15,614,430, 614)
                    document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                    document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                    //Footer

                    document.setFontSize(12);
                    document.addPage();

                    //Header
                    document.setFontSize(8);
                    document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                    document.line(15,16,430, 16)
                    //Header

                    document.setFontSize(12);
                    y2=40;
                }
        }
        document.setDrawColor(243, 240, 240);
        document.setLineWidth(16.0);
        document.line(15, y2, 430, y2);
        document.text("SEKCJA III - UDOSTĘPNIANIE DOKUMENTÓW ZAMÓWIENIA I KOMUNIKACJA", 30, y2+3)
        document.setLineWidth(0.1);
        document.setDrawColor(0, 0, 0);

        //section3

        let formSectionIII = [
            "3.1.) Adres strony internetowej prowadzonego postępowania: ",
            "3.2.) Zamawiający zastrzega dostęp do dokumentów zamówienia: ",
            "3.3.) Informacje na temat sposobu udostępnienia zastrzeżonych dokumentów zamówienia: ",
            "3.4.) Wykonawcy zobowiązani są do składania ofert, wniosków o dopuszczenie do udziału w postępowaniu, oświadczeń oraz innych dokumentów wyłącznie przy użyciu środków komunikacji elektronicznej: ",
            "3.5.) Informacje o środkach komunikacji elektronicznej, przy użyciu których zamawiający będzie komunikował się z wykonawcami - adres strony internetowej: ",
            "3.6.) Wymagania techniczne i organizacyjne dotyczące korespondencji elektronicznej: ",
            "3.6.1.) Sposób udzielania wyjaśnień i zmiany treści SWZ: ",
            "3.7.) Adres strony internetowej, pod którym są dostępne narzędzia, urządzenia lub formaty plików, które nie są ogólnie dostępne: ",
            "3.8.) Zamawiający wymaga sporządzenia i przedstawienia ofert przy użyciu narzędzi elektronicznego modelowania danych budowlanych lub innych podobnych narzędzi, które nie są ogólnie dostępne: ",
            "3.9.) Sposób dostępu do narzędzi elektronicznego modelowania danych budowlanych: ",
            "3.10.) Przyczyny odstąpienia od komunikacji elektronicznej: ",
            "3.11.) Sposób nieelektronicznego składania ofert, wniosków, oświadczeń oraz innych dokumentów: ",
            "3.12.) Oferta - katalog elektroniczny: ",
            "3.13.) Zamawiający przewiduje pobranie ze złożonych katalogów elektronicznych danych potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia: ",
            "3.14.) Języki, w jakich mogą być sporządzane dokumenty składane w postępowaniu: ",
            "3.14.1.) Informacje dotyczące języka postępowania: ",
            "3.15.) RODO (obowiązek informacyjny): ",
            "3.16.) RODO (ograniczenia stosowania): ",
            "3.17.) Pozostałe informacje: "
        ];

        let answerSectionIII = [
            auctionItem.urlDocuments,
            auctionItem.unable ? 'Tak' : 'Nie',
            auctionItem.access,
            auctionItem.ecommunication ? 'Tak' : 'Nie',
            '???????',
            auctionItem.emailRequirements,
            '??????',
            auctionItem.urlTools,
            auctionItem.bud ? 'Tak' : 'Nie',
            auctionItem.bud2,
            auctionItem.reason,
            auctionItem.way,
            auctionItem.bud3,
            auctionItem.bud4,
            auctionItem.lang,
            auctionItem.lang,
            auctionItem.rodo,
            auctionItem.resOrder,
            '????'
        ];


        let y3 = y2 + 30;
        for (let k = 0; k<formSectionIII.length; k++){
            if(!!answerSectionIII[k]) {
                document.setFont('RobotoBold');
                document.text(document.splitTextToSize(formSectionIII[k], 390), 30, y3)
                document.setFont('Roboto');

                let widthForm = document.getTextWidth(formSectionIII[k].toString());
                let linesForm = widthForm / 390;

                if (linesForm > 0 && linesForm <= 1){
                    y3 = y3 + 15;
                    document.text(document.splitTextToSize(answerSectionIII[k], 390), 40, y3)
                }else if(linesForm > 1 && linesForm < 2){
                    y3 = y3 + 25;
                    document.text(document.splitTextToSize(answerSectionIII[k], 390), 40, y3)
                }else if(linesForm > 2 && linesForm < 3){
                    y3 = y3 + 35;
                    document.text(document.splitTextToSize(answerSectionIII[k], 390), 40, y3)
                }else if(linesForm > 3 && linesForm < 4){
                    y3 = y3 + 45;
                    document.text(document.splitTextToSize(answerSectionIII[k], 390), 40, y3)
                }

                document.setDrawColor(0, 0, 0);
                let width = document.getTextWidth(answerSectionIII[k].toString());
                let lines = width / 390;

                let temp = y3;
                for (let i = 0; i < 14; i++) {
                    temp += 15;
                    if (lines > i && lines < i + 1) {
                        y3 = temp;
                        break;
                    }
                }
            }

            if(y3 > 580 - document.getTextWidth(answerSectionIII[formSectionIII.length - 1].toString())/420 - document.getTextWidth(formSectionIII[formSectionIII.length - 1].toString())/420){
                //Footer
                document.setFontSize(8);
                document.line(15,614,430, 614)
                document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                //Footer

                document.setFontSize(12);
                document.addPage();

                //Header
                document.setFontSize(8);
                document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                document.line(15,16,430, 16)
                //Header

                document.setFontSize(12);
                y3=40;
            }
        }


        //section4
        document.setDrawColor(243, 240, 240);
        document.setLineWidth(16.0);
        document.line(15, y3, 430, y3);
        document.text("SEKCJA IV - PRZEDMIOT ZAMÓWIENIA", 30, y3+3)
        document.setLineWidth(0.1);
        document.setDrawColor(0, 0, 0);

        let formSectionIV = [
            "4.1.1.) Przed wszczęciem postępowania przeprowadzono konsultacje rynkowe: ",
            "4.1.4.) Zamawiający udziela zamówienia w częściach, z których każda stanowi przedmiot odrębnego postępowania: ",
            "4.1.8.) Możliwe jest składanie ofert częściowych: ",
            "4.1.9.) Liczba części: ",
            "4.1.10.) Ofertę można składać na wszystkie części: ",
            "4.1.11.) Zamawiający ogranicza liczbę części zamówienia, którą można udzielić jednemu wykonawcy: ",
            "4.1.12.) Maksymalna liczba części, jaka może zostać udzielona jednemu wykonawcy: ",
            "4.1.12.1.) Kryteria lub zasady mające zastosowanie do ustalenia, które części zamówienia zostaną udzielone jednemu wykonawcy, w przypadku wyboru jego oferty w większej niż maksymalna liczbie części: ",
        ];

        let answerSectionIV = [
            auctionItem.marketCons ? 'Tak' : 'Nie',
            auctionItem.numberOfParts === 0 ? 'Nie' : 'Tak',
            auctionItem.numberOfParts === 0 ? 'Nie' : 'Tak',
            auctionItem.numberOfParts.toString(),
            auctionItem.amountPart === "1" ? 'Tak' : 'Nie',
            auctionItem.oneExecutorPart === false ? 'Tak' : 'Nie',
            auctionItem.maxNumberExecutorPart.toString(),
            auctionItem.partRequirements,
        ];

        let y4 = y3 + 30;

        for (let l = 0; l<formSectionIV.length; l++){

            if(y4 > 590 - document.getTextWidth(answerSectionIV[formSectionIV.length - 1].toString())/420 - document.getTextWidth(formSectionIV[formSectionIV.length - 1].toString())/420){

                //Footer
                document.setFontSize(8);
                document.line(15,614,430, 614)
                document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                //Footer

                document.setFontSize(12);
                document.addPage();

                //Header
                document.setFontSize(8);
                document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                document.line(15,16,430, 16)
                //Header

                document.setFontSize(12);
                y4=40;
            }

            if(!!answerSectionIV[l]) {

                document.setFont('RobotoBold');
                document.text(document.splitTextToSize(formSectionIV[l], 390), 30, y4)
                document.setFont('Roboto');

                let widthForm = document.getTextWidth(formSectionIV[l].toString());
                let linesForm = widthForm / 390;

                if (linesForm > 0 && linesForm <= 1){
                    y4 = y4 + 15;
                    document.text(document.splitTextToSize(answerSectionIV[l], 390), 40, y4)
                }else if(linesForm > 1 && linesForm < 2){
                    y4 = y4 + 25;
                    document.text(document.splitTextToSize(answerSectionIV[l], 390), 40, y4)
                }else if(linesForm > 2 && linesForm < 3){
                    y4 = y4 + 35;
                    document.text(document.splitTextToSize(answerSectionIV[l], 390), 40, y4)
                }else if(linesForm > 3 && linesForm < 4){
                    y4 = y4 + 45;
                    document.text(document.splitTextToSize(answerSectionIV[l], 390), 40, y4)
                }

                document.setDrawColor(0, 0, 0);
                let width = document.getTextWidth(answerSectionIV[l].toString());
                let lines = width / 390;


                let temp = y4;
                for (let i = 0; i < 14; i++) {
                    temp += 15;
                    if (lines > i && lines < i + 1) {
                        y4 = temp;
                        break;
                    }
                }
            }
        }

        let y7;

        if(auctionItem.numberOfParts !== 0){
            for(let p = 0; p < auctionItemParts.length; p++) {

                let yParts;
                if(p === 0){
                    yParts = y4
                }else if(p > 0){
                    yParts = y7
                }

                document.setDrawColor(243, 240, 240);
                document.setLineWidth(16.0);
                document.line(15, yParts, 430, yParts);
                document.text("CZĘŚĆ " + (p+1), 30, yParts+3)
                document.setLineWidth(0.1);
                document.setDrawColor(0, 0, 0);

                let formSectionIVParts = [
                    '4.2.1.) Nazwa zamówienia: ',
                    '4.2.2.) Krótki opis przedmiotu zamówienia: ',
                    '4.2.2.1.) Informacje o negocjacjach (jeżeli dotyczy): ',
                    '4.2.2.2.) Maksymalna liczba wykonawców, którzy zostaną zaproszeni do negocjacji: ',
                    '4.2.2.3.) Kryteria oceny ofert, które zamawiający zamierza stosować w celu ograniczenia liczby wykonawców: ',
                    '4.2.3.) Opis potrzeb zamawiającego',
                    '4.2.3.1.) Informacje o negocjacjach: ',
                    '4.2.3.2.) Maksymalna liczba wykonawców, którzy zostaną zaproszeni do negocjacji: ',
                    '4.2.3.3.) Krótki opis minimalnych wymagań dotyczących przedmiotu zamówienia lub realizacji zamówienia, niepodlegających negocjacjom, które muszą spełnić wszystkie oferty: ',
                    '4.2.3.4.) Nazwa kryteriów oceny ofert: ',
                    '4.2.3.5.) Kryteria oceny ofert, które zamawiający zamierza stosować w celu ograniczenia liczby wykonawców: ',
                    '4.2.3.6.) Wagi kryteriów: ',
                    '4.2.4.) Zapotrzebowanie na innowacyjny produkt, usługę lub roboty budowlane: ',
                    '4.2.6.) Główny kod CPV: ',
                    '4.2.7.) Dodatkowy kod CPV: ',
                    '4.2.8.) Zamówienie obejmuje opcje: ',
                    '4.2.9.) Rodzaj i maksymalna wartość opcji oraz okoliczności skorzystania z opcji: ',
                    '4.2.10.) Okres realizacji zamówienia: ',
                    '4.2.11.) Zamawiający przewiduje wznowienia: ',
                    '4.2.12.) Opis zamówienia: ',
                    '4.2.13.) Zamawiający przewiduje udzielenie dotychczasowemu wykonawcy zamówień na podobne usługi lub roboty budowlane: ',
                    '4.2.13.1.) Informacje na temat zamówień podobnych: ',
                    '4.2.14.) Zamówienie zastrzeżone wyłącznie dla wykonawców, o których mowa w art. 94: ',
                    '4.2.15.) Wymagania w zakresie zatrudnienia na podstawie stosunku pracy w okolicznościach, o których mowa w art. 95: ',
                    '4.2.16.) Wymagania w zakresie zatrudnienia osób, o których mowa w art. 96 ust. 2 pkt 2:',
                    '4.2.17.) Zamawiający uwzględnia aspekty społeczne, środowiskowe  lub innowacyjne w opisie przedmiotu zamówienia - art. 96: ',
                    '4.2.18.) Określenie aspektów społecznych, środowiskowych lub etykiet w opisie przedmiotu zamówienia: ',
                    '4.2.19.) Informacje dotycząca wymagań w zakresie dostępności dla osób niepełnosprawnych oraz projektowania - art. 100 ust.1: ',
                    '4.2.20.) Informacje w zakresie wymagań dotyczących wydajności lub funkcjonalności - art. 101 ust. 1 pkt 1: ',
                    '4.2.21.) Informacje dotyczące etykiety - art. 104: ',
                    '4.2.22.) O zamówienie mogą ubiegać się wyłącznie wykonawcy, którzy spełniają warunki określone w art. 361 - usługi społeczne: ',
                    '4.2.23.) Informacje na temat wizji lokalnej: ',
                    '4.2.24.) Miejsce realizacji zamówienia: ',
                    '4.3.) Kryteria oceny ofert ',
                    '4.3.1.) Sposób oceny ofert: ',
                    '4.3.2.) Sposób określania wagi kryteriów oceny ofert: ',
                    '4.3.3.) Stosowane kryteria oceny ofert: ',
                    'Kryterium 1: ',
                    '4.3.4.) Rodzaj kryterium: ',
                    '4.3.5.) Nazwa kryterium: ',
                    '4.3.6.) Waga: ',
                    '4.3.7.) Kolejność kryterium: ',
                    '4.3.10.) Zamawiający określa aspekty społeczne, środowiskowe lub innowacyjne, żąda etykiet lub stosuje rachunek kosztów cyklu życia w odniesieniu do kryterium oceny ofert: ',
                    '4.3.11.) Określenie aspektów społecznych, środowiskowych lub innowacyjnych, etykiet lub rachunku kosztów cyklu życia w odniesieniu do kryteriów oceny ofert: ',
                    '??) Informacja o składaniu oferty w postaci katalogów elektronicznych lub dołączenia katalogów elektronicznych do oferty, w sytuacji określonej w art. 93: ',
                    '??) Pozostałe informacje: ',
                ];

                let answerSectionIVParts = [
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    ' ',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                ]

                let y41 = yParts + 25;

                for (let l = 0; l < formSectionIVParts.length; l++) {
                    if (!!answerSectionIVParts[l]) {
                        document.setFont('RobotoBold');
                        document.text(document.splitTextToSize(formSectionIVParts[l], 390), 30, y41)
                        document.setFont('Roboto');

                        let widthForm = document.getTextWidth(formSectionIVParts[l].toString());
                        let linesForm = widthForm / 390;

                        if (linesForm > 0 && linesForm <= 1) {
                            y41 = y41 + 15;
                            document.text(document.splitTextToSize(answerSectionIVParts[l], 390), 40, y41)
                        } else if (linesForm > 1 && linesForm < 2) {
                            y41 = y41 + 25;
                            document.text(document.splitTextToSize(answerSectionIVParts[l], 390), 40, y41)
                        } else if (linesForm > 2 && linesForm < 3) {
                            y41 = y41 + 35;
                            document.text(document.splitTextToSize(answerSectionIVParts[l], 390), 40, y41)
                        } else if (linesForm > 3 && linesForm < 4) {
                            y41 = y41 + 45;
                            document.text(document.splitTextToSize(answerSectionIVParts[l], 390), 40, y41)
                        }

                        document.setDrawColor(0, 0, 0);
                        let width = document.getTextWidth(answerSectionIVParts[l].toString());
                        let lines = width / 390;

                        let temp = y41;
                        for (let i = 0; i < 14; i++) {
                            temp += 15;
                            if (lines > i && lines < i + 1) {
                                y41 = temp;
                                break;
                            }
                        }
                    }

                    if (y41 > 590 - document.getTextWidth(answerSectionIVParts[formSectionIVParts.length - 1].toString())/420 - document.getTextWidth(formSectionIVParts[formSectionIVParts.length - 1].toString())/420) {
                        //Footer
                        document.setFontSize(8);
                        document.line(15, 614, 430, 614)
                        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType, 425, 624, "right")
                        //Footer

                        document.setFontSize(12);
                        document.addPage();

                        //Header
                        document.setFontSize(8);
                        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                        document.line(15, 16, 430, 16)
                        //Header

                        document.setFontSize(12);
                        y41 = 40;
                    }
                }


                //section5
                document.setDrawColor(243, 240, 240);
                document.setLineWidth(16.0);
                document.line(15, y41, 430, y41);
                document.text("SEKCJA V - KWALIFIKACJA WYKONAWCÓW", 30, y41 + 3)
                document.setLineWidth(0.1);
                document.setDrawColor(0, 0, 0);

                let formSectionV = [
                    "??) Obligatoryjne podstawy wykluczenia, o których mowa w art. 108: ",
                    "5.1.) Zamawiający przewiduje fakultatywne podstawy wykluczenia (art. 109, ust 1): ",
                    "5.2.) Fakultatywne podstawy wykluczenia: ",
                    "5.3.) Warunki udziału w postępowaniu: ",
                    "5.4.) Nazwa i opis wrunków udziału w postępowaniu: ",
                    "5.4.1.) Zdolność do występowania w obrocie gospodarczym: ",
                    "5.4.2.) Uprawnienia do prowadzenia określonej działalności zawodowej, o ile wynika to z odrębnych przepisów: ",
                    "5.4.3.) Sytuacja ekonomiczna lub finansowa: ",
                    "5.4.4.) Zdolność techniczna lub zawodowa: ",
                    "5.4.5.) Informacje dodatkowe: ",
                    "??) Informacja dla wykonawców polegających na zasobach podmiotów trzecich: ",
                    "??) Informacje o obowiązku osobistego wykonania przez wykonawcę kluczowych zadań (art. 121): ",
                    "5.5.) Zamawiający wymaga złożenia oświadczenia JEDZ, o którym mowa w art. 125 ust. 1 ustawy: ",
                    "5.6.) Wykaz podmiotowych środków dowodowych na potwierdzenie niepodlegania wykluczeniu: ",
                    "5.6.1.) Informacja z KRK: ",
                    "5.6.2.) Oświadczenie o przynależności lub braku przynależności do tej samej grupy kapitałowej: ",
                    "5.6.3.) Zaświadczenie ZUS lub KRUS: ",
                    "5.6.4.) Zaświadczenie US: ",
                    "5.6.5.) Odpis lub informacja z KRS lub CEIDG: ",
                    "5.6.6.) Oświadczenie wykonawcy o aktualności informacji zawartych w JEDZ w zakresie podstaw wykluczenia: ",
                    "5.6.7.) Inne: ",
                    "5.7.) Wykaz podmiotowych środków dowodowych na potwierdzenie spełniania warunków udziału w postępowaniu: ",
                    "5.7.1.) Wykaz dostaw: ",
                    "5.7.2.) Wykaz usług: ",
                    "5.7.3.) Wykaz osób: ",
                    "5.7.4.) Inne: ",
                    "5.8.) Wykaz przedmiotowych środków dowodowych: ",
                    "5.8.1.) Etykieta: ",
                    "5.8.2.) Certyfikat: ",
                    "5.8.3.) Inne: ",
                    "5.9.) Zamawiający przewiduje uzupełnienie przedmiotowych środków dowodowych: ",
                    "5.10.) Przedmiotowe środki dowodowe podlegające uzupełnieniu po złożeniu oferty: ",
                    "5.11.) Wykaz innych, wymaganych oświadczeń lub dokumentów: ",
                    "5.11.1.) Zobowiązanie podmiotu udostępniającego zasoby (jeżeli dotczy): ",
                    "5.11.2.) Oświadczenie wykonawców wspólnie ubiegających się o udzielenie zamówienia (jeżeli dotyczy): ",
                    "5.11.3.) Pełnomocnictwo (jeżeli dotyczy): ",
                    "5.11.4.) Potwierdzenie wniesienia wadium (jeżeli dotyczy): ",
                    "5.11.5.) Inne: ",
                    "5.11.6.) Inne: ",
                    "??) Informacje dodatkowe: ",
                ];

                let answerSectionV = [
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                ];

                let y5 = y41 + 30;

                for (let m = 0; m < formSectionV.length; m++) {
                    if (!!answerSectionV[m]) {
                        document.setFont('RobotoBold');
                        document.text(document.splitTextToSize(formSectionV[m], 390), 30, y5)
                        document.setFont('Roboto');

                        let widthForm = document.getTextWidth(formSectionV[m].toString());
                        let linesForm = widthForm / 390;

                        if (linesForm > 0 && linesForm <= 1) {
                            y5 = y5 + 15;
                            document.text(document.splitTextToSize(answerSectionV[m], 390), 40, y5)
                        } else if (linesForm > 1 && linesForm < 2) {
                            y5 = y5 + 25;
                            document.text(document.splitTextToSize(answerSectionV[m], 390), 40, y5)
                        } else if (linesForm > 2 && linesForm < 3) {
                            y5 = y5 + 35;
                            document.text(document.splitTextToSize(answerSectionV[m], 390), 40, y5)
                        } else if (linesForm > 3 && linesForm < 4) {
                            y5 = y5 + 45;
                            document.text(document.splitTextToSize(answerSectionV[m], 390), 40, y5)
                        }

                        document.setDrawColor(0, 0, 0);
                        let width = document.getTextWidth(answerSectionV[m].toString());
                        let lines = width / 390;

                        let temp = y5;
                        for (let i = 0; i < 14; i++) {
                            temp += 15;
                            if (lines > i && lines < i + 1) {
                                y5 = temp;
                                break;
                            }
                        }
                    }

                    if (y5 > 590 - document.getTextWidth(answerSectionV[formSectionV.length - 1].toString())/420 - document.getTextWidth(formSectionV[formSectionV.length - 1].toString())/420) {
                        //Footer
                        document.setFontSize(8);
                        document.line(15, 614, 430, 614)
                        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType, 425, 624, "right")
                        //Footer

                        document.setFontSize(12);
                        document.addPage();

                        //Header
                        document.setFontSize(8);
                        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                        document.line(15, 16, 430, 16)
                        //Header

                        document.setFontSize(12);
                        y5 = 40;
                    }
                }

                //section6
                document.setDrawColor(243, 240, 240);
                document.setLineWidth(16.0);
                document.line(15, y5, 430, y5);
                document.text("SEKCJA IV - PRZEDMIOT ZAMÓWIENIA", 30, y5 + 3)
                document.setLineWidth(0.1);
                document.setDrawColor(0, 0, 0);

                let formSectionVI = [
                    '6.1.) Zamawiający wymaga albo dopuszcza oferty wariantowe: ',
                    '6.2.) Oferty wariantowe wraz ze złożeniem oferty podstawowej albo zamiast oferty podstawowej: ',
                    '6.2.1.) Informacje dotyczące ofert wariantowych: ',
                    '6.2.2.) Informacje o sposobie przedstawienia ofert wariantowych: ',
                    '6.2.3.) Minimalne warunki, jakim muszą odpowiadać oferty wariantowe: ',
                    '6.4.) Zamawiający wymaga wadium: ',
                    '6.4.1.) Informacje dotyczące wadium: ',
                    '6.5.) Zamawiający wymaga zabezpieczenia należytego wykonania umowy: ',
                    '6.6.) Wymagania dotyczące składania oferty przez wykonawców wspólnie ubiegających się o udzielenie zamówienia: ',
                    '6.6.1.) Informacja na temat obowiązku osobistego wykonania przez poszczególnych wykonawców wspólnie ubiegających się o udzielenie zamówienia kluczowych zadań (art. 60): ',
                    '6.7.) Zamawiający przewiduje unieważnienie postępowania, jeśli środki publiczne, które zamierzał przeznaczyć na sfinansowanie całości lub części zamówienia nie zostały przyznane: ',
                    '??) Dodatkowe informacje: ',
                ];

                let answerSectionVI = [
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                ];

                let y6 = y5 + 30;

                for (let n = 0; n < formSectionVI.length; n++) {
                    if (!!answerSectionVI[n]) {
                        document.setFont('RobotoBold');
                        document.text(document.splitTextToSize(formSectionVI[n], 390), 30, y6)
                        document.setFont('Roboto');

                        let widthForm = document.getTextWidth(formSectionVI[n].toString());
                        let linesForm = widthForm / 390;

                        if (linesForm > 0 && linesForm <= 0.99) {
                            y6 = y6 + 15;
                            document.text(document.splitTextToSize(answerSectionVI[n], 390), 40, y6)
                        } else if (linesForm > 0.99 && linesForm < 2) {
                            y6 = y6 + 25;
                            document.text(document.splitTextToSize(answerSectionVI[n], 390), 40, y6)
                        } else if (linesForm > 2 && linesForm < 3) {
                            y6 = y6 + 35;
                            document.text(document.splitTextToSize(answerSectionVI[n], 390), 40, y6)
                        } else if (linesForm > 3 && linesForm < 4) {
                            y6 = y6 + 45;
                            document.text(document.splitTextToSize(answerSectionVI[n], 390), 40, y6)
                        }

                        document.setDrawColor(0, 0, 0);
                        let width = document.getTextWidth(answerSectionVI[n].toString());
                        let lines = width / 390;

                        let temp = y6;
                        for (let i = 0; i < 14; i++) {
                            temp += 15;
                            if (lines > i && lines < i + 1) {
                                y6 = temp;
                                break;
                            }
                        }
                    }

                    if (y6 > 590 - document.getTextWidth(answerSectionVI[formSectionVI.length - 1].toString())/420 - document.getTextWidth(formSectionVI[formSectionVI.length - 1].toString())/420) {
                        //Footer
                        document.setFontSize(8);
                        document.line(15, 614, 430, 614)
                        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType, 425, 624, "right")
                        //Footer

                        document.setFontSize(12);
                        document.addPage();

                        //Header
                        document.setFontSize(8);
                        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                        document.line(15, 16, 430, 16)
                        //Header

                        document.setFontSize(12);
                        y6 = 40;
                    }
                }

                //section7
                document.setDrawColor(243, 240, 240);
                document.setLineWidth(16.0);
                document.line(15, y6, 430, y6);
                document.text("SEKCJA VII - PROJEKTOWANIE POSTANOWIENIA UMOWY", 30, y6 + 3)
                document.setLineWidth(0.1);
                document.setDrawColor(0, 0, 0);

                let formSectionVII = [
                    '??) Warunki płatności: ',
                    '7.1.) Zamawiający przewiduje udzielenia zaliczek: ',
                    '7.2.) Informacje na temat zaliczek: ',
                    '7.2.) Zamawiający przewiduje zmiany umowy: ',
                    '7.2.) Rodzaj i zakres zmian umowy oraz warunki ich wprowadzania: ',
                    '7.2.) Zamawiający uwzględnił aspekty społeczne, środowiskowe, innowacyjne lub etykiety związanie z realizacją zamówienia: ',
                    '7.2.) Zamawiający przewiduje następujące wymagania związane z realizacją zamówienia: ',
                ];

                let answerSectionVII = [
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                    '????',
                ];

                y7 = y6 + 30;

                for (let o = 0; o < formSectionVII.length; o++) {
                    if (!!answerSectionVII[o]) {
                        document.setFont('RobotoBold');
                        document.text(document.splitTextToSize(formSectionVII[o], 390), 30, y7)
                        document.setFont('Roboto');

                        let widthForm = document.getTextWidth(formSectionVII[o].toString());
                        let linesForm = widthForm / 390;

                        if (linesForm > 0 && linesForm <= 0.99) {
                            y7 = y7 + 15;
                            document.text(document.splitTextToSize(answerSectionVII[o], 390), 40, y7)
                        } else if (linesForm > 0.99 && linesForm < 2) {
                            y7 = y7 + 25;
                            document.text(document.splitTextToSize(answerSectionVII[o], 390), 40, y7)
                        } else if (linesForm > 2 && linesForm < 3) {
                            y7 = y7 + 35;
                            document.text(document.splitTextToSize(answerSectionVII[o], 390), 40, y7)
                        } else if (linesForm > 3 && linesForm < 4) {
                            y7 = y7 + 45;
                            document.text(document.splitTextToSize(answerSectionVII[o], 390), 40, y7)
                        }

                        document.setDrawColor(0, 0, 0);
                        let width = document.getTextWidth(answerSectionVII[o].toString());
                        let lines = width / 390;

                        let temp = y7;
                        for (let i = 0; i < 14; i++) {
                            temp += 15;
                            if (lines > i && lines < i + 1) {
                                y7 = temp;
                                break;
                            }
                        }
                    }

                    if (y7 > 590 - document.getTextWidth(answerSectionVII[formSectionVII.length - 1].toString())/420 - document.getTextWidth(formSectionVII[formSectionVII.length - 1].toString())/420) {
                        //Footer
                        document.setFontSize(8);
                        document.line(15, 614, 430, 614)
                        document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                        document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType, 425, 624, "right")
                        //Footer

                        document.setFontSize(12);
                        document.addPage();

                        //Header
                        document.setFontSize(8);
                        document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                        document.line(15, 16, 430, 16)
                        //Header

                        document.setFontSize(12);
                        y7 = 40;
                    }
                }
            }

            let y8 = y7 + 30;

            //section8
            document.setDrawColor(243, 240, 240);
            document.setLineWidth(16.0);
            document.line(15, y7, 430, y7);
            document.text("SEKCJA VIII - PROCEDURA", 30, y7+3)
            document.setLineWidth(0.1);
            document.setDrawColor(0, 0, 0);

            let formSectionVIII = [
                "8.1.) Termin składania ofert: ",
                "8.2.) Miejsce składania ofert: ",
                "8.3.) Termin otwarcia ofert: ",
                "8.4.) Termin związania ofertą: ",
                "8.4.1.) Informacje na temat terminu związania ofertą: ",
                "8.9.) Termin składania wniosków o dopuszczenie do udziału w postępowaniu: ",
                "8.11.) Termin otwarcia wniosków o dopuszczenie do udziału w postępowaniu: ",
                "8.12.) Zamawiający zamierza ograniczyć liczbę wykonawców, których zaprosi do składania ofert wstępnych: ",
                "8.18.) Zamawiający przewiduje podział negocjacji na etapy w celu ograniczenia liczby ofert: ",
                "8.19.) Umowa ramowa: ",
                "8.20.) Przewiduje się ograniczenie liczby uczestników umowy ramowej: ",
                "8.21.) Maksymalna liczba wykonawców, z którymi zamawiający zawrze umowę ramową: ",
                "8.22.) Informacje o formalnościach, jakie muszą zostać dopełnione po wyborze oferty w celu zawarcia umowy w sprawie zamówienia publicznego: ",
            ];

            let answerSectionVIII = [
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
            ];


            for (let p = 0; p<formSectionVIII.length; p++){
                if(!!answerSectionVIII[p]) {
                    document.setFont('RobotoBold');
                    document.text(document.splitTextToSize(formSectionVIII[p], 390), 30, y8)
                    document.setFont('Roboto');

                    let widthForm = document.getTextWidth(formSectionVIII[p].toString());
                    let linesForm = widthForm / 390;

                    if (linesForm > 0 && linesForm <= 0.99){
                        y8 = y8 + 15;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 0.99 && linesForm < 2){
                        y8 = y8 + 25;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 2 && linesForm < 3){
                        y8 = y8 + 35;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 3 && linesForm < 4){
                        y8 = y8 + 45;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }

                    document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(answerSectionVIII[p].toString());
                    let lines = width / 390;

                    let temp = y8;
                    for (let i = 0; i < 14; i++) {
                        temp += 15;
                        if (lines > i && lines < i + 1) {
                            y8 = temp;
                            break;
                        }
                    }
                }

                if(y8 > 590 - document.getTextWidth(answerSectionVIII[formSectionVIII.length - 1].toString())/420 - document.getTextWidth(formSectionVIII[formSectionVIII.length - 1].toString())/420){
                    //Footer
                    document.setFontSize(8);
                    document.line(15,614,430, 614)
                    document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                    document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                    //Footer

                    document.setFontSize(12);
                    document.addPage();

                    //Header
                    document.setFontSize(8);
                    document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                    document.line(15,16,430, 16)
                    //Header

                    document.setFontSize(12);
                    y8=40;
                }
            }

            //section9
            document.setDrawColor(243, 240, 240);
            document.setLineWidth(16.0);
            document.line(15, y8, 430, y8);
            document.text("SEKCJA IX - POZOSTAŁE INFORMACJE", 30, y8+3)
            document.setLineWidth(0.1);
            document.setDrawColor(0, 0, 0);

            let formSectionIX = [
                "9.1.) Załączniki do SWZ zostały utworzone w formacie pdf i umieszczone na stronie internetowej prowadzonego postępowania. Dodatkowo w skompresowanym pliku zip, umieszczone zostały pliki zawierające formularze ofertowe oraz oświadczenie w wersji edytowalnej w formacie odt [Open Document Format for Office Application]. Można je poprawnie edytować np. w programie LibreOffice Writer (program ten jest bezpłatny i ogólnodostępny), oraz innych aplikacjach obsługujących format Open Document Format.: ",
                "9.2.) Środki ochrony prawnej przysługujące wykonawcy: ",
                "9.3.) Pozdrawiam Hanka: ",
            ];

            let answerSectionIX = [
                '????',
                '????',
                '????',
            ];

            let y9 = y8 + 30;

            for (let r = 0; r<formSectionIX.length; r++){
                if(!!answerSectionIX[r]) {
                    document.setFont('RobotoBold');
                    document.text(document.splitTextToSize(formSectionIX[r], 390), 30, y9)
                    document.setFont('Roboto');

                    let widthForm = document.getTextWidth(formSectionIX[r].toString());
                    let linesForm = widthForm / 390;

                    if (linesForm > 0 && linesForm <= 0.99){
                        y9 = y9 + 15;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 0.99 && linesForm < 2){
                        y9 = y9 + 25;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 2 && linesForm < 3){
                        y9 = y9 + 35;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 3 && linesForm < 4){
                        y9 = y9 + 45;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 4 && linesForm < 5){
                        y9 = y9 + 55;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 5 && linesForm < 6){
                        y9 = y9 + 65 ;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }

                    document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(answerSectionIX[r].toString());
                    let lines = width / 390;

                    let temp = y9;
                    for (let i = 0; i < 14; i++) {
                        temp += 15;
                        if (lines > i && lines < i + 1) {
                            y9 = temp;
                            break;
                        }
                    }
                }

                if(y9 > 590 - document.getTextWidth(answerSectionIX[formSectionIX.length - 1].toString())/420 - document.getTextWidth(formSectionIX[formSectionIX.length - 1].toString())/420){
                    //Footer
                    document.setFontSize(8);
                    document.line(15,614,430, 614)
                    document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                    document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                    //Footer

                    document.setFontSize(12);
                    document.addPage();

                    //Header
                    document.setFontSize(8);
                    document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                    document.line(15,16,430, 16)
                    //Header

                    document.setFontSize(12);
                    y9=40;
                }
            }
        }else{
            let y8 = y4 + 30;

            //section8
            document.setDrawColor(243, 240, 240);
            document.setLineWidth(16.0);
            document.line(15, y4, 430, y4);
            document.text("SEKCJA VIII - PROCEDURA", 30, y4+3)
            document.setLineWidth(0.1);
            document.setDrawColor(0, 0, 0);

            let formSectionVIII = [
                "8.1.) Termin składania ofert: ",
                "8.2.) Miejsce składania ofert: ",
                "8.3.) Termin otwarcia ofert: ",
                "8.4.) Termin związania ofertą: ",
                "8.4.1.) Informacje na temat terminu związania ofertą: ",
                "8.9.) Termin składania wniosków o dopuszczenie do udziału w postępowaniu: ",
                "8.11.) Termin otwarcia wniosków o dopuszczenie do udziału w postępowaniu: ",
                "8.12.) Zamawiający zamierza ograniczyć liczbę wykonawców, których zaprosi do składania ofert wstępnych: ",
                "8.18.) Zamawiający przewiduje podział negocjacji na etapy w celu ograniczenia liczby ofert: ",
                "8.19.) Umowa ramowa: ",
                "8.20.) Przewiduje się ograniczenie liczby uczestników umowy ramowej: ",
                "8.21.) Maksymalna liczba wykonawców, z którymi zamawiający zawrze umowę ramową: ",
                "8.22.) Informacje o formalnościach, jakie muszą zostać dopełnione po wyborze oferty w celu zawarcia umowy w sprawie zamówienia publicznego: ",
            ];

            let answerSectionVIII = [
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
                '????',
            ];


            for (let p = 0; p<formSectionVIII.length; p++){
                if(!!answerSectionVIII[p]) {
                    document.setFont('RobotoBold');
                    document.text(document.splitTextToSize(formSectionVIII[p], 390), 30, y8)
                    document.setFont('Roboto');

                    let widthForm = document.getTextWidth(formSectionVIII[p].toString());
                    let linesForm = widthForm / 390;

                    if (linesForm > 0 && linesForm <= 0.99){
                        y8 = y8 + 15;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 0.99 && linesForm < 2){
                        y8 = y8 + 25;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 2 && linesForm < 3){
                        y8 = y8 + 35;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }else if(linesForm > 3 && linesForm < 4){
                        y8 = y8 + 45;
                        document.text(document.splitTextToSize(answerSectionVIII[p], 390), 40, y8)
                    }

                    document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(answerSectionVIII[p].toString());
                    let lines = width / 390;

                    let temp = y8;
                    for (let i = 0; i < 14; i++) {
                        temp += 15;
                        if (lines > i && lines < i + 1) {
                            y8 = temp;
                            break;
                        }
                    }
                }

                if(y8 > 590 - document.getTextWidth(answerSectionVIII[formSectionVIII.length - 1].toString())/420 - document.getTextWidth(formSectionVIII[formSectionVIII.length - 1].toString())/420){
                    //Footer
                    document.setFontSize(8);
                    document.line(15,614,430, 614)
                    document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                    document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                    //Footer

                    document.setFontSize(12);
                    document.addPage();

                    //Header
                    document.setFontSize(8);
                    document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                    document.line(15,16,430, 16)
                    //Header

                    document.setFontSize(12);
                    y8=40;
                }
            }

            //section9
            document.setDrawColor(243, 240, 240);
            document.setLineWidth(16.0);
            document.line(15, y8, 430, y8);
            document.text("SEKCJA IX - POZOSTAŁE INFORMACJE", 30, y8+3)
            document.setLineWidth(0.1);
            document.setDrawColor(0, 0, 0);

            let formSectionIX = [
                "9.1.) Załączniki do SWZ zostały utworzone w formacie pdf i umieszczone na stronie internetowej prowadzonego postępowania. Dodatkowo w skompresowanym pliku zip, umieszczone zostały pliki zawierające formularze ofertowe oraz oświadczenie w wersji edytowalnej w formacie odt [Open Document Format for Office Application]. Można je poprawnie edytować np. w programie LibreOffice Writer (program ten jest bezpłatny i ogólnodostępny), oraz innych aplikacjach obsługujących format Open Document Format.: ",
                "9.2.) Środki ochrony prawnej przysługujące wykonawcy: ",
                "9.3.) Pozdrawiam Hanka: ",
            ];

            let answerSectionIX = [
                '????',
                '????',
                '????',
            ];

            let y9 = y8 + 30;

            for (let r = 0; r<formSectionIX.length; r++){
                if(!!answerSectionIX[r]) {
                    document.setFont('RobotoBold');
                    document.text(document.splitTextToSize(formSectionIX[r], 390), 30, y9)
                    document.setFont('Roboto');

                    let widthForm = document.getTextWidth(formSectionIX[r].toString());
                    let linesForm = widthForm / 390;

                    if (linesForm > 0 && linesForm <= 0.99){
                        y9 = y9 + 15;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 0.99 && linesForm < 2){
                        y9 = y9 + 25;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 2 && linesForm < 3){
                        y9 = y9 + 35;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 3 && linesForm < 4){
                        y9 = y9 + 45;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 4 && linesForm < 5){
                        y9 = y9 + 55;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }else if(linesForm > 5 && linesForm < 6){
                        y9 = y9 + 65;
                        document.text(document.splitTextToSize(answerSectionIX[r], 390), 40, y9)
                    }

                    document.setDrawColor(0, 0, 0);
                    let width = document.getTextWidth(answerSectionIX[r].toString());
                    let lines = width / 390;

                    let temp = y9;
                    for (let i = 0; i < 14; i++) {
                        temp += 15;
                        if (lines > i && lines < i + 1) {
                            y9 = temp;
                            break;
                        }
                    }
                }

                if(y9 > 590 - document.getTextWidth(answerSectionIX[formSectionIX.length - 1].toString())/420 - document.getTextWidth(formSectionIX[formSectionIX.length - 1].toString())/420){
                    //Footer
                    document.setFontSize(8);
                    document.line(15,614,430, 614)
                    document.text('... Biuletyn Zamówień Publicznych', 20, 624, "left")
                    document.text('Ogłoszenie o zamówieniu - podstawa prawna - ' + auctionItem.orderType,425 , 624, "right")
                    //Footer

                    document.setFontSize(12);
                    document.addPage();

                    //Header
                    document.setFontSize(8);
                    document.text('Ogłoszenie nr ... z dnia ...', 20, 10)
                    document.line(15,16,430, 16)
                    //Header

                    document.setFontSize(12);
                    y9=40;
                }
            }
        }





        document.save('document.pdf')
    }



    return(
        <>
            <GetAppIcon style={{color: '#5a13e6', cursor: 'pointer'}} onClick={pdfGenerate}/>
        </>
    )
}

export default PDFViewer;