import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import tw from "twin.macro";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {Link} from "react-router-dom";
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import {SectionHeading} from "../components/misc/Headings";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch, useSelector} from "react-redux";
import StackedSnackbar from "../components/StackedSnackbar";
import {fetchHint} from "../services/fetchId";

const Forms = tw.div`mb-5 flex justify-center `;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl mb-0!`;
const HeadingRow = tw.div`flex p-0! mb-24`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center`;
const FormButtonSubmit = tw.div`mb-5 flex justify-center`;
const FormButton = tw.div`mb-5 flex justify-center`;
const SubmitButton = styled.button`
  ${tw`w-24 p-10 mx-5 font-semibold mt-8 bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;
const SubmitFormButton = styled.button`
  ${tw`my-3 w-80 p-10 font-semibold mt-8 bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;

const PriorInfoNotice = () => {

    const styleLabel = {
        marginTop: '5%',
        marginBottom: '3%',
        fontWeight: 'bold',
        fontSize: 'x-large',
        textAlign: 'center',
        color: 'black',
    };

    const styleDate = {
        display: 'flex',
        justifyContent: 'center'
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);

    const [expansion, setExpansion] = useState(false);
    const [noticeType, setNoticeType] = useState('')
    const [addInput, setAddInput] = useState(false)
    const [date, setDate] = React.useState(new Date());
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const handleChangeType = (e) => {
        setNoticeType(e.target.value);

        if (noticeType === "type-b"){
            setAddInput(false);
        }else {
            setAddInput(true);
        }
    }

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[1].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[2].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#c83351'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    if(expansion){
        handleDimissAll();
    }


    return(
        <div onMouseOver={handleClick}>
            <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
            <SidebarLeftAccount/>
            <SidebarRightAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                <ContentWithPaddingMd>
                    <HeadingRow >
                        <Heading>Wstępne ogłoszenie informacyjne</Heading>
                    </HeadingRow>
                </ContentWithPaddingMd>

                {!expansion ?
                    (<React.Fragment>
                        <div style={styleLabel}>Czy chcesz opublikować wstępne ogłoszenie
                            w Dzienniku Urzędowym Unii Europejskiej?
                        </div>
                        <TwoColumn>
                            <FormButton>
                                <SubmitButton onClick={() => setExpansion(true)}>
                                    Tak
                                </SubmitButton>
                                <Link to="/myAccount">
                                    <SubmitButton>
                                        Nie
                                    </SubmitButton>
                                </Link>
                            </FormButton>
                        </TwoColumn>
                    </React.Fragment>) : (
                        <React.Fragment>
                            <Forms>
                                <FormControl component="fieldset3" >
                                    <FormLabel component="legend3" style={styleLabel}>Wybierz jaki charakter ma mieć wstępne ogłoszenie informacyjne.
                                    </FormLabel>
                                    <RadioGroup aria-label="notice-type" name="notice-type" value={noticeType} onChange={handleChangeType}>
                                        <FormControlLabel value="type-a" control={<Radio color="primary"/>} label="Typ A - wstępne ogłoszenie informacyjne" />
                                        <FormControlLabel value="type-b" control={<Radio color="primary"/>} label="Typ B - wstępne ogłoszenie informacyjne mające na celu skrócenie terminu składania ofert" />
                                        <FormControlLabel value="type-c" control={<Radio color="primary"/>} label="Typ C - wstępne ogłoszenie informacyjne, które jest zaproszeniem do składania ofert" />
                                    </RadioGroup>
                                </FormControl>
                            </Forms><br/>
                            <Forms>
                                <FormControl>
                                    <FormLabel style={styleLabel}>Wpisz planowaną datę rozpoczęcia (ogłoszenia) postępowania
                                        o udzielenie zamówienia</FormLabel>
                                    <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                                        <DatePicker inputVariant="outlined" format="yyyy/MM/dd" value={date} onChange={setDate}/>
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Forms>
                            {addInput ?
                                (<Forms >
                                    <FormControl>
                                        <FormLabel style={styleLabel}>Uzasadnij, dlaczego wybrałaś/eś ogłoszenie mające na
                                            celu skrócenie terminu <br/>składania ofert </FormLabel>
                                        <TextField id="outlined-basic" variant="outlined"/>
                                    </FormControl>

                                </Forms>) : ''}
                            <FormButtonSubmit>
                                <SubmitFormButton type="submit">
                                    Rozpocznij przygotowanie wstępnego ogłoszenia informacyjnego
                                </SubmitFormButton>
                            </FormButtonSubmit>

                        </React.Fragment>)}

                </AnimationRevealPage>
            </div>

        </div>
    )
}

export default PriorInfoNotice;