import React, {useEffect, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import { Link } from "react-router-dom";
import { LoginForm } from "components/LoginForm";
import { fetchLogin } from "services/fetchLogin";
import Swal from 'sweetalert2/src/sweetalert2.js';
import '@sweetalert2/theme-material-ui/material-ui.scss';
import {fetchBipClientType, fetchCustomer, fetchCustomerId, fetchId, fetchUserId} from "../services/fetchId";


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw(Link)`flex justify-center`;
const LogoTitle = tw.h2`h-12 mx-auto text-2xl xl:text-3xl font-extrabold`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

// const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
// const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const loginAlert = Swal.mixin({
    customClass: {
        // confirmButton: 'btn btn-success',
    },
    buttonsStyling: false,
});

const Login = ({
                   // logoLinkUrl = "#",
                   illustrationImageSrc = illustration,
                   headingText = "Zaloguj się do Platformy Przetargowej",
                   // submitButtonText = "Zaloguj się",
                   // SubmitButtonIcon = LoginIcon,
                   // forgotPasswordUrl = "#",
                   // signupUrl = "#",
               }) => {

    const [customer, setCustomer] = useState([]);
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data.name)
        })
    }, [])

    console.log('nazwa customera', customer.name)

    return(
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink to={'/'}>
                            <LogoTitle>ARKAD</LogoTitle>
                        </LogoLink>

                        <MainContent>
                            <Heading className="text-center">{headingText}</Heading>
                            <FormContainer>

                                <LoginForm onSubmit={(email, password) => {
                                    fetchLogin(email, password).subscribe(
                                        user => loginAlert.fire({
                                                title: 'Sukces',
                                                text: `Zalogowano pomyślnie.`,
                                                icon: 'success',
                                                timer: 1500,
                                                showConfirmButton: false,
                                            },
                                            localStorage.setItem("token", "DASZXASDDASD ASxzCAWASD"),
                                            window.location.replace('/loginLoading')
                                        )
                                        ,
                                        error => {
                                            return Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: `Błędne dane logowania!`,
                                            })
                                        },
                                        fetchId().subscribe(data => {
                                                console.log(data['hydra:member'][0])
                                        }
                                        )
                                    )
                                }}/>

                                <p tw="mt-8 text-sm text-gray-600 text-center">
                                    <Link to={'/'} tw="border-b border-gray-500 border-dotted">
                                        Zapomniałeś hasła?
                                    </Link>
                                </p>

                                <p tw="mt-8 text-sm text-gray-600 text-center">
                                    Nie posiadasz konta?{" "}
                                    <Link to={'/register'} tw="border-b border-gray-500 border-dotted">
                                        Zarejestruj się
                                    </Link>
                                </p>

                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                        <IllustrationImage imageSrc={illustrationImageSrc}/>
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>)
};

export default Login;

