import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import RegisterDataForm from "components/registerData/RegisterDataForm";
import '@sweetalert2/theme-material-ui/material-ui.scss';
import { fetchRegisterData } from "services/fetchRegisterData";
// import RegDataHeader from "../components/headers/RegDataHeader";
import SidebarRightNonReg from "../components/sidebars/SidebarRightNonReg";
import SidebarLeftNonReg from "../components/sidebars/SidebarLeftNonReg";
import Swal from "sweetalert2";


const Container = tw(ContainerBase)`min-h-screen text-white font-medium flex justify-center -mx-8 -my-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div` sm:p-12 w-auto `;
const LogoLink = tw(Link)`flex justify-center`;
const LogoTitle = tw.h2`h-12 mx-auto text-2xl xl:text-3xl font-extrabold text-primary-900`;
const MainContent = tw.div`mt-0 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-gray-800`;
const FormContainer = tw.div``;



const RegisterData = ({
                    headingText = "Zarejestruj swoje dane",
                }) => {

    // const userId = localStorage.getItem('Id');
    const userId = localStorage.getItem('userId');

    return(
        <AnimationRevealPage>
            <SidebarRightNonReg/>
            <SidebarLeftNonReg/>
            <div className="content content-left">
                <Container>
                    <Content>
                        <MainContainer>
                            <LogoLink to={'/'}>
                                <LogoTitle>ARKAD</LogoTitle>
                            </LogoLink>
                            <MainContent>
                                <Heading>{headingText}</Heading>
                                <FormContainer>
                                    <RegisterDataForm onSubmit={(data) => {
                                        fetchRegisterData(data).subscribe(
                                            Swal.fire({
                                                    title: 'Sukces',
                                                    text: `Dane Zamawiającego zostały zapisane.`,
                                                    icon: 'success',
                                                    showCancelButton: false,
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#5a13e6',
                                                    confirmButtonText: 'Rozpocznij pracę w systemie'
                                                }, console.log('dataaaa fetch:', data)
                                            ).then(okay => {
                                                if (okay) {
                                                    window.location.replace('/myAccount')}}),
                                            error => {
                                                return Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: `Wprowadzono błędne dane Zamawiającego!`,
                                                })
                                            },
                                        )
                                        }}/>
                                </FormContainer>
                            </MainContent>
                        </MainContainer>
                    </Content>
                </Container>
            </div>

        </AnimationRevealPage>)

};

export default RegisterData;
