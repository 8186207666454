export class IdService {
    constructor(networkingService) {
        this.networkingService = networkingService;
        this.userId = localStorage.getItem('userId')
        this.customerId = localStorage.getItem('customerId')
        this.auctionId = localStorage.getItem('auctionId')
    }

    getVoivodeship() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/voivodeships", headers);
    }

    getVoivodeshipId(voivodeshipId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/voivodeships/" + voivodeshipId, headers);
    }

    getCountryId(countryId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries/" + countryId, headers);
    }

    getCountry1() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=1", headers);
    }

    getCountry2() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=2", headers);
    }

    getCountry3() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=3", headers);
    }

    getCountry4() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=4", headers);
    }

    getCountry5() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=5", headers);
    }

    getCountry6() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=6", headers);
    }

    getCountry7() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=7", headers);
    }

    getCountry8() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=8", headers);
    }

    getCountry9() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/countries?page=9", headers);
    }

    getBipClientType1() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/bip_client_types?page=1", headers);
    }

    getBipClientType2() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/bip_client_types?page=2", headers);
    }

    getBipCoreBusiness() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/bip_core_businesses", headers);
    }

    getBipCoreBusinessId(bipCoreBusinessId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/bip_core_businesses/" + bipCoreBusinessId, headers);
    }

    getDuueClientType() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/duue_client_types", headers);
    }
    getDuueCoreBusiness() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/duue_core_businesses", headers);
    }
    getId() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/me", headers);
    }
    getCustomer(customerId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/customers/" + customerId, headers);
    }

    getCustomers() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/customers", headers);
    }
    getUser(userId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/users/" + userId, headers);
    }

    getCustomerId(userId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/users/" + userId, headers);
    }
    getUserId() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/me", headers);
    }

    getAuctionId() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auctions" , headers);
    }

    getAuction() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auctions/" + this.auctionId , headers);
    }

    getAuctionWithId(auctionId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auctions/" + auctionId , headers);
    }

    getAuctionItem(auctionId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auction_items/" + auctionId , headers);
    }

    getAuctionItemId() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auction_items" , headers);
    }

    getAuctionItemParts(ids) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        let result = [];
        for(let i = 0; i < ids.length; i++ ){
            result = this.networkingService.getJSON("api/auction_item_parts/" + ids[i], headers);
            return result;
        }
    }

    getAuctionItemParts1(ids) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
        return this.networkingService.getJSON("api/auction_item_parts/" + ids, headers);
    }

    getAuctionItemPartsId() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/auction_item_parts" , headers);
    }

    getUsers() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/users" , headers);
    }


    patchPassword(data) {
        const body = { ...data };
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch("api/users/" + this.userId, body, headers);
    }

    patchCustomerData(data) {
        const body = { ...data };
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch("api/customers/" + this.customerId, body, headers);
    }

    patchUserData(data) {
        const body = { ...data };
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch("api/users/" + this.userId, body, headers);
    }

    patchUser(data) {
        const {userId, ...userData} = data;
        const headers = {
            "Content-type": "application/merge-patch+json"
        };

        return this.networkingService.patch("api/users/" + userId, userData, headers);
    }

    getEuProjects() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/eu_projects" , headers);
    }

    getMe() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/me" , headers);
    }

    getEuProject(projectId) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/eu_projects/" + projectId , headers);
    }

    getHint() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/hints" , headers);
    }

    getHint2() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
        };

        return this.networkingService.getJSON("api/hints?page=2" , headers);
    }


}