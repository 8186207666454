import React, {useEffect, useMemo, useRef, useState} from "react";
import tw from "twin.macro";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import styled from "styled-components";
import {Info} from '../misc/Typography'
import {Link} from 'react-router-dom';
import {useStyles} from "../materialUI/Stepper/StepperStyles";
import {FormControl, MenuItem, TextField} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {
    fetchVoivodeship,
    fetchBipClientType,
    fetchBipCoreBusiness,
    fetchDuueClientType,
    fetchDuueCoreBusiness,
    fetchCountry1, fetchCountry6, fetchBipClientType1, fetchBipClientType2,
} from "../../services/fetchId";
import InputMask from "react-input-mask";

const SectionDesc = tw(Info)`text-center mb-5 text-lg text-primary-900 uppercase`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl`;
const Column = tw.div`lg:w-1/3`;
const ThreeColumn = tw.div`flex flex-col items-center justify-center lg:items-stretch lg:flex-row flex-wrap`;
const Form = tw.div`mb-5 mx-5 mr-3`;
const FormSelect = tw.div`mb-5 w-full flex justify-center`;
const FormButton = tw.div`mb-5 mx-5 flex justify-center`;
const SubmitButton = styled.button`
  ${tw`my-3 w-56 font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }`;
const width = {
    width: '100%',
};

const styleLabel = {
    marginBottom: '5%',
    fontWeight: 'bold',
    fontSize: 'large'
};


const RegisterDataForm = ({...props}) => {

    const [postalCode, setPostalCode] = useState();
    const [phone, setPhone] = useState();
    const [nipNumber, setNipNumber] = useState();
    const [regonNumber, setRegonNumber] = useState();
    const [nuts, setNuts] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [faxNumber, setFaxNumber] = useState();
    const [email, setEmail] = useState();
    const [url, setUrl] = useState();

    const handleChangePostalCode = (e) => {
        setPostalCode(e.target.value);
    }
    const handleChangeNipNumber = (e) => {
        setNipNumber(e.target.value);
    }
    const handleChangeRegonNumber = (e) => {
        setRegonNumber(e.target.value);
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const handleChangeUrl = (e) => {
        setUrl(e.target.value);
    }
    const handleChangeNuts = (e) => {
        setNuts(e.target.value);
    }
    const handleChangePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    }
    const handleChangeFaxNumber = (e) => {
        setFaxNumber(e.target.value);
    }

    // Do czego to jest ??!!!
        // const [addInputBZP, setAddInputBZP] = useState(false);
        // const [addInputDUUE, setAddInputDUUE] = useState(false);


    useEffect(() => {

        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'])
        })

        fetchBipClientType1().subscribe(data => {
            setBipClientType1(data['hydra:member'])
        })

        fetchBipClientType2().subscribe(data => {
            setBipClientType2(data['hydra:member'])
        })

        fetchDuueClientType().subscribe(data => {
            setDuueClientType(data['hydra:member'])
        })

        fetchBipCoreBusiness().subscribe(data => {
            setBipCoreBusiness(data['hydra:member'])

        })

        fetchDuueCoreBusiness().subscribe(data => {
            setDuueCoreBusiness(data['hydra:member'])
        })

        fetchCountry6().subscribe(data => {
           setCountry(data['hydra:member'])
        })



    }, [])



    const [duueCoreBusiness, setDuueCoreBusiness] = useState([])
    const [duueClientType, setDuueClientType] = useState([])
    const [bipClientType1, setBipClientType1] = useState([])
    const [bipClientType2, setBipClientType2] = useState([])
    const [bipCoreBusiness, setBipCoreBusiness] = useState([])
    const [voivodeship, setVoivodeship] = useState([])
    const [country, setCountry] = useState([])
    const [isFinished, setIsFinished] = useState(false);

    // console.log(country);

    const nameRef = useRef(null);
    const departmentRef = useRef(null);
    const streetRef = useRef(null);
    const houseNumberRef = useRef(null);
    const postalCodeRef = useRef(null);
    const cityRef = useRef(null);
    const voivodeshipRef = useRef(null);
    const addressCountryRef = useRef(null);
    const nutsCodeRef = useRef(null);
    const nipRef = useRef(null);
    const regonRef = useRef(null);
    const emailRef = useRef(null);
    const urlRef = useRef(null);
    const contactPersonRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const faxNumberRef = useRef(null);
    const epuapRef = useRef(null);
    const addInfoRef = useRef(null);
    const valueBZP1Ref = useRef(null);
    const valueBZP2Ref = useRef(null);
    const valueDUUE1Ref = useRef(null);
    const valueDUUE2Ref= useRef(null);

    const classes = useStyles();

    const onSubmit = () => {
        const data = {
            name: nameRef.current.value,
            department: departmentRef.current.value,
            addressStreet: streetRef.current.value,
            addressHouseNumber: houseNumberRef.current.value,
            addressZipCode: postalCodeRef.current.value,
            addressCity: cityRef.current.value,
            addressVoivodeship: voivodeshipRef.current.value,
            addressCountry:addressCountryRef.current.value,
            nutsCode: nutsCodeRef.current.value,
            nip: nipRef.current.value,
            regon: regonRef.current.value,
            email: emailRef.current.value,
            www: urlRef.current.value,
            contactPerson: contactPersonRef.current.value,
            phoneNumber: phoneNumberRef.current.value,
            faxNumber: faxNumberRef.current.value,
            epuap: epuapRef.current.value,
            additionalInformation: addInfoRef.current.value,
            bipClientType: valueBZP1Ref.current.value,
            duueClientType: valueDUUE1Ref.current.value,
            bipCoreBusiness: valueBZP2Ref.current.value,
            duueCoreBusiness: valueDUUE2Ref.current.value,
        }
        setIsFinished(true)
        // localStorage.setItem('data', '');
        props.onSubmit(data);
    };



    return (
        <>
            <ValidatorForm onSubmit={onSubmit}>
                    <Form>
                        <TextValidator
                            inputRef={nameRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Nazwa Zamawiającego"
                            variant="outlined"
                            placeholder="Nazwa Zamawiającego"
                            required
                            name="name"/>
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={departmentRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Oddział zamawiającego"
                            variant="outlined"
                            placeholder="Oddział zamawiającego"
                            name="department"/>
                    </Form>
                <Form>
                    <TextValidator
                        inputRef={nutsCodeRef}
                        className={classes.input}
                        type="textnumber"
                        id="outlined-basic-u"
                        label="kodNUTS"
                        variant="outlined"
                        placeholder="kodNUTS"
                        required
                        value={nuts}
                        onChange={handleChangeNuts}
                        validators={['matchRegexp:^PL[0-9]*$']}
                        errorMessages={['Wprowadź poprawny format: PL...']}
                        name="nutsCode"
                    />
                </Form>
                <Form>
                    <TextValidator
                        inputRef={nipRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="NIP"
                        variant="outlined"
                        placeholder="NIP"
                        required
                        name="nip"
                        value={nipNumber}
                        onChange={handleChangeNipNumber}
                        validators={['matchRegexp:^PL[0-9]*$']}
                        errorMessages={['Wprowadź poprawny numer PL(10 cyfr)']}
                    />
                </Form>
                <Form>
                    <TextValidator
                        inputRef={regonRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="REGON"
                        variant="outlined"
                        placeholder="REGON"
                        required
                        name="regon"
                        value={regonNumber}
                        onChange={handleChangeRegonNumber}
                        validators={['matchRegexp:^[0-9]{9}$']}
                        errorMessages={['Wprowadź poprawny numer (9 cyfr)']}
                    />
                </Form>
                <SectionDesc>Adres</SectionDesc>
                    <Form>
                        <TextValidator
                            inputRef={streetRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Ulica"
                            variant="outlined"
                            placeholder="Ulica"
                            required
                            name="street"
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={houseNumberRef}
                            className={classes.input}
                            width={0.5}
                            type="text"
                            id="outlined-basic-u"
                            label="Nr domu"
                            variant="outlined"
                            placeholder="Nr domu"
                            required
                            name="houseNumber"
                        />
                    </Form>
                <Form>
                    <InputMask
                        mask="99-999"
                        value={postalCode}
                        disabled={false}
                        maskChar=" "
                        onChange={handleChangePostalCode}
                    >
                        {() => <TextField inputRef={postalCodeRef}
                                          className={classes.input}
                                          type="text"
                                          id="outlined-basic-u"
                                          label="Kod pocztowy"
                                          variant="outlined"
                                          placeholder="Kod pocztowy"
                                          required
                                          name="postalCode"/>}
                    </InputMask>
                </Form>

                <Form>
                    <TextValidator
                        inputRef={cityRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="Miejscowość"
                        variant="outlined"
                        placeholder="Miejscowość"
                        required
                        name="city"
                    />
                </Form>
                <Form>
                        <TextValidator
                            inputRef={voivodeshipRef}
                            labelid="emo-simple-select-outlined-label"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Województwo"
                            variant="outlined"
                            select
                            required
                        >
                            {voivodeship.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                }
                            )}
                        </TextValidator>
                </Form>
                <Form>
                    <FormLabel component="legend4" style={styleLabel}>
                    </FormLabel>
                    <TextValidator
                        inputRef={addressCountryRef}
                        className={classes.input}
                        id="outlined-basic-u"
                        label="Kraj"
                        variant="outlined"
                        value='/api/countries/168'
                        select
                        disabled
                        >
                            {country.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                                }
                            )}
                        </ TextValidator>

                </Form>
                <SectionDesc>Dane kontaktowe</SectionDesc>
                    <Form>
                        <TextValidator
                            inputRef={emailRef}
                            className={classes.input}
                            type="email"
                            value={email}
                            onChange={handleChangeEmail}
                            id="outlined-basic-u"
                            label="Adres e-mail"
                            variant="outlined"
                            placeholder="Adres e-mail"
                            required
                            name="email"
                            validators={['matchRegexp:^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$']}
                            errorMessages={['Wprowadź poprawny adres email']}
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={urlRef}
                            className={classes.input}
                            value={url}
                            onChange={handleChangeUrl}
                            type="text"
                            id="outlined-basic-u"
                            label="Adres strony internetowej"
                            variant="outlined"
                            placeholder="Adres strony internetowej"
                            required
                            name="url"
                            validators={['matchRegexp:^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?']}
                            errorMessages={['Wprowadź poprawny adres strony internetowej: https://...']}
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={contactPersonRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Osoba do kontaktu"
                            variant="outlined"
                            placeholder="Osoba do kontaktu"
                            required
                            name="contactPerson"
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={phoneNumberRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Numer telefonu"
                            variant="outlined"
                            placeholder="Numer telefonu"
                            required
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                            validators={['matchRegexp:^[0-9]{9}$']}
                            errorMessages={['Wprowadź poprawny numer (max. 15 znaków)']}
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={faxNumberRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Numer faksu"
                            variant="outlined"
                            placeholder="Numer faksu"
                            required
                            name="faxNumber"
                            value={faxNumber}
                            onChange={handleChangeFaxNumber}
                            validators={['matchRegexp:^[0-9]{9}$']}
                            errorMessages={['Wprowadź poprawny numer (max. 15 znaków)']}
                        />
                    </Form>
                    <Form>
                        <TextValidator
                            inputRef={epuapRef}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Skrzynka ePUAP"
                            variant="outlined"
                            placeholder="Skrzynka ePUAP"
                            required
                            name="epuap"
                        />
                    </Form>
                <SectionDesc>Informacje dodatkowe</SectionDesc>
                <Form>
                    <TextValidator
                        inputRef={addInfoRef}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        label="Informacje dodatkowe"
                        variant="outlined"
                        placeholder="Informacje dodatkowe"
                        required
                        name="addInfo"
                        style={width}
                    />
                </Form>
                    <Form>
                            <FormLabel component="legend1" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Biuletynie Zamówień Publicznych
                            </FormLabel>
                            <TextValidator
                                inputRef={valueBZP1Ref}
                                labelId="emo-simple-select-outlined-label"
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz rodzaj zamawiającego"
                                select
                                variant="outlined"
                            >
                                {bipClientType1.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                                {bipClientType2.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                    </Form>
                    <Form>
                            <FormLabel component="legend2" style={styleLabel}>Wybierz rodzaj Zamawiającego do ogłoszenia w Dzienniku Urzędowym Unii Europejskiej
                            </FormLabel>
                            <TextValidator
                                inputRef={valueDUUE1Ref}
                                labelId="emo-simple-select-outlined-label"
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz rodzaj zamawiającego"
                                select
                                variant="outlined"
                            >
                                {duueClientType.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                    </Form>
                        <Form>
                            <FormLabel component="legend3" style={styleLabel}>Wybierz przedmiot działalności
                                Zamawiającego zgodnie z klasyfikacją w
                                ogłoszeniu w Biuletynie Zamówień
                                Publicznych
                            </FormLabel>
                            <TextValidator
                                inputRef={valueBZP2Ref}
                                labelId="emo-simple-select-outlined-label"
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz przedmiot działalności "
                                select
                                variant="outlined"
                            >
                                {bipCoreBusiness.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                        </Form>
                        <Form>
                            <FormLabel component="legend4" style={styleLabel}>Wybierz przedmiot działalności
                                Zamawiającego zgodnie z
                                klasyfikacją w ogłoszeniu w
                                Dzienniku Urzędowym Unii
                                Europejskiej
                            </FormLabel>
                            <TextValidator
                                inputRef={valueDUUE2Ref}
                                labelId="emo-simple-select-outlined-label"
                                id="outlined-basic-u"
                                className={classes.input}
                                label="Wybierz przedmiot działalności "
                                select
                                variant="outlined"
                            >
                                {duueCoreBusiness.map((c, index) =>{
                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                })}
                            </TextValidator>
                        </Form>
                <TwoColumn>
                    <FormButton>
                        <Link to="/registerData">
                            <SubmitButton>
                                Cofnij
                            </SubmitButton>
                        </Link>
                    </FormButton>
                    <FormButton>
                        <SubmitButton onClick={onSubmit} type="submit">
                            Zapisz
                        </SubmitButton>
                    </FormButton>
                </TwoColumn>
            </ValidatorForm>
        </>
    );
}

export default RegisterDataForm;