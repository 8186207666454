import React, {useEffect, useRef, useState} from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import {
    setDateValue,
    setValues,
    setValuesApi,
    setValuesAuction,
    setValuesAuctionParts
} from '../state/auction/auctionActions'
import '../styles/globalStyles.css'
import {useDispatch, useSelector} from "react-redux";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import plLocale from "date-fns/locale/pl";
import * as PropTypes from "prop-types";
import {MenuItem} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
    fetchAuction,
    fetchAuctionId,
    fetchAuctionItemId,
    fetchAuctionItemPartsId, fetchBipClientType1, fetchBipClientType2, fetchBipCoreBusiness, fetchCountry1,
    fetchCustomer, fetchCustomers, fetchDuueClientType, fetchDuueCoreBusiness, fetchHint, fetchId, fetchVoivodeship
} from "../services/fetchId";
import WayForm from "./WayForm";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "./StackedSnackbar";

import CustomizedTooltip from "./CustomizedTooltip";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
        marginTop: '1%'
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        // marginLeft: '1% !important', // [DH]
        fontSize: '1em',
        lineHeight: '1.4',
        //textAlign: 'justify', // [DH]
        textAlign: 'left',
        color: 'black',
        width: 'auto',
    },
    labelRadioM: {
        marginLeft: '1%',
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: 'auto',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));

const OrderForm = (props) => {


    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();
    console.log(form);

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const formAuction = useSelector(state => state.auction.formAuction);
    console.log(formAuction);

    const setValueAuction = (e) => {
        dispatch(setValuesAuction(e))
        console.log(formAuction)
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }
    const toggleBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    console.log(formAuctionParts);

    const setValueAuctionParts = (e) => {
        dispatch(setValuesAuctionParts(e))
        console.log(formAuctionParts)
    }

    const [addInput, setAddInput] = useState(false);
    const [eOfferInput, setEOfferInput] = useState(true);
    const [bud4, setBud4] = useState('');
    const [bud3, setBud3] = useState('');
    const [resOrder, setResOrder] = useState('');
    const [contract, setContract] = useState('');
    const [date, setDate] = useState();
    const [addInput2, setAddInput2] = useState(false);
    const [addInput3, setAddInput3] = useState("");
    const [addInput4, setAddInput4] = useState(false);
    const [auction, setAuction] = useState();
    const [auctionItem, setAuctionItem] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [ecommunication, setEcommunication] = useState('');
    const [ecommunicationForm, setEcommunicationForm] = useState();
    const [bud, setBud] = useState('');
    const [budForm, setBudForm] = useState();
    const [way, setWay] = useState('');
    const [wayForm, setWayForm] = useState();
    const [bud3Val, setBud3Val] = useState('');
    const [bud3Form, setBud3Form] = useState();
    const [bud4Val, setBud4Val] = useState('');
    const [bud4Form, setBud4Form] = useState();
    const [resOrder2, setResOrder2] = useState('');
    const [resOrder2Form, setResOrder2Form] = useState();
    const [contractVal, setContractVal] = useState('');
    const [contractForm, setContractForm] = useState();
    const [show, setShow] = useState(true);
    const [voivodeship, setVoivodeship] = useState([])
    const [country, setCountry] = useState([])
    const [voivodeshipForm, setVoivodeshipForm] = useState([])
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeshipIri, setVoivodeshipIri] = useState();
    const [countryId, setCountryId] = useState();
    const [countryIri, setCountryIri] = useState();
    const [countryForm, setCountryForm] = useState([])
    const [customersId, setCustomersId] = useState('');
    const userId = localStorage.getItem('userId');
    const myForm = React.useRef(null);

    useEffect(() => {
        fetchCustomers().subscribe(data => {
            setCustomersId(data['hydra:member'].filter(c => c.owner.slice(11) === userId)[0]['@id'].slice(15))
        })
    }, [])

    useEffect(() => {
        fetchVoivodeship().subscribe(data => {
            setVoivodeshipForm(data['hydra:member'])
        })

/*        fetchCountry1().subscribe(data => {
            setCountryForm(data['hydra:member'])
        })*/
        }, [customersId]
    )

    useEffect(() => {
        fetchCustomer(customersId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)) - 1);
        })

/*        fetchCustomer(customerId).subscribe(data => {
            setCountryId(parseInt(data.addressCountry.slice(15)) - 1);
        })*/

    }, [customersId])

    if(!!voivodeshipId){
        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'][voivodeshipId]['@id'])
            setVoivodeshipIri(data['hydra:member'][voivodeshipId]["@id"])
        })
    }

/*    if(!!countryId){
        fetchCountry1().subscribe(data => {
            setCountry(data['hydra:member'][countryId]['@id'])
            setCountryIri(data['hydra:member'][countryId]["@id"])
        })
    }*/


    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setAuction(data['hydra:member'].find(auction => auction['@id'].slice(14) === props.id))
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )

    }, [])

    useEffect(() => {
        if(!!auctionItem) {
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionItemParts(data['hydra:member'].find(auctionItemParts => auctionItemParts.auctionItem.slice(19) === auctionItem['@id'].slice(19)))
                }
            )
            setEcommunication(auctionItem.ecommunication.toString())
            setBud(auctionItem.bud.toString())
            setWay(auctionItem.way)
            setBud3Val(auctionItem.bud3)
            setBud4Val(auctionItem.bud4)
            setResOrder2(auctionItem.resOrder2.toString())
            setContractVal(auctionItem.contract.toString())
        }
    }, [auctionItem])

    useEffect(() => {
        setEcommunicationForm(
            <Form className={classes.label} >
                <FormControl component="fieldset2" className={classes.labelRadio}>
                    <FormLabel component="legend2" className={classes.label} value={form.ecommunication} >Określ, czy wykonawcy będą zobowiązani do składania ofert i innych dokumentów wyłącznie przy użyciu środków komunikacji elektronicznej</FormLabel>
                    <RadioGroup name="ecommunication" value={ecommunication} onChange={(e) => {setBooleanValue(e); handleOfferChange(e); setEcommunication(e.target.value)}}>
                        <FormControlLabel value="true" control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value="false" control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>)
        setBudForm(
            <Form className={classes.label} >
                <FormControl component="fieldset1" className={classes.labelRadio} value={form.bud}>
                    <FormLabel component="legend3" className={classes.label}>W przypadku zamówień na roboty budowlane lub konkursów określ,czy będziesz wymagać sporządzenia i przedstawienia ofert przy użyciu narzędzi elektronicznego modelowania danych budowlanych lub
                        innych podobnych narzędzi, które nie są ogólnie dostępne</FormLabel>
                    <RadioGroup name="bud" value={bud} onChange={(e) => {setValue(e); handleBudChange(e); setBud(e.target.value)}}>
                        <FormControlLabel  value='Tak' name="bud" control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='Nie' name="bud" control={<Radio color="primary"/>} label="Nie" />
                        <FormControlLabel value='Nie dotyczy' name="bud" control={<Radio color="primary"/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setWayForm(
            <Form className={classes.label} >
                <FormControl className={classes.labelRadio} value={form.way}>
                    <FormLabel className={classes.label}>Wskaż sposób składania oferty i JEDZ/oswiadczenia o
                        niepodleganiu wykluczeniu zgodnie z art. 65 ust. 2</FormLabel>
                    <RadioGroup name="way" value={way} onChange={(e) => {setValue(e); handleOfferChange1(e); setWay(e.target.value)}}>
                        <FormControlLabel value="Osobiście" control={<Radio color="primary"/>} label="Osobiście" />
                        <FormControlLabel value="Za pośrednictwem kuriera" control={<Radio color="primary"/>} label="Za pośrednictwem kuriera" />
                        <FormControlLabel value="Za pośrednictwem operatora pocztowego" control={<Radio color="primary"/>} label="Za pośrednictwem operatora pocztowego" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setBud3Form(
            <Form className={classes.label} >
                <FormControl component="fieldset4" className={classes.labelRadio} value={form.bud3}>
                    <FormLabel component="legend4" className={classes.label}>Informacja o sposobie złożenia oferty w postaci katalogu elektronicznego
                        <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/katalogi-elektroniczne/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a>
                    </FormLabel>
                    <RadioGroup name="bud3" value={bud3Val} onChange={(e) => {setValue(e); setBud3Val(e.target.value)}}>
                        <FormControlLabel value="Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego" name="bud3" control={<Radio color="primary"/>} label="Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego" />
                        <FormControlLabel value="Zamawiający wymaga dołączenia katalogu elektronicznego do składanej oferty" name="bud3" control={<Radio color="primary"/>} label="Zamawiający wymaga dołączenia katalogu elektronicznego do składanej oferty" />
                        <FormControlLabel value="Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty" name="bud3" control={<Radio color="primary"/>} label="Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty" />
                        <FormControlLabel value="Zamawiający nie dopuszcza składania oferty w postaci katalogu elektronicznego" name="bud3" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza składania oferty w postaci katalogu elektronicznego" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setBud4Form(
            <Form className={classes.label} >
                <FormControl component="fieldset5" className={classes.labelRadio} value={form.bud4}>
                    <FormLabel component="legend5" className={classes.label}>W przypadku umowy ramowej, wskaż, czy przewidujesz pobranie ze złożonych katalogów elektronicznych danych
                        potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia</FormLabel>
                    <RadioGroup name="bud4" value={bud4Val}  onChange={(e) => {setValue(e); handleBud4Change(e); setBud4Val(e.target.value)}}>
                        <FormControlLabel value="Tak" name="bud4" control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value="Nie" name="bud4" control={<Radio color="primary"/>} label="Nie" />
                        <FormControlLabel value="Nie dotczy" name="bud4" control={<Radio color="primary"/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setResOrder2Form(
            <Form className={classes.label} >
                <FormControl component="fieldset6" className={classes.labelRadio} value={form.resOrder2}>
                    <FormLabel component="legend6" className={classes.label}>Określ, czy o udzielenie zamówienia będą mogły ubiegać się wyłącznie zakłady pracy chronionej, spółdzielnie socjalne oraz inni wykonawcy, o których mowa w art. 94 <a target="_blank" href="https://platforma-przetargowa.pl/ustawa/artykul-94/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a></FormLabel>
                    <RadioGroup name="resOrder2" value={resOrder2}  onChange={(e) => {setBooleanValue(e); handleResChange(e); setResOrder2(e.target.value)}}>
                        <FormControlLabel value='true'  control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false'  control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
        setContractForm(
            <Form className={classes.labelRadio} >
                <FormControl className={classes.label} value={form.contract}>
                    <FormLabel className={classes.labelRadio}>W przypadku zamówień na usługi lub roboty budowlane, określ, czy przewidujesz wymagania związane z zatrudnieniem na podstawie stosunku pracy</FormLabel>
                    <RadioGroup name="contract" value={contractVal}  onChange={(e) => {setValue(e); handleContractChange(e); setContractVal(e.target.value)}}>
                        <FormControlLabel value='Tak' name="contract" control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value="Nie" name="contract" control={<Radio color="primary"/>} label="Nie" />
                        <FormControlLabel value="Nie dotyczy" name="contract" control={<Radio color="primary"/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
    }, [ecommunication, bud, way, bud3Val, bud4Val, resOrder2, contractVal])


    const classes = useStyles();

    const handleChange = (e) => {

        if (e.target.value === 'true'){
            setAddInput(true);
        }else {
            setAddInput(false);
        }
    }

    const handleBud3Change = (e) => {
        setBud3(e.target.value);
        if(e.target.value === 'Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego'){
            setAddInput3("1");
        }else if (e.target.value === 'Zamawiający wymaga dołączenia katalogu elektronicznego do składanej oferty' || e.target.value === "Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty"){
            setAddInput3("2")
        }else if (e.target.value === 'Zamawiający nie dopuszcza składania oferty w postaci katalogu elektronicznego'){
            setAddInput3("0")
        }
    }

    const handleBudChange = (e) => {
        if(e.target.value === 'Tak'){
            setAddInput2(true);
        }else{
            setAddInput2(false);
        }
    }


    const handleBud4Change = (e) => {
        setBud4(e.target.value);
        if(e.target.value === 'Tak'){
            setAddInput4(true);
        }else if(e.target.value === 'Nie'){
            setAddInput4(false);
        }else if(e.target.value === 'Nie dotyczy'){
            setAddInput4(false);
        }
    }

    const handleContractChange = (e) => {
        setContract(e.target.value);
    }

    const handleResChange = (e) => {
        setResOrder(e.target.value);
    }

    const handleOfferChange1 = (e) => {
        setResOrder(e.target.value);
    }

    const handleOfferChange = (e) => {


        if (e.target.value === 'true'){
            setEOfferInput(false);
        }else if (e.target.value === 'false') {
            setEOfferInput(true);
        }else{
            setEOfferInput(true)
        }
    }

    const customerId = localStorage.getItem('customerId');
    const [customer, setCustomer] = useState();
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    useEffect(() => {
        fetchCustomer(customerId).subscribe(
            data => {
                if(!!data){
                    console.log(data)
                    dispatch(setValuesApi(data))
                    setCustomer(data)
                }

            }
        );
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));


    const handleDimissAll = () => {
        closeSnackbar();
    };

    const submit = ()  => {
        props.handleNext(myForm);
    }

    return (
        <React.Fragment>
            <AnimationRevealPage>
                <ValidatorForm ref={myForm} onSubmit={submit}>
                    {props.edit === true && !!auction && !!auctionItem ? (
                        <>
                            <Typography className={classes.nameSec}>Dane zamawiającego</Typography>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Nazwa zamawiającego</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.nameOrdering}
                                    value={form.nameOrdering}
                                    name="nameOrdering"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Nazwa zamawiającego"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Miejscowość</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.addressCity}
                                    value={form.addressCity}
                                    name="addressCity"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Miasto"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Kraj</FormLabel>
                                <TextValidator
                                    defaultValue='Polska'
                                    /*value={form.addressCountry}*/
                                    disabled
                                    name="addressCountry"
                                    onChange={setValue}
                                    className={classes.input}
                                    id="outlined-basic-u"
                                    //label="Kraj"
                                    variant="outlined"
                                >
                   {/*                 <MenuItem value={countryIri}>{country}</MenuItem>
                                    {countryForm.map((c, index) =>{
                                            return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                                        }
                                    )}*/}

                                </TextValidator>
                            </Form>
                            <Form>
                                <FormLabel className={classes.labelRadio}>Województwo</FormLabel>
                                <SelectValidator
                                    value={form.addressVoivodeship}
                                    name="addressVoivodeship"
                                    onChange={setValue}
                                    id="outlined-basic-u"
                                    className={classes.input}
                                    //label="Województwo"
                                    select
                                    required
                                    variant="outlined"
                                    defaultValue={voivodeship}
                                >
                                    <MenuItem value={voivodeshipIri}>{voivodeship}</MenuItem>
                                    {voivodeshipForm.map((c, index) =>{
                                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                        }
                                    )}
                                </SelectValidator>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.addressZipCode}
                                    value={form.addressZipCode}
                                    name="addressZipCode"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                                    errorMessages={['Wprowadź poprawny format: xx-xxx']}
                                    id="outlined-basic-u"
                                    //label="Kod pocztowy"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.addressStreet}
                                    value={form.addressStreet}
                                    name="addressStreet"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Ulica"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer budynku</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.addressHouseNumber}
                                    value={form.addressHouseNumber}
                                    name="addressHouseNumber"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Nr domu"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer lokalu</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.addressPropertyNumber}
                                    value={form.addressPropertyNumber}
                                    name="addressHouseNumber"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Nr domu"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer Identyfikacji Podatkowej (NIP)</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.nip}
                                    value={form.nip}
                                    name="nip"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Numer NIP"
                                    variant="outlined"
                                    validators={['matchRegexp:^PL[0-9]{10}$']}
                                    errorMessages={['Wprowadź poprawny numer PL(10 cyfr)']}
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Osoba do kontaktu</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.contactPerson}
                                    value={form.contactPerson}
                                    name="contactPerson"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Pełnomocnik (jeżeli dotyczy)</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.proxy}
                                    value={form.proxy}
                                    name="proxy"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Pełnomocnik"
                                    variant="outlined"
                                    placeholder="Pełnomocnik"
                                />
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer referencyjny postępowania https://platforma-przetargowa.pl/encyclopedia/numer-referencyjny-zamowienia/</FormLabel>
                                <TextValidator
                                    defaultValue={auction.refNumber}
                                    value={formAuction.refNumber}
                                    name="refNumber"
                                    onChange={setValueAuction}
                                    className={classes.input}
                                    width={0.5}
                                    validators={['required:true']}
                                    type="text"
                                    //label="Numer referencyjny postępowania"
                                    placeholder="Numer referencyjny postępowania"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                />
                            </Form>
                            <Form className={classes.label}>
                                <FormControl
                                    className={classes.labelRadio}
                                    name="startDate"
                                    style={{display: 'flex', justifyContent: 'center', textAlign: 'left !important'}}>
                                    <FormLabel className={classes.labelRadio}>Planowana data rozpoczęcia (ogłoszenia) postępowania <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/data-rozpoczecia-postepowania/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a></FormLabel>
                                    <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
                                        <DatePicker
                                            defaultValue={auction.startDate}
                                            value={date}
                                            onChange={(e) => {setDate(e); dispatch(setDateValue(e))}}
                                            inputVariant="outlined"
                                            className={classes.input}
                                            format="yyyy/MM/dd"
                                            style={{width: '100%'}}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Form>
                            <Form className={classes.label} >
                                <FormLabel className={classes.labelRadio}>Adres email zamawiającego</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.email}
                                    value={form.email}
                                    name="email"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="email"
                                    id="outlined-basic-u"
                                    //label="Adres email"
                                    variant="outlined"
                                    placeholder="Adres email"
                                    required
                                />
                            </Form>
                            <Form>
                                <FormLabel className={classes.labelRadio}>Numer telefonu zamawiającego</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.phoneNumber}
                                    value={form.phoneNumber}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Adres strony internetowej Zamawiającego"
                                    variant="outlined"
                                    required
                                    name="www"
                                />
                            </Form>
                            <Typography className={classes.nameSec}>Sposób komunikacji z wykonawcą w trakcie postępowania</Typography>
                            <Form className={classes.label}>
                                <FormControl component="fieldset1" className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Adres strony internetowej prowadzonego postępowania,
                                        na której udostępniane będą zmiany i wyjaśnienia treści Specyfikacji Warunków Zamówienia (SWZ)
                                        oraz inne dokumenty zamówienia bezpośrednio związane z postępowaniem <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/specyfikacja-warunkow-zamowienia-swz/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                        </a>
                                    </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.urlDocuments}
                                    value={form.urlDocuments}
                                    name="urlDocuments"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Adres strony"
                                    variant="outlined"
                                    placeholder="Adres strony"
                                    required
                                />
                                </FormControl>
                            </Form>

                            <Form className={classes.label}>
                                <FormControl component="fieldset1" className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Jeżeli do złożenia oferty wymagasz użycia narzędzi, urządzeń lub formatów plików, które nie są ogólnie dostępne,
                                        wpisz adres strony internetowej, na której będą one udostępnione</FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.urlTools}
                                    value={form.urlTools}
                                    name="urlTools"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Adres strony"
                                    variant="outlined"
                                    placeholder="Adres strony"
                                    required
                                />
                                </FormControl>
                            </Form>
                            <Form className={classes.label} >
                                <FormControl component="fieldset1" className={classes.labelRadio} value={form.unable}>
                                    <FormLabel component="legend1" className={classes.label}>Jeżeli z powodu ochrony poufnego charakteru informacji zawartych w dokumentach zamówienia
                                        (w tym części SWZ/opisu potrzeb i wymagań) nie możesz ich udostępnić na stronie internetowej
                                        prowadzonego postępowania, zaznacz poniższe pole</FormLabel>
                                    <RadioGroup   name="unable" onChange={(e) => {setBooleanValue(e); handleChange(e)}}>
                                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                addInput && (
                                    <React.Fragment>
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.labelRadio}>Opisz sposób dostępu do części zamówienia zawierającej poufne informacje</FormLabel>
                                            <TextValidator
                                                value={form.access}
                                                defaultValue={auctionItem.access}
                                                name="access"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Sposób dostępu"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.labelRadio}>Wpisz wymagania związane z ochroną poufnego charakteru tych informacji</FormLabel>
                                            <TextValidator
                                                defaultValue={auctionItem.requirements}
                                                value={form.requirements}
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Wymagania"
                                                variant="outlined"
                                                required
                                                name="requirements"
                                            />
                                            </FormControl>
                                        </Form>
                                    </React.Fragment>
                                )}
                            {ecommunicationForm}

                            {
                                ecommunication === 'true' ? (
                                    <div>
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.label}>Wpisz adres poczty elektronicznej, na który wykonawca prześle ofertę
                                            </FormLabel>
                                            <TextValidator
                                                defaultValue={auctionItem.emailOffer}
                                                value={form.emailOffer}
                                                name="emailOffer"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="email"
                                                id="outlined-basic-u"
                                                //label="Adres email"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.label}>Wpisz informacje o wymaganiach technicznych i organizacyjnych
                                                sporządzania, wysyłania i odbierania korespondencji elektronicznej,
                                                w tym informacje dotyczące wymogu użycia podpisu elektronicznego
                                            </FormLabel>
                                            <TextValidator
                                                defaultValue={auctionItem.emailRequirements}
                                                value={form.emailRequirements}
                                                name="emailRequirements"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Wymagania"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                    </div>
                                ) : (   <React.Fragment>
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.label}>Wskaż dlaczego odstępujesz od wymagania użycia środków
                                                komunikacji elektronicznej – okoliczności z art 65
                                            </FormLabel>
                                            <TextValidator
                                                defaultValue={auctionItem.reason}
                                                value={form.reason}
                                                name="reason"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Wskaż powód"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                        {wayForm}
                                        <Form className={classes.label}>
                                            <FormControl className={classes.label}>
                                                <FormLabel className={classes.label}>Wskaż sposób składania oferty i JEDZ/ośw. O niepodleganiu
                                                    wykluczeniu zgodnie z art. 65 ust. 3
                                                </FormLabel>
                                                <TextField
                                                    value="wnioski o dopuszczenie do udziału w postępowaniu lub konkursie, wnioski, o których mowa w art. 371 ust. 3, oferty lub ich części składa się, pod rygorem nieważności, w formie pisemnej"
                                                    className={classes.input}
                                                    id="outlined-read-only-input"
                                                    disabled
                                                    multiline
                                                    rows={5}
                                                />
                                            </FormControl>
                                        </Form>
                                    </React.Fragment>
                                )
                            }
                            <div>
                                <Typography className={classes.nameSec}>Inny sposób składania ofert</Typography>
                                {budForm}
                                {bud === "Tak" &&
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1" className={classes.label}>
                                    <FormLabel className={classes.label}>Uzupełnij informację o sposobie dostępu do narzędzi elektronicznego
                                        modelowania danych budowlanych lub innych podobnych narzędzi
                                    </FormLabel>
                                    <TextValidator
                                        defaultValue={auctionItem.bud2}
                                        value={form.bud2}
                                        name="bud2"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Sposób dostępu"
                                        variant="outlined"
                                        required/>
                                    </FormControl>
                                </Form>
                                }
                            </div>
                            {bud3Form}
                            {bud3Val === 'Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego' && (
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1" className={classes.label}>
                                        <FormLabel className={classes.label}>Uzupełnij informację o sposobie złożenie
                                            oferty w postaci katalogu elektronicznego albo dołączenia katalogu do
                                            składanej oferty
                                        </FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.way3}
                                            value={form.way3}
                                            name="way3"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            //label="Sposób złożenia oferty"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Form>)
                            }
                            {(bud3Val === 'Zamawiający wymaga dołączenia katalogu elektronicznego do składanej' || bud3Val === 'Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty') && (
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1" className={classes.label}>
                                        <FormLabel className={classes.label}>Uzupełnij informację o sposobie dołączenia katalogu do składanej oferty
                                        </FormLabel>
                                        <TextValidator
                                            value={form.way5}
                                            name="way5"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            //label="Sposób złożenia oferty"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Form>)
                            }
                            {bud3Val === 'Zamawiający nie dopuszcza składania oferty w postaci katalogu' && (
                                <></>)
                            }
                                    {bud4Form}
                                    {bud4Val === 'Tak' && (
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.label}>Uzupełnij informację dotyczącą pobrania ze złożonych katalogów elektronicznych danych potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia
                                            </FormLabel>
                                            <TextValidator
                                                defaultValue={auctionItem.dataInfo}
                                                value={form.dataInfo}
                                                name="dataInfo"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Sposób złożenia oferty"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                    )}

                            <Typography className={classes.nameSec}>Ochrona danych osobowych</Typography>
                            <Form className={classes.labelRadio}>
                                <FormControl component="fieldset1" className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Uzupełnij obowiązkową klauzulę RODO - informacje wymagane na podstawie art. 13 ust. 1 i 3 ogólnego rozporządzenia o ochronie danych Parlamentu
                                    Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r.
                                </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.rodo}
                                    value={form.rodo}
                                    name="rodo"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Klauzula RODO"
                                    variant="outlined"
                                    required
                                />
                                </FormControl>
                            </Form>
                            <Form className={classes.labelRadio}>
                                <FormControl component="fieldset1" className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Jeżeli przewidujesz, zgodnie z art. 19, ust. 4, ograniczenia stosowania przepisów rozporządzenia Parlamentu Europejskiego nr 2016/679 z dnia 27 kwietnia 2016 r., uzupełnij
                                    informacje o tych ograniczeniach
                                </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.resOrder}
                                    value={form.resOrder}
                                    name="resOrder"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Ograniczenia"
                                    variant="outlined"
                                    required
                                />
                                </FormControl>
                            </Form>
                            <Typography className={classes.nameSec}>Język postępowania</Typography>
                            <Form className={classes.labelRadio}>
                                <FormControl component="fieldset1" className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Uzupełnij infomrację o języku postępowania
                                </FormLabel>
                                <TextValidator
                                    defaultValue={auctionItem.lang}
                                    value={form.lang}
                                    name="lang"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Języki postępowania"
                                    variant="outlined"
                                    required
                                />
                                </FormControl>
                            </Form>
                            <Form className={classes.labelRadio}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Jeżeli język postępowania jest inny niż język polski, uzupełnij informację o języku składania ofert wraz z uzasadnieniem tego wyboru
                                    </FormLabel>
                                    <TextValidator
                                        defaultValue={auctionItem.language}
                                        value={form.language}
                                        name="language"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Języki postępowania"
                                        variant="outlined"
                                        required
                                    />
                                </FormControl>
                            </Form>
                            <Typography className={classes.nameSec}>Zamówienia zastrzeżone
                                <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/zamowienia-zastrzezone/">
                                <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                </a></Typography>
                            {resOrder2Form}
                            <Typography className={classes.nameSec}>Zatrudnienie na podstawie stosunku pracy <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/zatrudnienia-na-podstawie-stosunku-pracy/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a></Typography>
                            {contractForm}
                            {
                                contractVal === 'Tak' && (
                                    <Form className={classes.labelRadio}>
                                        <FormLabel className={classes.labelRadio}>Opisz wymagania związane z zatrudnieniem na podstawie stosunku pracy
                                        </FormLabel>
                                        <TextValidator
                                            defaultValue={auctionItem.contract2}
                                            value={form.contract2}
                                            name="contract2"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            required
                                            id="outlined-basic-u"
                                            //label="Dodatkowe informacje"
                                            variant="outlined"
                                        />
                                    </Form>
                                )
                            }
                            <Typography className={classes.nameSec}>Informacje uzupełniające</Typography>
                            <Form>
                                <FormLabel className={classes.label}>Informacje uzupełniające</FormLabel>
                                <FormControl component="fieldset1" className={classes.label}>
                                <TextValidator
                                    defaultValue={auctionItem.addInfo}
                                    value={form.addInfo}
                                    name="addInfo"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    required
                                    id="outlined-basic-u"
                                    //label="Dodatkowe informacje"
                                    variant="outlined"
                                />
                                </FormControl>
                            </Form>
                        </>) : (
                        <>
                            <Typography className={classes.nameSec}>Dane zamawiającego</Typography>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Nazwa zamawiającego</FormLabel>
                                <TextValidator
                                    value={form.nameOrdering}
                                    name="nameOrdering"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Kraj</FormLabel>
                                <TextValidator
                                    defaultValue='Polska'
                                    /*value={form.addressCountry}*/
                                    disabled
                                    name="addressCountry"
                                    onChange={setValue}
                                    className={classes.input}
                                    id="outlined-basic-u"
                                    variant="outlined"
                                >
                                    {/*                 <MenuItem value={countryIri}>{country}</MenuItem>
                                    {countryForm.map((c, index) =>{
                                            return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                                        }
                                    )}*/}

                                </TextValidator>
                            </Form>
                            <Form>
                                <FormLabel className={classes.labelRadio}>Województwo</FormLabel>
                                <SelectValidator
                                    value={form.addressVoivodeship}
                                    name="addressVoivodeship"
                                    onChange={setValue}
                                    id="outlined-basic-u"
                                    className={classes.input}
                                    select
                                    required
                                    variant="outlined"
                                    defaultValue={voivodeship}
                                >
                                    <MenuItem value={voivodeshipIri}>{voivodeship}</MenuItem>
                                    {voivodeshipForm.map((c, index) =>{
                                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                        }
                                    )}
                                </SelectValidator>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Miejscowość</FormLabel>
                                <TextValidator
                                    value={form.addressCity}
                                    name="addressCity"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                                <TextValidator
                                    value={form.addressZipCode}
                                    name="addressZipCode"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                                    errorMessages={['Wprowadź poprawny format: xx-xxx']}
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                                <TextValidator
                                    value={form.addressStreet}
                                    name="addressStreet"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer budynku</FormLabel>
                                <TextValidator
                                    value={form.addressHouseNumber}
                                    name="addressHouseNumber"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer lokalu</FormLabel>
                                <TextValidator
                                    value={form.addressPropertyNumber}
                                    name="addressPropertyNumber"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer Identyfikacji Podatkowej (NIP)</FormLabel>
                                <TextValidator
                                    value={form.nip}
                                    name="nip"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    validators={['matchRegexp:^PL[0-9]{10}$']}
                                    errorMessages={['Wprowadź poprawny numer PL(10 cyfr)']}
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Osoba do kontaktu</FormLabel>
                                <TextValidator
                                    value={form.contactPerson}
                                    name="contactPerson"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required/>
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Pełnomocnik (jeżeli dotyczy)</FormLabel>
                                <TextValidator
                                    value={form.proxy}
                                    name="proxy"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Pełnomocnik"
                                    variant="outlined"
                                    // placeholder="Pełnomocnik"
                                />
                            </Form>
                            <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer referencyjny postępowania 
                                 <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/numer-referencyjny-zamowienia/">
                                 <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a></FormLabel>
                                <TextValidator
                                    value={formAuction.refNumber}
                                    name="refNumber"
                                    onChange={setValueAuction}
                                    className={classes.input}
                                    width={0.5}
                                    type="text"
                                    //label="Numer referencyjny postępowania"
                                    // placeholder="Numer referencyjny postępowania"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                />
                            </Form>
                            <Form className={classes.label}>
                                <FormControl
                                    // className={classes.labelRadio} // [DH]
                                    name="startDate"
                                    style={{display: 'flex', justifyContent: 'center', textAlign: 'left !important'}}>
                                    <FormLabel className={classes.labelRadio}>Planowana data rozpoczęcia (ogłoszenia) postępowania <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/data-rozpoczecia-postepowania/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a></FormLabel>
                                    <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
                                        <DatePicker
                                            value={date}
                                            onChange={(e) => {setDate(e); dispatch(setDateValue(e))}}
                                            inputVariant="outlined"
                                            className={classes.input}
                                            format="yyyy/MM/dd"
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Form>
                            <Form className={classes.label} >
                                <FormLabel className={classes.labelRadio}>Adres email zamawiającego</FormLabel>
                                <TextValidator
                                    value={form.email}
                                    name="email"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="email"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                />
                            </Form>
                            <Form>
                                <FormLabel className={classes.labelRadio}>Numer telefonu zamawiającego</FormLabel>
                                <TextValidator
                                    value={form.phoneNumber}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    required
                                    name="www"
                                />
                            </Form>
                            <Typography className={classes.nameSec}>Sposób komunikacji z wykonawcą w trakcie postępowania</Typography>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Adres strony internetowej prowadzonego postępowania,
                                        na której udostępniane będą zmiany i wyjaśnienia treści Specyfikacji Warunków Zamówienia (SWZ)
                                        oraz inne dokumenty zamówienia bezpośrednio związane z postępowaniem
                                        <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/specyfikacja-warunkow-zamowienia-swz/">
                                        <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                        </a>
                                    </FormLabel>
                                    <TextValidator
                                        value={form.urlDocuments}
                                        name="urlDocuments"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Adres strony"
                                        variant="outlined"
                                        placeholder="Adres strony"
                                        required
                                        validators={['matchRegexp:^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?']}
                                        errorMessages={['Wprowadź poprawny adres strony internetowej: https://...']}
                                    />
                            </Form>
                            <Form className={classes.labelRadio}>
                                <FormControl component="fieldset1" className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Jeżeli do złożenia oferty wymagasz użycia narzędzi, urządzeń lub formatów plików, które nie są ogólnie dostępne,
                                        wpisz adres strony internetowej, na której będą one udostępnione</FormLabel>
                                    <TextValidator
                                        value={form.urlTools}
                                        name="urlTools"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Adres strony"
                                        variant="outlined"
                                        placeholder="Adres strony"
                                        required
                                        validators={['matchRegexp:^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?']}
                                        errorMessages={['Wprowadź poprawny adres strony internetowej: https://...']}
                                    />
                                </FormControl>
                            </Form>
                            <Form className={classes.label} >
                                <FormControl component="fieldset1" value={form.unable}>
                                    <FormLabel component="legend1" className={classes.labelRadio}>Jeżeli z powodu ochrony poufnego charakteru informacji zawartych w dokumentach zamówienia
                                        (w tym części SWZ/opisu potrzeb i wymagań) nie możesz ich udostępnić na stronie internetowej
                                        prowadzonego postępowania, zaznacz poniższe pole:</FormLabel>
                                    <RadioGroup  name="unable"  className={classes.input} onChange={(e) => {setBooleanValue(e); handleChange(e)}}>
                                        <FormControlLabel value='true'  control={<Radio color="primary"/>} label="" />
                                    </RadioGroup>
                                    {/* <FormGroup  name="unable"  className={classes.input}>
                                        <FormControlLabel value='true'  control={<Checkbox color="primary" onChange={(e) => {toggleBooleanValue(e); handleChange(e)}}/>} label="aaa" />
                                    </FormGroup> */}
                                </FormControl>
                            </Form>
                            {
                                addInput && (
                                    <React.Fragment>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.labelRadio}>Opisz sposób dostępu do części zamówienia zawierającej poufne informacje</FormLabel>
                                            <TextValidator
                                                value={form.access}
                                                name="access"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Sposób dostępu"
                                                variant="outlined"
                                                required
                                            />
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.labelRadio}>Wpisz wymagania związane z ochroną poufnego charakteru tych informacji</FormLabel>
                                            <TextValidator
                                                value={form.requirements}
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Wymagania"
                                                variant="outlined"
                                                required
                                                name="requirements"
                                            />
                                        </Form>
                                    </React.Fragment>
                                )}
                            <WayForm id={props.id} open={true}/>
                            <div onMouseOver={handleDimissAll}>
                                <Typography className={classes.nameSec}>Inny sposób składania ofert</Typography>
                                <Form className={classes.label} >
                                    <FormControl component="fieldset1" value={form.bud}>
                                        <FormLabel component="legend3" className={classes.label}>W przypadku zamówień na roboty budowlane lub konkursów określ, czy będziesz wymagać sporządzenia i przedstawienia ofert przy użyciu narzędzi elektronicznego modelowania danych budowlanych lub
                                            innych podobnych narzędzi, które nie są ogólnie dostępne</FormLabel>
                                        <RadioGroup name="bud" className={classes.input} onChange={(e) => {setValue(e); handleBudChange(e)}}>
                                            <FormControlLabel  value='Tak' name="bud" control={<Radio required color="primary"/>} label="Tak" />
                                            <FormControlLabel value='Nie' name="bud" control={<Radio color="primary"/>} label="Nie" />
                                            <FormControlLabel value='Nie dotyczy' name="bud" control={<Radio color="primary"/>} label="Nie dotyczy" />
                                        </RadioGroup>
                                    </FormControl>
                                </Form>
                                {addInput2 &&
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1">
                                    <FormLabel className={classes.label}>Uzupełnij informację o sposobie dostępu do narzędzi elektronicznego
                                        modelowania danych budowlanych lub innych podobnych narzędzi
                                    </FormLabel>
                                    <TextValidator
                                        value={form.bud2}
                                        name="bud2"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Sposób dostępu"
                                        variant="outlined"
                                        required
                                    />
                                    </FormControl>
                                </Form>
                                }
                            </div>
                            <Form className={classes.label} >
                                <FormControl component="fieldset4" value={form.bud3}>
                                    <FormLabel component="legend4" className={classes.label}>Informacja o sposobie złożenia oferty w postaci katalogu elektronicznego
                                        <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/katalogi-elektroniczne/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a>
                                    </FormLabel>
                                    <RadioGroup name="bud3" className={classes.input} onChange={(e) => {setValue(e); handleBud3Change(e)}}>
                                        <FormControlLabel value="Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego" name="bud3" control={<Radio color="primary" required/>} label="Zamawiający wymaga złożenia oferty w postaci katalogu elektronicznego" />
                                        <FormControlLabel value="Zamawiający wymaga dołączenia katalogu elektronicznego do składanej oferty" name="bud3" control={<Radio color="primary"/>} label="Zamawiający wymaga dołączenia katalogu elektronicznego do składanej oferty" />
                                        <FormControlLabel value="Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty" name="bud3" control={<Radio color="primary"/>} label="Zamawiający dopuszcza dołączenie katalogu elektronicznego do składanej oferty" />
                                        <FormControlLabel value="Zamawiający nie dopuszcza składania oferty w postaci katalogu elektronicznego" name="bud3" control={<Radio color="primary"/>} label="Zamawiający nie dopuszcza składania oferty w postaci katalogu elektronicznego" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {addInput3 === "1" && (
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1" className={classes.label}>
                                        <FormLabel className={classes.label}>Uzupełnij informację o sposobie złożenie
                                            oferty w postaci katalogu elektronicznego albo dołączenia katalogu do
                                            składanej oferty
                                        </FormLabel>
                                        <TextValidator
                                            value={form.way3}
                                            name="way3"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            //label="Sposób złożenia oferty"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Form>)
                            }
                            {addInput3 === "2" && (
                                <Form className={classes.label}>
                                    <FormControl component="fieldset1" className={classes.label}>
                                        <FormLabel className={classes.label}>Uzupełnij informację o sposobie dołączenia katalogu do składanej oferty
                                        </FormLabel>
                                        <TextValidator
                                            value={form.way5}
                                            name="way5"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            //label="Sposób złożenia oferty"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Form>)
                            }
                            {addInput3 === "0" && (
                                <></>
                            )
                            }
                                    <Form className={classes.label} >
                                        <FormControl component="fieldset5" value={form.bud4}>
                                            <FormLabel component="legend5" className={classes.label}>W przypadku umowy ramowej, wskaż, czy przewidujesz pobranie ze złożonych katalogów elektronicznych danych
                                                potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia</FormLabel>
                                            <RadioGroup name="bud4"  className={classes.input} onChange={(e) => {setValue(e); handleBud4Change(e)}}>
                                                <FormControlLabel value="Tak" name="bud4" control={<Radio required color="primary"/>} label="Tak" />
                                                <FormControlLabel value="Nie" name="bud4" control={<Radio color="primary"/>} label="Nie" />
                                                <FormControlLabel value="Nie dotyczy" name="bud4" control={<Radio color="primary"/>} label="Nie dotyczy" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Form>
                                    {addInput4 && (
                                        <Form className={classes.label}>
                                            <FormControl component="fieldset1" className={classes.label}>
                                            <FormLabel className={classes.label}>Uzupełnij informację dotyczącą pobrania ze złożonych katalogów elektronicznych danych potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia
                                            </FormLabel>
                                            <TextValidator
                                                value={form.dataInfo}
                                                name="dataInfo"
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                //label="Sposób złożenia oferty"
                                                variant="outlined"
                                                required
                                            />
                                            </FormControl>
                                        </Form>
                                    )}


                            <Typography className={classes.nameSec}>Ochrona danych osobowych</Typography>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Uzupełnij obowiązkową klauzulę RODO - informacje wymagane na podstawie art. 13 ust. 1 i 3 ogólnego rozporządzenia o ochronie danych Parlamentu
                                        Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r.
                                    </FormLabel>
                                    <TextValidator
                                        defaultValue={'tekst'}
                                        value={form.rodo}
                                        name="rodo"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Klauzula RODO"
                                        variant="outlined"
                                        required
                                    />
                                </FormControl>

                            </Form>
                            <Form className={classes.labelRadio}>
                                <FormControl className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Jeżeli przewidujesz, zgodnie z art. 19, ust. 4, ograniczenia stosowania przepisów rozporządzenia Parlamentu Europejskiego nr 2016/679 z dnia 27 kwietnia 2016 r., uzupełnij
                                    informacje o tych ograniczeniach
                                </FormLabel>
                                <TextValidator
                                    defaultValue={'tekst'}
                                    value={form.resOrder}
                                    name="resOrder"
                                    onChange={setValue}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    //label="Ograniczenia"
                                    variant="outlined"
                                    required
                                />
                                </FormControl>
                            </Form>
                            <Typography className={classes.nameSec}>Język postępowania</Typography>
                                <Form className={classes.labelRadio}>
                                    <FormLabel className={classes.labelRadio}>Uzupełnij informację o języku postępowania
                                    </FormLabel>
                                    <TextValidator
                                        defaultValue={"Postępowanie prowadzone jest w języku polskim"}
                                        value={form.lang}
                                        name="lang"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        //label="Języki postępowania"
                                        variant="outlined"
                                        required
                                    />
                                </Form>
                                <Form className={classes.labelRadio}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.labelRadio}>Jeżeli język postępowania jest inny niż język polski, uzupełnij informację o języku składania ofert wraz z uzasadnieniem tego wyboru
                                        </FormLabel>
                                        <TextValidator
                                            value={form.language}
                                            name="language"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            //label="Języki postępowania"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Form>

                            <Typography className={classes.nameSec}>Zamówienia zastrzeżone
                                <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/zamowienia-zastrzezone/">
                                <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                </a></Typography>
                            <Form className={classes.label} >
                                <FormControl component="fieldset6" value={form.resOrder2}>
                                    <FormLabel component="legend6" className={classes.label}>Określ, czy o udzielenie zamówienia będą mogły ubiegać się wyłącznie zakłady pracy chronionej,
                                        spółdzielnie socjalne oraz inni wykonawcy, o których mowa w art. 94 <a target="_blank" href="https://platforma-przetargowa.pl/ustawa/artykul-94/"><CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" /></a>
                                    </FormLabel>
                                    <RadioGroup name="resOrder2" className={classes.input} onChange={(e) => {setBooleanValue(e); handleResChange(e)}}>
                                        <FormControlLabel value='true' name='resOrder2'  control={<Radio required color="primary"/>} label="Tak" />
                                        <FormControlLabel value='false' name='resOrder2'  control={<Radio color="primary"/>} label="Nie" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            <Typography className={classes.nameSec}>Zatrudnienie na podstawie stosunku pracy  
                                <a target="_blank" href="https://platforma-przetargowa.pl/encyclopedia/zatrudnienia-na-podstawie-stosunku-pracy/">
                                <CustomizedTooltip content="Skorzystaj ze Słownika pojęć" placement="top" type="info" />
                                </a></Typography>
                            <Form className={classes.labelRadio} >
                                <FormControl value={form.contract}>
                                    <FormLabel className={classes.label}>W przypadku zamówień na usługi lub roboty budowlane, określ, czy przewidujesz wymagania związane z zatrudnieniem na podstawie stosunku pracy</FormLabel>
                                    <RadioGroup name="contract" className={classes.input} required onChange={(e) => {setValue(e); handleContractChange(e)}}>
                                        <FormControlLabel value='Tak'  name="contract" control={<Radio color="primary"/>} label="Tak" />
                                        <FormControlLabel value='Nie'  name="contract" control={<Radio color="primary"/>} label="Nie" />
                                        <FormControlLabel value="Nie dotyczy" name="contract" control={<Radio color="primary"/>} label="Nie dotyczy" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                contract === 'Tak' && (
                                    <Form className={classes.labelRadio}>
                                        <FormLabel className={classes.labelRadio}>Opisz wymagania związane z zatrudnieniem na podstawie stosunku pracy
                                        </FormLabel>
                                        <TextValidator
                                            value={form.contract2}
                                            name="contract2"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            required
                                            id="outlined-basic-u"
                                            //label="Dodatkowe informacje"
                                            variant="outlined"
                                        />
                                    </Form>
                                )
                            }
                            <Typography className={classes.nameSec}>Informacje uzupełniające</Typography>
                            <Form className={classes.labelRadio}>
                                    <FormLabel className={classes.labelRadio}>Informacje uzupełniające
                                    </FormLabel>
                                    <TextValidator
                                        value={form.addInfo}
                                        name="addInfo"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        required
                                        id="outlined-basic-u"
                                        //label="Dodatkowe informacje"
                                        variant="outlined"
                                    />
                            </Form>
                        </>)
                }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {props.handleNext(myForm); handleDimissAll()}}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </React.Fragment>

    )
}

export default OrderForm;