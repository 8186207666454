import { dependencies } from "dependencies/Dependeencies";

import React from "react";


export function fetchLogin(email, password) {

    const { loginService } = dependencies;

    return loginService.login(email, password);
}



