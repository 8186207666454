import React, {useEffect} from "react";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import tw from "twin.macro";
import {SectionHeading} from "../components/misc/Headings";
import {
    fetchAuction,
    fetchAuctionId,
    fetchCustomer, fetchCustomers,
    fetchUserId,
    fetchUsers,
    patchUser,
    patchUser1
} from "../services/fetchId";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import EditUsersData from "../components/EditUsersData";
import Swal from "sweetalert2";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import EditUserDataAbonament from "../components/EditUserDataAbonament";
import EditUserDataStatus from "../components/EditUserDataStatus";
import {patchCustomer} from "../services/patchCustomer";

const HeadingRow = tw.div`flex p-0! mb-24`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;

function createData(idx, name, email, edit, userId, subscription, active, customerId) {
    return {idx, name, email, edit, userId, subscription, active, customerId};
}



function descendingComparator(a, b, orderBy) {
    if (a[orderBy] < b[orderBy]) {
        return -1;
    }
    if (a[orderBy] > b[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'idx', numeric: false, disablePadding: false, label: 'Lp.' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Nazwa Użytkownika' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Adres email Użytkownika' },
    { id: 'edit', numeric: true, disablePadding: false, label: 'Edycja danych użytkownika' },
    { id: 'subscription', numeric: true, disablePadding: false, label: 'Abonament' },
    { id: 'active', numeric: true, disablePadding: false, label: 'Status Użytkownika' },
];

function EnhancedTableHead(props) {



    useEffect(() => {
        fetchAuctionId().subscribe(data => {
            console.log(data['hydra:member'][0]['@id'].slice(14));
        })
    }, [])

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key={headCells[0].id}
                    align='center'
                    padding={headCells[0].disablePadding ? 'none' : 'default'}
                >
                    <TableSortLabel
                        style={{textAlign: 'center'}}
                        active={true}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}>
                        {headCells[0].label}
                        {orderBy === headCells[0].id ? (
                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key={headCells[1].id}
                    align='center'
                    padding={headCells[1].disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCells[1].id ? order : false}
                >
                    <TableSortLabel
                        style={{textAlign: 'center'}}
                        active={true}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}>
                        {headCells[1].label}
                        {orderBy === headCells[1].id ? (
                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key={headCells[2].id}
                    align='center'
                    padding={headCells[2].disablePadding ? 'none' : 'default'}
                >Adres email Użytkownika
                </TableCell>
                <TableCell
                    key={headCells[3].id}
                    align='center'
                    padding={headCells[3].disablePadding ? 'none' : 'default'}
                >Edycja danych Użytkownika
                </TableCell>
                <TableCell
                    key={headCells[4].id}
                    align='center'
                    padding={headCells[4].disablePadding ? 'none' : 'default'}
                >Abonament
                </TableCell>
                <TableCell
                    key={headCells[5].id}
                    align='center'
                    padding={headCells[5].disablePadding ? 'none' : 'default'}
                >Status Użytkownika
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontFamily: 'inherit',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Użytkownicy
                </Typography>
            )}

        </Toolbar>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontFamily: 'inherit !important',
        textAlign: 'center'
    },
    paper: {
        width: '100%',
        fontFamily: 'inherit !important',
        textAlign: 'center'
    },
    table: {
        fontFamily: 'inherit !important',
        minWidth: 500,
        textAlign: 'center',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const Users = () => {

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClickOpen1 = () => {
        setOpen1(true);
    }
    const handleClickOpen2 = () => {
        setOpen2(true);
    }


    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [procedureType, setProcedureType] = React.useState();
    const [rows, setRows] = React.useState([]);
    const [edit, setEdit] = React.useState(true);
    const [index, setIndex] = React.useState();
    const [subs, setSubs] = React.useState();
    const [reloadSubs, setReloadSubs] = React.useState(true);


    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        if(reloadSubs){
            fetchCustomers().subscribe(
                data1 => {
                    setSubs(data1['hydra:member'])
                }
            )
            setReloadSubs(false)
        }
    }, [reloadSubs])

    useEffect(() => {
        if(!!subs){
            fetchUsers().subscribe(
                data => {
                    setRows(
                        data['hydra:member'].map((user, idx) => {
                                let subscription = false;
                                if(!!user.customer){
                                    subscription = subs.find(sub => sub.owner === user["@id"]).isSubscription
                                }
                                return createData(idx + 1, user.username, user.email, '', user["@id"].slice(11), subscription, user.isEnabled, user.customer)
                            }

                        )
                    )
                }
            )
        }


    }, [subs])

    console.log(rows)


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        fetchAuction().subscribe(data => {
            setProcedureType(data.procedureType);
        })
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <>
            <SidebarLeftAccount/>
            <SidebarRightAccount/>
            <AnimationRevealPage>
                <div className="content content-left">
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Użytkownicy</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <div>
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                console.log(row)
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.name}
                                                    >
                                                        <TableCell align='center' component="th" id={labelId} scope="row" padding="none">
                                                            {row.idx}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" id={labelId} scope="row" padding="none">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" id={labelId} scope="row" padding="none">
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.edit}
                                                            <EditRoundedIcon
                                                                style={{color: '#5a13e6', cursor: 'pointer'}}
                                                                onClick={() => {handleClickOpen(); setIndex(index)}}/>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.subscription
                                                                ?   <CheckIcon onClick={() => {handleClickOpen1();setIndex(index)}} style={{color: 'green', cursor: 'pointer'}}/>
                                                                :
                                                                !!row.customerId
                                                                    ? <BlockIcon style={{color: 'gray', cursor: 'pointer'}}  onClick={() => {if (!!row.customerId){handleClickOpen1(); setIndex(index)}}}/>
                                                                    : ''
                                                            }

                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.active
                                                                ? <CheckIcon onClick={() => {handleClickOpen2();setIndex(index)}} style={{color: 'green', cursor: 'pointer'}}/>
                                                                : <BlockIcon onClick={() => {handleClickOpen2();setIndex(index)}} style={{color: 'gray', cursor: 'pointer'}}/>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage = 'Ilość wierszy na stronie:'
                            />
                        </Paper>
                    </div>

                    {edit &&
                    <>
                        <EditUsersData
                            open={open}
                            setOpen={setOpen}
                            user={rows[index]}
                            onSubmit={(data) => {
                                patchUser1(data).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Dane zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Kontynuuj'
                                        },
                                    ).then(okay => {}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane!`,
                                        })
                                    },
                                )
                            }}
                        />
                        <EditUserDataAbonament
                            open={open1}
                            setOpen={setOpen1}
                            user={rows[index]}
                            onSubmit={(data) => {
                                console.log(data)
                                patchCustomer(data).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Dane zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Kontynuuj'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                            setReloadSubs(true)}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane!`,
                                        })
                                    },
                                )
                            }}/>
                        <EditUserDataStatus
                            open={open2}
                            setOpen={setOpen2}
                            user={rows[index]}
                            onSubmit={(data) => {
                                patchUser1(data).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Dane zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Kontynuuj'
                                        },
                                    ).then(okay => {setReloadSubs(true)}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane!`,
                                        })
                                    },
                                )
                            }}/>
                    </>
                    }
                </div>
            </AnimationRevealPage>

        </>
    );
}

export default Users;