import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {
    fetchBipClientType,
    fetchBipCoreBusiness, fetchCountry1,
    fetchCustomer,
    fetchDuueClientType,
    fetchDuueCoreBusiness, fetchVoivodeship
} from "../../services/fetchId";
import {useSelector} from "react-redux";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const OrderContactData = ({id}) => {

    const [customer, setCustomer] = useState([]);
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [country, setCountry] = useState();
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setCountryId(parseInt(data.addressCountry.slice(15)) - 1);
        })


    }, [])


    if(!!voivodeshipId){
        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'][voivodeshipId].name)
        })
    }

    if(!!countryId){
        fetchCountry1().subscribe(data => {
            setCountry(data['hydra:member'][countryId].englishShortName)
        })
    }

    const classes = useStyles();

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    return(
        <AnimationRevealPage>
            <ValidatorForm >
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Nazwa zamawiającego</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={customer.name}
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Ulica</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.addressStreet}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Nr domu</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.addressHouseNumber}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.addressZipCode}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Miejscowość</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.addressCity}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Województwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={voivodeship}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Państwo</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={country}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Kod NUTS </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.nutsCode}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>NIP</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.nip}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>REGON</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.regon}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Numer telefonu</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.phoneNumber}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Numer faksu</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.faxNumber}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres e-mail</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.email}
                            name="proxy"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Adres strony internetowej</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={customer.www}
                            name="proxy"
                        />
                </Form>
            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default OrderContactData