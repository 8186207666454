import React, {useState, useEffect} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SectionHeading} from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import NegotiationPartI from "../components/OrderSpecificationPartI/NegotiationPartI";
import OrderDescriptionPartI from "../components/OrderSpecificationPartI/OrderDescriptionPartI";
import AdditionalRequirementsPartI from "../components/OrderSpecificationPartI/AdditionalRequirementsPartI";
import ConditionsPartI from "../components/OrderSpecificationPartI/ConditionsPartI";
import LocalVisionPartI from "../components/OrderSpecificationPartI/LocalVisionPartI";
import SubjectiveMeansPartI from "../components/OrderSpecificationPartI/SubjectiveMeansPartI";
import OptionsPartI from "../components/OrderSpecificationPartI/OptionsPartI";
import ExecutionPartI from "../components/OrderSpecificationPartI/ExecutionPartI";
import EmploymentPartI from "../components/OrderSpecificationPartI/EmploymentPartI";
import Employment96PartI from "../components/OrderSpecificationPartI/Employment96PartI";
import Contractors94PartI from "../components/OrderSpecificationPartI/Contractors94PartI";
import DeadlinePartI from "../components/OrderSpecificationPartI/DeadlinePartI";
import ProvisionsPartI from "../components/OrderSpecificationPartI/ProvisionsPartI";
import WadiumPartI from "../components/OrderSpecificationPartI/WadiumPartI";
import AuctionPartI from "../components/OrderSpecificationPartI/AuctionPartI";
import PricingPartI from "../components/OrderSpecificationPartI/PricingPartI";
import EvaluationPartI from "../components/OrderSpecificationPartI/EvaluationPartI";
import ProtectionPartI from "../components/OrderSpecificationPartI/ProtectionPartI";
import FrameworkAgreementPartI from "../components/OrderSpecificationPartI/FrameworkAgreementPartI";
import {fetchAuctionItemId, fetchAuctionItemParts1, fetchAuctionWithId} from "../services/fetchId";
import {useSelector} from "react-redux";
import SummmaryOrderSpecificationPart from "../components/OrderSpecificationPartI/SummmaryOrderSpecificationPart";
import InitialOpFinished from "../components/InitialOpFinished";
import {patchInitialOpAuction} from "../services/patchInitialOpAuction";
import Swal from "sweetalert2";
import {patchInitialOp} from "../services/patchInitialOp";
import ReverseProcedurePartI from "../components/OrderSpecificationPartI/ReverseProcedurePartI";

const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row'
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },

}));

const OrderSpecificationParts = (props) => {

    const classes = useStyles();
    const {params} = props.match;
    const [auctionItem, setAuctionItem] = useState('');
    const [auctionItemPartsId, setAuctionItemPartsId] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [path, setPath] = useState('');
    const form = useSelector(state => state.auction.form);

    console.log(form);

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === params.id)[0]);
            }
        )
    }, [])

    useEffect(() => {
        if(!!auctionItem){
            setAuctionItemPartsId((auctionItem.auctionItemParts[parseInt(params.part)]).slice(24));
        }
        fetchAuctionItemParts1(auctionItemPartsId).subscribe(
            data => setAuctionItemParts(data)
        )
    }, [auctionItem, auctionItemPartsId])

    useEffect(() => {
        if(params.edit === 'form'){
            if(auctionItem.numberOfParts === 2){
                if(params.part === '0'){
                    setPath('/orderSpecificationParts/form/' + params.id + '/1')
                }else if(params.part === '1'){
                    setPath('/finalSteps/form/' + params.id)
                }
            }else if(auctionItem.numberOfParts === 3){
                if(params.part === 0){
                    setPath('/orderSpecificationParts/form/' + params.id + '/1')
                }else if(params.part === 1){
                    setPath('/orderSpecificationParts/form/' + params.id + '/2')
                }else if(params.part === 2){
                    setPath('/finalSteps/form/' + params.id)
                }
            }
        }else if(params.edit === 'edit'){
            setPath('/inProgress')
        }
    }, [auctionItem])


    console.log(auctionItem)

    function getSteps() {
        if(params.edit === "edit"){
            if (auctionItem.negotiation === 'z' || auctionItem.negotiation === 'możliwość') {
                if(auctionItem.ueFunds === true){
                    return [
                        'NEGOCJACJE',
                        'OPIS PRZEDMIOTU ZAMÓWIENIA',
                        'DODATKOWE WYMAGANIA',
                        'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                        'WIZJA LOKALNA',
                        'PODMIOTOWE ŚRODKI DOWODOWE',
                        'MOŻLIWOŚĆ OPCJI',
                        'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                        'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                        'ZATRUDNIENIE OSÓB Z ART. 96',
                        'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                        'TERMIN WYKONANIA ZAMÓWIENIA',
                        'ISTOTNE ZAPISY UMOWY',
                        'WADIUM',
                        'AUKCJA ELEKTRONICZNA',
                        'SPOSÓB OBLICZENIA CENY',
                        'KRYTERIA OCENY OFERT',
                        'PROCEDURA ODWRÓCNA',
                        'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                        'UMOWA RAMOWA',
                        'ZAKOŃCZ'
                    ];
                }else{
                    return [
                        'NEGOCJACJE',
                        'OPIS PRZEDMIOTU ZAMÓWIENIA',
                        'DODATKOWE WYMAGANIA',
                        'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                        'WIZJA LOKALNA',
                        'PODMIOTOWE ŚRODKI DOWODOWE',
                        'MOŻLIWOŚĆ OPCJI',
                        'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                        'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                        'ZATRUDNIENIE OSÓB Z ART. 96',
                        'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                        'TERMIN WYKONANIA ZAMÓWIENIA',
                        'ISTOTNE ZAPISY UMOWY',
                        'WADIUM',
                        'AUKCJA ELEKTRONICZNA',
                        'SPOSÓB OBLICZENIA CENY',
                        'KRYTERIA OCENY OFERT',
                        'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                        'UMOWA RAMOWA',
                        'ZAKOŃCZ'
                    ];
                }
            } else {
                if(auctionItem.ueFunds === true){
                return [
                    'OPIS PRZEDMIOTU ZAMÓWIENIA',
                    'DODATKOWE WYMAGANIA',
                    'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                    'WIZJA LOKALNA',
                    'PODMIOTOWE ŚRODKI DOWODOWE',
                    'MOŻLIWOŚĆ OPCJI',
                    'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                    'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                    'ZATRUDNIENIE OSÓB Z ART. 96',
                    'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                    'TERMIN WYKONANIA ZAMÓWIENIA',
                    'ISTOTNE ZAPISY UMOWY',
                    'WADIUM',
                    'AUKCJA ELEKTRONICZNA',
                    'SPOSÓB OBLICZENIA CENY',
                    'KRYTERIA OCENY OFERT',
                    'PROCEDURA ODWRÓCNA',
                    'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                    'UMOWA RAMOWA',
                    'ZAKOŃCZ'
                ];
            }else{
                    return [
                        'OPIS PRZEDMIOTU ZAMÓWIENIA',
                        'DODATKOWE WYMAGANIA',
                        'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                        'WIZJA LOKALNA',
                        'PODMIOTOWE ŚRODKI DOWODOWE',
                        'MOŻLIWOŚĆ OPCJI',
                        'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                        'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                        'ZATRUDNIENIE OSÓB Z ART. 96',
                        'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                        'TERMIN WYKONANIA ZAMÓWIENIA',
                        'ISTOTNE ZAPISY UMOWY',
                        'WADIUM',
                        'AUKCJA ELEKTRONICZNA',
                        'SPOSÓB OBLICZENIA CENY',
                        'KRYTERIA OCENY OFERT',
                        'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                        'UMOWA RAMOWA',
                        'ZAKOŃCZ'
                    ];
                }
            }
        }else if(params.edit === 'form'){
            if (auctionItem.negotiation === 'z' || auctionItem.negotiation === 'możliwość') {
                if(auctionItem.ueFunds === true){
                return [
                    'NEGOCJACJE',
                    'OPIS PRZEDMIOTU ZAMÓWIENIA',
                    'DODATKOWE WYMAGANIA',
                    'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                    'WIZJA LOKALNA',
                    'PODMIOTOWE ŚRODKI DOWODOWE',
                    'MOŻLIWOŚĆ OPCJI',
                    'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                    'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                    'ZATRUDNIENIE OSÓB Z ART. 96',
                    'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                    'TERMIN WYKONANIA ZAMÓWIENIA',
                    'ISTOTNE ZAPISY UMOWY',
                    'WADIUM',
                    'AUKCJA ELEKTRONICZNA',
                    'SPOSÓB OBLICZENIA CENY',
                    'KRYTERIA OCENY OFERT',
                    'PROCEDURA ODWRÓCNA',
                    'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                    'UMOWA RAMOWA',
                    'PODSUMOWANIE',
                    'ZAKOŃCZ'
                ];}else{
                    return [
                        'NEGOCJACJE',
                        'OPIS PRZEDMIOTU ZAMÓWIENIA',
                        'DODATKOWE WYMAGANIA',
                        'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                        'WIZJA LOKALNA',
                        'PODMIOTOWE ŚRODKI DOWODOWE',
                        'MOŻLIWOŚĆ OPCJI',
                        'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                        'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                        'ZATRUDNIENIE OSÓB Z ART. 96',
                        'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                        'TERMIN WYKONANIA ZAMÓWIENIA',
                        'ISTOTNE ZAPISY UMOWY',
                        'WADIUM',
                        'AUKCJA ELEKTRONICZNA',
                        'SPOSÓB OBLICZENIA CENY',
                        'KRYTERIA OCENY OFERT',
                        'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                        'UMOWA RAMOWA',
                        'PODSUMOWANIE',
                        'ZAKOŃCZ'
                    ]
                }
            } else {
                if(auctionItem.ueFunds === true){
                return [
                    'OPIS PRZEDMIOTU ZAMÓWIENIA',
                    'DODATKOWE WYMAGANIA',
                    'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                    'WIZJA LOKALNA',
                    'PODMIOTOWE ŚRODKI DOWODOWE',
                    'MOŻLIWOŚĆ OPCJI',
                    'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                    'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                    'ZATRUDNIENIE OSÓB Z ART. 96',
                    'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                    'TERMIN WYKONANIA ZAMÓWIENIA',
                    'ISTOTNE ZAPISY UMOWY',
                    'WADIUM',
                    'AUKCJA ELEKTRONICZNA',
                    'SPOSÓB OBLICZENIA CENY',
                    'KRYTERIA OCENY OFERT',
                    'PROCEDURA ODWRÓCNA',
                    'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                    'UMOWA RAMOWA',
                    'PODSUMOWANIE',
                    'ZAKOŃCZ'
                ];}else{
                    return [
                        'OPIS PRZEDMIOTU ZAMÓWIENIA',
                        'DODATKOWE WYMAGANIA',
                        'WARUNKI UDZIAŁU W POSTĘPOWANIU',
                        'WIZJA LOKALNA',
                        'PODMIOTOWE ŚRODKI DOWODOWE',
                        'MOŻLIWOŚĆ OPCJI',
                        'OSOBISTE WYKONANIE KLUCZOWYCH ZADAŃ',
                        'ZATRUDNIENIE NA PODSTAWIE STOSUNKU PRACY',
                        'ZATRUDNIENIE OSÓB Z ART. 96',
                        'WYKONAWCY, O KTÓRYCH MOWA W ART. 94',
                        'TERMIN WYKONANIA ZAMÓWIENIA',
                        'ISTOTNE ZAPISY UMOWY',
                        'WADIUM',
                        'AUKCJA ELEKTRONICZNA',
                        'SPOSÓB OBLICZENIA CENY',
                        'KRYTERIA OCENY OFERT',
                        'ZABEZPIECZENIE NALEŻYTEGO WYKONANIA UMOWY',
                        'UMOWA RAMOWA',
                        'PODSUMOWANIE',
                        'ZAKOŃCZ'
                    ]
                }
            }
        }
    }

    function getStepContent(step) {
        if(!!auctionItemParts){
            switch (step) {
                case 0:
                    if(auctionItem.negotiation === 'z' || auctionItem.negotiation === 'możliwość'){
                        return ( <NegotiationPartI auctionObject={auctionItemParts} edit={params.edit} id={params.id} no={params.part}/>)
                    };
                case 1:
                    return ( <OrderDescriptionPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part} id={params.id}/>
                    );
                case 2:
                    return ( <AdditionalRequirementsPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 3:
                    return ( <ConditionsPartI auctionItemObject={auctionItem} auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 4:
                    return ( <LocalVisionPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 5:
                    return ( <SubjectiveMeansPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 6:
                    return ( <OptionsPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part} id={params.id}/>
                    );
                case 7:
                    return ( <ExecutionPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part} id={params.id}/>
                    );
                case 8:
                    return ( <EmploymentPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 9:
                    return ( <Employment96PartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 10:
                    return ( <Contractors94PartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 11:
                    return ( <DeadlinePartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 12:
                    return ( <ProvisionsPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 13:
                    return (<WadiumPartI auctionItemObject={auctionItem} auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 14:
                    return ( <AuctionPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 15:
                    return ( <PricingPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 16:
                    return ( <EvaluationPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 17:
                    if(auctionItem.ueFunds === true){
                        return (
                            <ReverseProcedurePartI auctionItemObject={auctionItem} auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                        );
                    };
                case 18:
                    return ( <ProtectionPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 19:
                    return ( <FrameworkAgreementPartI auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>
                    );
                case 20:
                    if(params.edit === 'form'){
                        return ( <SummmaryOrderSpecificationPart ueFunds={auctionItem.ueFunds} negotiation={auctionItem.negotiation} auctionObject={auctionItemParts} edit={params.edit} no={params.part}/>);
                    }
                case 21:
                    return (<InitialOpFinished
                        handleLast={handleLast}
                        onSubmit={(form) => {
                            patchInitialOp(form, auctionItem['@id'].slice(19)).subscribe(
                                Swal.fire({
                                        title: 'Sukces',
                                        text: `Dane zostały zapisane.`,
                                        icon: 'success',
                                        showCancelButton: false,
                                        showConfirmButton: true,
                                        confirmButtonColor: '#5a13e6',
                                        confirmButtonText: 'Przejdź dalej'
                                    },
                                ).then(okay => {
                                    if (okay) {
                                        window.location.replace(path);
                                    }}),
                                error => {
                                    return Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: `Wprowadzono błędne dane !`,
                                    })
                                },
                            );
                        }
                        }
                    />)
                default:
                    return 'Unknown step';
            }
        }

    }

    const [activeStep, setActiveStep] = useState(16);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleLast = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.location.replace('/finalSteps')
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        console.log('changed step scrolling to top of window');
        window.scrollTo(0, 0)
    }, [activeStep])


    return(
        <React.Fragment>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Specyfikacja warunków zamówienia część {parseInt(params.part) + 1}</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <div>
                        <Stepper className={classes.name} activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step className={classes.name} key={label}>
                                    <StepLabel><Typography className={classes.name}>{label}</Typography></StepLabel>
                                    <StepContent>
                                        <TwoColumnStep>
                                            <Typography>{getStepContent(index)}</Typography>
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    Wróć
                                                </Button>
                                                {activeStep === steps.length - 1 ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleLast}
                                                        className={classes.button}
                                                        type="submit"

                                                    >Zakończ</Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                        className={classes.button}
                                                        type="submit"
                                                    >Dalej</Button>
                                                )}
                                            </div>
                                        </TwoColumnStep>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>Proces zakończony pomyślnie</Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </div>
                </AnimationRevealPage>
            </div>


        </React.Fragment>
    )
}

export default OrderSpecificationParts;