import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {NonActiveButton, NotActiveButton, PrimaryButton} from "../misc/Buttons";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {
    fetchAuctionItemId,
    fetchAuctionItemParts,
    fetchAuctionItemPartsId,
    fetchAuctionWithId, fetchHint
} from "../../services/fetchId";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import StackedSnackbar from "../StackedSnackbar";
import CloseIcon from "@material-ui/icons/Close";

const Form = tw.div`mb-5 mx-5 mr-3`;
const ButtonA = tw(PrimaryButton)`mb-3 text-base w-64`
const ButtonN = tw(NonActiveButton)`mb-3 text-base w-64`

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '3% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const PartialOfferts = ({edit, id}) => {

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);
    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    console.log(formAuctionParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i))
        console.log(formAuctionParts)
    }

    console.log(form)
    console.log(formAuction)

    const classes = useStyles();
    const [auction, setAuction] = useState('');
    const [auctionItem, setAuctionItem] = useState('');
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [auctionItemPartsId, setAuctionItemPartsId] = useState();
    const [auctionParts, setAuctionParts] = useState();
    const [parts, setParts] = useState(parseInt(localStorage.getItem('parts')));
    const [form1, setForm1] = useState();

    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => {
                setAuction(data);
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )
    },[])


    useEffect(() => {
        if(!!auctionItem){
            setAuctionItemParts(auctionItem.auctionItemParts);
        }
    }, [auctionItem])

    useEffect(() => {
        if(!!auctionItemParts){
           setAuctionItemPartsId(auctionItemParts.map(part => part.slice(24)))
        }

    }, [auctionItemParts])

    useEffect(() => {
        if(!!auctionItemPartsId){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionParts(data['hydra:member'].filter(auction => auctionItemPartsId.includes(auction['@id'].slice(24))));
                }
            )
        }
    }, [auctionItemPartsId])

    let part = [];

    useEffect(() => {
        for (let i=0; i<auctionItem.numberOfParts; i++){
                part.push(
                    <>
                        <Typography className={classes.nameSec}>Część {i+1}</Typography>

                        {
                            !!auctionParts && (
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Nazwa części {i+1}</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auctionParts[i].partName}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                            )
                        }
                        {
                            edit ? (
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Krótki opis przedmiotu zamówienia części {i+1}</FormLabel>
                                    <TextValidator
                                        name="shortAuctionDescription"
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        required
                                        defaultValue={auctionParts.shortDescription}
                                        value={formAuctionParts.shortDescription}
                                        onChange={(e) => setValueAuctionParts(e, i)}
                                        label="Krótki opis"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                                </Form>
                            ) : (
                                <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Krótki opis przedmiotu zamówienia części {i+1}</FormLabel>
                                        <TextValidator
                                            value={formAuctionParts.shortDescription}
                                            onChange={(e) => setValueAuctionParts(e, i)}
                                            name="shortAuctionDescription"
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            required
                                            label="Krótki opis"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                </Form>
                            )
                        }

                    </>)
        }
        setForm1(part)
    }, [auctionParts])

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[18].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };



    return(
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    {localStorage.getItem('parts') === '0' ?
                        (
                            <>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={'Zamawiający nie dopuszcza składania ofert częściowych.'}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                {
                                    !!auctionItem && (
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Powód niedokonania podziału na części                                                  </FormLabel>
                                            <TextValidator
                                                value={auctionItem.noPart}
                                                disabled
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                variant="outlined"
                                                name="noPart"/>
                                        </Form>
                                    )
                                }

                            </>

                        ) : (
                            <>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={'Zamawiający dopuszcza składanie ofert częściowych.'}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Ilość części</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={localStorage.getItem('parts')}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                {form1}
                            </>
                        )
                    }

                </ValidatorForm>
            </AnimationRevealPage>
        </div>

    )
}

export default PartialOfferts