import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {fetchCustomer} from "../../services/fetchId";


const Form = tw.div`mb-5 mx-5 mr-3`;

const CommunicationPerson = () => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [customer, setCustomer] = useState([]);
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
        })

    }, [])


    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Osoby uprawnione do komunikowania się z wykonawcami</FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                value={customer.contactPerson}
                                variant="outlined"
                                disabled/>
                        </FormControl>
                    </Form>

                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default CommunicationPerson;