import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";
import {fetchAuctionItemId, fetchEuProject} from "../../services/fetchId";
import {useSelector} from "react-redux";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const OrderModeSpec = ({id}) => {

    const [auctionItem, setAuctionItem] = useState('');
    const [ueFunds, setUeFunds] = useState('');
    const [euProject, setEuProject] = useState('');
    const [euProjectId, setEuProjectId] = useState();

    const formAuction = useSelector(state => state.auction.formAuction);
    const form = useSelector(state => state.auction.form);

    console.log(form)
    console.log(formAuction)

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0])
            }
        )
    }, [])

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setUeFunds(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0].ueFunds)
                setEuProjectId(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0].euProject)
            }
        )
    }, [])


    useEffect(() => {
        if(!!euProjectId){
            fetchEuProject(euProjectId.slice(17)).subscribe(
                data => {
                    setEuProject(data)
                }
            )
        }

    }, [euProjectId])




    const classes = useStyles();

    return(
        <AnimationRevealPage>
            <ValidatorForm >
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Tryb udzialenia zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={'Ref Tryb'}
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Podstawa prawna</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionItem.legalBasis}
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                {
                    ueFunds === true &&
                    (
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <FormLabel className={classes.label}>Nazwa programu unijnego w ramach którego realizowane jest zamówienie</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={euProject.name}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Form>
                    )
                }


            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default OrderModeSpec