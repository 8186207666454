import React, {useEffect, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import {PrimaryButton} from "../components/misc/Buttons";
import {Link, withRouter} from 'react-router-dom';
import HintsPermanent from "../components/hints/HintsPermanent";
import SidebarLeftNonReg from "../components/sidebars/SidebarLeftNonReg";
import SidebarRightNonReg from "../components/sidebars/SidebarRightNonReg";
import {Typography} from "@material-ui/core";
import {Info} from "../components/misc/Typography";
import {Links} from "../components/misc/Links";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import {fetchCustomer} from "../services/fetchId";

const ButtonRegister = tw(PrimaryButton)`my-16 text-lg p-6`;
const CenteringDiv = tw.div`flex items-center justify-center flex-col mt-32`;

function MyAccountReg() {

    const [customer, setCustomer] = useState([]);
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data.name)
        })
    }, [customer])






    return (
        <React.Fragment>
            <SidebarLeftNonReg/>
            <SidebarRightNonReg/>

           {/* <HintsPermanent id={'hint'} message={'Aby uzyskać dostęp do wszystkich funkcji aplikacji należy zawrzeć umowę abonamentową. Wszelkie informacje znajdują się TUTAJ. '}/>
            <HintsPermanent id={'hint'} className={"two"} message={'Czynność rejestracji jest jednorazowa. Dane zostaną zapisane w systemie i będą się pobierały dla każdego przetargu. Zamawiający będzie miał możliwość aktualizacji danych podczas otwierania każdego przetargu. '}/>*/}
            <div style={{marginLeft: '300px', marginRight: '300px'}}>
                <AnimationRevealPage>
                    <CenteringDiv>
                        <Info>Aby uzyskać dostęp do wszystkich funkcji aplikacji należy zawrzeć umowę abonamentową. Wszelkie informacje znajdują się <Links>TUTAJ.</Links></Info>
                        <Link to="/registerData">
                            <ButtonRegister >
                                Rejestracja danych zamawiającego
                                <RecentActorsIcon style={{marginLeft:'15px'}}/>
                            </ButtonRegister>
                        </Link>
                        <Info>Czynność rejestracji jest jednorazowa. Dane zostaną zapisane w systemie i będą się pobierały dla każdego przetargu.<br/> Zamawiający będzie miał możliwość aktualizacji danych podczas otwierania każdego przetargu.</Info>
                    </CenteringDiv>
                </AnimationRevealPage>
            </div>

        </React.Fragment>
    )
}
export default withRouter(MyAccountReg);