import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@material-ui/core";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Approval = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Miejscowość
                            </FormLabel>
                            <TextValidator
                                defaultValue={edit === 'edit' ? (auctionItemObject.approvalCity) : ''}
                                value={form.approvalCity}
                                onChange={setValue}
                                name="approvalCity"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Miejscowość"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Data
                                <Tooltip title="Pole zostanie uzupełnione na wersji papierowej przez kierownika Zmawiającego"
                                         placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                disabled
                                id="outlined-basic-u"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Podpis
                                <Tooltip title="Pole zostanie uzupełnione na wersji papierowej przez kierownika Zmawiającego"
                                         placement="bottom">
                                    <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                </Tooltip>
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                type="text"
                                disabled
                                id="outlined-basic-u"
                                variant="outlined"
                            />
                        </FormControl>
                    </Form>
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Approval;