import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {NavLink} from "../headers/light2";
import tw from "twin.macro";
import {Linkssidebar} from "../misc/Links";
import {SideLogo} from "../misc/Typography";
import {NonActiveButton, PrimaryButton} from "../misc/Buttons";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    list: {
        width: 260,
    },
    fullList: {
        width: 'auto',
    },
});
const Logo = styled(NavLink)`
  ${tw`font-black text-3xl! border-b-0 `};`
const ButtonCol = tw.div`flex flex-col justify-center mt-10!`;
const ButtonNew = tw(NonActiveButton)`mb-3 text-base p-5 w-48`


const SidebarLeftNonReg = () => {

    const windowWidth = useSelector(state => state.main.windowWidth);

    const classes = useStyles();

    const [isOn, setIsOn] = React.useState(false);
    const [permanent, setPermanent] = React.useState();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    useEffect(()=>{
        if (windowWidth < 900){
            setPermanent(false);
        } else {
            setPermanent(true);
        }
    },[windowWidth])




    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        ><AnimationRevealPage>
            <List>
                <Logo to="/" style={{display: 'flex', justifyContent: 'center'}}>
                    ARKAD
                </Logo>
                <SideLogo>Generator dokumentów przetargowych</SideLogo>
                <Divider />
                <List>
                    <ButtonCol>
                        <Link to="/choosingOrderType">
                            <ButtonNew disabled>Nowy przetarg</ButtonNew>
                        </Link>
                        <ButtonNew disabled>Przetarg w trakcie przygotowywania - nieogłoszony</ButtonNew>
                        <ButtonNew disabled>Przetarg ogłoszony - niezakończony</ButtonNew>
                        <ButtonNew disabled>Archiwum moich przetargów</ButtonNew>
                        <ButtonNew disabled>Archiwum moich ogłoszeń</ButtonNew>
                    </ButtonCol>
                </List>
            </List>
        </AnimationRevealPage>
        </div>

    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        permanent ? (
                            <Drawer anchor={anchor} variant="permanent">
                                {list(anchor)}
                            </Drawer>

                        ) : (
                            <>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(anchor, true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon style={{fontSize: '50px', textAlign: 'left'}}/>
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </div>
    );
}

export default SidebarLeftNonReg;