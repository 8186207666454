import {fetchId} from "./fetchId";

export class RegisterDataService {
    constructor(networkingService) {
        this.networkingService = networkingService;
    }

    registerData(data) {
        const body = { ...data };
        const headers = {
            'Content-type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
        };


        return this.networkingService.post('api/customers', body, headers);
    }
}
