import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {MenuItem, Paper, Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from '@material-ui/core';

import CustomizedTooltip from "../components/CustomizedTooltip";
export const theme = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '0.175rem',
            }
        }
    }
});

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl `;
const Form = tw.div`flex items-center justify-between`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100%',
        marginRight: '35% !important',
        marginTop: '1%',
        maxWidth: '800px !important',
        minWidth: '350px !important',
    },
    label: {
        fontSize: '1rem !important',
    },
    labelRadio: {
        maxWidth: '750px !important',
        minWidth: '200px !important',
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelNormal: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem !important',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.2em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        borderBottom: '1px solid black',
        padding: '1%'
    },
    root: {
        flexGrow: 1,
        alignItems: 'center',
        fontSize: '1em !important'
    },

    control: {
        padding: theme.spacing(2),
    },
}));

const Summary = (props) => {

    const [valueWoVat, setValueWoVat] = useState();

    const classes = useStyles();

    const form = useSelector(state => state.auction.form);
    const formAuction = useSelector(state => state.auction.formAuction);
    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    let part = [];
    let jointForm;
    let proxyForm;


    if(formAuction.procedureType === "Jako zamawiający wspólnie z innym zamawiającym/innymi zamawiającymi"){
        jointForm =
            <>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nazwa drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.nameSecOrdering}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Krajowy numer identyfikacji drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.kni}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer NIP drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.nipSecOrdering}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Adres Drugiego Zmawiającego</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                    <TextValidator
                        value={form.adressSecOrderingStreet}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Miasto</FormLabel>
                    <TextValidator
                        className={classes.input}
                        value={form.adressSecOrderingCity}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nr domu</FormLabel>
                    <TextValidator
                        value={form.adressSecOrderingPlaceNo}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                    <TextValidator
                        value={form.adressSecOrderingZipCode}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>

                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer telefonu lub faksu drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.numberSec}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres email drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.emailSec}
                        className={classes.input}
                        type="email"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres strony internetowej drugiego zamawiającego</FormLabel>
                    <TextValidator
                        value={form.urlSec}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Podział obowiązków między zamawiającymi</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Wpisz informację, który zamawiający i w jakim zakresie
                        odpowiada za przeprowadzenie postępowania</FormLabel>
                    <TextValidator
                        value={form.division}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Wpisz informację czy zamówienie będzie udzielane przez każdego z
                        zamawiających indywidualnie albo czy zostanie udzielone
                        w imieniu i na rzecz pozostałych zamawiających</FormLabel>
                    <TextValidator
                        value={form.joint}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Jeżeli postępowanie jest przeprowadzane wspólnie z zamawiającymi z innych państw członkowskich Unii Europejskiej, informacja o
                        mających zastosowaniu przepisach innego państwa członkowskiego Unii Europejskiej lub umowy międzynarodowej zawartej między
                        Rzecząpospolitą Polską a innymi państwami członkowskimi Unii Europejskiej</FormLabel>
                    <TextValidator
                        value={form.otherCountry}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>

                </Form>
            </>
    }

    if(formAuction.procedureType === "Jako podmiot, któremu zamawiający powierzył/powierzyli przeprowadzenie postępowania"){
        proxyForm =
            <>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nazwa Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia
                    </FormLabel>
                    <TextValidator
                        value={form.nameProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Krajowy numer identyfikacji Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.kniProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer NIP Podmiotu, któremu zamawiający powierzył realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.nipProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Adres Podmiotu, któremu zamawiający powierzył realizację zamówienia</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                    <TextValidator
                        value={form.adressProxyStreet}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Miasto</FormLabel>
                    <TextValidator
                        className={classes.input}
                        value={form.adressProxyCity}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nr domu</FormLabel>
                    <TextValidator
                        value={form.adressProxyPlaceNo}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                    <TextValidator
                        value={form.adressProxyZipCode}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>


                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer telefonu lub faksu Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.numberProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres email Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.emailProxy}
                        className={classes.input}
                        type="email"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres strony internetowej Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.urlProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Dodatkowe Informacje</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Dodatkowe Informacje</FormLabel>
                    <TextValidator
                        value={form.addInfoProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        diabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nazwa Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia
                    </FormLabel>
                    <TextValidator
                        value={form.nameProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Krajowy numer identyfikacji Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.kniProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer NIP Podmiotu, któremu zamawiający powierzył realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.nipProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Adres Podmiotu, któremu zamawiający powierzył realizację zamówienia</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                    <TextValidator
                        value={form.adressProxyStreet}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Miasto</FormLabel>
                    <TextValidator
                        className={classes.input}
                        value={form.adressProxyCity}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nr domu</FormLabel>
                    <TextValidator
                        value={form.adressProxyPlaceNo}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                    <TextValidator
                        value={form.adressProxyZipCode}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Numer telefonu lub faksu Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.numberProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres email Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.emailProxy}
                        className={classes.input}
                        type="email"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Adres strony internetowej Podmiotu, któremu zamawiający
                        powierzył/powierzyli realizację zamówienia</FormLabel>
                    <TextValidator
                        value={form.urlProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                <Typography className={classes.nameSec}>Dodatkowe Informacje</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Dodatkowe Informacje</FormLabel>
                    <TextValidator
                        value={form.addInfoProxy}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
            </>
    }

    for (let i=0; i<localStorage.getItem('parts'); i++){
            part.push(
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Nazwa części {i + 1} zamówienia</FormLabel>
                    <TextValidator
                        value={formAuctionParts.partName}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
            )}

    let part1 = [];

    useEffect(() => {
        for (let i=0; i<localStorage.getItem('parts'); i++){
                part1.push(
                    <>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Nazwa części {i+1} zamówienia</FormLabel><br/>
                                <TextValidator
                                    value={formAuctionParts.partName}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled/>
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Wybierz główny kod CPV
                                    dla części {i+1} zamówienia
                                </FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    value={formAuctionParts.cpvCode}
                                    disabled>
                                </TextValidator>
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Wybierz dodatkowy kod CPV
                                    dla części {i+1} zamówienia</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    value={formAuctionParts.addCpvCode}
                                    disabled>
                                </TextValidator>
                        </Form>
                    </>)
            }}, [])

    let part2 = [];

    for (let i=0; i<localStorage.getItem('parts'); i++){
        part2.push(
         <>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Wartość części {i+1} zamówienia podstawowego</FormLabel>
                 <TextValidator
                     value={formAuctionParts.partValue}
                     className={classes.input}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled
                 />
             </Form>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Wartość zamówień dodatkowych dla części {i+1} zamówienia
                 </FormLabel>
                 <TextValidator
                     className={classes.input}
                     value={formAuctionParts.partValueSim}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled/>
             </Form>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Wartość opcji dla części {i+1} zamówienia (jeśli dotyczy)
                 </FormLabel>
                 <TextValidator
                     value={formAuctionParts.partValueOp}
                     className={classes.input}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled/>
             </Form>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy) <br/>
                 </FormLabel><br/>
                 <TextValidator
                     value={form.valueSupplyPart}
                     className={classes.input}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled
                 />
             </Form>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Całkowita wartość zamówienia bez VAT
                 </FormLabel>
                 <TextValidator
                     className={classes.input}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled
                     value={valueWoVat}/>
             </Form>
             <Form className={classes.label}>
                 <FormLabel className={classes.labelRadio}>Waluta
                 </FormLabel>
                 <TextValidator
                     className={classes.input}
                     type="text"
                     id="outlined-basic-u"
                     variant="outlined"
                     disabled
                     value='PLN'/>
             </Form>
         </>
        )}



    return(
        <>
            <ValidatorForm>
                {jointForm}
                {proxyForm}
                <Typography className={classes.nameSec}>Dane zamawiającego</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nazwa zamawiającego</FormLabel>
                    <TextValidator
                        value={form.nameOrdering}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Miasto</FormLabel>
                    <TextValidator
                        value={form.addressCity}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Kod pocztowy</FormLabel>
                <TextValidator
                    value={form.addressZipCode}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Ulica</FormLabel>
                    <TextValidator
                        value={form.addressStreet}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Numer domu</FormLabel>
                <TextValidator
                    value={form.addressHouseNumber}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled/>
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Numer NIP</FormLabel>
                <TextValidator
                    value={form.nip}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled/>
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Osoba do kontaktu</FormLabel>
                <TextValidator
                    value={form.contactPerson}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Pełnomocnik (jeżeli dotyczy)</FormLabel>
                <TextValidator
                    value={form.proxy}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Numer referencyjny</FormLabel>
                <TextValidator
                    value={formAuction.refNumber}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Wpisz planowaną datę rozpoczęcia (ogłoszenia) postępowania o udzielenie zamówienia</FormLabel>
                    <TextValidator
                        value={formAuction.startDate.toLocaleDateString('en-CA')}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
            </Form>
            <Form className={classes.label} >
                <FormLabel className={classes.labelRadio}>Adres email</FormLabel>
                <TextValidator
                    value={form.email}
                    className={classes.input}
                    type="email"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form>
                <FormLabel className={classes.labelRadio}>Adres strony internetowej Zamawiającego</FormLabel>
                <TextValidator
                    value={form.www}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Typography className={classes.nameSec}>Komunikacja z wykonawcą w trakcie postępowania</Typography>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Adres strony internetowej 
                    <CustomizedTooltip content="Adres strony internetowej prowadzonego postępowania, na której będą udostępniane dokumenty zamówienia, w tym specyfikacja." placement="top" type="info" />
                    {/* <Tooltip title="Adres strony internetowej prowadzonego postępowania, na której będą udostępniane dokumenty zamówienia, w tym specyfikacja,"
                             placement="bottom">
                        <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                    </Tooltip> */}
                </FormLabel>
                <TextValidator
                    value={form.urlDocuments}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>

            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Adres strony internetowej 
                <CustomizedTooltip content="Wpisz adres strony internetowej, na której będą dostępne narzędzia, urządzenia lub formaty plików, które nie są ogólnie dostępne, jeżeli wymagasz ich do złożenia oferty. Art 61, ust 1." placement="top" type="info" />
                    
                    {/* <Tooltip title="Wpisz adres strony internetowej, na której będą dostępne narzędzia, urządzenia lub formaty plików, które nie są ogólnie dostępne, jeżeli wymagasz ich do złożenia oferty. Art 61, ust 1"
                             placement="bottom">
                        <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                    </Tooltip> */}
                </FormLabel>
                <TextValidator
                    value={form.urlTools}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.labelRadioM}>
                <FormControl component="fieldset1" className={classes.label} value={'form.unable'}>
                    <FormLabel component="legend1" className={classes.labelNormal}>Nie możesz udostępnić części dokumentów zamówienia, w tym części SWZ/opisu potrzeb i wymagań, na stronie internetowej prowadzonego postępowania z powodu ochrony poufnego charakteru informacji w nich zawartych.</FormLabel>
                    <RadioGroup  name="unable">
                        <FormControlLabel value='true' disabled checked={form.unable} control={<Radio  color="primary"/>} label="" />
                    </RadioGroup>
                </FormControl>
            </Form>
            {
                form.unable && (
                    <React.Fragment>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Sposób dostępu do części zamówienia zawierającej poufne informacje</FormLabel>
                            <TextValidator
                                value={form.access}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Wymagania związane z ochroną poufnego charakteru tych informacji</FormLabel>
                            <TextValidator
                                value={form.requirements}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                    </React.Fragment>
                )}
            <Typography className={classes.nameSec}>Sposób składania ofert</Typography>
            <Form className={classes.label} >
                <FormControl className={classes.label}>
                    <FormLabel className={classes.labelNormal} value={form.ecommunication} >Czy wykonawcy będą zobowiązani do składania ofert i innych dokumentów wyłącznie przy użyciu środków komunikacji elektronicznej</FormLabel>
                    <RadioGroup name="ecommunication">
                        <FormControlLabel value='true' disabled  control={<Radio checked={form.ecommunication} color="primary"/>} label={<Typography className={classes.label}>Tak</Typography>}/>
                        <FormControlLabel value='false' disabled control={<Radio checked={!form.ecommunication} color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>

            {
                form.ecommunication ? (
                    <div>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Adres poczty elektronicznej, na który wykonawca prześle ofertę
                            </FormLabel>
                            <TextValidator
                                value={form.emailOffer}
                                className={classes.input}
                                type="email"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Informacje o wymaganiach technicznych i organizacyjnych
                                sporządzania, wysyłania i odbierania korespondencji elektronicznej,
                                w tym informacje dotyczące wymogu użycia podpisu elektronicznego
                            </FormLabel>
                            <TextValidator
                                value={form.emailRequirements}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                    </div>
                ) : (   <React.Fragment>
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Dlaczego odstępujesz od wymagania użycia środków
                                komunikacji elektronicznej – okoliczności z art 65
                            </FormLabel>
                            <TextValidator
                                value={form.reason}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                        <Form className={classes.label} >
                            <FormControl className={classes.label} value={form.way}>
                                <FormLabel className={classes.labelNormal}>Sposób składania oferty i JEDZ/oswiadczenia o
                                    niepodleganiu wykluczeniu zgodnie z art. 65 ust. 2</FormLabel>
                                <RadioGroup name="way">
                                    <FormControlLabel disabled value="Osobiście" control={<Radio checked={form.way === "Osobiście"} color="primary"/>} label="Osobiście" />
                                    <FormControlLabel disabled value="Za pośrednictwem kuriera" control={<Radio checked={form.way === "Za pośrednictwem kuriera"} color="primary"/>} label="Za pośrednictwem kuriera" />
                                    <FormControlLabel disabled value="Za pośrednictwem operatora pocztowego" control={<Radio checked={form.way === "Za pośrednictwem operatora pocztowego"} color="primary"/>} label="Za pośrednictwem operatora pocztowego" />
                                </RadioGroup>
                            </FormControl>
                        </Form>
                        <Form className={classes.label}>
                            <FormControl className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Sposób składania oferty i JEDZ/ośw. O niepodleganiu
                                    wykluczeniu zgodnie z art. 65 ust. 3
                                </FormLabel>
                                <TextField
                                    value="wnioski o dopuszczenie do udziału w postępowaniu lub konkursie, wnioski, o których mowa w art. 371 ust. 3, oferty lub ich części składa się, pod rygorem nieważności, w formie pisemnej"
                                    className={classes.input}
                                    id="outlined-read-only-input"
                                    disabled
                                    multiline
                                    rows={5}
                                />
                            </FormControl>
                        </Form>
                    </React.Fragment>
                )
            }
            <div>
                <Typography className={classes.nameSec}>Inny sposób składania ofert</Typography>
                <Form className={classes.label} >
                    <FormControl component="fieldset1" className={classes.label} value={form.bud}>
                        <FormLabel component="legend3" className={classes.labelNormal}>Czy będziesz wymagać sporządzenia i przedstawienia ofert przy użyciu narzędzi elektronicznego modelowania danych budowlanych lub
                            innych podobnych narzędzi, które nie są ogólnie dostępne - w przypadku zamówień na roboty budowlane</FormLabel>
                        <RadioGroup name="bud">
                            <FormControlLabel  disabled value='true' name="bud" control={<Radio checked={form.bud} color="primary"/>} label="Tak" />
                            <FormControlLabel disabled value='false' name="bud" control={<Radio checked={!form.bud}  color="primary"/>} label="Nie" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form.bud &&
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Informacje o sposobie dostępu do narzędzi elektronicznego
                        modelowania danych budowlanych lub innych podobnych narzędzi
                    </FormLabel>
                    <TextValidator
                        value={form.bud2}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                }
            </div>
            <Form className={classes.label} >
                <FormControl component="fieldset4" className={classes.label} value={form.bud3}>
                    <FormLabel component="legend4" className={classes.labelNormal}>Czy będziesz wymagać lub dopuszczać złożenie oferty w postaci katalogu elektronicznego albo dołączenia katalogu do składanej oferty</FormLabel>
                    <RadioGroup name="bud3">
                        <FormControlLabel disabled value="Tak" name="bud3" control={<Radio checked={form.bud3 === "Tak"} color="primary"/>} label="Tak" />
                        <FormControlLabel disabled value="Nie" name="bud3" control={<Radio checked={form.bud3 === "Nie"} color="primary"/>} label="Nie" />
                        <FormControlLabel disabled value="Nie dotyczy" name="bud3" control={<Radio checked={form.bud3 === "Nie dotyczy"} color="primary"/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
            {form.bud3 === 'Tak' ? (
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Informacje o sposobie złożenie oferty w postaci katalogu elektronicznego albo dołączenia katalogu do składanej oferty
                    </FormLabel>
                    <TextValidator
                        value={form.way3}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>) : (
                <>
                    <Form className={classes.label} >
                        <FormControl component="fieldset5" className={classes.label} value={form.bud4}>
                            <FormLabel component="legend5" className={classes.labelNormal}>W przypadku umowy ramowej, o którym mowa w art. 315 ustawy, wskaż, czy przewidujesz pobranie ze złożonych katalogów elektronicznych danych
                                potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia</FormLabel>
                            <RadioGroup name="bud4">
                                <FormControlLabel disabled value="Tak" name="bud4" control={<Radio checked={form.bud4 === "Tak"} color="primary"/>} label="Tak" />
                                <FormControlLabel disabled value="Nie" name="bud4" control={<Radio checked={form.bud4 === "Nie"} color="primary"/>} label="Nie" />
                                <FormControlLabel disabled value="Nie dotczy" name="bud4" control={<Radio checked={form.bud4 === "Nie dotyczy"} color="primary"/>} label="Nie dotyczy" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form.bud4 === 'Tak' && (
                        <Form className={classes.label}>
                            <FormLabel className={classes.labelRadio}>Informacje dotyczące pobrania ze złożonych katalogów elektronicznych danych potrzebnych do sporządzenia ofert dostosowanych do wymagań danego zamówienia
                            </FormLabel>
                            <TextValidator
                                value={form.dataInfo}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                variant="outlined"
                                disabled
                            />
                        </Form>
                    )}
                </>
            )
            }


            <Typography className={classes.nameSec}>Ochrona danych osobowych</Typography>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Obowiązkowa klauzula RODO - informacje wymagane na podstawie art. 13 ust. 1 i 3 ogólnego rozporządzenia o ochronie danych Parlamentu
                    Europejskiego i Rady (UE) nr 2016/679
                </FormLabel>
                <TextValidator
                    value={form.rodo}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Jeżeli przewidujesz, zgodnie z art 19, ust 4, ograniczenia stosowania przepisów rozporządzenia PE nr 2016/679 z dnia 27 kwietnia 2016 r. wpisz
                    informacje o tych ograniczeniach
                </FormLabel>
                <TextValidator
                    value={form.resOrder}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Typography className={classes.nameSec}>Wybór języka postępowania</Typography>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Informacja o języku lub językach, w jakich mogą być sporządzane wnioski podmiotowe środki dowodowe, przedmiotowe środki dowodowe,
                    inne oświadczenia lub dokumenty oraz dokonywane niektóre czynności w postępowaniu; dopuszczenie do udziału w
                    postępowaniu,oferty,oświadczenia, o których w art.125 ust.1 ustawy
                </FormLabel>
                <TextValidator
                    value={form.lang}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
            <Typography className={classes.nameSec}>Zamówienia zastrzeżone</Typography>
            <Form className={classes.label} >
                <FormControl component="fieldset6" className={classes.label} value={form.resOrder2}>
                    <FormLabel component="legend6" className={classes.labelNormal}>Czy o udzielenie zamówienia będą mogły ubiegać się wyłącznie zakłady pracy chronionej,
                        spółdzielnie socjalne oraz inni wykonawcy, o których mowa w art. 94 zatwierdź tekst poniżej
                    </FormLabel>
                    <RadioGroup name="resOrder2">
                        <FormControlLabel disabled value='true'  control={<Radio color="primary" checked={form.resOrder2}/>} label="O udzielenie zamówienia mogą ubiegać się wyłącznie wykonawcy mający status zakładu pracy chronionej, spółdzielnie socjalne oraz
inni wykonawcy, których głównym celem lub głównym celem działalności ich wyodrębnionych organizacyjnie jednostek, które będą
realizowały zamówienie, jest społeczna i zawodowa integracja osób społecznie marginalizowanych" />
                        <FormControlLabel disabled value='false'  control={<Radio color="primary" checked={!form.resOrder2}/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
            <Typography className={classes.nameSec}>Zatrudnienie na umowę o pracę</Typography>
            <Form className={classes.label} >
                <FormControl className={classes.label} value={form.contract}>
                    <FormLabel className={classes.labelNormal}>Czy przewidujesz wymagania w zakresie zatrudnienia osób realizujących zamówienie na
                        podstawie umowy o pracę i zatwierdzasz poniższy tekst?</FormLabel>
                    <TextValidator
                        value={'tekst'}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                    <RadioGroup name="contract">
                        <FormControlLabel disabled value='true' name="contract" control={<Radio color="primary" />} label="Tak" />
                        <FormControlLabel disabled value="false" name="contract" control={<Radio color="primary"/>} label="Nie dotyczy" />
                    </RadioGroup>
                </FormControl>
            </Form>
            <Typography className={classes.nameSec}>Dodatkowe informacje</Typography>
            <Form className={classes.label}>
                <FormLabel className={classes.labelRadio}>Dodatkowe Informacje
                </FormLabel>
                <TextValidator
                    value={form.addInfoInfo}
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    variant="outlined"
                    disabled
                />
            </Form>
                <Typography className={classes.nameSec}>Nazwa zamówienia</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Nazwa zamówienia nadana przez Zamawiającego</FormLabel>
                    <TextValidator
                        value={formAuction.orderName}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>Na ile części podzielone jest zamówienie</FormLabel>
                    <TextValidator
                        value={localStorage.getItem('parts')}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled>
                    </TextValidator>
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.labelRadio}>W przypadku niedokonania podziału na części wskaż powody takiej decyzji art 91, 25, ust 2, 24, ust 4                                                  </FormLabel>
                    <TextValidator
                        value={form.noPart}
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled/>
                </Form>
                {part}
                <Typography className={classes.nameSec}>Dofinansowanie unijne</Typography>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Czy zamówienie dotyczy projektu unijnego lub programu współfinansowanego ze
                            środków Unii Europejskiej
                        </FormLabel>
                        <RadioGroup value={form.ueFunds}  name="ueFunds">
                            <FormControlLabel disabled value='true' control={<Radio checked={form.ueFunds} color="primary"/>} label="Tak" />
                            <FormControlLabel disabled value='false' control={<Radio checked={!form.ueFunds} color="primary"/>} label="Nie" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form.ueFunds && (
                    <>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Nazwa projektu zgodnie z umową o dofinansowanie</FormLabel>
                                <TextValidator
                                    value={form.nameUe}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled/>
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Numer identyfikacyjny projektu</FormLabel>
                                <TextValidator
                                    value={form.numUe}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled/>
                        </Form>
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Nazwa programu unijnego</FormLabel>
                                <TextValidator
                                    value={form.programName}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled>
                                </TextValidator>
                        </Form>
                        {
                            form.myProgramName === 'inny' && (
                                <Form className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Twoja nazwa programu unijnego</FormLabel>
                                    <TextValidator
                                        value={form.myProgramName}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        disabled/>
                                </Form>
                            )
                        }
                        <Form className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Logo programu</FormLabel>
                                <TextValidator
                                    value={form.programLogo}
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    variant="outlined"
                                    disabled>
                                </TextValidator>
                        </Form>
                    </>)}
                <Typography className={classes.nameSec}>Konsultacje rynkowe </Typography>
                <Form className={classes.label}>
                    <FormControl value={form.marketCons} className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Czy przeprowadzono wstępne konsultacje rynkowe
                        </FormLabel>
                        <RadioGroup  name="marketCons">
                            <FormControlLabel disabled value='true' control={<Radio checked={form.marketCons} color="primary"/>} label="Tak" />
                            <FormControlLabel disabled value='false' control={<Radio checked={!form.marketCons} color="primary"/>} label="Nie" />
                        </RadioGroup>
                    </FormControl>
                </Form>

                {form.marketCons &&
                    <>
                        <Form className={classes.label}>
                            <FormControl value={form.ifMarketCons} className={classes.label}>
                                <FormLabel className={classes.labelRadio}>Czy chcesz przeprowadzić wstępne konsultacje rynkowe?
                                </FormLabel>
                                <RadioGroup   name="ifMarketCons" >
                                    <FormControlLabel disabled value='true' control={<Radio checked={form.ifMarketCons} color="primary"/>} label="Tak" />
                                    <FormControlLabel disabled value='false' control={<Radio checked={!form.ifMarketCons} color="primary"/>} label="Nie" />
                                </RadioGroup>
                            </FormControl>
                        </Form>
                    </>
                    }
                <Typography className={classes.nameSec}>Rodzaj zamówienia</Typography>
                <Form className={classes.label} >
                    <FormControl value={form.orderType} className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Wybierz główny rodzaj dla całego zamówienia.</FormLabel>
                        <RadioGroup name="orderType">
                            <FormControlLabel disabled value="usługi" name="orderType" control={<Radio checked={form.orderType === "usługi"} color="primary"/>} label="Usługi" />
                            <FormControlLabel disabled value="dostawy" name="orderType" control={<Radio checked={form.orderType === "dostawy"} color="primary"/>} label="Dostawy" />
                            <FormControlLabel disabled value="budowlane" name="orderType" control={<Radio checked={form.orderType === "budowlane"} color="primary"/>} label="Roboty budowlane" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {
                    form.orderType === 'usługi' && (
                        <>
                            <Form className={classes.label} >
                                <FormControl value={form.serviceType} className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Wybierz rodzaj zamawianej usługi.</FormLabel>
                                    <RadioGroup name="serviceType">
                                        <FormControlLabel disabled value="klasyczne" name="serviceType" control={<Radio checked={form.serviceType === "klasyczne"} color="primary"/>} label="Usługi klasyczne" />
                                        <FormControlLabel disabled value="sektorowe" name="serviceType" control={<Radio checked={form.serviceType === "sektorowe"} color="primary"/>} label="Usługi sektorowe" />
                                        <FormControlLabel disabled value="społeczne" name="serviceType" control={<Radio checked={form.serviceType === "społeczne"} color="primary"/>} label="Usługi społeczne i inne szczególne usługi" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                            {
                                form.serviceType === 'społeczne' && (
                                    <Form className={classes.label} >
                                        <FormControl value={form.service361} className={classes.label}>
                                            <FormLabel className={classes.labelRadio}>Czy o udzielenie zamówienia na usługi zdrowotne, społeczne oraz kulturalne objęte kodami CPV mogą ubiegać się wyłącznie wykonawcy,
                                                którzy spełniają łącznie warunki określone w art. 361 ust. 1 </FormLabel>
                                            <RadioGroup name="service361" >
                                                <FormControlLabel disabled value='true' name="service361" control={<Radio checked={form.service361} color="primary"/>} label="Tak" />
                                                <FormControlLabel disabled value='false' name="service361" control={<Radio checked={!form.service361} color="primary"/>} label="Nie" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Form>
                                )
                            }
                        </>
                    )
                }
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Wybierz główny kod CPV dla całego zamówienia</FormLabel>
                        <TextValidator
                            value={formAuction.cpvCode}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled>
                        </TextValidator>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Wybierz dodatkowy kod CPV dla całego zamówienia</FormLabel>
                        <TextValidator
                            value={formAuction.addCpvCode}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled>
                        </TextValidator>
                </Form>
                {part1}
                <Typography className={classes.nameSec}>Wartość zamówienia</Typography>
                <Form className={classes.labelJ}>
                        <FormLabel className={classes.labelRadio}>Wartość zamówienia podstawowego</FormLabel>
                        <TextValidator
                            value={form.basicValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Wartość zamówienia dodatkowego (jeśli dotyczy)</FormLabel>
                        <TextValidator
                            value={form.addValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Wartość dostaw i usług oddanych przez zamawiającego do dyspozycji wykonawcy (jeśli dotyczy) <br/></FormLabel>
                        <TextValidator
                            value={form.valueSupply}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Całkowita wartość zamówienia bez VAT</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={form.valueWoVat}/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Waluta</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value='PLN'/>
                </Form>
                {part2}
                <Typography className={classes.nameSec}>Informacje dodatkowe</Typography>
                <Form className={classes.label}>
                    <FormControl value={form.bzpName} className={classes.label}>
                        <FormLabel  className={classes.labelRadio}>Nazwa ogłoszenia w BZP
                        </FormLabel>
                        <RadioGroup name="bzpName" >
                            <FormControlLabel disabled value="Obowiązkowe" control={<Radio checked={form.bzpName === "Obowiązkowe"} color="primary"/>} label="Obowiązkowe" />
                            <FormControlLabel disabled value="Nieobowiązkowe" control={<Radio checked={form.bzpName === "Nieobowiązkowe"} color="primary"/>} label="Nieobowiązkowe" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl value={form.bzpPublic} className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Czy ogłoszenie w BZP dotyczy zamówienia publicznego albo umowy ramowej
                        </FormLabel>
                        <RadioGroup  name="bzpPublic">
                            <FormControlLabel disabled value='true' control={<Radio checked={form.bzpPublic} color="primary"/>} label="Tak" />
                            <FormControlLabel disabled value='false' control={<Radio checked={!form.bzpPublic} color="primary"/>} label="Nie" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl value={form.plan} className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Czy zamówienie było ujęte w planie postępowań o udzielenie zamówień
                        </FormLabel>
                        <RadioGroup  name="plan">
                            <FormControlLabel disabled value='true' control={<Radio checked={form.plan} color="primary"/>} label="Tak" />
                            <FormControlLabel disabled value='false' control={<Radio checked={!form.plan} color="primary"/>} label="Nie" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Numer w BZP, pod którym został zamieszczony plan postępowań o udzielenie zamówień</FormLabel>
                        <TextValidator
                            className={classes.input}
                            value={form.bzpNumer}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Ientyfikator pozycji planu
                            postępowań</FormLabel>
                        <TextValidator
                            value={form.planId}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Informacja o podstawie prawnej prowadzonego postępowania</FormLabel>
                        <TextValidator
                            disabled
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Informacja, czy wartość szacunkowa zamówienia mieści się poniżej progów ustalonych
                            na podstawie art. 3 ust 2</FormLabel>
                        <TextValidator
                            disabled
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"/>
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.labelRadio}>Informacje dodatkowe</FormLabel>
                        <TextValidator
                            value={form.addInfoInfo}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled/>
                </Form>
                <Button
                    onClick={props.handleBack}
                    className={classes.button}>
                    Wróć
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleNext}
                    className={classes.button}
                    type="submit"
                >Dalej</Button>
            </ValidatorForm>
        </>

    )
}

export default Summary;