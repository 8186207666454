import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormLabel from "@material-ui/core/FormLabel";
import {MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Input from '@material-ui/core/Input';
import FileInput from "./FileInput";
import Hints from "./Hints";
import HintsInfo from "./HintsInfo";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../state/auction/auctionActions";
import Button from "@material-ui/core/Button";
import {fetchAuctionId, fetchAuctionItemId, fetchEuProjects, fetchHint} from "../services/fetchId";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../state/hints1/hintsActions";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "./StackedSnackbar";


const Form = tw.div`mb-5 mx-5 mr-3`;


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'justify',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1.1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
    one: {
        width: '95%',
    },
    two: {
        marginBottom: '80px',
        width: '95%'
    },
    three: {
        marginBottom: '160px',
        width: '95%'
    },
}));

const UEFunding = (props) => {

    const isHintOn = useSelector(state => state.toggleHints.hints);

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
    }

    const setBooleanValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value === 'true'))
    }

    console.log(form)
    const classes = useStyles();


    const [UEFunds, setUEFunds] = useState(false);
    const [addInput, setAddInput] = useState(false);
    const [programName, setProgramName] = useState(false);
    const [programLogo, setProgramLogo] = useState(false);
    const [addNameInput, setAddNameInput] = useState(false);
    const [euProjects, setEuProjects] = useState([]);
    const [auctionItem, setAuctionItem] = useState();
    const [ueFundsVal, setUeFundsVal] = useState('');
    const [ueFundsForm, setUeFundsForm] = useState();
    const [show, setShow] = useState(true);

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].find(auctionItem => auctionItem.auction.slice(14) === props.id))
            }
        )
        if(!!auctionItem){
            setUeFundsVal(auctionItem.ueFunds.toString())
        }
    }, [auctionItem])

    useEffect(() => {
        setUeFundsForm(
            <Form className={classes.label}>
                <FormControl className={classes.label}>
                    <FormLabel className={classes.label}>Czy zamówienie dotyczy projektu unijnego lub programu współfinansowanego ze
                        środków Unii Europejskiej
                    </FormLabel>
                    <RadioGroup value={ueFundsVal}  name="ueFunds" onChange={(e) => {setBooleanValue(e); handleChange(e); setUeFundsVal(e.target.value)}}>
                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                    </RadioGroup>
                </FormControl>
            </Form>
        )
    }, [ueFundsVal])

    const handleChange = (e) => {
        setUEFunds(e.target.value);
        if (e.target.value === 'true'){
            setAddInput(true);
        }else if (e.target.value === 'false'){
            setAddInput(false);
        }
    }

    const programLogoHandler = (e) => {
        setProgramLogo(e.target.value);
    }

    const programNameHandler = (e) => {
        setProgramName(e.target.value);
        if (e.target.value === 'inny'){
            setAddNameInput(true);
        }else {
            setAddNameInput(false);
        }
    }

    useEffect(() => {
        fetchEuProjects().subscribe(data => {
            setEuProjects(data['hydra:member'])
        })
    }, [])

    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[9].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[10].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            enqueueSnackbar({
                message: <p>{hints[11].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <ValidatorForm >
                    {props.edit === true && !!auctionItem ? (
                        <>
                            {ueFundsForm}
                        {ueFundsVal === 'true' ? (
                                <React.Fragment>
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label}>
                                            <FormLabel className={classes.label}>Wpisz nazwę projektu zgodnie z umowa o dofinansowanie</FormLabel>
                                            <TextValidator
                                                value={form.nameUe}
                                                defaultValue={auctionItem.nameUe}
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                label="Nazwa projektu"
                                                variant="outlined"
                                                placeholder="Nazwa projektu"
                                                required
                                                name="nameUe"/>
                                        </FormControl>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label}>
                                            <FormLabel className={classes.label}>Numer identyfikacyjny projektu</FormLabel>
                                            <TextValidator
                                                value={form.numUe}
                                                defaultValue={auctionItem.numUe}
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                label="Numer identyfikacyjny"
                                                variant="outlined"
                                                placeholder="Numer identyfikacyjny"
                                                required
                                                name="numUe"/>
                                        </FormControl>

                                    </Form>
                                    <Form className={classes.label}>
                                        <FormControl className={classes.label}>
                                            <FormLabel className={classes.label}>Wybierz z listy nazwę programu unijnego</FormLabel>
                                            <SelectValidator
                                                defaultValue={auctionItem.euProject}
                                                onChange={setValue}
                                                value={form.euProject}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                variant="outlined"
                                                required
                                                name="euProject">
                                                {euProjects.map((c, index) =>{
                                                        return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                                    }
                                                )}
                                            </SelectValidator>
                                        </FormControl>

                                    </Form>
                                    {
                                        addNameInput ? (
                                            <Form className={classes.label}>
                                                <FormLabel className={classes.label}>Wpisz swoją nazwę programu unijnego</FormLabel>
                                                <TextValidator
                                                    defaultValue={auctionItem.myProgramName}
                                                    value={form.myProgramName}
                                                    onChange={setValue}
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    label="Wpisz nazwę projektu"
                                                    variant="outlined"
                                                    placeholder="Wpisz nazwę projektu"
                                                    required
                                                    name="myProgramName"/>
                                            </Form>
                                        ) : (
                                            ''
                                        )
                                    }
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                    </>) : (
                        <div onMouseOver={handleClick}>
                            <StackedSnackbar/>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Czy zamówienie dotyczy projektu unijnego lub programu współfinansowanego ze
                                    środków Unii Europejskiej
                                    </FormLabel>
                                    <RadioGroup className={classes.input} value={String(form.ueFunds)}  name="ueFunds" onChange={(e) => {setBooleanValue(e); handleChange(e)}}>
                                        <FormControlLabel value='true' control={<Radio color="primary"/>} label="Tak" />
                                        <FormControlLabel value='false' control={<Radio color="primary"/>} label="Nie" />
                                    </RadioGroup>
                                </FormControl>
                            </Form>
                        {addInput ? (
                            <React.Fragment>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Wpisz nazwę projektu zgodnie z umowa o dofinansowanie</FormLabel>
                                        <TextValidator
                                        value={form.nameUe}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Nazwa projektu"
                                        variant="outlined"
                                        placeholder="Nazwa projektu"
                                        required
                                        name="nameUe"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Numer identyfikacyjny projektu</FormLabel>
                                    <TextValidator
                                        value={form.numUe}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Numer identyfikacyjny"
                                        variant="outlined"
                                        placeholder="Numer identyfikacyjny"
                                        required
                                        name="numUe"/>
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl>
                                    <FormLabel className={classes.label}>Wybierz z listy nazwę programu unijnego</FormLabel>
                                    <SelectValidator
                                        onChange={setValue}
                                        value={form.euProject}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        variant="outlined"
                                        required
                                        name="euProject">
                                        {euProjects.map((c, index) =>{
                                            return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                        }
                                            )}
                                    </SelectValidator>
                                </FormControl>

                            </Form>
                    {
                        addNameInput ? (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Wpisz swoją nazwę programu unijnego</FormLabel>
                            <TextValidator
                                value={form.myProgramName}
                                onChange={setValue}
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                label="Wpisz nazwę projektu"
                                variant="outlined"
                                placeholder="Wpisz nazwę projektu"
                                required
                                name="myProgramName"/>
                        </Form>
                        ) : (
                        ''
                        )
                    }
                        </React.Fragment>
                        ) : (
                        ''
                        )}
                        </div>
                    )}

                    <Button
                        onClick={() => {props.handleBack(); handleDimissAll()}}
                        className={classes.button}
                    >
                        Wróć
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {props.handleNext(); handleDimissAll()}}
                        className={classes.button}
                        type="submit"
                    >Dalej</Button>
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}

export default UEFunding;