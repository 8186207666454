import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import {
    fetchAuctionItemId, fetchAuctionItemParts, fetchAuctionItemParts1, fetchAuctionItemPartsId,
    fetchAuctionWithId,
    fetchBipClientType,
    fetchBipCoreBusiness, fetchCountry1,
    fetchCustomer,
    fetchDuueClientType,
    fetchDuueCoreBusiness, fetchHint, fetchVoivodeship
} from "../../services/fetchId";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setValues, setValuesAuctionParts} from "../../state/auction/auctionActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import StackedSnackbar from "../StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const OrderDescription = ({id, no, edit, auctionObject}) => {


    const [customer, setCustomer] = useState([]);
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [country, setCountry] = useState();
    const [voivodeshipForm, setVoivodeshipForm] = useState([])
    const [countryForm, setCountryForm] = useState([])
    const customerId = localStorage.getItem('customerId');
    const [voivodeshipIri, setVoivodeshipIri] = useState();
    const [countryIri, setCountryIri] = useState();

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)) - 1);
        })
        fetchCustomer(customerId).subscribe(data => {
            setCountryId(parseInt(data.addressCountry.slice(15)) - 1);
        })
        fetchVoivodeship().subscribe(data => {
            setVoivodeshipForm(data['hydra:member'])
        })
        fetchCountry1().subscribe(data => {
            setCountryForm(data['hydra:member'])
        })

    }, [])

    useEffect(() => {
        if(!!voivodeshipId){
            fetchVoivodeship().subscribe(data => {
                setVoivodeship(data['hydra:member'][voivodeshipId].name)
                setVoivodeshipIri(data['hydra:member'][voivodeshipId]["@id"])
            })
        }

    }, [voivodeshipId])

    useEffect(() => {
        if(!!countryId){
            fetchCountry1().subscribe(data => {
                setCountry(data['hydra:member'][countryId].englishShortName)
                setCountryIri(data['hydra:member'][countryId]["@id"])
            })
        }

    }, [countryId])



    const classes = useStyles();

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[17].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    let form1;
    if(!!customer.name && !!country && !!voivodeship) {
        form1 =
            <>
            <Typography className={classes.nameSec}>Miejsce świadczenia usług</Typography>
            <FormLabel className={classes.label}>Ulica</FormLabel>
            <Form>
                <TextValidator
                    value={formAuctionParts.serviceAddressStreet}
                    onChange={(e) => setValueAuctionParts(e, no)}
                    name="serviceAddressStreet"
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    defaultValue={customer.addressStreet}
                    variant="outlined"
                />
            </Form>
            <FormLabel className={classes.label}>Nr domu</FormLabel>
            <Form>
                <TextValidator
                    value={formAuctionParts.serviceAddressHouseNumber}
                    onChange={(e) => setValueAuctionParts(e, no)}
                    name="serviceAddressHouseNumber"
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    defaultValue={customer.addressHouseNumber}
                    variant="outlined"
                />
            </Form>
            <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
            <Form>
                <TextValidator
                    value={formAuctionParts.serviceAddressZipCode}
                    onChange={(e) => setValueAuctionParts(e, no)}
                    name="serviceAddressZipCode"
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    defaultValue={customer.addressZipCode}
                    variant="outlined"
                />
            </Form>
            <FormLabel className={classes.label}>Miejscowość
            </FormLabel>
            <Form>
                <TextValidator
                    value={formAuctionParts.serviceAddressCity}
                    onChange={(e) => setValueAuctionParts(e, no)}
                    name="serviceAddressCity"
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    defaultValue={customer.addressCity}
                    variant="outlined"
                />
            </Form>
                <FormLabel className={classes.label}>Państwo</FormLabel>
                <Form>
                    <TextValidator
                        value={formAuctionParts.serviceAddressCountry}
                        onChange={(e) => setValueAuctionParts(e, no)}
                        name="serviceAddressCountry"
                        className={classes.input}
                        id="outlined-basic-u"
                        select
                        variant="outlined"
                        defaultValue={countryIri}
                    >
                        <MenuItem value={countryIri} data-text={country}>{country}</MenuItem>
                        {countryForm.map((c, index) =>{

                                return <MenuItem key={index} value={c["@id"]}>{c.polishShortName}</MenuItem>
                            }
                        )}

                    </TextValidator>

                </Form>
                <FormLabel className={classes.label}>Województwo</FormLabel>
                <Form>
                    <TextValidator
                        value={formAuctionParts.serviceAddressVoivodeship}
                        onChange={(e) => setValueAuctionParts(e, no)}
                        name="serviceAddressVoivodeship"
                        id="outlined-basic-u"
                        className={classes.input}
                        select
                        required
                        variant="outlined"
                        defaultValue={voivodeshipIri}
                    >
                        <MenuItem value={voivodeshipIri}>{voivodeship}</MenuItem>
                        {voivodeshipForm.map((c, index) =>{
                                return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                            }
                        )}
                    </TextValidator>
                </Form>
            <Typography className={classes.nameSec}>Kod NUTS</Typography>
            <FormLabel className={classes.label}>Kod NUTS</FormLabel>
            <Form>
                <TextValidator
                    value={formAuctionParts.serviceNutsCode}
                    onChange={(e) => setValueAuctionParts(e, no)}
                    name="serviceNutsCode"
                    className={classes.input}
                    type="text"
                    id="outlined-basic-u"
                    defaultValue={customer.nutsCode}
                    variant="outlined"
                />
            </Form>
            </>
    }

    return(
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm >

                    {
                        !!auctionObject && (
                            <>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Krótki opis przedmiotu zamówienia częsci {parseInt(no) + 1}
                                            <Tooltip title="(wielkość, zakres, rodzaj i ilość dostaw, usług lub robót budowlanych)"
                                                     placement="bottom">
                                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                                            </Tooltip>
                                        </FormLabel>
                                        <TextValidator
                                            defaultValue={edit === 'edit' ? (auctionObject.shortDescriptionPart) : ''}
                                            className={classes.input}
                                            value={formAuctionParts.shortDescriptionPart}
                                            onChange={(e) => setValueAuctionParts(e, no)}
                                            name="shortDescriptionPart"
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Krótki opis przedmiotu zamówienia częsci"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Główny kod CPV dla częsci {parseInt(no) + 1} zamówienia</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auctionObject.cpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                                <Form className={classes.label}>
                                    <FormControl className={classes.label}>
                                        <FormLabel className={classes.label}>Dodatkowy kod CPV dla częsci {parseInt(no) + 1} zamówienia</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auctionObject.addCpvCode}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Form>
                            </>
                        )
                    }
                    {form1}
                </ValidatorForm>
            </AnimationRevealPage>
        </div>

    )
}

export default OrderDescription