import { dependencies } from "dependencies/Dependeencies";

import React from "react";


export function fetchVoivodeship() {

    const { idService } = dependencies;

    return idService.getVoivodeship();
}

export function fetchVoivodeshipId(voivodeshipId) {

    const { idService } = dependencies;

    return idService.getVoivodeshipId(voivodeshipId);
}

export function fetchCountryId(countryId) {
    const { idService } = dependencies;
    return idService.getCountryId(countryId);
}

export function fetchCountry1() {
    const { idService } = dependencies;
    return idService.getCountry1();
}

export function fetchCountry2() {
    const { idService } = dependencies;
    return idService.getCountry2();
}

export function fetchCountry3() {
    const { idService } = dependencies;
    return idService.getCountry3();
}

export function fetchCountry4() {
    const { idService } = dependencies;
    return idService.getCountry4();
}

export function fetchCountry5() {
    const { idService } = dependencies;
    return idService.getCountry5();
}

export function fetchCountry6() {
    const { idService } = dependencies;
    return idService.getCountry6();
}

export function fetchCountry7() {
    const { idService } = dependencies;
    return idService.getCountry7();
}

export function fetchCountry8() {
    const { idService } = dependencies;
    return idService.getCountry8();
}

export function fetchCountry9() {
    const { idService } = dependencies;
    return idService.getCountry9();
}

export function fetchBipClientType1() {

    const { idService } = dependencies;

    return idService.getBipClientType1();
}

export function fetchBipClientType2() {

    const { idService } = dependencies;

    return idService.getBipClientType2();
}

export function fetchBipCoreBusiness() {

    const { idService } = dependencies;

    return idService.getBipCoreBusiness();
}

export function fetchBipCoreBusinessId(bipCoreBusinessId) {

    const { idService } = dependencies;

    return idService.getBipCoreBusinessId(bipCoreBusinessId);
}

export function fetchDuueClientType() {

    const { idService } = dependencies;

    return idService.getDuueClientType();
}
export function fetchDuueCoreBusiness() {

    const { idService } = dependencies;

    return idService.getDuueCoreBusiness();
}

export function fetchId() {

    const { idService } = dependencies;

    return idService.getId();
}

export function fetchCustomer(customerId) {

    const { idService } = dependencies;

    return idService.getCustomer(customerId);
}

export function fetchCustomers() {

    const { idService } = dependencies;

    return idService.getCustomers();
}


export function fetchUser(userId) {

    const { idService } = dependencies;

    return idService.getUser(userId);
}

export function fetchCustomerId(userId) {

    const { idService } = dependencies;

    return idService.getCustomerId(userId);
}

export function fetchUserId() {

    const { idService } = dependencies;

    return idService.getUserId();
}

export function fetchPassword() {

    const { idService } = dependencies;

    return idService.getUser();
}

export function fetchAuctionId() {

    const { idService } = dependencies;

    return idService.getAuctionId();
}

export function fetchAuctionItemParts(ids) {

    const { idService } = dependencies;

    return idService.getAuctionItemParts(ids);
}

export function fetchAuctionItemParts1(ids) {

    const { idService } = dependencies;

    return idService.getAuctionItemParts1(ids);
}


export function fetchAuctionItemPartsId() {

    const { idService } = dependencies;

    return idService.getAuctionItemPartsId();
}

export function fetchAuction() {

    const { idService } = dependencies;

    return idService.getAuction();
}

export function fetchAuctionWithId(auctionId) {

    const { idService } = dependencies;

    return idService.getAuctionWithId(auctionId);
}

export function fetchAuctionItem(auctionId) {

    const { idService } = dependencies;

    return idService.getAuctionItem(auctionId);
}

export function fetchAuctionItemId() {

    const { idService } = dependencies;

    return idService.getAuctionItemId();
}

export function fetchUsers() {

    const { idService } = dependencies;

    return idService.getUsers();
}

export function patchUser1(data) {

    const { idService } = dependencies;

    return idService.patchUser(data);
}

export function patchCustomer(data) {

    const { idService } = dependencies;

    return idService.patchCustomerData(data);
}

export function fetchEuProjects() {

    const { idService } = dependencies;

    return idService.getEuProjects();
}

export function fetchMe() {

    const { idService } = dependencies;

    return idService.getMe();
}

export function fetchEuProject(projectId) {

    const { idService } = dependencies;

    return idService.getEuProject(projectId);
}

export function fetchHint() {

    const { idService } = dependencies;

    return idService.getHint();
}

export function fetchHint2() {

    const { idService } = dependencies;

    return idService.getHint2();
}




