import React, {useEffect, useRef, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";
import {fetchUsers} from "../services/fetchId";

const Form = tw.div`px-12 py-3 w-128`;
const ButtonPass = tw(PrimaryButton)`mb-8! mt-3! w-full text-base inline-block `;

const EditUsersData = ({open,  setOpen, user, onSubmit}) => {


    const useStyles = makeStyles({
        form: {
            display: 'flex !important',
            justifyContent: 'center !important',
            alignItems: 'center'
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.2em !important',
            fontWeight: 'bolder',
            color: '#5011cc',
            textAlign: 'center',
            marginBottom: '2% !important',
            borderBottom: '1px solid black',
            padding: '2%'
        },

        input: {
            minWidth: '300px',
            marginRight: '0 !important'
        }
    });

    const emailRef = useRef(null);
    const usernameRef = useRef(null);

    const onSubmit1 = () => {
        console.log(user)
        const data = {
            userId : user.userId,
            email : emailRef.current.value,
            username : usernameRef.current.value,
        };

        onSubmit(data);
        setOpen(false);
    };

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    console.log(user)

    return(
        <>
            <Dialog open={open} onClose={handleClose} className={classes.root}>
                <DialogContent>
                    <Typography className={classes.nameSec}>ZMIEŃ DANE UŻYTKOWNIKA</Typography><br/>
                    <ValidatorForm
                        onSubmit={onSubmit1}
                        style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                        {!!user &&
                        <>
                            <Form className={classes.form}>
                                <TextValidator
                                    className={classes.input}
                                    defaultValue={user.email}
                                    inputRef={emailRef}
                                    type="email"
                                    id="outlined-basic-p"
                                    label="Adres email"
                                    variant="outlined"
                                    name="email"
                                />
                            </Form>
                            <Form className={classes.form}>
                                <TextValidator
                                    className={classes.input}
                                    defaultValue={user.name}
                                    inputRef={usernameRef}
                                    type="text"
                                    id="outlined-basic-p"
                                    label="Nazwa użytkownika"
                                    variant="outlined"
                                    name="username"
                                />
                            </Form>
                        </>

                        }

                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <ButtonPass onSubmit={onSubmit1} onClick={onSubmit1} type="submit" style={{width: '70%', margin: 'auto'}}>Zmień dane</ButtonPass>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditUsersData;