import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {NavLink} from "./headers/light2";
import tw from "twin.macro";
import {Linksmenu, Linkssidebar} from "./misc/Links";
import {PrimaryButton} from "./misc/Buttons";
import {Sideheader} from "./misc/Typography";
import CustomCalendar from "../pages/CustomCalendar";
import {useDispatch, useSelector} from "react-redux";
import {closeHints, openHints, toggleHints} from "../state/hints/hintsActions";

const useStyles = makeStyles({
    list: {
        width: 320,
    },
    fullList: {
        width: 'auto',
    },
});
const Logo = styled(NavLink)`
  ${tw`font-black text-3xl! border-b-0 `};`
const drawerWidth = 300;
const LinkSidebar = tw(Linkssidebar)`p-0!`;
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const LinkHeaders = tw.p`text-primary-700 font-bold`
const Button = tw(PrimaryButton)`my-2 w-full text-sm inline-block `;
const SideHeader = tw(Sideheader)``;

const SidebarRight = () => {



    //const [width, setWidth] = useState(window.innerWidth);
    const windowWidth = useSelector(state => {
        console.log("wtf")
        return state.main.windowWidth
    });




   // const width= window.innerWidth;

    const dispatch = useDispatch();
    const classes = useStyles();

    const [isOn, setIsOn] = React.useState(false);
    const [permanent, setPermanent] = React.useState();
    const [state, setState] = React.useState({
        right: false,
    });
    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.replace('/logout');
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleHintsOn = () => {
        dispatch(openHints());
        setIsOn(true);
    }

    const handleHintsOff = () => {
        dispatch(closeHints());
        setIsOn(false);
    }

    useEffect(()=>{
        console.log('?')
        if (windowWidth < 750){
            setPermanent(false);
        } else {
            setPermanent(true);
        }
    },[windowWidth])

    console.log(permanent);


    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        ><AnimationRevealPage>
            <List>
                <Logo to="/" style={{display: 'flex', justifyContent: 'center'}}>
                    ARKAD
                </Logo>
                <List >
                    <Link to="/myTenders">
                        <ListItem >
                            <LinkMenu>
                                Moje przetargi
                            </LinkMenu>
                        </ListItem>
                    </Link>
                    <Link to="/#">
                        <ListItem>
                            <LinkMenu>
                                Baza wiedzy
                            </LinkMenu>
                        </ListItem>
                    </Link>
                    {
                        isOn ? (
                            <ListItem>
                                <LinkMenu onClick={handleHintsOff}>
                                    Wyłącz podpowiedzi
                                </LinkMenu>
                            </ListItem>
                        ) : (
                            <ListItem>
                                <LinkMenu onClick={handleHintsOn}>
                                    Włącz podpowiedzi
                                </LinkMenu>
                            </ListItem>
                        )
                    }
                </List>
                <Button  style={{display: 'flex', justifyContent: 'center'}} onClick={handleLogout}>
                    Wyloguj się
                </Button>
                <Divider/>
                <List>
                    <ListItem button >
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień krajowych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OPIS POTRZEB I WYMAGAŃ
                            </LinkHeaders>
                            dla zamówień krajowych
                            i unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 50.000 -130.000 zł
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 130.000 – do progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <Link to="/priorInfoNotice">
                        <ListItem button>
                            <LinkSidebar>
                                <LinkHeaders>
                                    WSTĘPNE OGŁOSZENIE
                                    INFORMACYJNE
                                </LinkHeaders>
                                – w Dzienniku
                                Urzędowym Unii Europejskiej dla zamówień
                                powyżej progów unijnych
                            </LinkSidebar>
                        </ListItem>
                    </Link>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W DZIENNIKU
                                URZĘDOWYM UNII EUROPEJSKIEJ
                            </LinkHeaders>
                            - dla zamówień powyżej progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OŚWIADCZENIA
                                DO SPECYFIKACJI
                            </LinkHeaders>
                        </LinkSidebar>
                    </ListItem>
                </List>
                <Divider />
            </List>
        </AnimationRevealPage>
            <SideHeader>MOJE KALENDARIUM</SideHeader>
            <CustomCalendar/>

        </div>

    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        permanent ? (
                                <Drawer anchor={anchor} variant="permanent">
                                    {list(anchor)}
                                </Drawer>

                        ) : (
                            <>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(anchor, true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon style={{fontSize: '50px', textAlign: 'right'}}/>
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </div>
    );
}

export default SidebarRight;