import React from "react";
import Header, {LogoLink, NavLink, NavLinks} from "./light";
import {Link} from "react-router-dom";
import tw from "twin.macro";
import {PrimaryButton} from "../misc/Buttons";


const AccountHeader = () => {

    const Button = tw(PrimaryButton)`mt-3 mb-3 text-sm inline-block `;

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.setItem("token1", "DASZXASDDASD ASxHzCAWASD");
        window.location.replace('/logout');

    }

    const links = [
        <NavLinks key={1}>
            <NavLink to="/#">Moje przetargi</NavLink>
            <NavLink to="/knowledge">Baza wiedzy</NavLink>
            <NavLink to="/#">Włącz podpowiedzi</NavLink>
            <Link to="/login">
                <Button  onClick={handleLogout}>
                    Wyloguj się
                </Button>
            </Link>
        </NavLinks>
    ];

    return(
        <Header links={links}/>
    )
}

export default AccountHeader;