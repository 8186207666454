import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import {NonActiveButton, NotActiveButton, PrimaryButton} from "../misc/Buttons";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import {fetchAuctionItemId, fetchAuctionItemParts1, fetchHint} from "../../services/fetchId";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";
import StackedSnackbar from "../StackedSnackbar";
import CloseIcon from "@material-ui/icons/Close";

const Form = tw.div`mb-5 mx-5 mr-3`;
const ButtonA = tw(PrimaryButton)`mb-3 text-base w-64`
const ButtonN = tw(NonActiveButton)`mb-3 text-base w-64`

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));

const OptionsPartI = ({no, id, edit, auctionObject}) => {

    const classes = useStyles();

    const [auctionItem, setAuctionItem] = useState('');
    const [auctionItemPartsId, setAuctionItemPartsId] = useState();
    const [auctionItemParts, setAuctionItemParts] = useState();


    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )
    },[])


    useEffect(() => {
        if(!!auctionItem){
            setAuctionItemPartsId((auctionItem.auctionItemParts[no]).slice(24));
        }

        fetchAuctionItemParts1(auctionItemPartsId).subscribe(
            data => setAuctionItemParts(data)
        )

    }, [auctionItem, auctionItemPartsId])

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[22].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };


    return(
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    {
                        !!auctionItemParts && (
                            <>
                                {auctionItemParts.partValueOp === 0 ?
                                    (
                                        <Form className={classes.label}>
                                            <FormControl className={classes.label}>
                                                <TextValidator
                                                    className={classes.input}
                                                    type="text"
                                                    id="outlined-basic-u"
                                                    disabled
                                                    value={"Zamówienie nie obejmuje opcji"}
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                            </FormControl>
                                        </Form>
                                    ) :
                                    (
                                        <>
                                            <Form className={classes.label}>
                                                <FormControl className={classes.label}>
                                                    <TextValidator
                                                        className={classes.input}
                                                        type="text"
                                                        id="outlined-basic-u"
                                                        disabled
                                                        value={"Zamówienie obejmuje opcje"}
                                                        variant="outlined"
                                                        multiline
                                                        rows={5}
                                                    />
                                                </FormControl>
                                            </Form>
                                            <Form className={classes.label}>
                                                <FormControl className={classes.label}>
                                                    <FormLabel className={classes.label}>Rodzaj opcji</FormLabel>
                                                    <TextValidator
                                                        value={formAuctionParts.optionType}
                                                        onChange={(e) => setValueAuctionParts(e, no)}
                                                        defaultValue={edit === 'edit' ? (auctionObject.optionType) : ''}
                                                        className={classes.input}
                                                        type="text"
                                                        id="outlined-basic-u"
                                                        label="Rodzaj opcji"
                                                        variant="outlined"
                                                        multiline
                                                        name="optionType"
                                                        rows={5}
                                                    />
                                                </FormControl>
                                            </Form>
                                            <Form className={classes.label}>
                                                <FormControl className={classes.label}>
                                                    <FormLabel className={classes.label}>Maksymalna wartość opcji</FormLabel>
                                                    <TextValidator
                                                        value={auctionItemParts.partValueOp}
                                                        className={classes.input}
                                                        type="text"
                                                        id="outlined-basic-u"
                                                        disabled
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Form>
                                            <Form className={classes.label}>
                                                <FormControl className={classes.label}>
                                                    <FormLabel className={classes.label}>Okoliczności skorzystania z opcji</FormLabel>
                                                    <TextValidator
                                                        value={formAuctionParts.optionCircumstances}
                                                        onChange={(e) => setValueAuctionParts(e, no)}
                                                        defaultValue={edit === 'edit' ? (auctionObject.optionCircumstances) : ''}
                                                        className={classes.input}
                                                        type="text"
                                                        name="optionCircumstances"
                                                        id="outlined-basic-u"
                                                        label="Okoliczności skorzystania z opcji"
                                                        variant="outlined"
                                                        multiline
                                                        rows={5}
                                                    />
                                                </FormControl>
                                            </Form>
                                        </>
                                    )}
                            </>
                        )

                    }
                </ValidatorForm>
            </AnimationRevealPage>
        </div>

    )
}

export default OptionsPartI