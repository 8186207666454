import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {MenuItem, Paper, Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import tw from "twin.macro";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from '@material-ui/core';
import {
    fetchAuctionItemId, fetchAuctionItemPartsId,
    fetchAuctionWithId,
    fetchCountry1,
    fetchCustomer, fetchEuProject,
    fetchVoivodeship
} from "../../services/fetchId";
export const theme = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '0.175rem',
            }
        }
    }
});

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl `;
const Form = tw.div`flex items-center justify-between`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100%',
        marginRight: '35% !important',
        marginTop: '1%',
        maxWidth: '800px !important',
        minWidth: '350px !important',
    },
    label: {
        fontSize: '1rem !important',
    },
    labelRadio: {
        maxWidth: '750px !important',
        minWidth: '200px !important',
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    labelNormal: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1rem !important',
        textAlign: 'left',
        lineHeight: '1.4',
        color: 'black',
        width: 'auto',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.2em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        borderBottom: '1px solid black',
        padding: '1%'
    },
    root: {
        flexGrow: 1,
        alignItems: 'center',
        fontSize: '1em !important'
    },

    control: {
        padding: theme.spacing(2),
    },
}));

const Summary = ({id}) => {

    const [auction, setAuction] = useState('');
    const [auctionItem, setAuctionItem] = useState('');
    const [customer, setCustomer] = useState([]);
    const [voivodeshipId, setVoivodeshipId] = useState();
    const [voivodeship, setVoivodeship] = useState();
    const [countryId, setCountryId] = useState();
    const [ueFunds, setUeFunds] = useState('');
    const [country, setCountry] = useState();
    const [euProjectId, setEuProjectId] = useState();
    const [euProject, setEuProject] = useState('');
    const customerId = localStorage.getItem('customerId');
    const [auctionItemParts, setAuctionItemParts] = useState();
    const [auctionItemPartsId, setAuctionItemPartsId] = useState();
    const [auctionParts, setAuctionParts] = useState();
    const [parts, setParts] = useState(parseInt(localStorage.getItem('parts')));
    const [form4, setForm4] = useState();


    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => {
                setAuction(data);
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )
    }, [])

    useEffect(() => {
        fetchCustomer(customerId).subscribe(data => {
            setCustomer(data)
            setVoivodeshipId(parseInt(data.addressVoivodeship.slice(18)) - 1);
        })

        fetchCustomer(customerId).subscribe(data => {
            setCountryId(parseInt(data.addressCountry.slice(15)) - 1);
        })
    }, [])

    useEffect(() => {
        if(!!euProjectId){
            fetchEuProject(euProjectId.slice(17)).subscribe(
                data => {
                    setEuProject(data)})}
    }, [euProjectId])

    useEffect(() => {
        fetchAuctionItemId().subscribe(
            data => {
                setUeFunds(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0].ueFunds)
                setEuProjectId(data['hydra:member'].filter(auction => auction.auction.slice(14) === id)[0].euProject)
            }
        )
    }, [])

    if(!!voivodeshipId){
        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'][voivodeshipId].name)
        })
    }

    if(!!countryId){
        fetchCountry1().subscribe(data => {
            setCountry(data['hydra:member'][countryId].englishShortName)
        })
    }

    const classes = useStyles();

    const form = useSelector(state => state.auction.form);
    const formAuction = useSelector(state => state.auction.formAuction);
    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);

    useEffect(() => {
        if(!!auctionItem){
            setAuctionItemParts(auctionItem.auctionItemParts);
        }

    }, [auctionItem])

    useEffect(() => {
        if(!!auctionItemParts){
            setAuctionItemPartsId(auctionItemParts.map(part => part.slice(24)))
        }

    }, [auctionItemParts])

    useEffect(() => {
        if(!!auctionItemPartsId){
            fetchAuctionItemPartsId().subscribe(
                data => {
                    setAuctionParts(data['hydra:member'].filter(auction => auctionItemPartsId.includes(auction['@id'].slice(24))));
                }
            )
        }
    }, [auctionItemPartsId])

    let part = [];


    useEffect(() => {
        for (let i=0; i<parts; i++){
            part.push(
                <>
                    <Typography className={classes.nameSec}>Część {i+1}</Typography>

                    {
                        !!auctionParts && (
                            (auctionParts.length > 0) && (
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nazwa części {i+1}</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            disabled
                                            value={auctionParts[i].partName}
                                            variant="outlined"
                                        />
                                    </Form>
                                )
                        )
                    }
                    <Form className={classes.label}>
                            <FormLabel className={classes.label}>Krótki opis przedmiotu zamówienia części {i+1}</FormLabel>
                            <TextValidator
                                name="shortAuctionDescription"
                                className={classes.input}
                                type="text"
                                id="outlined-basic-u"
                                disabled
                                label="Krótki opis"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                    </Form>
                </>)
        }
        setForm4(part)
    }, [auctionParts])

    let form1;
    let form2;
    let form3;

    if(form.joinedPart === false){
        form3 =
            <>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Do których części zamawiający zastrzega sobie prawo do udzielenia łącznie </FormLabel>
                        <TextValidator
                            value={form.numberJoinedPart}
                            name="numberJoinedPart"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            label="Maksymalna ilość części"
                            placeholder="Maksymalna ilość części"
                            variant="outlined"
                        />
                </Form>
            </>

    }


    if(form.oneExecutorPart === true){
        form2 =
            <>
                <Form className={classes.label}>
                    <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                        <RadioGroup name="joinedPart">
                            <FormControlLabel disabled value="true" control={<Radio checked={form.joinedPart === true} color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            <FormControlLabel disabled value="false" control={<Radio checked={form.joinedPart === false} color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {form3}
            </>
    } else if (form.oneExecutorPart === false){
        form2 =
            <>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                        <TextValidator
                            value={form.maxNumberPart}
                            name="maxNumberPart"
                            className={classes.input}
                            type="number"
                            id="outlined-basic-u"
                            disabled
                            label="Maksymalna ilość części"
                            placeholder="Maksymalna ilość części"
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Kryteria lub zasady, mające zastosowanie do ustalenia,
                            które części zamówienia zostaną udzielone jednemu wykonawcy, w przypadku
                            wyboru jego oferty w większej niż maksymalnej liczbie części.
                        </FormLabel>
                        <TextValidator
                            value={form.partRequirements}
                            name="partRequirements"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            placeholder="Kryteria i zasady"
                            label="Kryteria i zasady"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                </Form>
                <Form className={classes.label}>
                    <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                        <RadioGroup name="joinedPart">
                            <FormControlLabel value="true" disabled control={<Radio checked={form.joinedPart === true} color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            <FormControlLabel value="false" disabled control={<Radio checked={form.joinedPart === false} color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {form3}
            </>
    }

    if (form.numberParts === '1') {
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl value={form.oneExecutorPart} className={classes.label} name="oneExecutorPart">
                        <RadioGroup name="oneExecutorPart">
                            <FormControlLabel disabled value="true" control={<Radio checked={form.oneExecutorPart === true} color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na wszystkie części" />
                            <FormControlLabel disabled value="false" control={<Radio checked={form.oneExecutorPart === false} color="primary"/>} label="Zamówienie może zostać udzielone temu samemu wykonawcy na określoną ilość części" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {form2}
            </>
    } else if (form.numberParts === '2'){
        form1 =
            <>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Maksymalna ilość części, na którą wykonawca może złożyć ofertę </FormLabel>
                        <TextValidator
                            value={form.maxNumberExecutorPart}
                            name="maxNumberExecutorPart"
                            className={classes.input}
                            type="number"
                            id="outlined-basic-u"
                            disabled
                            label="Maksymalna ilość części"
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                    <FormControl value={form.joinedPart} className={classes.label} name="joinedPart">
                        <RadioGroup name="joinedPart">
                            <FormControlLabel disabled value="true" control={<Radio checked={form.joinedPart === true} color="primary"/>} label="Zamawiający nie zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                            <FormControlLabel disabled value="false" control={<Radio checked={form.joinedPart === false} color="primary"/>} label="Zamawiający zastrzega sobie prawa do udzielenia łącznie wybranych części lub grup części zamówienia" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {form3}
            </>
    }


    return (
        <>
            <ValidatorForm >
                <Typography className={classes.nameSec}>Strona tytułowa</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Nazwa Zamawiającego</FormLabel>
                    <TextValidator
                        className={classes.input}
                        name="nameOrdering"
                        disabled
                        value={auctionItem.nameOrdering}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Nazwa zamówienia</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        name="orderName"
                        value={auction.orderName}
                        disabled
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Numer referencyjny zamówienia</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={auction.refNumber}
                        name="refNumber"
                    />
                </Form>
                <Typography className={classes.nameSec}>Informacje wprowadzające</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Informacje wprowadzające
                        <Tooltip title="Informacje pomocne do prawidłowego zrozumienia SWZ, np. objaśnienia użytw w specyfikacji określeń" placement="bottom">
                            <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                        </Tooltip>
                    </FormLabel>
                    <TextValidator
                        value={form.bgInfo}
                        className={classes.input}
                        name="bgInfo"
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        variant="outlined"
                        multiline
                        rows={5}
                    />
                </Form>
                <Typography className={classes.nameSec}>Dane zamawiającego</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Nazwa zamawiającego</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        value={customer.name}
                        variant="outlined"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Ulica</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.addressStreet}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Nr domu</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.addressHouseNumber}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.addressZipCode}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Miejscowość</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.addressCity}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Województwo</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={voivodeship}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Państwo</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={country}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Kod NUTS </FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.nutsCode}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>NIP</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.nip}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>REGON</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.regon}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Numer telefonu</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.phoneNumber}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Numer faksu</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.faxNumber}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Adres e-mail</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.email}
                        name="proxy"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Adres strony internetowej</FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        variant="outlined"
                        disabled
                        value={customer.www}
                        name="proxy"
                    />
                </Form>
                <Typography className={classes.nameSec}>Strony internetowe zamówienia</Typography>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Adres strony internetowej postępowania
                        <Tooltip title="Adres strony internetowej, na której udostępniane będą zmiany i wyjaśnienia treści SWZ oraz inne dokumenty zamówienia bezpoœrednio związane z postępowaniem o udzielenie zamówienia"
                                 placement="bottom">
                            <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                        </Tooltip>
                    </FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        value={auctionItem.urlDocuments}
                        name="urlTools"
                        variant="outlined"
                    />
                </Form>
                <Form className={classes.label}>
                    <FormLabel className={classes.label}>Adres dodatkowej strony internetowej
                        <Tooltip title="Adres strony internetowej, pod którym wykonawca będzie mógł uzyskać dostęp do narzędzii urządzeñ lub formatów plików, które nie są ogólnie dostępne"
                                 placement="bottom">
                            <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                        </Tooltip>
                    </FormLabel>
                    <TextValidator
                        className={classes.input}
                        type="text"
                        id="outlined-basic-u"
                        disabled
                        value={auctionItem.urlTools}
                        variant="outlined"
                    />
                </Form>
                <Typography className={classes.nameSec}>Tryb udzialenia zamówienia</Typography>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Tryb udzialenia zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={'Ref Tryb'}
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Podstawa prawna</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            value={auctionItem.legalBasis}
                            variant="outlined"
                        />
                </Form>
                {
                    ueFunds === true &&
                    (
                        <Form className={classes.label}>
                                <FormLabel className={classes.label}>Nazwa programu unijnego w ramach którego realizowane jest zamówienie</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={euProject.name}
                                    variant="outlined"
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Negocjacje</Typography>
                {auctionItem.negotiation === 'z' || auctionItem.negotiation === 'możliwość' ?
                    (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Negocjacje</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={'Zamawiający przewiduje możliwość prowadzenia negocjacji w celu wyboru najkorzystniejszej oferty.'}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                        </Form>
                    ) : (
                        <Form className={classes.label}>
                            <FormLabel className={classes.label}>Negocjacje</FormLabel>
                                <TextValidator
                                    className={classes.input}
                                    type="text"
                                    id="outlined-basic-u"
                                    disabled
                                    value={'Zamawiający nie przewiduje prowadzenia negocjacji w celu wyboru najkorzystniejszej oferty.'}
                                    variant="outlined"
                                />
                        </Form>
                    )
                }
                <Typography className={classes.nameSec}>Oferty częściowe</Typography>
                {localStorage.getItem('parts') === '0' ?
                    (
                        <>
                            <Form className={classes.label}>
                                <FormLabel className={classes.label}>Oferty częściowe</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        disabled
                                        value={'Zamawiający nie dopuszcza składania ofert częściowych.'}
                                        variant="outlined"
                                    />
                            </Form>
                            {
                                !!auctionItem && (
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Powód niedokonania podziału na części                                                  </FormLabel>
                                        <TextValidator
                                            value={auctionItem.noPart}
                                            disabled
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            variant="outlined"
                                            name="noPart"/>
                                    </Form>
                                )
                            }

                        </>

                    ) : (
                        <>
                            <Form className={classes.label}>
                                <FormLabel className={classes.label}>Oferty częściowe</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        disabled
                                        value={'Zamawiający dopuszcza składanie ofert częściowych.'}
                                        variant="outlined"
                                    />
                            </Form>
                            <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Ilość części</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        disabled
                                        value={localStorage.getItem('parts')}
                                        variant="outlined"
                                    />
                            </Form>
                            {form4}
                        </>
                    )
                }
                <Typography className={classes.nameSec}>Części zamówienia dla 1 wykonawcy</Typography>
                <Form className={classes.label}>
                    <FormControl value={form.numberParts} className={classes.label} name="numberParts">
                        <RadioGroup name="numberParts">
                            <FormControlLabel value="1" disabled control={<Radio checked={form.numberParts === "1"} color="primary"/>} label="Wykonawca może złożyć ofertę na wszystkie części zamówienia" />
                            <FormControlLabel value="2" disabled control={<Radio checked={form.numberParts === "2"} color="primary"/>} label="Wykonawca może złożyć ofertę na określoną ilość części zamówienia" />
                            <FormControlLabel value="3" disabled control={<Radio checked={form.numberParts === "3"} color="primary"/>} label="Wykonawca może złożyć ofertę tylko na jedną część zamówienia" />
                        </RadioGroup>
                    </FormControl>
                </Form><br/>
                {form1}
            </ValidatorForm>
        </>

    )
}

export default Summary;