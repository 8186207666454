import {createActions} from 'redux-actions'

export const {
    openHints,
    closeHints,
    closeHint,
    setCurrentPage
} = createActions({
    OPEN_HINTS: () => ({}),
    CLOSE_HINTS: () => ({}),
    CLOSE_HINT: (index, page) => ({index, page}),
    SET_CURRENT_PAGE: (page) =>({page}),
})