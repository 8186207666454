import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionItem, fetchAuctionItemId, fetchAuctionWithId} from "../../services/fetchId";
import {useSelector} from "react-redux";

const Form = tw.div`mb-5 mx-5 mr-3`;


const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    }
}));

const TitlePage = ({id}) => {

    const classes = useStyles();
    const [auction, setAuction] = useState('');
    const [auctionItem, setAuctionItem] = useState('');

    const form = useSelector(state => state.auction.form);
    const formAuction = useSelector(state => state.auction.formAuction);

    console.log(form)
    console.log(formAuction)

    useEffect(() => {
        fetchAuctionWithId(id).subscribe(
            data => {
                setAuction(data);
            }
        )
        fetchAuctionItemId().subscribe(
            data => {
                setAuctionItem(data['hydra:member'].filter(auc => auc.auction.slice(14) === id)[0]);
            }
        )
    }, [])

    console.log(auctionItem);


    return(
        <AnimationRevealPage>
            <ValidatorForm >
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Nazwa zamawiającego</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            disabled
                            name="nameOrdering"
                            value={auctionItem.nameOrdering}
                            variant="outlined"
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Nazwa zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            name="orderName"
                            disabled
                            value={auction.orderName}
                        />
                </Form>
                <Form className={classes.label}>
                        <FormLabel className={classes.label}>Numer referencyjny zamówienia</FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            disabled
                            value={auction.refNumber}
                            name="refNumber"
                        />
                </Form>
            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default TitlePage