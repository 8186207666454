import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const EvaluationPartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [value2, setValue2] = useState();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifProtection === true && 2));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }



    let form1;


    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Wysokość zabezpieczenia należytego wykonania umowy w procentach</FormLabel>
                        <TextValidator
                            value={formAuctionParts.protectionValue}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.protectionValue) : ''}
                            name="protectionValue"
                            type="number"
                            min="0"
                            max="100"
                            step="1"
                            className={classes.input}
                            id="outlined-basic-u"
                            label="Wysokość zabezpieczenia w procentach"
                            placeholder="Wysokość zabezpieczenia w procentach"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Kwota</FormLabel>
                        <TextValidator
                            value={formAuctionParts.protectionValue1}
                            onChange={(e)=> {setValue2(e.target.value); setValueAuctionParts(e, no)}}
                            defaultValue={edit === 'edit' ? (auctionObject.protectionValue1) : ''}
                            validators={['matchRegexp:^[0-9]+\.[0-9]{2}$']}
                            errorMessages={['Wprowadź poprawny format: x,xx']}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Kwota"
                            variant="outlined"
                            name="protectionValue1"
                            placeholder="Kwota"
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Informacja o formach wnoszenia zabezpieczenia należytego wykonania umowy</FormLabel>
                        <TextValidator
                            value={formAuctionParts.protectionInfo}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.protectionInfo) : 'Tekst wstawiony przez system'}
                            name="protectionInfo"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={formAuctionParts[no].ifProtection.toString()} name="ifProtection">
                            <RadioGroup name="ifProtection" defaultValue={edit === 'edit' && auctionObject.ifProtection.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e);}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje zabezpieczenia należytego wykonania umowy" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje zabezpieczenie należytego wykonania umowy" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default EvaluationPartI;