import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import AnimationRevealPage2 from "../../helpers/AnimationRevealPage2";
import {Link} from "react-router-dom";
import tw from "twin.macro";
import {Linksmenu, Linkssidebar} from "../misc/Links";
import {PrimaryButton} from "../misc/Buttons";
import {Info, Sideheader} from "../misc/Typography";
import CustomCalendar from "../../pages/CustomCalendar";
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import ChangePassword from "../ChangePassword";
import {Popover} from "@material-ui/core";
import {changePass} from "../../services/patchPassword";
import {fetchUser} from "../../services/fetchId";
import Swal from "sweetalert2";
import {fetchLogin} from "../../services/fetchLogin";
import {setValues} from "../../state/auction/auctionActions";
import {toggleHints} from "../../state/toggleHints/toggleHintsActions";
import {closeSnackbar as closeSnackbarAction} from "../../state/hints1/hintsActions";

const useStyles = makeStyles({
    list: {
        width: 260,
        height: 'auto'
    },
    fullList: {
        width: 'auto',
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    drawer: {
        overflowX: 'hidden'
    },
    root: {

    }
});

const LinkSidebar = tw(Linkssidebar)`text-center`;
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const LinkHeaders = tw.p`text-primary-700 font-bold`
const Button = tw(PrimaryButton)`mb-8! w-full text-base inline-block `;
const ButtonLogout = tw(PrimaryButton)`mb-10! mt-3! w-full text-base inline-block `;
const SideHeader = tw(Sideheader)`mt-0!`;
const IconDiv = tw.div`flex justify-center mt-6 mb-3 hover:text-primary-800`;


const SidebarRightAccount = () => {

    const windowWidth = useSelector(state => state.main.windowWidth);


    const [isOn, setIsOn] = React.useState(false);
    const [permanent, setPermanent] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [openPass, setOpenPass] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({right: false,});
    const [username, setUsername] = useState([])
    const [elo, setElo] = useState(false)
    const userId = localStorage.getItem('userId');

    const isHintOn = useSelector(defaultState => defaultState.toggleHints.hints);
    const dispatch = useDispatch();
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const toggleHintsHandler = () => {
        dispatch(toggleHints())
        if(isHintOn === true){
            closeSnackbar();
        }
    }

    useEffect(() => {
        fetchUser(userId).subscribe(data => {
            setUsername(data.username)
        })

    }, [userId])

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("customerId");
        localStorage.removeItem("hints");
        window.location.replace('https://platforma-przetargowa.pl/');
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    useEffect(()=>{
        if (windowWidth < 900){
            setPermanent(false);
        } else {
            setPermanent(true);
        }
    },[windowWidth])

    function SimpleDialog(props) {
        const classes = useStyles();
        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <List>
                    <ListItem button >
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień krajowych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OPIS POTRZEB I WYMAGAŃ
                            </LinkHeaders>
                            dla zamówień krajowych
                            i unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 130.000 – do progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <Link to="/priorInfoNotice">
                        <ListItem button>
                            <LinkSidebar>
                                <LinkHeaders>
                                    WSTĘPNE OGŁOSZENIE
                                    INFORMACYJNE
                                </LinkHeaders>
                                – w Dzienniku
                                Urzędowym Unii Europejskiej dla zamówień
                                powyżej progów unijnych
                            </LinkSidebar>
                        </ListItem>
                    </Link>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W DZIENNIKU
                                URZĘDOWYM UNII EUROPEJSKIEJ
                            </LinkHeaders>
                            - dla zamówień powyżej progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OŚWIADCZENIA
                                DO SPECYFIKACJI
                            </LinkHeaders>
                        </LinkSidebar>
                    </ListItem>
                </List>
            </Dialog>
        );
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenPass = () => {
        setOpenPass(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}>
                <List >
                    <br/>
                    <List>
                        <Button  style={{display: 'flex', justifyContent: 'center', width: '70%', margin: 'auto'}}  onClick={handlePopoverClick}>
                            <PersonIcon/>
                        </Button>
                        <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <><br/>
                                <Link to="/myProfile">
                                    <ListItem >
                                        <LinkMenu>
                                            Mój profil
                                        </LinkMenu>
                                    </ListItem>
                                </Link>
                                <ChangePassword active={true} onSubmit={(password, email, oldPassword) => {
                                    fetchLogin(email, oldPassword).subscribe(
                                        user => Swal.fire({
                                                title: 'Sukces',
                                                text: `Hasło zostało pomyślnie zmienione.`,
                                                icon: 'success',
                                                timer: 3000,
                                                showCancelButton: false,
                                                showConfirmButton: false
                                            },
                                        ).then(okay => {
                                            if (okay) {
                                                changePass(password).subscribe()
                                            }
                                        }),
                                        error => {
                                            return Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: `Wprowadzono błędne hasło!`,
                                            })
                                        },)

                                }}/>
                                <Link to="/orderData" >
                                    <ListItem style={{marginBottom: '20px !important'}}>
                                        <LinkMenu >
                                            Dane zamawiającego
                                        </LinkMenu>
                                    </ListItem>
                                </Link>
                                <ButtonLogout  style={{display: 'flex', justifyContent: 'center', width: '70%', margin: 'auto'}} onClick={handleLogout}>
                                    Wyloguj się
                                </ButtonLogout>
                            </>
                        </Popover>
                        <Divider />

                        {/* <ListItem onClick={handleClickOpen}>
                            <LinkMenu>
                                Pliki PDF
                            </LinkMenu>
                        </ListItem> */}
                        {/* <SimpleDialog open={open} onClose={handleClose} /> */}

                        <a href="https://platforma-przetargowa.pl/baza-wiedzy/" target="_blank">
                            <ListItem>
                                <LinkMenu>
                                    Baza wiedzy
                                </LinkMenu>
                            </ListItem>
                        </a>
                        <a href="https://platforma-przetargowa.pl/slownik-pojec/" target="_blank">
                            <ListItem>
                                <LinkMenu>
                                    Słownik pojęć
                                </LinkMenu>
                            </ListItem>
                        </a>

                        <IconDiv>
                            <h2>Infolinia</h2>
                            <PhoneInTalk style={{fontSize: '30px', marginRight: '10px'}}/> +48 507 160 240
                            {/* <FontDownloadIcon style={{fontSize: '30px', marginRight: '10px'}}/>
                            <WbSunnyIcon style={{fontSize: '30px', marginLeft: '10px'}}/> */}
                        </IconDiv>

                        {/* {isHintOn
                            ? (<ListItem onClick={toggleHintsHandler}>
                                <LinkMenu>
                                    Wyłącz podpowiedzi
                                </LinkMenu>
                            </ListItem>)
                            : (<ListItem onClick={toggleHintsHandler}>
                                <LinkMenu>
                                    Włącz podpowiedzi
                                </LinkMenu>
                            </ListItem>)

                        } */}
                    </List>
                </List>

                <Divider />

                <SideHeader>Moje Kalendarium</SideHeader>
                <CustomCalendar/>
        </div>

    );

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        permanent ? (
                            <Drawer className="scrollhost"  anchor={anchor} variant="permanent">
                                {list(anchor)}
                            </Drawer>

                        ) : (
                            <>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(anchor, true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon style={{fontSize: '50px', textAlign: 'right'}}/>
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </div>
    );
}

export default SidebarRightAccount;