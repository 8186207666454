import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {useDispatch, useSelector} from "react-redux";
import {setDateOffersDate, setDateValue, setDateValueAuction, setValues} from "../../state/auction/auctionActions";
import plLocale from "date-fns/locale/pl";


const Form = tw.div`mb-5 mx-5 mr-3`;

const DeadlineOffer = ({form, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));


    const [date, setDate] = React.useState(new Date());
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label}>
                            <FormLabel className={classes.label}>Termin związania ofertą - ilość dni:
                            </FormLabel>
                            <TextValidator
                                className={classes.input}
                                value={form.offersDays}
                                onChange={setValue}
                                type="number"
                                defaultValue={edit === 'edit' ? (auctionItemObject.offersDays) : ''}
                                name="offersDays"
                                id="outlined-basic-u"
                                label="Termin związania ofertą - ilość dni"
                                variant="outlined"
                                />
                        </FormControl>
                    </Form>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} name="offersDate">
                            <FormLabel className={classes.label}>Termin związania ofertą - od dnia:
                            </FormLabel>
                            <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} locale={plLocale} utils={DateFnsUtils}>
                                <DatePicker
                                    format="yyyy/MM/dd"
                                    inputVariant="outlined"
                                    className={classes.input}
                                    style={{width: '100%'}}
                                    value={date}
                                    onChange={(e) => {setDate(e); dispatch(setDateOffersDate(e))}}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Form>

                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default DeadlineOffer;