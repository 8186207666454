import React from 'react';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const useTipStyles = makeStyles((theme) => ({
  arrow: {
    color: "rgba(100, 21, 255)",
  },
  tooltip: {
    backgroundColor: "rgba(100, 21, 255)",
    color: "rgba(247,250,252)",
    fontSize: "0.8em"
  },
}));

function InfoTooltip(props) {
  const classes = useTipStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}


export default function CustomizedTooltips(props) {

  if (props.type === "info")
  return (
      <InfoTooltip title={props.content} placement={props.placement}><InfoIcon /></InfoTooltip>
  );

  else if (props.type === "link")
  return (
      <InfoTooltip title={props.content} placement={props.placement}><LinkIcon /></InfoTooltip>
  );
}
