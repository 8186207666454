import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import '@sweetalert2/theme-material-ui/material-ui.scss';
import EditOrderDataForm from "../components/registerData/EditOrderDataForm";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import {patchCustomer} from "../services/patchCustomer";
import Swal from "sweetalert2";



const MainContainer = tw.div` w-auto `;
const MainContent = tw.div`mt-0 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold mb-5 text-gray-800`;
const FormContainer = tw.div`xl:w-5/6 w-full`;


const EditOrderData = ({
                           headingText = "Edytuj swoje dane",
                       }) => {

    // const userId = localStorage.getItem('userId');
    // const customerId = localStorage.getItem('customerId');

    
    return(
        <AnimationRevealPage>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <MainContainer>
                    <MainContent>
                        <Heading>{headingText}</Heading>
                        <FormContainer>
                            <EditOrderDataForm onSubmit={(data) => {
                                patchCustomer(data).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Dane Zamawiającego zostały zedytowane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Rozpocznij pracę w systemie'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                            window.location.replace('/myAccount')}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane Zamawiającego!`,
                                        })
                                    },)}}/>
                        </FormContainer>
                    </MainContent>
                </MainContainer>
            </div>

        </AnimationRevealPage>)

};

export default EditOrderData
