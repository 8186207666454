import React, {useState} from "react";
import tw from "twin.macro";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {NonActiveButton, NotActiveButton, PrimaryButton} from "../misc/Buttons";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useDispatch, useSelector} from "react-redux";
import {setValuesAuctionParts} from "../../state/auction/auctionActions";

const Form = tw.div`mb-5 mx-5 mr-3`;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '3%',
        fontSize: '1.1em',
        lineHeight: '1.4',
        textAlign: 'left',
        color: 'black',
        width: '100%',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
    radio: {
        marginTop: '3%'
    }
}));

const LocalVisionPartI = ({no, edit, auctionObject}) => {

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.ifLocal === 2 ||  auctionObject.ifLocal === 3 && 2));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const handleChange1 = (e) => {
        if(e.target.value === '1'){
            setAddValue1(1);
        }else if(e.target.value === '2'){
            setAddValue1(2);
        }else if(e.target.value === '3'){
            setAddValue1(3);
        }
    }

    let form;

    if(addValue1 === 2 || addValue1 === 3){
        form =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Opis warunków przeprowadzenia wizji lokalnej
                            <Tooltip title="Zamawiający opisuje warunki przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez neigo dokumentów niezbędnych do realizacji zamówienia"
                                     placement="bottom">
                                <InfoOutlinedIcon style={{marginLeft: '10px'}}/>
                            </Tooltip>
                        </FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionObject.localDescription) : ''}
                            value={formAuctionParts.localDescription}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            name="localDescription"
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            required
                            label="Opis warunków przeprowadzenia wizji lokalnej"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }





    return(
        <AnimationRevealPage>
            <ValidatorForm>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].ifLocal} name="ifLocal">
                        <RadioGroup name="ifLocal" defaultValue={edit === 'edit' && auctionObject.ifLocal} onChange={(e) => {setValueAuctionParts(e, no); handleChange1(e);}}>
                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Zamawiający nie wymaga przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Zamawiający wymaga przeprowadzenia przez wykonawcę wizji lokalnej lub sprawdzenia przez niego niezbędnych dokumentów" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form}
            </ValidatorForm>
        </AnimationRevealPage>
    )
}

export default LocalVisionPartI