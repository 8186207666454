import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import AnimationRevealPage2 from "../../helpers/AnimationRevealPage2";
import {Link} from "react-router-dom";
import tw from "twin.macro";
import {Linksmenu, Linkssidebar, LinksmenuNonActive} from "../misc/Links";
import {PrimaryButton} from "../misc/Buttons";
import {Sideheader} from "../misc/Typography";
import CustomCalendarNonReg from "../../pages/CustomCalendarNonReg";
import {useDispatch, useSelector} from "react-redux";
import {closeHints, openHints, toggleHints} from "../../state/hints/hintsActions";
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import {changePass} from "../../services/patchPassword";
import ChangePassword from "../ChangePassword";
import {Popover} from "@material-ui/core";
import {fetchUser} from "../../services/fetchId";
import CustomCalendar from "../../pages/CustomCalendar";

const useStyles = makeStyles({
    list: {
        width: 260,
    },
    fullList: {
        width: 'auto',
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const LinkSidebar = tw(Linkssidebar)`text-center`;
const LinkMenu = tw(Linksmenu)`p-0! text-center!`;
const LinkMenuNonActive = tw(LinksmenuNonActive)`p-0! text-center!`;
const LinkHeaders = tw.p`text-primary-700 font-bold`
const Button = tw(PrimaryButton)`mb-8! w-full text-base inline-block `;
const ButtonLogout = tw(PrimaryButton)`mb-8! mt-6! w-full text-base inline-block `;
const SideHeader = tw(Sideheader)``;
const IconDiv = tw.div`flex justify-center mt-6 mb-3 hover:text-primary-800`;


const SidebarRightNonReg = () => {

    const windowWidth = useSelector(state => state.main.windowWidth);

   /* const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const currentPage = useSelector(state => state.hints.currentPage);
    const hints = useSelector(state => state.hints.hints[currentPage]);*/

    const [isOn, setIsOn] = React.useState(false);
    const [permanent, setPermanent] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [openPass, setOpenPass] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({right: false,});
    const [username, setUsername] = useState([])


    useEffect(() => {
        fetchUser().subscribe(data => {
            setUsername(data.username)
        })

    }, [])


    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.replace('https://platforma-przetargowa.pl/');
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    /*const handleHintsOn = () => {
        dispatch(openHints());
        setIsOn(true);
        hints.map(hint => {
            enqueueSnackbar(hint.message, {variant:hint.severity});
        })
    }

    const handleHintsOff = () => {
        dispatch(closeHints());
        closeSnackbar();
        setIsOn(false);
    }*/


    useEffect(()=>{
        if (windowWidth < 900){
            setPermanent(false);
        } else {
            setPermanent(true);
        }
    },[windowWidth])

    function SimpleDialog(props) {
        const classes = useStyles();
        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <List style={{textAlign: 'left',}}>
                    <ListItem button >
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień krajowych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                SPECYFIKACJA WARUNKÓW
                                ZAMÓWIENIA
                            </LinkHeaders>
                            - dla zamówień unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OPIS POTRZEB I WYMAGAŃ
                            </LinkHeaders>
                            dla zamówień krajowych
                            i unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 50.000 -130.000 zł
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W BZP
                            </LinkHeaders>
                            – dla zamówień od 130.000 – do progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <Link to="/priorInfoNotice">
                        <ListItem button>
                            <LinkSidebar>
                                <LinkHeaders>
                                    WSTĘPNE OGŁOSZENIE
                                    INFORMACYJNE
                                </LinkHeaders>
                                – w Dzienniku
                                Urzędowym Unii Europejskiej dla zamówień
                                powyżej progów unijnych
                            </LinkSidebar>
                        </ListItem>
                    </Link>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OGŁOSZENIE W DZIENNIKU
                                URZĘDOWYM UNII EUROPEJSKIEJ
                            </LinkHeaders>
                            - dla zamówień powyżej progów unijnych
                        </LinkSidebar>
                    </ListItem>
                    <ListItem button>
                        <LinkSidebar>
                            <LinkHeaders>
                                OŚWIADCZENIA
                                DO SPECYFIKACJI
                            </LinkHeaders>
                        </LinkSidebar>
                    </ListItem>
                </List>
            </Dialog>
        );
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <AnimationRevealPage2>
                <List >
                    <br/>
                    <List>
                        <Button  style={{display: 'flex', justifyContent: 'center', width: '70%', margin: 'auto'}}  onClick={handlePopoverClick}>
                            {username}
                            <PersonIcon style={{marginLeft:'5px'}}/>
                        </Button>
                        <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <><br/>
                                <ListItem >
                                    <LinkMenuNonActive>
                                       Mój profil
                                    </LinkMenuNonActive>
                                </ListItem>
                                <ChangePassword active={false} onSubmit={(data) => {
                                    changePass(data).subscribe(
                                        console.log(data)
                                    )
                                }}/>
                                <ListItem style={{marginBottom: '20px !important'}}>
                                    <LinkMenuNonActive>
                                        Dane zamawiającego
                                    </LinkMenuNonActive>
                                </ListItem>
                                <ButtonLogout  style={{display: 'flex', justifyContent: 'center', width: '70%', margin: 'auto'}} onClick={handleLogout}>
                                    Wyloguj się
                                </ButtonLogout>
                            </>
                        </Popover>

                        <Divider />

                        {/* <IconDiv>
                            <PhoneInTalk style={{fontSize: '30px', marginRight: '10px'}}/> +48 507 160 240
                            <FontDownloadIcon style={{fontSize: '30px', marginRight: '10px'}}/>
                            <WbSunnyIcon style={{fontSize: '30px', marginLeft: '10px'}}/>
                        </IconDiv> */}

                        {/* <ListItem onClick={handleClickOpen}>
                            <LinkMenu>
                                Pliki PDF
                            </LinkMenu>
                        </ListItem> */}
                        <a href="https://platforma-przetargowa.pl/baza-wiedzy/" target="_blank">
                            <ListItem>
                                <LinkMenu >
                                    Baza wiedzy
                                </LinkMenu>
                            </ListItem>
                        </a>
                        <a href="https://platforma-przetargowa.pl/slownik-pojec/" target="_blank">
                            <ListItem>
                                <LinkMenu>
                                    Słownik pojęć
                                </LinkMenu>
                            </ListItem>
                        </a>
                        <SimpleDialog open={open} onClose={handleClose} />

                        {/* {
                            isOn ? (
                                <>
                                    <ListItem>
                                        <LinkMenu //onClick={handleHintsOff}
                                            >
                                            Wyłącz podpowiedzi
                                        </LinkMenu>
                                    </ListItem>
                                </>


                            ) : (
                                <>
                                    <ListItem>
                                        <LinkMenu //onClick={handleHintsOn}
                                            >
                                            Włącz podpowiedzi
                                        </LinkMenu>
                                    </ListItem>
                                </>

                            )
                        } */}
                    </List>
                </List>

                <Divider />

                <SideHeader>Moje Kalendarium</SideHeader>
                <CustomCalendar/>
            </AnimationRevealPage2>
        </div>

    );

    const classes = useStyles();

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        permanent ? (
                            <Drawer anchor={anchor} variant="permanent">
                                {list(anchor)}
                            </Drawer>

                        ) : (
                            <>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(anchor, true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon style={{fontSize: '50px', textAlign: 'right'}}/>
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </div>
    );
}

export default SidebarRightNonReg;