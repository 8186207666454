import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Refund = ({form, setBooleanValue, setValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [value1, setValue1] = useState();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionItemObject.refund === true && 2));


    const handleChange1 = (e) => {
        setValue1(e.target.value);

        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }

    let form1;

    if (addValue1 === 1){
        form1 =
            <>

            </>
    }else if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Warunki zwrotu kosztów udziału w postępowaniu
                        </FormLabel>
                        <TextValidator
                            value={form.refundConditions}
                            onChange={setValue}
                            name="refundConditions"
                            defaultValue={edit === 'edit' ? (auctionItemObject.refundConditions) : ''}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            required
                            label="Warunki zwrotu kosztów"
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }

    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!form.refund && form.refund.toString()} name="refund">
                            <RadioGroup name="refund" defaultValue={edit === 'edit' && auctionItemObject.refund.toString()} onChange={(e) => {setBooleanValue(e); handleChange1(e)}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje możliwości zwrotu kosztów udziału w postępowaniu" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje możliwość zwrotu kosztów udziału w postępowaniu" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Refund;