import React, {useEffect, useState} from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from 'react-redux';
import {closeHint, closeHintMyAccount, closeHintPriorNotice, closeHintSecond} from "../state/hints/hintsActions";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    color: {
        backgroundColor: '#6415FF !important',
        fontSize: '0.6em'
    },
    root: {
        maxWidth: 600,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const Hints = (props) => {

    const dispatch = useDispatch();
    const classes = useStyles();


    const handleHintsClosing = () => {
        dispatch(closeHint(props.index));

    }


    return(
        <React.Fragment>
            <div className="hints"><Snackbar
            id="hint"
            className={props.class}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open
        ><MuiAlert
            action={
                <React.Fragment>
                    <IconButton
                        className={classes.close}
                        aria-label="close"
                        color="inherit"
                        onClick={handleHintsClosing}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
            severity='info'
            className={classes.color}
            elevation={6}
            variant="filled">
            {props.message}
        </MuiAlert>
        </Snackbar>
        </div>
        </React.Fragment>
    )
}

export default Hints