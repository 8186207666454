import React from 'react';
import SoloOrder from "../SoloOrder";



const EditSoloOrder = () => {



    const edit = true

    return(
        <SoloOrder edit={edit}/>
    )
}

export default EditSoloOrder;