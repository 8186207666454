import React, {useEffect, useRef, useState} from "react";
import {ContentWithPaddingMd} from "../components/misc/Layouts";
import tw from "twin.macro";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SectionHeading} from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import FormLabel from "@material-ui/core/FormLabel";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import OrderForm from "../components/OrderForm";
import OrderNameForm from "../components/OrderNameForm";
import UEFunding from "../components/UEFunding";
import FormControl from "@material-ui/core/FormControl";
import MarketCons from "../components/MarketCons";
import OrderType from "../components/OrderType";
import OrderMode from "../components/OrderMode";
import OrderValue from "../components/OrderValue";
import SidebarRightNonReg from "../components/sidebars/SidebarRightNonReg";
import SidebarLeftNonReg from "../components/sidebars/SidebarLeftNonReg";
import SidebarLeftAccount from "../components/sidebars/SidebarLeftAccount";
import SidebarRightAccount from "../components/sidebars/SidebarRightAccount";
import {useDispatch, useSelector} from "react-redux";
import {setValues} from "../state/auction/auctionActions";
import AdditionalInfo from "../components/AdditionalInfo";
import InitialOpFinished from "../components/InitialOpFinished";
import {patchInitialOpAuction} from "../services/patchInitialOpAuction";
import Swal from "sweetalert2";
import {postInitialOp} from "../services/postInitialOp";
import {patchInitialOp} from "../services/patchInitialOp";
import {postInitialOpAuction} from "../services/postInitialOpAuction";
import {
    fetchAuctionId,
    fetchAuctionItemId,
    fetchBipClientType1,
    fetchBipClientType2, fetchBipCoreBusiness, fetchCountry1, fetchDuueClientType, fetchDuueCoreBusiness,
    fetchVoivodeship
} from "../services/fetchId";
import Summary from "./Summary";
import {MenuItem} from "@material-ui/core";



const TwoColumnStep = tw.div`flex flex-col justify-center max-w-screen-xl`;
const Form = tw.div`mb-5 mx-5 mr-3`;
const Heading = tw(SectionHeading)`text-gray-800 text-4xl`;
const HeadingRow = tw.div`flex p-0! mb-24`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),

    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    input: {
        padding: '10px !important',
        width: '100% !important',
    },
    label: {
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'justify',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadio: {
        marginTop: '2%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    labelRadioM: {
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: '1em',
        textAlign: 'left',
        color: 'black',
        width: '100%',
        lineHeight: '1.4',
    },
    name: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
    },
    nameSec: {
        fontFamily: 'Inter !important',
        fontSize: '1.6em !important',
        fontWeight: 'bolder',
        color: '#935bff',
        textAlign: 'center',
        marginBottom: '6% !important',
        borderBottom: '1px solid black',
        padding: '3%'
    },
}));


const ProxyOrder = (props) => {

    const {params} = props.match;
    const [edit, setEdit] = useState(false);
    const [auction, setAuction] = useState();
    const [auctionItemId, setAuctionItemId] = useState();
    const [voivodeship, setVoivodeship] = useState([])
    const [country, setCountry] = useState([])

    useEffect(() => {

        fetchVoivodeship().subscribe(data => {
            setVoivodeship(data['hydra:member'])
        })

        fetchCountry1().subscribe(data => {
            setCountry(data['hydra:member'])
        })


    }, [])


    useEffect(() => {
        let check = [];
        fetchAuctionItemId().subscribe(
            data => {
                check = data['hydra:member'].filter(auction => auction.auction === localStorage.getItem('auctionRef'));
                console.log(check)
                if(check.length > 0){
                    fetchAuctionItemId().subscribe(
                        data => {
                            setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                        }
                    )
                }
            }
        )



        /*if(check === []){

        }else{
            fetchAuctionItemId().subscribe(
                data => {
                    setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                }
            )
        }*/

        /* if(!!localStorage.getItem('auctionRef')){
             fetchAuctionItemId().subscribe(
                 data => {
                     setAuctionItemId(data['hydra:member'].filter(auction => auction.auction.slice(14) === localStorage.getItem('auctionRef').slice(14))[0]['@id'].slice(19));
                 }
             )
         }*/
    }, [])

    useEffect(() => {
        fetchAuctionId().subscribe(
            data => {
                setAuction(data['hydra:member'].find(auction => auction['@id'].slice(14) === props.id))
            }
        )
    }, [])

    useEffect(() => {
        if (params.edit === 'edit'){
            setEdit(true);
        }
    }, [])

    const form = useSelector(state => state.auction.form);
    const dispatch = useDispatch();

    const setValue = (e) => {
        dispatch(setValues(e.target.name, e.target.value))
        console.log(form)
    }

    const classes = useStyles();

    function getSteps() {
        if(!edit){
            return ['DANE ZAMAWIAJĄCEGO', 'DANE PODMIOTU, KTÓREMU ZAMAWIAJĄCY POWIERZYŁ REALIZACJĘ ZAMÓWIENIA', 'NAZWA ZAMÓWIENIA', 'DOFINANSOWANIE UNIJNE', 'KONSULTACJE RYNKOWE', 'RODZAJ ZAMÓWIENIA', 'WARTOŚĆ ZAMÓWIENIA', 'WYBÓR TRYBU', 'INFORMACJE UZUPEŁNIAJĄCE', 'PODSUMOWANIE', 'ZAKOŃCZ'];
        }else if(edit){
            return ['DANE ZAMAWIAJĄCEGO', 'DANE PODMIOTU, KTÓREMU ZAMAWIAJĄCY POWIERZYŁ REALIZACJĘ ZAMÓWIENIA', 'NAZWA ZAMÓWIENIA', 'DOFINANSOWANIE UNIJNE', 'KONSULTACJE RYNKOWE', 'RODZAJ ZAMÓWIENIA', 'WARTOŚĆ ZAMÓWIENIA', 'WYBÓR TRYBU', 'INFORMACJE UZUPEŁNIAJĄCE', 'ZAKOŃCZ'];
        }

    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (<OrderForm handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>);
            case 1:
                return (
                    <React.Fragment>
                        <AnimationRevealPage>
                            <ValidatorForm>
                                {
                                    edit === true && !!auction
                                        ? (
                                <>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Nazwa Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia
                                    </FormLabel>
                                    <TextValidator
                                        defaultValue={auction.nameProxy}
                                        value={form.nameProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Nazwa Podmiotu"
                                        variant="outlined"
                                        placeholder="Nazwa Podmiotu"
                                        required
                                        name="nameProxy"/>
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Krajowy numer identyfikacji Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.kniProxy}
                                        value={form.kniProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Krajowy numer identyfikacji"
                                        variant="outlined"
                                        placeholder="Krajowy numer identyfikacji"
                                        required
                                        name="kniProxy"/>
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.labelRadio}>Numer NIP Podmiotu, któremu zamawiający powierzył realizację zamówienia</FormLabel>
                                    <TextValidator
                                        value={form.nipProxy}
                                        defaultValue={auction.nipProxy}
                                        name="nipProxy"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Numer NIP"
                                        variant="outlined"
                                        validators={['matchRegexp:^[0-9]{10}$']}
                                        errorMessages={['Wprowadź poprawny nymer (10 cyfr)']}
                                        required/>
                                </Form>
                                <Typography className={classes.nameSec}>Adres Podmiotu, któremu zamawiający powierzył realizację zamówienia</Typography>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Ulica</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.adressProxyStreet}
                                        value={form.adressProxyStreet}
                                        name="adressProxyStreet"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Ulica"
                                        variant="outlined"
                                        required
                                    />
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Miasto</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        value={form.adressProxyCity}
                                        defaultValue={auction.adressProxyCity}
                                        name="adressProxyCity"
                                        onChange={setValue}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Miejscowość"
                                        variant="outlined"
                                        required
                                    />
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Nr domu</FormLabel>
                                    <TextValidator
                                        value={form.adressProxyPlaceNo}
                                        defaultValue={auction.adressProxyPlaceNo}
                                        name="adressProxyPlaceNo"
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Nr domu"
                                        variant="outlined"
                                        required
                                    />
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                    <TextValidator
                                        value={form.adressProxyZipCode}
                                        defaultValue={auction.adressProxyZipCode}
                                        name="adressProxyZipCode"
                                        onChange={(e) => {setValue(e)}}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Kod pocztowy"
                                        variant="outlined"
                                        required
                                        validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                                        errorMessages={['Wprowadź poprawny format: xx-xxx']}
                                    />
                                </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Państwo
                                        </FormLabel>
                                        <TextValidator
                                            value={form.adressProxyCountry}
                                            defaultValue={auction.adressProxyCountry}
                                            className={classes.input}
                                            onChange={(e) => {setValue(e)}}
                                            id="outlined-basic-u"
                                            label="Państwo"
                                            variant="outlined"
                                            select
                                            name="adressProxyCountry"
                                        >
                                            {country.map((c, index) =>{
                                                    return <MenuItem key={index} value={c["@id"]}>{c.englishShortName}</MenuItem>
                                                }
                                            )}

                                        </TextValidator>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Województwo
                                        </FormLabel>
                                        <TextValidator
                                            value={form.adressProxyVoivodeship}
                                            defaultValue={auction.adressProxyVoivodeship}
                                            labelid="emo-simple-select-outlined-label"
                                            id="outlined-basic-u"
                                            className={classes.input}
                                            onChange={(e) => {setValue(e)}}
                                            label="Województwo"
                                            select
                                            required
                                            name="adressProxyVoivodeship"
                                            variant="outlined"
                                        >
                                            {voivodeship.map((c, index) =>{
                                                    return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                                }
                                            )}
                                        </TextValidator>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Lokalizacja NUTS
                                        </FormLabel>
                                        <TextValidator
                                            value={form.proxyNuts}
                                            defaultValue={auction.proxyNuts}
                                            className={classes.input}
                                            type="textnumber"
                                            onChange={(e) => {setValue(e)}}
                                            id="outlined-basic-u"
                                            label="kodNUTS"
                                            variant="outlined"
                                            placeholder="kodNUTS"
                                            required
                                            validators={['matchRegexp:^PL[0-9]*$']}
                                            errorMessages={['Wprowadź poprawny format: PL...']}
                                            name="proxyNuts"
                                        />
                                    </Form>

                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Numer telefonu Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.numberProxy}
                                        value={form.numberProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Numer telefonu"
                                        variant="outlined"
                                        placeholder="Numer telefonu"
                                        required
                                        name="numberProxy"/>
                                </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Numer faksu Podmiotu, któremu zamawiający
                                            powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.numberFaxProxy}
                                            defaultValue={auction.numberFaxProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer faksu"
                                            variant="outlined"
                                            placeholder="Numer faksu"
                                            required
                                            name="numberFaxProxy"/>
                                    </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Adres email Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.emailProxy}
                                        value={form.emailProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="email"
                                        id="outlined-basic-u"
                                        label="Adres email"
                                        variant="outlined"
                                        required
                                        name="emailProxy"/>
                                </Form>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Adres strony internetowej Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.urlProxy}
                                        value={form.urlProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Adres strony internetowej"
                                        variant="outlined"
                                        placeholder="Adres strony internetowej"
                                        required
                                        name="urlProxy"/>
                                </Form>
                                <Typography className={classes.nameSec}>Dodatkowe Informacje</Typography>
                                <Form className={classes.label}>
                                    <FormLabel className={classes.label}>Dodatkowe Informacje</FormLabel>
                                    <TextValidator
                                        defaultValue={auction.addInfoProxy}
                                        value={form.addInfoProxy}
                                        onChange={setValue}
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        label="Dodatkowe Informacje"
                                        variant="outlined"
                                        placeholder="Dodatkowe Informacje"
                                        name="addInfoProxy"/>
                                </Form>
                                </>) : (
                                    <>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nazwa Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia
                                        </FormLabel>
                                        <TextValidator
                                            value={form.nameProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Nazwa Podmiotu"
                                            variant="outlined"
                                            placeholder="Nazwa Podmiotu"
                                            required
                                            name="nameProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Krajowy numer identyfikacji Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.kniProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Krajowy numer identyfikacji"
                                            variant="outlined"
                                            placeholder="Krajowy numer identyfikacji"
                                            required
                                            name="kniProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.labelRadio}>Numer NIP Podmiotu, któremu zamawiający powierzył realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.nipProxy}
                                            name="nipProxy"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer NIP"
                                            variant="outlined"
                                            validators={['matchRegexp:^[0-9]{10}$']}
                                            errorMessages={['Wprowadź poprawny nymer (10 cyfr)']}
                                            required/>
                                    </Form>
                                    <Typography className={classes.nameSec}>Adres Podmiotu, któremu zamawiający powierzył realizację zamówienia</Typography>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Ulica</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyStreet}
                                            name="adressProxyStreet"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Ulica"
                                            variant="outlined"
                                            required
                                        />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Miasto</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            value={form.adressProxyCity}
                                            name="adressProxyCity"
                                            onChange={setValue}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Miejscowość"
                                            variant="outlined"
                                            required
                                        />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nr domu</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyPlaceNo}
                                            name="adressProxyPlaceNo"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Nr domu"
                                            variant="outlined"
                                            required
                                        />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyZipCode}
                                            name="adressProxyZipCode"
                                            onChange={(e) => {setValue(e)}}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Kod pocztowy"
                                            variant="outlined"
                                            required
                                            validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                                            errorMessages={['Wprowadź poprawny format: xx-xxx']}
                                            />
                                    </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Państwo</FormLabel>
                                            <TextValidator
                                                value={form.adressProxyCountry}
                                                className={classes.input}
                                                onChange={(e) => {setValue(e)}}
                                                id="outlined-basic-u"
                                                label="Państwo"
                                                variant="outlined"
                                                select
                                                name="adressProxyCountry"
                                            >
                                                {country.map((c, index) =>{
                                                        return <MenuItem key={index} value={c["@id"]}>{c.englishShortName}</MenuItem>
                                                    }
                                                )}

                                            </TextValidator>
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Województwo</FormLabel>
                                            <TextValidator
                                                value={form.adressProxyVoivodeship}
                                                labelid="emo-simple-select-outlined-label"
                                                id="outlined-basic-u"
                                                className={classes.input}
                                                onChange={(e) => {setValue(e)}}
                                                label="Województwo"
                                                select
                                                required
                                                name="adressProxyVoivodeship"
                                                variant="outlined"
                                            >
                                                {voivodeship.map((c, index) =>{
                                                        return <MenuItem key={index} value={c["@id"]}>{c.name}</MenuItem>
                                                    }
                                                )}
                                            </TextValidator>
                                        </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Lokalizacja NUTS
                                            </FormLabel>
                                            <TextValidator
                                                value={form.proxyNuts}
                                                className={classes.input}
                                                type="textnumber"
                                                onChange={(e) => {setValue(e)}}
                                                id="outlined-basic-u"
                                                label="kodNUTS"
                                                variant="outlined"
                                                placeholder="kodNUTS"
                                                required
                                                validators={['matchRegexp:^PL[0-9]*$']}
                                                errorMessages={['Wprowadź poprawny format: PL...']}
                                                name="proxyNuts"
                                            />
                                        </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Numer telefonu Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.numberProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer telefonu"
                                            variant="outlined"
                                            placeholder="Numer telefonu"
                                            required
                                            name="numberProxy"/>
                                    </Form>
                                        <Form className={classes.label}>
                                            <FormLabel className={classes.label}>Numer faksu Podmiotu, któremu zamawiający
                                                powierzył/powierzyli realizację zamówienia</FormLabel>
                                            <TextValidator
                                                value={form.numberFaxProxy}
                                                onChange={setValue}
                                                className={classes.input}
                                                type="text"
                                                id="outlined-basic-u"
                                                label="Numer faksu"
                                                variant="outlined"
                                                placeholder="Numer faksu"
                                                required
                                                name="numberFaxProxy"/>
                                        </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Adres email Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.emailProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="email"
                                            id="outlined-basic-u"
                                            label="Adres email"
                                            variant="outlined"
                                            required
                                            name="emailProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Adres strony internetowej Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.urlProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Adres strony internetowej"
                                            variant="outlined"
                                            placeholder="Adres strony internetowej"
                                            required
                                            name="urlProxy"/>
                                    </Form>
                                    <Typography className={classes.nameSec}>Dodatkowe Informacje</Typography>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Dodatkowe Informacje</FormLabel>
                                        <TextValidator
                                            value={form.addInfoProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Dodatkowe Informacje"
                                            variant="outlined"
                                            placeholder="Dodatkowe Informacje"
                                            name="addInfoProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nazwa Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia
                                        </FormLabel>
                                            <TextValidator
                                            value={form.nameProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Nazwa Podmiotu"
                                            variant="outlined"
                                            placeholder="Nazwa Podmiotu"
                                            required
                                            name="nameProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Krajowy numer identyfikacji Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.kniProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Krajowy numer identyfikacji"
                                            variant="outlined"
                                            placeholder="Krajowy numer identyfikacji"
                                            required
                                            name="kniProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.labelRadio}>Numer NIP Podmiotu, któremu zamawiający powierzył realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.nipProxy}
                                            name="nipProxy"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer NIP"
                                            variant="outlined"
                                            validators={['matchRegexp:^[0-9]{10}$']}
                                            errorMessages={['Wprowadź poprawny nymer (10 cyfr)']}
                                            required/>
                                    </Form>
                                    <Typography className={classes.nameSec}>Adres Podmiotu, któremu zamawiający powierzył realizację zamówienia</Typography>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Ulica</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyStreet}
                                            name="adressProxyStreet"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Ulica"
                                            variant="outlined"
                                            required
                                        />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Miasto</FormLabel>
                                        <TextValidator
                                            className={classes.input}
                                            value={form.adressProxyCity}
                                            name="adressProxyCity"
                                            onChange={setValue}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Miejscowość"
                                            variant="outlined"
                                            required
                                            />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Nr domu</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyPlaceNo}
                                            name="adressProxyPlaceNo"
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Nr domu"
                                            variant="outlined"
                                            required
                                        />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Kod pocztowy</FormLabel>
                                        <TextValidator
                                            value={form.adressProxyZipCode}
                                            name="adressProxyZipCode"
                                            onChange={(e) => {setValue(e)}}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Kod pocztowy"
                                            variant="outlined"
                                            required
                                            validators={['matchRegexp:^[0-9]{2}-[0-9]{3}$']}
                                            errorMessages={['Wprowadź poprawny format: xx-xxx']}
                                            />
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Numer telefonu lub faksu Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.numberProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Numer telefonu lub faksu"
                                            variant="outlined"
                                            placeholder="Numer telefonu lub faksu"
                                            required
                                            name="numberProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Adres email Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.emailProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="email"
                                            id="outlined-basic-u"
                                            label="Adres email"
                                            variant="outlined"
                                            required
                                            name="emailProxy"/>
                                    </Form>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Adres strony internetowej Podmiotu, któremu zamawiający
                                        powierzył/powierzyli realizację zamówienia</FormLabel>
                                        <TextValidator
                                            value={form.urlProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Adres strony internetowej"
                                            variant="outlined"
                                            placeholder="Adres strony internetowej"
                                            required
                                            name="urlProxy"/>
                                    </Form>
                                    <Typography className={classes.nameSec}>Dodatkowe Informacje</Typography>
                                    <Form className={classes.label}>
                                        <FormLabel className={classes.label}>Dodatkowe Informacje</FormLabel>
                                        <TextValidator
                                            value={form.addInfoProxy}
                                            onChange={setValue}
                                            className={classes.input}
                                            type="text"
                                            id="outlined-basic-u"
                                            label="Dodatkowe Informacje"
                                            variant="outlined"
                                            placeholder="Dodatkowe Informacje"
                                            name="addInfoProxy"/>
                                        </Form>
                                    </>
                                    )}




                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Wróć
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    type="submit"
                                >Dalej</Button>
                            </ValidatorForm>
                        </AnimationRevealPage>

                    </React.Fragment>
                );
            case 2:
                return (<OrderNameForm handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 3:
                return (<UEFunding handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 4:
                return (<MarketCons handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 5:
                return (<OrderType handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 6:
                return (<OrderValue handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 7:
                return (<OrderMode handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 8:
                return (<AdditionalInfo handleNext={handleNext} handleBack={handleBack} id={params.id} edit={edit}/>)
            case 9:
                if(!edit){
                    return (<Summary handleNext={handleNext} handleBack={handleBack}/>)}
            case 10:
                return (<InitialOpFinished
                    handleLast={handleLast}
                    onSubmit={
                        edit ? (
                            (formAuction, form) => {
                                patchInitialOpAuction(formAuction, localStorage.getItem('auctionRef').slice(14)).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {
                                            window.location.replace('/orderSpecification')}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                                patchInitialOp(form, auctionItemId).subscribe(
                                    Swal.fire({
                                            title: 'Sukces',
                                            text: `Czynności wstępne zostały zapisane.`,
                                            icon: 'success',
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#5a13e6',
                                            confirmButtonText: 'Przejdź dalej'
                                        },
                                    ).then(okay => {
                                        if (okay) {}}),
                                    error => {
                                        return Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: `Wprowadzono błędne dane !`,
                                        })
                                    },
                                );
                            }
                        ) : (
                            (formAuction, form) => {
                                postInitialOpAuction(formAuction).subscribe(
                                    (data) => {
                                        localStorage.setItem('auctionRef', data['@id']);
                                        postInitialOp({
                                            ...form,
                                            auction: data['@id']
                                        }).subscribe(
                                            Swal.fire({
                                                    title: 'Sukces',
                                                    text: `Czynności wstępne zostały zapisane.`,
                                                    icon: 'success',
                                                    showCancelButton: false,
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#5a13e6',
                                                    confirmButtonText: 'Przejdź dalej'
                                                },
                                            ).then(okay => {
                                                if (okay) {
                                                    window.location.replace('/orderSpecification')}}),
                                            error => {
                                                return Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: `Wprowadzono błędne dane !`,
                                                })
                                            },
                                        );
                                    }

                                )

                            }
                        )
                    }
                />)
            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleLast = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.location.replace('/orderSpecification')
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        console.log('changed step scrolling to top of window');
        window.scrollTo(0, 0)
    }, [activeStep])

    return(
        <React.Fragment>
            <SidebarRightAccount/>
            <SidebarLeftAccount/>
            <div className="content content-left">
                <AnimationRevealPage>
                    <ContentWithPaddingMd>
                        <HeadingRow >
                            <Heading>Informacje o zamówieniu</Heading>
                        </HeadingRow>
                    </ContentWithPaddingMd>
                    <div>
                        <Stepper className={classes.name} activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step className={classes.name}key={label}>
                                    <StepLabel><Typography className={classes.name}>{label}</Typography></StepLabel>
                                    <StepContent>
                                        <TwoColumnStep>
                                            <Typography>{getStepContent(index)}</Typography><br/><br/><br/>
                                        </TwoColumnStep>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>Proces zakończony pomyślnie</Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </div>
                </AnimationRevealPage>
            </div>
        </React.Fragment>
    )
}

export default ProxyOrder;