
import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import {fetchHint, fetchHint2} from "../../services/fetchId";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "../StackedSnackbar";
import {useDispatch, useSelector} from "react-redux";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Exclusion109 = ({form, setValue, setBooleanValue, edit, auctionItemObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        labelD: {
            marginBottom: '5%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionItemObject.exclusion1 === true && 2));
    const [addValue3, setAddValue3] = useState(edit === 'edit' && (auctionItemObject.exclusion2 === true && 2));
    const [addValue4, setAddValue4] = useState();
    const [isSector, setIsSector] = useState(false);
    const dispatch = useDispatch();

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint2().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[1].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    const handleChange1 = (e) => {
        if(e.target.value === 'false'){
            setAddValue1(1);
        }else if(e.target.value === 'true'){
            setAddValue1(2);
        }
    }

    const handleChange2 = (e) => {
        if(e.target.value === 'false'){
            setAddValue3(1);
        }else if(e.target.value === 'true'){
            setAddValue3(2);
        }
    }

    const handleChange4 = (e) => {
        if(e.target.value === 'false'){
            setAddValue4(1);
        }else if(e.target.value === 'true'){
            setAddValue4(2);
        }
    }

    let form1;
    let form3;
    let formSector;

    if(addValue4 === 2){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Wymagane podmiotowe środki dowodowe
                        </FormLabel>
                        <TextValidator
                            defaultValue={edit === 'edit' ? (auctionItemObject.exclusion4) : ''}
                            name="exclusion4"
                            value={form.exclusion4}
                            onChange={setValue}
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            label="Wymagane podmiotowe środki dowodowe "
                            variant="outlined"
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Form>
            </>
    }


    if(isSector === true){
        formSector =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="exclusion3" value={!!form.exclusion3 && form.exclusion3.toString()}>
                        <RadioGroup name="exclusion3" defaultValue={edit === 'edit' && auctionItemObject.exclusion3.toString()} onChange={(e) => {setBooleanValue(e); handleChange4(e)}}>
                            <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie zastosuje podstaw wykluczenia innych niż określony w art. 108 i art. 109" />
                            <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający zastosuje podstawy wykluczenia inne niż określone w art. 108 i art. 109" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }

    if (addValue1 === 1){
        form1 =
            <>

            </>
    }else if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Podstawy wykluczenia, o których mowa w art. 109 ust. 1
                        </FormLabel>
                        <TextValidator
                            className={classes.input}
                            type="text"
                            id="outlined-basic-u"
                            value={'Tekst wstawiony przez program'}
                            variant="outlined"
                            multiline
                            rows={5}
                            disabled
                        />
                    </FormControl>
                </Form>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!form.exclusion2 && form.exclusion2.toString()} name="exclusion2">
                        <RadioGroup name="exclusion2" defaultValue={edit === 'edit' && auctionItemObject.exclusion2.toString()} onChange={(e) => {setBooleanValue(e); handleChange2(e)}}>
                            <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie będzie badał, czy nie zachodzą wobec podwykonawcy niebędącego podmiotem udostępniającym zasoby podstawy wykluczenia" />
                            <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający będzie badał, czy nie zachodzą wobec podwykonawcy niebędącego podmiotem udostępniającym zasoby podstawy wykluczenia" />
                        </RadioGroup>
                    </FormControl>
                </Form>
            </>
    }

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!form.exclusion1 && form.exclusion1.toString()} name="exclusion1">
                            <RadioGroup name="exclusion1" defaultValue={edit === 'edit' && auctionItemObject.exclusion1.toString()} onChange={(e) => {setBooleanValue(e); handleChange1(e)}}>
                                <FormControlLabel value='false' control={<Radio color="primary"/>} label="Zamawiający nie przewiduje wykluczenia, o którym mowa w art. 109, ust. 1" />
                                <FormControlLabel value='true' control={<Radio color="primary"/>} label="Zamawiający przewiduje wykluczenie, o którym mowa w art. 109, ust. 1" />
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                    {formSector}

                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}


export default Exclusion109;