import React, {useEffect} from "react";
import { Route, Redirect} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setWidth} from "../state/main/mainActions";
import {closeSnackbar as closeSnackbarAction} from "../state/hints1/hintsActions";


const AppRoute = ({
                      component: Component,
                      layout: Layout,
                      isAuthProtected,
                      ...rest
                  }) => {

    const dispatch = useDispatch();
    useEffect(() =>
        window.addEventListener('resize', () => dispatch(setWidth(window.innerWidth))),
[])
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));


    return (
        <Route
            onChange={closeSnackbar()}
            {...rest}
            render={props => {
                console.log(props.location);
                if (isAuthProtected && (!localStorage.getItem("token"))){
                    console.log("im in ")
                    return <Redirect to={{ pathname: "/login" }} />
                }
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )
            }}
        />
    )
};

export default AppRoute;