import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import SuccessActivateAccountPage from './SuccessActivateAccountPage';
import ErrorActivateAccountPage from './ErrorActivateAccountPage';

const Verification = () =>{

    const [isVerifySuccess, setIsVerifySuccess] = useState(null);

    let { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');

    function checkVerify(){
        fetch(`${process.env.REACT_APP_HOST_VERIFY}/verify-user?token=${token}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( response => {
            response.json()
            if( response.status === 200){
                setIsVerifySuccess(true);
            }
            else{
                setIsVerifySuccess(false);
            }
        })
    }

    useEffect(() => {
        checkVerify();
    },[]);

            if (isVerifySuccess === true) {
                return(
                    <React.Fragment>
                    (
                        <SuccessActivateAccountPage/>
                    )
                    </React.Fragment>
                )
            } else if (isVerifySuccess === false) {
                return(
                    <React.Fragment>
                    (
                        <ErrorActivateAccountPage/>
                    ) 
                    </React.Fragment>
                )
            } else {
                return(
                    <React.Fragment>
                    </React.Fragment>
                )
            }
}

export default Verification;