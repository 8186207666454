import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {useDispatch, useSelector} from "react-redux";
import {
    setDatePartEndDate,
    setDatePartStartDate,
    setDateValueAuctionPart,
    setValuesAuctionParts
} from "../../state/auction/auctionActions";
import {
    closeSnackbar as closeSnackbarAction,
    enqueueSnackbar as enqueueSnackbarAction
} from "../../state/hints1/hintsActions";
import Button from "@material-ui/core/Button";
import {fetchHint} from "../../services/fetchId";
import CloseIcon from "@material-ui/icons/Close";
import StackedSnackbar from "../StackedSnackbar";

const Form = tw.div`mb-5 mx-5 mr-3`;

const DeadlinePartI = ({no, edit, auctionObject}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();

    const [dateE, setDateE] = React.useState(new Date());
    const [dateB, setDateB] = React.useState(new Date());
    const [addValue1, setAddValue1] = useState(edit === 'edit' && (auctionObject.deadline === 'Określony długością obowiązywania umowy' ? 1 : 2));
    const [addValue2, setAddValue2] = useState(edit === 'edit' && (auctionObject.deadline1 === 'Miesiącach' ? 1 : 2));
    const [addValue3, setAddValue3] = useState(edit === 'edit' && (auctionObject.deadlineDate === 'Rozpoczęcia' ? 1 : 2));

    const formAuctionParts = useSelector(state => state.auction.form.auctionItemParts);
    const dispatch = useDispatch();
    console.log(useSelector(state => state.auction.form))

    const setValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value, i));
        console.log(formAuctionParts);
    }

    const setDateValueAuctionParts = (e) => {
        dispatch(setDateValueAuctionPart(e));
        console.log(formAuctionParts);
    }

    const setBooleanValueAuctionParts = (e, i) => {
        dispatch(setValuesAuctionParts(e.target.name, e.target.value === 'true', i));
    }

    const isHintOn = useSelector(state => state.toggleHints.hints);
    const [show, setShow] = useState(true);
    const [hints, setHints] = useState();

    useEffect(() => {
        fetchHint().subscribe(
            data => {
                setHints(data['hydra:member'])
            }
        )
    }, [])

    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    const handleClick = () => {
        if(show && isHintOn && !!hints){
            enqueueSnackbar({
                message: <p>{hints[29].content}</p>,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}><CloseIcon style={{color: '#dd75e9'}}/></Button>
                    ),
                },
            });
            setShow(false);
        }
    };

    const handleDimissAll = () => {
        closeSnackbar();
    };

    const handleChange1 = (e) => {
        if(e.target.value === 'Określony długością obowiązywania umowy'){
            setAddValue1(1);
        }else if(e.target.value === 'Określony przy pomocy daty'){
            setAddValue1(2);
        }
    }

    const handleChange2 = (e) => {
        if(e.target.value === 'Miesiącach'){
            setAddValue2(1);
        }else if(e.target.value === 'Dniach'){
            setAddValue2(2);
        }
    }

    const handleChange3 = (e) => {
        if(e.target.value === 'Rozpoczęcia'){
            setAddValue3(1);
        }else if(e.target.value === 'Zakończenia'){
            setAddValue3(2);
        }
    }

    let form1;
    let form2;
    let form3;

    if(addValue3 === 1){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="startDate">
                        <FormLabel className={classes.label}>Wybierz datę rozpoczęcia:</FormLabel>
                        <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                            <DatePicker inputVariant="outlined" format="yyyy/MM/dd" value={dateB} onChange={(e) => {setDateB(e); dispatch(setDatePartStartDate(e, no))}}/>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Form>
            </>
    }else if (addValue3 === 2){
        form3 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} name="endDate">
                        <FormLabel className={classes.label}>Wybierz datę zakończenia:</FormLabel>
                        <MuiPickersUtilsProvider style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} utils={DateFnsUtils}>
                            <DatePicker inputVariant="outlined" format="yyyy/MM/dd" value={dateE} onChange={(e) => {setDateE(e); dispatch(setDatePartEndDate(e, no))}}/>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Form>
            </>
    }

    if(addValue2 === 1){
        form2 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Podaj okres określony w miesiącach:</FormLabel>
                        <TextValidator
                            value={formAuctionParts.deadlineMonths}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.deadlineMonths) : ''}
                            className={classes.input}
                            name="deadlineMonths"
                            type="number"
                            id="outlined-basic-u"
                            label="Okres określony w miesiącach"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
            </>
    }else if (addValue2 === 2){
        form2 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label}>
                        <FormLabel className={classes.label}>Podaj okres określony w dniach:</FormLabel>
                        <TextValidator
                            value={formAuctionParts.deadlineDays}
                            onChange={(e) => setValueAuctionParts(e, no)}
                            defaultValue={edit === 'edit' ? (auctionObject.deadlineDays) : ''}
                            name="deadlineDays"
                            className={classes.input}
                            type="number"
                            id="outlined-basic-u"
                            label="Okres określony w dniach"
                            variant="outlined"
                        />
                    </FormControl>
                </Form>
            </>
    }

    if (addValue1 === 1){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={formAuctionParts[no].deadline1.toString()} name="deadline1">
                        <FormLabel className={classes.label}>Okres określony zostanie w:</FormLabel>
                        <RadioGroup name="deadline1" defaultValue={edit === 'edit' && auctionObject.deadline1.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange2(e);}}>
                            <FormControlLabel value="Miesiącach" control={<Radio color="primary"/>} label="Miesiącach" />
                            <FormControlLabel value="Dniach" control={<Radio color="primary"/>} label="Dniach" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form2}
            </>
    }

    if (addValue1 === 2){
        form1 =
            <>
                <Form className={classes.label}>
                    <FormControl className={classes.label} value={!!formAuctionParts ? formAuctionParts[no].deadlineDate : ''} name="deadlineDate">
                        <FormLabel className={classes.label}>Termin określony przy pomocy daty:</FormLabel>
                        <RadioGroup name="deadlineDate" defaultValue={edit === 'edit' && auctionObject.deadlineDate.toString()} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange3(e);}}>
                            <FormControlLabel value="Rozpoczęcia" control={<Radio color="primary"/>} label="Rozpoczęcia" />
                            <FormControlLabel value="Zakończenia" control={<Radio color="primary"/>} label="Zakończenia" />
                        </RadioGroup>
                    </FormControl>
                </Form>
                {form3}
            </>
    }

    return (
        <div onMouseOver={handleClick}>
            <AnimationRevealPage>
                <StackedSnackbar onMouseOver={handleClick} onMouseLeave={handleDimissAll}/>
                <ValidatorForm>
                    <Form className={classes.label}>
                        <FormControl className={classes.label} value={!!formAuctionParts ? formAuctionParts[no].deadline : ''} name="deadline">
                            <FormLabel className={classes.label}>Termin wykonania zamówienia:</FormLabel>
                            <RadioGroup name="deadline" defaultValue={edit === 'edit' && auctionObject.deadline} onChange={(e) => {setBooleanValueAuctionParts(e, no); handleChange1(e)}}>
                                <FormControlLabel value="Określony długością obowiązywania umowy" control={<Radio color="primary"/>} label="Określony długością obowiązywania umowy"/>
                                <FormControlLabel value="Określony przy pomocy daty" control={<Radio color="primary"/>} label="Określony przy pomocy daty"/>
                            </RadioGroup>
                        </FormControl>
                    </Form>
                    {form1}
                </ValidatorForm>
            </AnimationRevealPage>
        </div>
    )
}


export default DeadlinePartI;