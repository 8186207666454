import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core/styles";
import {fetchAuctionId, fetchAuctionItemId} from "../../services/fetchId";


const Form = tw.div`mb-5 mx-5 mr-3`;

const Communication = ({auctionItemObject, edit}) => {

    const useStyles = makeStyles((theme) => ({
        input: {
            padding: '10px !important',
            width: '100% !important',
        },
        label: {
            marginBottom: '3%',
            fontSize: '1.1em',
            lineHeight: '1.4',
            textAlign: 'left',
            color: 'black',
            width: '100%',
        },
        nameSec: {
            fontFamily: 'Inter !important',
            fontSize: '1.6em !important',
            fontWeight: 'bolder',
            color: '#935bff',
            textAlign: 'center',
            marginBottom: '6% !important',
            borderBottom: '1px solid black',
            padding: '3%'
        },
        radio: {
            marginTop: '3%',
        }
    }));

    const classes = useStyles();




    return (
        <>
            <AnimationRevealPage>
                <ValidatorForm>
                        <>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Informacje o środkach komunikacji elektronicznej, przy użyciu których zamawiający będzie komunikował się z wykonawcami, oraz informacje o wymaganiach technicznych i organizacyjnych sporządzania, wysyłania i odbierania korespondencji elektronicznej:</FormLabel>
                                    <FormLabel className={classes.label}>Adres poczty elektronicznej, na który wykonawca prześle ofertę</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={auctionItemObject.emailOffer}
                                        variant="outlined"
                                        disabled/>
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Informacje o środkach komunikacji elektronicznej, przy użyciu których zamawiający będzie komunikował się z wykonawcami, oraz informacje o wymaganiach technicznych i organizacyjnych sporządzania, wysyłania i odbierania korespondencji elektronicznej:</FormLabel>
                                    <FormLabel className={classes.label}>Adres strony internetowej, na który wykonawca prześle ofertę</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={auctionItemObject.wwwOffer}
                                        variant="outlined"
                                        disabled/>
                                </FormControl>
                            </Form>
                            <Form className={classes.label}>
                                <FormControl className={classes.label}>
                                    <FormLabel className={classes.label}>Informacje o wymaganiach technicznych i organizacyjnych sporządzania, wysyłania i odbierania korespondencji elektronicznej, w tym informacje dotyczące wymogu użycia podpisu elektronicznego</FormLabel>
                                    <TextValidator
                                        className={classes.input}
                                        type="text"
                                        id="outlined-basic-u"
                                        value={auctionItemObject.emailRequirements}
                                        variant="outlined"
                                        disabled/>
                                </FormControl>
                            </Form>
                        </>
                </ValidatorForm>

            </AnimationRevealPage>
        </>
    )
}


export default Communication;