import {defaultState} from "./toggleHintsInitialState";
import {handleActions} from "redux-actions";

const toggleHintsReducer = handleActions({
        TOGGLE_HINTS: (state) => {
            console.log(state.hints)
            return(
            {
                ...state,
                hints: !state.hints
            }
        )}
    }
    , defaultState)

export default toggleHintsReducer;

